import { Col, Badge } from "react-bootstrap";
import { textVal, typeVal } from "../../utils";

const RenderStatus = ({isMobile, status}) => {
    return (
    <>
        {isMobile ?
        <>
            <Col xl={3} lg={3} md={6} sm={6}>
                <Badge bg={typeVal(status)}>{textVal(status)}</Badge>{" "}
            </Col>
        </>
        :
        <>
        <td>
            <Badge bg={typeVal(status)}>{textVal(status)}</Badge>{" "}
        </td>
        </>
        }
    </>
    );
};

export default RenderStatus;