import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { VscWorkspaceTrusted, VscWorkspaceUntrusted } from "react-icons/vsc";

const RenderModalCompany = ({ show, setShow, company, isMobile}) => {

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <h5 className="fyb_h5 text-center">Informacion de la compañía</h5>
        </Modal.Header>
        {company && (
          <Modal.Body>
            <Row>
              <label>Nombre de compañía</label>
              <h5>{company.name}</h5>
            </Row>
            <Row>
                <label>Verificada</label>
                <h5>{company.verified ? <VscWorkspaceTrusted /> : <VscWorkspaceUntrusted />}</h5>
            </Row>
            <Row>
              <label>Pagina Web</label>
              <h5>{company.website_url ? company.website_url : "-"}</h5>
            </Row>
            <Row>
              <Col>
                <label>Usuario de Twitter</label>
                <h5>{company.twitter_user ? company.twitter_user : "-"}</h5>
              </Col>
              <Col>
                <label>Facebook</label>
                <h5>{company.facebook_url ? company.facebook_url : "-"}</h5>
              </Col>
            </Row>
            <Row>
              <label>Código C-TPAT</label>
              <h5>{company.ctpat_code ? company.ctpat_code : "-"}</h5>
            </Row>
            <Row>
              <label>Numero de MC</label>
              <h5>{company.mc_code ? company.mc_code : "-"}</h5>
            </Row>
            <Row>
              <label>RFC</label>
              <h5>{company.rfc_code ? company.rfc_code : "-"}</h5>
            </Row>
            <Row>
              <label>Cantidad de días para realizar el pago</label>
              <h5>{company.default_days_to_pay} dias</h5>
            </Row>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default RenderModalCompany;