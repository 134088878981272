import { useState } from "react";
import { Container, Button } from "react-bootstrap";
import ModalTopLogoCompany from "../ModalTopLogoCompany";

const Info = ({ dataCompany }) => {
  const [modal, setModal] = useState(false);
  return (
    <>
    <Container>
      <h2 className="mt-2">Mi Compañia</h2>
      <hr />
      <Button variant="primary" className="text-white" type="submit" onClick={() => setModal(true)}>
        Subir logo de la compañía
      </Button>
      <br />
      <br />
      <p><b>Nombre: </b> {dataCompany?.name ?? "N/R"}</p>
      <p><b>Facebook: </b> {dataCompany?.facebook_url ?? "N/R"}</p>
      <p><b>Twitter: </b> {dataCompany?.twitter_user ?? "N/R"}</p>
      <p><b>Website: </b> {dataCompany?.website_url ?? "N/R"}</p>
      <p><b>CTPAT: </b> {dataCompany?.ctpat_code ?? "N/R"}</p>
      <p><b>DOT: </b> {dataCompany?.dot_code ?? "N/R"}</p>
      <p><b>MC: </b> {dataCompany?.mc_code ?? "N/R"}</p>
      <p><b>OEA: </b> {dataCompany?.oea_code ?? "N/R"}</p>
      <p><b>Días de pago por defecto: </b> {dataCompany?.default_days_to_pay ?? "N/R"}</p>
    </Container>
    <ModalTopLogoCompany state={modal} setState={setModal} />
    </>
  );
};

export default Info;
