import axios from "axios";
import { useContext } from "react";
import { BodegasContext } from "../context";
import toast from "react-hot-toast"; 

import { erroresCodes } from "../utils";

const useFetch = () => {
  const { session } = useContext(BodegasContext);

  const fletesAPI = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: { "Content-Type": "application/json", AUTHORIZATION: session },
  });

  const fletesAPIFile = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: { "Content-Type": "multipart/form-data", AUTHORIZATION: session },
  });

  const fletesAPINotURL = axios.create({
    baseURL: "",
    headers: { "Content-Type": "application/json", AUTHORIZATION: session },
  });

  const errorInterceptor = (error) => {
    const requestURL = error.config?.url || "URL desconocida";
    const errorMessage = error.message || "Error desconocido";
    if (error.response?.status === 403) {
      window.location.href = "/dashboard/forbidden";
      return;
    }
    if (error.response?.data?.error_code) {
      if (error.response?.data?.error_code !== 118) {
        toast.error(erroresCodes(error.response?.data?.error_code), {
          duration: 5000,
        });
      }
    } else {
      if (error.code === "ECONNABORTED") {
        toast.error(
          "La solicitud ha tardado demasiado en responder. Por favor, inténtalo nuevamente.",
          {
            duration: 5000,
          }
        );
      } else if (!error.response) {
        toast.error("No tienes conexión a internet. Verifica tu red.", {
          duration: 5000,
        });
      } else if (error.response) {
        const statusCode = error.response?.status || "Desconocido";
        toast.error(`Error ${statusCode}: ${requestURL} - ${errorMessage}`, {
          duration: 5000,
        });
      }
    }

    return Promise.reject(error);
  };

  fletesAPI.interceptors.response.use((response) => response, errorInterceptor);

  fletesAPIFile.interceptors.response.use((response) => response, errorInterceptor);

  fletesAPINotURL.interceptors.response.use((response) => response, errorInterceptor);

  return {
    fletesAPI,
    fletesAPINotURL,
    fletesAPIFile,
  };
};

export default useFetch;
