import React, { useState } from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import ModalDelete from "../MyTrucksById/ModalDeleteAppointment";

export const Appointment = ({ order, update }) => {
  const [showModal, setShowModal] = useState(false);

  console.log(order);
  

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div>
              <Card.Title>{order?.title}</Card.Title>
              <Card.Text>{order?.description ?? "Descripción"}</Card.Text>
              <Card.Text>
                {moment(order?.start_date).format("DD/MM/YYYY HH:mm")} a{" "}
                {moment(order?.end_date).format("DD/MM/YYYY HH:mm")}
              </Card.Text>
            </div>
            <div>
              <button
                className="bg-danger btn text-white"
                onClick={handleShowModal}
              >
                <MdDelete />
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <ModalDelete
        show={showModal}
        handleClose={handleCloseModal}
        dataDelete={order}
        update={update}
        id={order?.id}
      />
    </>
  );
};
