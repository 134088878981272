import React, { useContext } from "react";
import { BodegasContext } from "../../context";
import { sessionType } from "../../context/reducers/index";
import { Form as Formu, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { erroresCodes } from "../../utils";

const styles = {
  container: {
    height: "800px",
    background: "#3E85C6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputCont: {
    position: "relative",
    cursor: "pointer",
  },
  inputEye: {
    position: "absolute",
    right: "5px",
    top: "0",
    bottom: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "120px",
  },
};

const resetPasswordValidation = yup.object().shape({
  password: yup.string().required(),
  passwordConfirm: yup.string().required(),
});

export default function Form() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [userId, setUserId] = React.useState(searchParams.get("user_id"));

  const { dispSession } = useContext(BodegasContext);

  const [token, setToken] = React.useState(searchParams.get("token"));

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resetPasswordValidation),
  });

  const onSubmit = (data) => {
    changePassword(data);
  };

  const changePassword = async (data) => {
    data["user_id"] = userId;
    data["token"] = token;
    let url = process.env.REACT_APP_URL_API + "api/users/" + userId + "/recover_with_token/";
    await axios
      .post(url, data)
      .then((e) => {
        toast.success("Tu contraseña ha sido actualizada, ahora puedes hacer iniciar sesion", {
          duration: 4000,
        });
        dispSession({ type: sessionType.setSession, session: e.data.session });
      })

      .catch((e) => {
        if (e.request.status == 500) {
          toast.error("Error en Fletes & Bodegas, favor de contactar soporte@fletesybodegas.com");
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };

  return (
    <>
      <Toaster />
      <div style={styles.container}>
        <Formu className="bg-light p-4 rounded" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <h2>Olvidaste tu contraseña?</h2>
            </Col>
          </Row>
          <Row>
            <Col className="my-4">
              <h4 className="text-primary">Paso 3 de 3</h4>
              <p>Recuperemos la cuenta</p>
            </Col>
          </Row>
          <Row>
            <Formu.Group className="mb-3" controlId="formGroupPassword">
              <Formu.Label>Contraseña</Formu.Label>
              <Formu.Control
                type="password"
                placeholder="Contraseña"
                {...register("password")}
                isInvalid={errors.password ? true : false}
              />
            </Formu.Group>
          </Row>

          <Row>
            <Formu.Group className="mb-3" controlId="formGroupPasswordConfirm">
              <Formu.Label>Confirma tu contraseña</Formu.Label>
              <Formu.Control
                type="password"
                placeholder="Confirma tu contraseña"
                {...register("passwordConfirm")}
                isInvalid={errors.passwordConfirm ? true : false}
              />
            </Formu.Group>
          </Row>
          <Row>
            <Col>
              <Button className="text-light w-100" type="submit">
                Cambiar la contraseña
              </Button>
            </Col>
          </Row>
        </Formu>
      </div>
    </>
  );
}
