import { useState } from "react";
import styled from "styled-components";
import { MdMoreVert } from "react-icons/md";

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  color: #323232;
  position: relative;
  &:focus {
    outline: none;
  }
`;
const Options = styled.div`
  display: ${props => props.state ? 'block' : 'none'};
  border: 1px solid lightgray;
  background: white;
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 999;
  min-width: 130px;
  padding: 10px 3px ;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  .option{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    border-radius: 3px;
    font-weight: 500;
    color: #323232;
    svg{
      margin-left: 5px;
    }
    &:hover{
      background: #7cc0ff;
    }
  }
`;

const RenderButtonMore = ({ buttons = [] }) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <Button onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)} >
      <MdMoreVert />
      <Options state={showOptions} >
        {buttons.map(({label, onClick, disable}, i) => (
          !disable ?
          <div className="option" key={i} onClick={onClick}>
            {label}
          </div>
          :
          <></>
        ))}
      </Options>
    </Button>
  );
};
export default RenderButtonMore;
