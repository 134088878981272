import React from 'react'
import styled from "styled-components";

const SwitchCont = styled.div`
  width: 220px;
  height: 50px;
  border-radius: 30px;
  background-color: #EBEBEB;
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .rowSw{
    display: flex;
    width: 100%;
    .colSwTr{
      background-color: #083D6E;
      height: 100%;
      width: 52%;
      position: absolute;
      border-radius: 30px;
      left: ${(e) => e.state ? '48%' : '0%'};
      transition: 0.2s;
    }
    .colSw{
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: center;
      z-index: 2;
      p{
        margin: 0;
        font-size: 13px;
        font-weight: 500;
      }
      .p1{
        color: ${(e) => e.state ? 'var(--bs-primary)' : 'var(--bs-light)'};
      }
      .p2{
        color: ${(e) => e.state ? 'var(--bs-light)' : 'var(--bs-primary)' };
        
      }
    }
  }
`;

export default function Switch({state = false, onClick}) {
  return (
    <SwitchCont state={state} onClick={onClick}>
      <div className="rowSw">
        <div className="colSwTr"></div>
        <div className="colSw">
          <p className='p1'>Mensual</p>
        </div>
        <div className="colSw">
          <p className='p2'>Anual</p>
        </div>
      </div>
    </SwitchCont>
  )
}
