import { erroresCodes } from "../utils";

export const getDrivers = async ({
  setData,
  setLoading,
  companyId,
  fletesAPI,
}) => {
  await fletesAPI
    .get(`api/company/${companyId}/members/drivers/`)
    .then((res) => {
      setData(res.data);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export const patchDetailsDriver = async ({
  fletesAPI,
  order_id,
  dataFetch,
  toast,
}) => {
  await fletesAPI
    .patch(`api/freightpost/${order_id}/driver/`, dataFetch)
    .then(() => {
      toast.success("Conductor asignado correctamente", 800);
    })
    .catch((e) => {
      toast.error(erroresCodes(e.response.data.error_code), 800);
    });
};

export const patchDetailsDestination = async ({
  order_id,
  fletesAPI,
  toast,
  dataFetch,
}) => {
  await fletesAPI
    .patch(`api/freightpost/${order_id}/detail/destination/`, dataFetch)
    .then(() => {
      toast.success("Detalles actualizados correctamente", 800);
    })
    .catch((e) => {
      toast.error(erroresCodes(e.response.data.error_code), 800);
    });
};

export const patchDetailsOrigin = async ({
  dataFetch,
  fletesAPI,
  order_id,
  toast,
}) => {
  await fletesAPI
    .patch(`api/freightpost/${order_id}/detail/origin/`, dataFetch)
    .then(() => {
      toast.success("Detalles actualizados correctamente", 800);
    })
    .catch((e) => {
      toast.error(erroresCodes(e.response.data.error_code), 800);
    });
};

export const patchDetailsStatus = async ({
  dataFetch,
  fletesAPI,
  order_id,
  toast,
}) => {
  await fletesAPI
    .patch(`api/freightpost/${order_id}/detail/`, dataFetch)
    .then(() => {
      toast.success("Detalles actualizados correctamente", 800);
    })
    .catch((e) => {
      toast.error(erroresCodes(e.response.data.error_code), 800);
    });
};
