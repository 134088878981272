import DoneIcon from "./DoneIcon";

const Step = ({ title, status = "pending", onClick = () => null, last = false }) => {
  return (
    <div className="step">
      <div className="step_button-cont" onClick={onClick}>
        <span className={`step_text t-${status}`}>{title}</span>
        <div className={`step_circle-${status}`}>
          {status === "done" && <DoneIcon className={"icon"} />}
        </div>
      </div>
      {!last && (
        <div className="step_line">
          <div className={`step_line_progress-${status}`} />
        </div>
      )}
    </div>
  );
};

export default Step;
