import { Col, Badge } from "react-bootstrap";
import { textInvit, typeInvit } from "../../utils";

const RenderInviteStatus = ({isMobile, status}) => {
    return(
        <>
        {isMobile ?
        <>
            <Col xl={3} lg={3} md={6} sm={6}>
                <Badge bg={typeInvit(status)}>{textInvit(status)}</Badge>{" "}
            </Col>
        </>
        :
        <>
        <td>
            <Badge bg={typeInvit(status)}>{textInvit(status)}</Badge>{" "}
        </td>
        </>
        }
    </>
    );
};

export default RenderInviteStatus;