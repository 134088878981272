import { useState, useContext, useEffect } from "react";
import { Row, Col, Button, Spinner, Container } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import { RiAddLine } from "react-icons/ri";
import { BodegasContext } from "../../context";
import ModalFormAdd from "./TruckLinePost/ModalFormAdd";
import toast from "react-hot-toast";
import RenderMyTrucks from "../../fyb_components/results/RenderMyTrucks";
import RenderSpace from "../../fyb_components/results/RenderSpace";
import { checkMobile } from "../../utils";

export default function Trucks() {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showClone, setShowClone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(null);
  const [bedTypes, setBedTypes] = useState(null);
  const [points, setPoints] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const { companyId } = useContext(BodegasContext);
  const [modalUDT, setModalUDT] = useState(false);
  const [modalClone, setModalClone] = useState(false);
  const [isMobile, setIsMobile] = useState(null);

  const reloadOrders = async (e) => {
    var ur;
    switch (e) {
      case null:
        ur = null;
        break;
      case "init":
        ur =
          process.env.REACT_APP_URL_API +
          `api/trucklinepost/?company_id=${companyId}`;
        break;
      default:
        ur = e;
        break;
    }
    if (ur != null) {
      setLoading(true);
      await fletesAPINotURL
        .get(ur)
        .then((i) => {
          setOrders(i.data);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          setLoading(false);
        })
        .catch((i) => {
          console.log(i);
          setLoading(false);
        });
    }
  };

  const getBedTypes = async () => {
    await fletesAPI
      .get(`api/freightbedoptions/`)
      .then((e) => {
        setBedTypes(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPoints = async () => {
    await fletesAPI
      .get(`api/pointofcontact/?company_id=${companyId}`)
      .then((e) => {
        setPoints(e.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (!points) {
    getPoints();
  }

  useEffect(() => {
    showUpdate
      ? setModalUDT(true)
      : setTimeout(() => {
          setModalUDT(false);
        }, 200);
  }, [showUpdate]);

  useEffect(() => {
    showClone
      ? setModalClone(true)
      : setTimeout(() => {
          setModalClone(false);
        }, 200);
  }, [showClone]);

  useEffect(() => {
    checkMobile(setIsMobile);
  }, [isMobile]);

  useEffect(() => {
    if (orders == null) {
      reloadOrders("init");
    }
  }, [orders]);

  const functionButton = () => {
    getBedTypes();
    getPoints();
    if (points.length == 0) {
      toast.error(
        "No tienes puntos de contacto, primero crea 1 punto de contacto usando el menu de la izquierda que dice puntos de contacto para cargas y camiones",
        30000
      );
      setShowAdd(false);
    } else {
      setShowAdd(true);
    }
  };

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Camiones Libres De Mi Compañia</h1>
          <Button
            onClick={functionButton}
            variant="primary"
            className="text-white"
            style={{ width: "200px" }}
          >
            <RiAddLine /> Agregar carga
          </Button>
        </Row>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <RenderMyTrucks
            orders={orders}
            title={""}
            isMobile={isMobile}
            reloadOrders={reloadOrders}
          />
        )}
        <ModalFormAdd
          show={showAdd}
          setShow={setShowAdd}
          getMore={reloadOrders}
          bedTypes={bedTypes}
          points={points}
        />
      </Container>
    </>
  );
}
