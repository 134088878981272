const RenderWhatsappContent = ({order}) => {
    return (
        <>
        {order.point_of_contact.preference_reach_by_whatsapp ?
            <a target="_blank" rel="noreferrer"
            href={`https://wa.me/${order.point_of_contact.phone}?text=` + encodeURIComponent(`Hola me interesa tu carga con numero de referencia ${order.internal_reference_number} con origen ${order.origin_city.name}, ${order.origin_city.state.name}, ${order.origin_city.state.country.name}, con destino a ${order.destination_city.name}, ${order.destination_city.state.name}, ${order.destination_city.state.country.name}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`)}>
                <img src="https://fletesandbodegas-prod.sfo3.digitaloceanspaces.com/whatsapp_logo.png" class="whatsapp_logo" />
             </a>
             : ""}
        </>
    );
};

export default RenderWhatsappContent;