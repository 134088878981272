import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast, { Toaster } from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import { erroresCodes } from "../../../utils/errorCodes";
import { addNewExpenses } from "../../../utils/formsValidation";
import RenderDropFile from "../RenderDropFile";
import RenderSelect from "../RenderSelect";

const TypesExpenses = [
  { value: "Combustible", label: "Combustible" },
  { value: "Alimento", label: "Alimento" },
  { value: "Hospedaje", label: "Hospedaje" },
  { value: "Mantenimiento", label: "Mantenimiento" },
  { value: "Reparación", label: "Reparación" },
  { value: "Otros", label: "Otros" },
];

const RenderModalAddExpenses = ({ state, setState, id, getMore }) => {
  const { fletesAPI, fletesAPIFile } = useFetch();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(addNewExpenses),
  });

  const onSubmit = (e) => {
    registerExpenses(e);
  };

  const addExpensesAttachment = async (data, expenseId) => {
    let formData = new FormData();
    formData.append("file_name", data.file_name);
    formData.append("type", data.type.value);
    formData.append("expense_id", expenseId);
    formData.append("file_content", data.file_content[0]);
    await fletesAPIFile
      .post(`api/freightpost/${id}/expense/attachment/`, formData)
      .then((e) => {
        toast.success("Se ha guardado el archivo correctamente");
        getMore();
        setState(false);
        reset();
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error("Ha ocurrido un error");
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };

  const registerExpenses = async (data) => {
    await fletesAPI
      .post(`api/freightpost/${id}/expense/`, {
        cost: data.cost,
        type: data.type.value,
        comment: data.comment,
      })
      .then((e) => {
        toast.success("Gasto agregado correctamente", 800);
        addExpensesAttachment(data, e.data.id);

        console.log(e);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
      });
  };
  return (
    <>
      <Toaster />
      <Modal show={state} onHide={() => {setState(false); reset()}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Añadir gasto</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group controlId="comment" className="mb-4">
                  <Form.Label>Comentario</Form.Label>
                  <Form.Control
                    {...register("comment")}
                    isInvalid={errors.comment}
                    type="text"
                    placeholder="Comentario"
                  />
                  <Form.Text className="text-danger position-absolute">
                    {errors.comment && errors.comment.message}
                  </Form.Text>
                </Form.Group>
                <RenderSelect
                  control={control}
                  name="type"
                  label="Tipo de gasto"
                  placeholder="Seleccione tipo de gasto"
                  options={TypesExpenses}
                  error={errors.type && errors.type.message}
                />
                <Form.Group controlId="cost" className="mb-4">
                  <Form.Label>Costo</Form.Label>
                  <Form.Control
                    {...register("cost")}
                    isInvalid={errors.cost}
                    type="number"
                    placeholder="costo"
                  />
                  <Form.Text className="text-danger position-absolute">
                    {errors.cost && errors.cost.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Form.Group controlId="file_name" className="mb-5">
                  <Form.Label>Nombre del archivo</Form.Label>
                  <Form.Control
                    {...register("file_name")}
                    isInvalid={errors.file_name}
                    type="text"
                    placeholder="Nombre del archivo"
                  />
                  <Form.Text className="text-danger position-absolute">
                    {errors.file_name && errors.file_name.message}
                  </Form.Text>
                </Form.Group>
                <RenderDropFile register={register} name="file_content" watch={watch} setValue={setValue} />
                <Form.Text className="text-danger">
                  {errors.file_content && errors.file_content.message}
                </Form.Text>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="success">
              Agregar
            </Button>
            <Button variant="danger" onClick={() => {setState(false); reset() }}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RenderModalAddExpenses;
