import { testJSON, days } from "../../utils";
import { Accordion } from "react-bootstrap";

const day = (e) => {
  const time =  e.enabled ? `${e.start} - ${e.end}` : "Cerrado";
  return time 
}

const dayCT = (e) => {
  const time =  e.enabled ? `abierto de ${e.start} hrs a ${e.end} hrs` : "Cerrado";
  return time 
}

const getFormattedHours = (e, data) => {
  return data[e.name] ? `${e.label}: ${day(data[e.name])}` : "no seleccionado"
}  
const getFormattedHoursCT = (e, data) => {
  return data[e.name] ? `Hoy ${e.label} ${dayCT(data[e.name])}` : "no seleccionado"
}
const RenderHours = ({ hours }) => {
  const hoursCL = testJSON(hours) ? JSON.parse(hours) : null;


  let hoy = new Date();
  let dayHoy = hoy.getDay();
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{ hoursCL ? getFormattedHoursCT(days[dayHoy], hoursCL) : "Horario no disponible" }</Accordion.Header>
          <Accordion.Body>
            {hoursCL ?  days.map((e, i) => (
              <p key={i}>
                {getFormattedHours(e, hoursCL)}
              </p>
            )) : "Horario no seleccionado"}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default RenderHours;
