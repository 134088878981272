import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useFetch from "../../../hooks/useFetch";
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";

const searchEmailValidation = yup.object().shape({
  email: yup.string().email().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
});

export default function ModalFormAdd({ show, setShow, getMore }) {
  const { fletesAPI } = useFetch()
  const sendInvit = async (data) => {
    await fletesAPI.post(`api/users/invite/`, data)
    .then((e) => {
      getMore("init");
      setShow(false);
      toast.success(mensajesAPI("invitation_success_sent"));
      reset();
    })
    .catch((e) => {
      console.log(e);
    });
  };
  
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(searchEmailValidation),
  });
  const onSubmit = (data) => {
    sendInvit(data);
  };

  return (
    <> 
      <Toaster />
      <Modal show={show} onHide={() => {setShow(false); reset()}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Invitar un nuevo miembro</h5>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  {...register("first_name")}
                  isInvalid={errors.first_name}
                />
                { errors.first_name && <p className="dashboard_form_error_message">{mensajesFormulario("first_name_required")}</p> }
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Apellido"
                  {...register("last_name")}
                  isInvalid={errors.last_name}
                />
                { errors.last_name && <p className="dashboard_form_error_message">{mensajesFormulario("last_name_required")}</p> }
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Correo electrónico"
                  {...register("email")}
                  isInvalid={errors.email}
                />
                { errors.email && <p className="dashboard_form_error_message">{mensajesFormulario("email_required")}</p> }
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" size="sm" type="submit">
              Invitar
            </Button>
            <Button variant="danger" size="sm" onClick={() => {setShow(false); reset()}}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
