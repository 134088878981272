import React from "react";
import { Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import { mensajesAPI } from "../../../utils/mensajes.js";

export default function ModalDelete({ show, setShow, data, getMore }) {
  const { fletesAPI } = useFetch();
  const deletePointOfContact = async () => {
    await fletesAPI
      .delete(`api/pointofcontact/${data.id}/`)
      .then(() => {
        setShow(false);
        getMore("init");
        toast.success(mensajesAPI("point_of_contact_deleted_success"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <h6>¿Estás seguro de eliminar este punto de contacto?</h6>
          <p>{data && `${data.first_name} ${data.last_name} | ${data.email}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" size="sm" onClick={() => deletePointOfContact()}>
            Eliminar
          </Button>
          <Button variant="danger" size="sm" onClick={() => setShow(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
