export const mensajesAPI = (e) => {
  switch (e) {
    case "toast_success_saving_message":
      return "Tus cambios han sido guardados con exito.";
    case "toast_success_register_user":
      return "Se registro correctamente el usuario!";
    case "toast_success_user_added_to_company":
      return "Se agrego con exito al usuario a tu compañia";
    case "toast_generic_error":
        return "Error en Fletes & Bodegas, favor de contactar soporte@fletesybodegas.com";
    case "user_not_found":
        return "No se encontro el usuario, intente mejor registrarlo";
    case "user_success_deleted":
        return "El usuario ha sido removido de la compañia";
    case "invitation_success_generated":
        return "Invitación generada con exito, lista para ser pegada";
    case "invitation_success_sent":
        return "La invitación a sido enviada al correo electrónico"
    case "point_of_contact_deleted_success":
        return "El punto de contacto se elimino con exito";
    case "point_of_contact_created_success":
        return "El punto de contacto fue creado con exito";
    case "truck_added_success":
        return "Se agrego con exito el camion";
    case "reverified_email_sent":
        return "Se envio un correo electrónico para verificar tu cuenta, checa tu correo electrónico";
    default:
      return "error";
  }
};

export const mensajesFormulario = (e) => {
    switch(e){
        case "first_name_required":
            return "El nombre es requerido";
        case "last_name_required":
            return "El apellido es requerido";
        case "phone_invalid_format":
            return "El telefono debe ser en formato +1 555 555555";
        case "address_line1_required":
            return "La dirección es requerida";
        case "zip_code_required":
            return "El código postal es requerido en formato 12345";
        case "city_required":
            return "La ciudad es requerida";
        case "email_required":
            return "El correo es requerido, ejemplo: alfonso@gmail.com";
        case "password_required":
            return "La contraseña es requerida, almenos 6 digitos";
        case "password_must_match":
            return "Las contraseñas deben ser las mismas";
        case "point_of_contact_required":
            return "El punto de contacto es requerido";
        case "bed_type_required":
            return "Selecciona almenos un tipo de caja";
        case "ready_pickup_date_required":
            return "Selecciona el dia de departura del camion";
        case "weight_required":
            return "Porfavor introduce el peso";
        case "internal_reference_number_required":
            return "Porfavor introduce tu numero de referencia interna";
        case "comments_required":
            return "Porfavor introduce si require permisos, notas especiales, etc.";
        case "positive_number":
            return "El ";
        default:
            return "error";
    }
};