import { useState } from "react";
import { Pagination, Row, Col, Table, Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import RenderEmailContent from "../../fyb_components/results/RenderEmailContent";
import RenderPhoneContent from "../../fyb_components/results/RenderPhoneContent";
import RenderUserCompanyRole from "../../fyb_components/results/RenderUserCompanyRole";
import ModalDelete from "../../components/Dashboard/Users/ModalDelete";
import moment from "moment";
import RenderVerifyEmail from "./RenderVerifyEmail";

const RenderMyUsers = ({ users, isMobile, reloadUsers }) => {
  const [showDel, setShowDel] = useState(false);
  const [dataDelete, setDelete] = useState(null);

  return (
    <>
      {isMobile ? (
        <>
          {users &&
            users.results.map((e, i) => {
              return (
                <div className="v2_dashboard_body_box" key={i}>
                  <Row>
                    <Col md={6} sm={6}>
                      <p>{moment(e.created).format("DD/MM/YYYY")}</p>
                    </Col>
                    {e.user.profile.first_name && (
                      <Col md={6} sm={6}>
                        <p>
                          {e.user.profile.first_name} {e.user.profile.last_name}
                        </p>
                      </Col>
                    )}
                    {e.user.profile.position_title && (
                      <Col md={6} sm={6}>
                        <p>{e.user.profile.position_title}</p>
                      </Col>
                    )}

                    <Col md={6} sm={6}>
                      <RenderPhoneContent phone={e.user.profile.phone} />
                    </Col>
                    <Col md={6} sm={6}>
                      <RenderEmailContent
                        mailto={e.user.email}
                        label={e.user.email}
                      />
                    </Col>
                    <RenderUserCompanyRole isMobile={isMobile} role={e.role} />
                  </Row>
                  <Row>
                    <Col xl={3} lg={3} md={6} sm={6}>
                      {parseInt(e.role) !== 0 && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setShowDel(true);
                            setDelete(e);
                          }}
                        >
                          <MdDelete />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
        </>
      ) : (
        <>
          <Row>
            <Col>
              {users && users.results.length > 0 ? (
                <>
                  <Table
                    striped
                    bordered
                    className="dashboard_main_container_table"
                  >
                    <thead>
                      <tr>
                        <td>Fecha de ingreso</td>
                        <td>Nombre</td>
                        <td>Apellidos</td>
                        <td>Correo Electrónico</td>
                        <td>Verificado</td>
                        <td>Telefono</td>
                        <td>Titulo</td>
                        <td>Rol</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.results.map((e, i) => {
                          return (
                            <tr key={i}>
                              <td>{moment(e.created).format("DD/MM/YYYY")}</td>
                              <td>{e.user.profile.first_name}</td>
                              <td>{e.user.profile.last_name}</td>
                              <td>{e.user.email}</td>
                              <RenderVerifyEmail
                                verify={e.user.profile.verified}
                              />
                              <td>
                                <RenderPhoneContent
                                  phone={e.user.profile.phone}
                                />
                              </td>
                              <td>{e.user.profile.position_title}</td>
                              <td>
                                <RenderUserCompanyRole
                                  isMobile={isMobile}
                                  role={e.role}
                                />
                              </td>
                              <td>
                                {parseInt(e.role) !== 0 && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => {
                                      setShowDel(true);
                                      setDelete(e);
                                    }}
                                  >
                                    <MdDelete />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => {
                        reloadUsers(users.previous);
                      }}
                      disabled={users.previous === null}
                    />
                    <Pagination.Next
                      onClick={() => {
                        reloadUsers(users.next);
                      }}
                      disabled={users.next === null}
                    />
                  </Pagination>
                </>
              ) : (
                <>
                  <br />
                  <h3 className="text-center fyb_h2">
                    No se encontraron usuarios
                  </h3>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
      <ModalDelete
        show={showDel}
        setShow={setShowDel}
        dataDelete={dataDelete}
        getMore={reloadUsers}
      />
    </>
  );
};

export default RenderMyUsers;
