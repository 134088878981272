import { Button, Container, Row, Card, ListGroup, ListGroupItem, Badge } from "react-bootstrap";
import { fullNameCity } from "../../../utils";
import moment from "moment";

import imageDefault from "../../../assets/images/profile-placeholder.jpg";
const QuotesList = ({ restart, finishQuote }) => {
  return (
    <Container>
      <h1>Cotizaciones</h1>
      {finishQuote.map((quote) => (
        <Card className="position-relative mb-3 d-flex justify-content-center align-items-center" key={quote.id} style={{minHeight: 180}}>
          <Badge bg="success" className="best-quote">
            Mejor Oferta
          </Badge>
          <Card.Body className="w-100 d-flex h-100">
            <div className="d-flex w-100 justify-content-between align-items-center" style={{minHeight: 150}}>
              <div>
                <p className="m-0">
                  <b>Compañia: </b>
                  {quote.transportation_company.name}
                </p>
                <p className="m-0">
                  <b>No. Referencia: </b>
                  {quote.reference}
                </p>
                <p className="m-0">
                  <b>Horario:</b> {moment(quote.pickup_time).format("DD MM YYYY HH:mm")} -{" "}
                  {moment(quote.delivery_time).format("DD MM YYYY HH:mm")}
                </p>
                <p className="m-0">
                  <b>Ruta:</b> {quote.origin_city.name} - {quote.destination_city.name}
                </p>
              </div>
              <div>
                <p className="m-0">
                  <b>Contactó: </b>
                  {quote.transportation_company.contact_information.name}
                </p>
                <p className="m-0">
                  <b>Telefono: </b>
                  {quote.transportation_company.contact_information.phone}
                </p>
                <p className="m-0">
                  <b>Correo: </b>
                  {quote.transportation_company.contact_information.email}
                </p>
                <p className="m-0">
                  <b>Camión: </b>
                  {quote.bed_type.name}
                </p>
              </div>
              <Card style={{width: 250}}>
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <h3 className="m-0">
                    $ {quote.total} {quote.currency}
                  </h3>
                  {/* <Button variant="primary" className="text-white">
                    Ver Cotizacion
                  </Button> */}
                  <a className="btn btn-primary text-white" href={`/pdf_view/quote/${quote.id}`} target="_blank">
                    imprimir
                  </a>
                </Card.Body>
              </Card>
            </div>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default QuotesList;
