import { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../../hooks/useFetch";
import { erroresCodes, getAddressData, fullNameCity, setHours } from "../../../../utils";
import RenderInputPhone from "../../../../fyb_components/results/RenderInputPhone";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import SelectQouteCity from "../../../../fyb_components/results/SelectQouteCity";
import { BoardingRequestViewPDF } from "../BoardingRequestVIewPDF";

const schemaValidation = yup.object().shape({
  origin_city: yup.object().required("Campo requerido").nullable(),
  origin_address_line1: yup.string().required("Campo requerido"),
  origin_address_line2: yup.string().required("Campo requerido"),
  origin_zip_code: yup.string().required("Campo requerido"),
  origin_phone: yup.string().required("Campo requerido").nullable(),
  origin_name: yup.string().required("Campo requerido"),
  destination_city: yup.object().required("Campo requerido").nullable(),
  destination_address_line1: yup.string().required("Campo requerido"),
  destination_address_line2: yup.string().required("Campo requerido"),
  destination_zip_code: yup.string().required("Campo requerido"),
  destination_phone: yup.string().required("Campo requerido").nullable(),
  destination_name: yup.string().required("Campo requerido"),
});

const GenerateBoardingRequest = ({ order, getOrder }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const { fletesAPI } = useFetch();

  const updateDestination = async (data) => {
    await fletesAPI
      .patch(`api/freight/${order.id}/destination_address/`, data)
      .then((e) => {
        console.log(e.data);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
      });
  };

  const updateOrigin = async (data) => {
    await fletesAPI
      .patch(`api/freight/${order.id}/origin_address/`, data)
      .then((e) => {
        console.log(e.data);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
      });
  };

  const onSubmit = (data) => {
    const originData = getAddressData({ data, prefix: "origin_" });
    const destinationData = getAddressData({ data, prefix: "destination_" });

    toast.promise(
      updateOrigin(originData)
        .then(() => updateDestination(destinationData))
        .finally(() => getOrder()),
      {
        loading: "Guardando cambios...",
        success: "Cambios guardados",
        error: "Error al guardar los cambios",
      }
    );
  };

  useEffect(() => {
    if (Boolean(order)) {
      if (order?.origin_address?.city) {
        setValue("origin_city", {
          value: order.origin_address.city.id,
          label: fullNameCity(order.origin_address.city),
        });
      }
      if (order?.origin_address?.address_line1) {
        setValue("origin_address_line1", order.origin_address.address_line1);
      }
      if (order?.origin_address?.address_line2) {
        setValue("origin_address_line2", order.origin_address.address_line2);
      }
      if (order?.origin_address?.zip_code) {
        setValue("origin_zip_code", order.origin_address.zip_code);
      }
      if (order?.origin_address?.phone) {
        setValue("origin_phone", order.origin_address.phone);
      }
      if (order?.origin_address?.name) {
        setValue("origin_name", order.origin_address.name);
      }
      if (order?.origin_address?.notes) {
        setValue("origin_notes", order.origin_address.notes);
      }
      if (order?.origin_address?.hours) {
        setHours({ data: order?.origin_address?.hours, setValue, prefix: "origin_" });
      }

      if (order?.destination_address?.city) {
        setValue("destination_city", {
          value: order.destination_address.city.id,
          label: fullNameCity(order.destination_address.city),
        });
      }
      if (order?.destination_address?.address_line1) {
        setValue("destination_address_line1", order.destination_address.address_line1);
      }
      if (order?.destination_address?.address_line2) {
        setValue("destination_address_line2", order.destination_address.address_line2);
      }
      if (order?.destination_address?.zip_code) {
        setValue("destination_zip_code", order.destination_address.zip_code);
      }
      if (order?.destination_address?.phone) {
        setValue("destination_phone", order.destination_address.phone);
      }
      if (order?.destination_address?.name) {
        setValue("destination_name", order.destination_address.name);
      }
      if (order?.destination_address?.notes) {
        setValue("destination_notes", order.destination_address.notes);
      }
      if (order?.destination_address?.hours) {
        setHours({ data: order?.destination_address?.hours, setValue, prefix: "destination_" });
      }
    }
  }, [order]);
  return (
    <>
      {false && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="primary" className="text-white" type="submit">
                Generar Carta de instrucciones
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="d-flex flex-column gap-4">
              <Row>
                <SelectQouteCity
                  name="origin_city"
                  control={control}
                  placeholder="Origen"
                  label="Origen"
                  url="origin_cities"
                  error={errors.origin_city}
                  disabled
                />
              </Row>
              <Row>
                <RenderInput
                  className=""
                  name="origin_address_line1"
                  label="Dirección de origen 1"
                  register={register}
                  placeholder="Ingrese su dirección de origen 1"
                  error={
                    Boolean(errors.origin_address_line1)
                      ? errors.origin_address_line1?.message
                      : null
                  }
                />
              </Row>
              <Row>
                <RenderInput
                  className=""
                  name="origin_address_line2"
                  label="Dirección de origen 2"
                  register={register}
                  placeholder="Ingrese su dirección de origen 2"
                  error={
                    Boolean(errors.origin_address_line2)
                      ? errors.origin_address_line2?.message
                      : null
                  }
                />
              </Row>
              <Row>
                <Col>
                  <RenderInput
                    className=""
                    name="origin_zip_code"
                    label="C.P. de origen"
                    register={register}
                    placeholder="Código postal"
                    error={errors.origin_zip_code ? errors.origin_zip_code.message : null}
                  />
                </Col>
                <Col>
                  <RenderInputPhone
                    className=""
                    name="origin_phone"
                    label="Teléfono de origen"
                    control={control}
                    placeholder="Ingrese su teléfono de origen"
                    errors={errors.origin_phone ? errors.origin_phone.message : null}
                  />
                </Col>
              </Row>
              <Row>
                <RenderInput
                  className=""
                  name="origin_name"
                  label="Nombre de contanto de origen"
                  register={register}
                  placeholder="Ingrese su nombre de contacto de origen"
                  error={errors?.origin_name ? errors?.origin_name?.message : null}
                />
              </Row>
              <Row>
                <RenderInput
                  name="origin_notes"
                  label="Notas de origen"
                  as="textarea"
                  register={register}
                  placeholder="Ingrese notas de origen"
                  error={errors?.origin_notes ? errors?.origin_notes?.message : null}
                />
              </Row>
            </Col>
            <Col className="d-flex flex-column gap-4">
              <Row>
                <SelectQouteCity
                  name="destination_city"
                  control={control}
                  placeholder="Destino"
                  error={errors?.destination_city}
                  label="Destino"
                  url="destination_cities"
                  disabled
                />
              </Row>
              <Row>
                <RenderInput
                  className=""
                  name="destination_address_line1"
                  label="Dirección de destino 1"
                  register={register}
                  placeholder="Ingrese su dirección de destino 1"
                  error={
                    Boolean(errors.destination_address_line1)
                      ? errors.destination_address_line1?.message
                      : null
                  }
                />
              </Row>
              <Row>
                <RenderInput
                  className=""
                  name="destination_address_line2"
                  label="Dirección de destino 2"
                  register={register}
                  placeholder="Ingrese su dirección de destino 2"
                  error={
                    Boolean(errors.destination_address_line2)
                      ? errors.destination_address_line2?.message
                      : null
                  }
                />
              </Row>
              <Row>
                <Col>
                  <RenderInput
                    className=""
                    name="destination_zip_code"
                    label="C.P. de destino"
                    register={register}
                    placeholder="Código postal"
                    error={errors.destination_zip_code ? errors.destination_zip_code.message : null}
                  />
                </Col>
                <Col>
                  <RenderInputPhone
                    className=""
                    name="destination_phone"
                    label="Teléfono de destino"
                    control={control}
                    placeholder="Ingrese su teléfono de destino"
                    errors={errors.destination_phone ? errors.destination_phone.message : null}
                  />
                </Col>
              </Row>
              <Row>
                <RenderInput
                  className=""
                  name="destination_name"
                  label="Nombre de contanto de destino"
                  register={register}
                  placeholder="Ingrese su nombre de contacto de destino"
                  error={errors.destination_name ? errors.destination_name.message : null}
                />
              </Row>
              <Row>
                <RenderInput
                  name="destination_notes"
                  label="Notas de destino"
                  as="textarea"
                  register={register}
                  placeholder="Ingrese notas de destino"
                  error={errors.destination_notes ? errors.destination_notes.message : null}
                />
              </Row>
            </Col>
          </Row>
        </Form>
      )}
      {true && (
        <BoardingRequestViewPDF order={order} />
      )}
    </>
  );
};

export default GenerateBoardingRequest;
