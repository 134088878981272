import styled from "styled-components";

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

export const UiContainer = styled.div`
  width: 620px;
  height: 100%;
  padding: 0 16px;
`;

export const CardsContainer = styled.div`
  position: relative;
  width: 620px;
  height: 100%;
  max-height: calc(100vh - 250px);
  overflow: auto;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  box-sizing: border-box;
  padding: 0;
`;

export const FilterContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  form{
    width: 100%;
    button{
      width: 100%;
      color: #fff;
    }
  }
`;
