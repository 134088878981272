export const userIdType = {
  setUserId: 'SET_USER_ID',
  delUserId: 'DEL_USER_ID',
}

export const userIdReducer = (state, action) => {
  const { type, userId } = action;
  const { setUserId, delUserId } = userIdType;
  switch(type){
    case setUserId:
      localStorage.setItem('user_id', userId);
      return userId;
    case delUserId:
      localStorage.removeItem('user_id');
      return ''
    default:
      return state
  }
}