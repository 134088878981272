export const sessionType = {
  setSession: 'SET_SESSION',
  delSession: 'DEL_SESSION',
}

export const sessionReducer = (state, action) => {
  const { type, session } = action;
  const { setSession, delSession } = sessionType;
  switch(type){
    case setSession:
      localStorage.setItem('session', session);
      return session;
    case delSession:
      localStorage.removeItem('session');
      return ''
    default:
      return state
  }
}