import {Col} from "react-bootstrap";
const RenderBedTypes = ({bed_types, isMobile}) => {

    const bedTypeArray = (e) => e.flatMap((d) => d.name);

    return (
    <>
        {isMobile ?
        <>
            <Col xl={12} lg={12} md={12} sm={12}>
                <p>
                    {bedTypeArray(bed_types).join(" | ")}
                </p>
            </Col>
        </>
        :
        <>
            <td>
                {bedTypeArray(bed_types).join(" | ")}
            </td>
        </>
        }
    </>
    );
};

export default RenderBedTypes;