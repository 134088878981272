import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const SelectHours = ({ register, prefix = "", defaultValue, errors, text = "", buttonText = "" }) => {
  const [show, setShow] = useState(false);
  const days = [
    { day: "monday", label: "Lunes" },
    { day: "tuesday", label: "Martes" },
    { day: "wednesday", label: "Miércoles" },
    { day: "thursday", label: "Jueves" },
    { day: "friday", label: "Viernes" },
    { day: "saturday", label: "Sábado" },
    { day: "sunday", label: "Domingo" },
  ];

  const hide = () => {
    setShow(false);
  };

  const success = () => {
    setShow(false);
  };

  const showModal = () => {
    setShow(true);
  };

  return (
    <>
      <Button
        className="text-white w-100 d-flex gap-4 justify-content-center align-items-center"
        onClick={showModal}
      >
        <p className="m-0">{buttonText}</p>
      </Button>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p> {text} </p>
          <Form.Check
            className="mb-3"
            {...register(`${prefix}fullTime`)}
            isInvalid={errors.hours_monday_checkbox}
            type="checkbox"
            id={`${prefix}fullTime`}
            label={"Tiempo completo 24/7"}
          />
          <Form.Group>
            <Form.Label>Horas de servicio</Form.Label>
            {days.map((day) => (
              <Row>
                <Col className="col-3">
                  <Form.Check
                    {...register(`${prefix}${day.day}`)}
                    isInvalid={errors.hours_monday_checkbox}
                    type="checkbox"
                    id={`${prefix}${day.day}`}
                    label={day.label}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="Hours"
                    {...register(`${prefix}${day.day}_start`)}
                    isInvalid={errors.hours_monday_start}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="Hours"
                    {...register(`${prefix}${day.day}_end`)}
                    isInvalid={errors.hours_monday_end}
                  />
                </Col>
              </Row>
            ))}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={success}>
            Confirmar
          </Button>
          <Button variant="danger" onClick={hide}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectHours;
