import React from "react";
import { Container, Button, Nav } from "react-bootstrap";

function Join({ text, link }) {
  return (
    <div className="py-5">
      <Container className="d-flex justify-content-center">
        <h2> {text} </h2>
        <Nav.Link href={link} className="d-flex justify-content-center align-items-center">
          <Button variant="danger" className="fs-5 py-2" style={{ width: '160px', height: '55px'}}> Únete Ya! </Button>
        </Nav.Link>
      </Container>
    </div>
  );
}

export default Join;
