import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import useFetch from '../../../hooks/useFetch'
import { colourStyles } from "../../../utils/styles";
import { updateCompanyContactInformationValidation } from "../../../utils/formsValidation";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";


export default function ModalEditCompanyContactInformation({
  show,
  setShow,
  getMore,
  dataUpdate,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(updateCompanyContactInformationValidation),
  });
  const onSubmit = (data) => {
    updateCompanyContactInformation(data);
  };
  const { fletesAPI } = useFetch()
  const updateCompanyContactInformation = async (data) => {
    var url = 'api/company/'+ dataUpdate.id +'/contact_information/';
    await fletesAPI
      .patch(url, {
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        zip_code: data.zip_code,
        phone: data.phone,
        city_id: data.city.value
      })
      .then((e) => {
        // console.log(e);
        setShow(false);
        getMore();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
      setTimeout(() => {
        if (dataUpdate) {
          setValue("address_line1", dataUpdate.contact_information.address_line1);
          setValue("address_line2", dataUpdate.contact_information.address_line2);
          setValue("zip_code", dataUpdate.contact_information.zip_code);
          setValue("phone", dataUpdate.contact_information.phone);
          if(dataUpdate.contact_information.city != null){
            setValue("city", {
                value: dataUpdate.contact_information.city.id,
                label: `${dataUpdate.contact_information.city.name}, ${dataUpdate.contact_information.city.state.name}, ${dataUpdate.contact_information.city.state.country.name}`,
            });
          }
        }
      }, 50);
   }, [dataUpdate, show])

  const [originCities, setOriginCities] = useState(null);
  const resOriginCities = originCities === null ? [] : originCities.map((e) => ({ value: e.id, label: `${e.name}, ${e.state.name}, ${e.state.country.name}`}));
  const [loadOriginCities, setLoadOriginCities] = useState(false);


  const updateOriginCities = async (data) => {
    await fletesAPI
      .get(`api/city/search/?name=${data}`)
      .then((e) => {
        setOriginCities(e.data);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getOriginCities = async () => {
    await fletesAPI
      .get(`api/city/`)
      .then((e) => {
        setOriginCities(e.data.results);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateLoadOrigin = (e) => {
    setLoadOriginCities(true);
    setTimeout(() => {
      updateOriginCities(e.target.value);
    }, 400);
  };


  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Editar informacion de contacto</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="formCompanyName">
                <Form.Label>Dirección Linea 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección linea 1"
                  {...register("address_line1")}
                  isInvalid={errors.address_line1}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Dirección Linea 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección linea 2"
                  {...register("address_line2")}
                  isInvalid={errors.address_line2}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formCityOrgin">
                <Form.Label>Ciudad</Form.Label>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isLoading={loadOriginCities}
                      isClearable
                      placeholder="Seleccione su ciudad de origen"
                      {...field}
                      options={resOriginCities}
                      onFocus={() => {
                        getOriginCities();
                        setLoadOriginCities(true);
                      }}
                      onKeyDown={(e) => {
                        e.target.value
                          ? e.target.value.length > 2
                            ? updateLoadOrigin(e)
                            : setTimeout(() => {
                                getOriginCities();
                              }, 50)
                          : setTimeout(() => {
                              getOriginCities();
                            }, 50);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Código Postal</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Código Postal"
                  {...register("zip_code")}
                  isInvalid={errors.zip_code}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Telefono</Form.Label>
                <PhoneInputWithCountry
                  name="phone"
                  placeholder="Ingrese su número telefónico"
                  defaultCountry="MX"
                  control={control}
                  rules={{ required: true }}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" size="sm">
              Guardar Cambios
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancelar Cambios
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
