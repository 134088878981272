import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import useFetch from "../../../../hooks/useFetch";
import { erroresCodes } from "../../../../utils";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import FormDetails from "../../../../fyb_components/Forms/FormDetails";

const RederDetails = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const { fletesAPI } = useFetch();

  const getDetails = async () => {
    setLoading(true);
    await fletesAPI
      .get(`api/freightpost/${data.id}/detail/`)
      .then((e) => {
        setDetails(e.data);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!details) {
      getDetails();
    }
  }, [details]);

  return (
    <>
      <Toaster />
      {loading ? (
        <RenderSpace>
          <Spinner />
        </RenderSpace>
      ) : (
        <FormDetails data={details} updateData={getDetails} />
      )}
    </>
  );
};

export default RederDetails;
