import React, { useState, useEffect } from "react";
import { Row, Form, FormControl, Button, Col, InputGroup, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";
import useFetch from "../../../hooks/useFetch";
import BedCards from "../BedCards";
import SelectAllCity from "../SelectAllCity";

const searchValidation = yup.object().shape({
  bed_type_id: yup.object().required("Selecciona un camión").nullable(),
  origin_city_id: yup.object().required("La ciudad de origen es obligatoria").nullable(),
  destination_city_id: yup.object().required("La ciudad de destino es obligatoria").nullable(),
  currency: yup.object().required("El tipo de moneda es obligatorio").nullable(),
  pickup_time: yup.string().required("La hora de recogida es obligatoria"),
  delivery_time: yup.string().required("La hora de entrega es obligatoria"),
  expiration: yup.string().required("La fecha de expiración es obligatoria"),
  pallets: yup
    .string()
    .required("El número de tarimas es obligatorio")
    .test(
      "max-pallets",
      "El número de tarimas excede el máximo permitido para este camión",
      function (value) {
        const { bed_type_id } = this.parent;
        return bed_type_id && value
          ? parseFloat(value) <= parseFloat(bed_type_id.max_pallets)
          : true;
      }
    ),
  weight: yup
    .number()
    .required("El peso es obligatorio")
    .typeError("El peso debe ser un número")
    .test("max-weight", "El peso excede el máximo permitido para este camión", function (value) {
      const { bed_type_id } = this.parent;
      return bed_type_id && value ? parseFloat(value) <= parseFloat(bed_type_id.max_weight) : true;
    }),
  total: yup.number().required("El total es obligatorio").typeError("El total debe ser un número"),
});

export default function FormSelectInfo({ defaultData, next }) {
  const [extras, setExtras] = useState(defaultData?.extras ? defaultData?.extras : []);
  const [bedTypes, setBedTypes] = useState([]);
  const { fletesAPI } = useFetch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(searchValidation),
  });

  const getBedTypes = async () => {
    await fletesAPI
      .get(`api/quote/available_beds/`)
      .then((e) => {
        setBedTypes(e.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = (data) => {
    data.extras = extras;
    next(data);
  };

  useEffect(() => {
    if (bedTypes.length === 0) {
      getBedTypes();
    }
  }, [bedTypes]);

  useEffect(() => {
    const keys = Object.keys(errors);
    if (Boolean(keys.length !== 0)) {
      toast.error(errors[keys[0]].message, 2000);
    }
  }, [errors]);

  useEffect(() => {
    if (defaultData) {
      setValue("pickup_time", defaultData?.pickup_time ? new Date(defaultData?.pickup_time) : "");
      setValue(
        "delivery_time",
        defaultData?.delivery_time ? new Date(defaultData?.delivery_time) : ""
      );
      setValue("total", defaultData?.total ? defaultData?.total : "");
      setValue("currency", defaultData?.currency ? defaultData?.currency : undefined);
      setValue("expiration", defaultData?.expiration ? new Date(defaultData?.expiration) : "");
      setValue("pallets", defaultData?.pallets ? defaultData?.pallets : "");
      setValue("weight", defaultData?.weight ? defaultData?.weight : "");
      setValue("cargo", defaultData?.cargo ? defaultData?.cargo : "");
    }
  }, [defaultData]);

  return (
    <>
      <Container>
        <Form className="w-100 d-flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Controller
              control={control}
              name="bed_type_id"
              render={({ field }) => (
                <BedCards
                  beds={bedTypes}
                  onChange={(e) => field.onChange(e)}
                  defaultValue={defaultData?.bed_type_id}
                />
              )}
            />
          </Row>
          <Row>
            <Col>
              <SelectAllCity
                name="origin_city_id"
                control={control}
                errors={null}
                label="Ciudad de Origen"
                placeholder="Ciudad de Origen"
                defaultValue={defaultData?.origin_city_id}
              />
            </Col>
            <Col>
              <SelectAllCity
                name="destination_city_id"
                control={control}
                errors={null}
                label="Ciudad de Destino"
                placeholder="Ciudad de Destino"
                defaultValue={defaultData?.destination_city_id}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="date">
                <Controller
                  control={control}
                  name="pickup_time"
                  render={({ field }) => (
                    <DatePicker
                      className={`form-control`}
                      placeholderText="Selecciona fecha y hora de recolección"
                      minDate={new Date()}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="date">
                <Controller
                  control={control}
                  name="delivery_time"
                  defaultValue={""}
                  render={({ field }) => (
                    <DatePicker
                      className={`form-control`}
                      placeholderText="Selecciona fecha y hora de entrega"
                      minDate={new Date()}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                control={control}
                name="total"
                defaultValue={""}
                render={({ field }) => (
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    <FormControl
                      placeholder="Total"
                      aria-label="Total"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                )}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="currency"
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    placeholder="Moneda"
                    options={[
                      { value: "USD", label: "USD" },
                      { value: "MXN", label: "MXN" },
                    ]}
                  />
                )}
              />
            </Col>
            <Col>
              <Form.Group controlId="date">
                <Controller
                  control={control}
                  name="expiration"
                  defaultValue={""}
                  render={({ field }) => (
                    <DatePicker
                      className={`form-control`}
                      placeholderText="Fecha de expiración"
                      minDate={new Date()}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                control={control}
                name="pallets"
                defaultValue={""}
                render={({ field }) => (
                  <InputGroup>
                    <FormControl
                      placeholder="Tarimas"
                      aria-label="Tarimas"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1">Uds</InputGroup.Text>
                  </InputGroup>
                )}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="weight"
                defaultValue={""}
                render={({ field }) => (
                  <InputGroup>
                    <FormControl
                      placeholder="Peso"
                      aria-label="Peso"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1">Kg</InputGroup.Text>
                  </InputGroup>
                )}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="cargo"
                defaultValue={""}
                render={({ field }) => (
                  <InputGroup>
                    <FormControl
                      placeholder="Producto"
                      aria-label="Producto"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                )}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Button
              type="submit"
              className="fyb_large_button text-white"
              style={{ width: "400px", fontSize: "1.2rem", fontWeight: 600 }}
            >
              Siguiente
            </Button>
          </Row>
        </Form>
      </Container>
    </>
  );
}
