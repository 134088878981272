import { useEffect, useState } from "react";
import { Button, Container, Row, Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
import { fullNameCity } from "../../utils";

const Confirmation = ({ finish, orderId, companyId, invoice }) => {
  const [order, setOrder] = useState(null);
  const [invoiceData, setInvoiceData] = useState(invoice);

  const { fletesAPI } = useFetch();

  
  const getOrder = async () => {
    try {
      const response = await fletesAPI.get(`api/freight/${orderId}/`);
      setOrder(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <Container className="d-flex flex-column gap-4">
      <Row style={{ justifyContent: "center" }}>
        <Card style={{ width: "600px" }}>
          <Card.Body className="gap-2 d-flex flex-column">
            <ListGroup>
              <ListGroup.Item>
                <div className="header-card_section">
                  <p>
                    <b>Informacion</b>
                  </p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Compañia:</b> {order?.quote?.company?.name || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Nombre:</b> {order?.quote?.company?.contact_information?.name || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Correo:</b> {order?.quote?.company?.contact_information?.email || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Teléfono:</b> {order?.quote?.company?.contact_information?.phone || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Dirección:</b> {order?.quote?.company?.contact_information?.address_line1 || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Camión:</b> {order?.quote?.bed_type_id?.name || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Placas:</b> {order?.quote?.truck?.license_plate || "N/A"}
              </ListGroup.Item>
            </ListGroup>
            <div className="d-flex ">
              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section">
                    <p>
                      <b>Origen</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Origen:</b> {order?.origin_address ? fullNameCity(order?.origin_address?.city) : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Dirección:</b> {order?.origin_address ? `${order?.origin_address?.address_line1} ${order?.origin_address?.address_line2}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Fecha:</b> {moment.utc(order?.quote?.pickup_time).format("YYYY-MM-DD HH:mm") || ""}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section">
                    <p>
                      <b>Destino</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Destino:</b> {order?.destination_address ? fullNameCity(order?.destination_address?.city) : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Dirección:</b> {order?.destination_address ? `${order?.destination_address?.address_line1} ${order?.destination_address?.address_line2}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Fecha:</b> {moment.utc(order?.quote?.delivery_time).format("YYYY-MM-DD HH:mm") || ""}
                </ListGroup.Item>
              </ListGroup>
            </div>
            <ListGroup>
              <ListGroup.Item>
                <div className="header-card_section">
                  <p>
                    <b>Detalles del Flete</b>
                  </p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Tarimas:</b> {order?.quote?.pallets || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Peso:</b> {order?.quote?.weight || ""} kg
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Carga:</b> {order?.quote?.cargo || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Precio:</b> ${order?.quote?.total || "Error"} {order?.quote?.currency || "Error"}
              </ListGroup.Item>
            </ListGroup>

            <ListGroup>
              <ListGroup.Item>
                <div className="header-card_section">
                  <p>
                    <b>Información de Factura</b>
                  </p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Nombre legal:</b> {invoiceData?.legal_name || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>RFC:</b> {invoiceData?.rfc_code || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Correo:</b> {invoiceData?.address?.email || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Teléfono:</b> {invoiceData?.address?.phone || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Dirección:</b> {invoiceData ? `${invoiceData?.address?.address_line1} ${invoiceData?.address?.address_line2}` : ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Código Postal:</b> {invoiceData?.address?.zip_code || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Ciudad:</b> {fullNameCity(invoiceData?.address?.city) || ""}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </Row>
      <Row className="justify-content-center">
        <Button
          type="submit"
          className="fyb_large_button text-white"
          style={{ width: "400px", fontSize: "1.2rem", fontWeight: 600 }}
          onClick={() => finish(order, invoiceData)}
        >
          Confirmar
        </Button>
      </Row>
    </Container>
  );
};

export default Confirmation;
