import { useState } from "react";
import { Form } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const styles = {
  inputCont: {
    position: "relative",
    cursor: "pointer",
  },
  inputEye: {
    position: "absolute",
    right: "5px",
    top: "0",
    bottom: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
};

const RenderInputPassword = ({ register, errors, mensajesFormulario, name, placeholder, label }) => {
  const [viewPass, setViewPass] = useState(true);
  return (
    <Form.Group className="mb-3" controlId={name}>
      <Form.Label>{label}</Form.Label>
      <div style={styles.inputCont}>
        <Form.Control
          type={viewPass ? "password" : "text"}
          placeholder={placeholder}
          {...register(name)}
          isInvalid={errors.password}
        />
        <div style={styles.inputEye} onClick={() => setViewPass(!viewPass)}>
          {viewPass ? <AiFillEyeInvisible /> : <AiFillEye />}
        </div>
      </div>
      {errors.password && (
        <p className="dashboard_form_error_message">
          {mensajesFormulario("password_required")}
        </p>
      )}
    </Form.Group>
  );
};

export default RenderInputPassword;
