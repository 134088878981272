import { useState } from "react";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import { MdEdit, MdDelete, MdRocketLaunch } from "react-icons/md";
import { IoLogoWhatsapp, IoIosMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import toast from "react-hot-toast";
import moment from "moment";
import { FaPrint } from "react-icons/fa";

import RenderPickupDate from "../../../fyb_components/results/RenderPickupDate";
import RenderCityResult from "../../../fyb_components/results/RenderCityResult";
import RenderRate from "../../../fyb_components/results/RenderRate";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import ModalDelete from "./ModalDelete";
import useFetch from "../../../hooks/useFetch";
import ModalContact from "./ModalContact";
import { urlWhatsQuoteMessage } from "../../../utils";

const QoutesTable = ({ quotes, isMobile, reloadOrders }) => {
  const [showDel, setShowDel] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);
  const [modalContact, setModalContact] = useState({
    show: false,
    data: null,
  });

  const { fletesAPI } = useFetch();

  const showModalContact = (e) => {
    setModalContact({ show: true, data: e });
  };

  const hideModalContact = () => {
    setModalContact({ show: false, data: null });
  };

  const doCarga = async (id) => {
    await fletesAPI
      .post(`api/freight/`, {
        quote_id: id,
      })
      .then((e) => {
        window.location.href = `/dashboard/my_order/${e.data.id}/edit`;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const goFreight = async (id) => {
      window.location.href = `/dashboard/my_order/${id}/edit`;

  };

  const doCargaToast = (id) => {
    toast.promise(doCarga(id), {
      loading: "Creando flete...",
      success: "Flete creada",
      error: "Error al crear flete",
    });
  };

  return (
    <>
      <Row>
        <Col>
          {quotes && quotes.results.length > 0 ? (
            <>
              <Table striped bordered className="dashboard_main_container_table">
                <thead>
                  <tr>
                    <th>Expiracion</th>
                    <th>No. Referencía</th>
                    <th>Cliente</th>
                    <th>Ciudad de origen</th>
                    <th>Ciudad de destino</th>
                    <th>Estimado </th>
                    <th>Distacia </th>
                    <th>Hora de Recolecion </th>
                    <th>Hora de Entrega </th>
                    <th>Contactar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {quotes &&
                    quotes.results.map((e, i) => {
                      return (
                        <tr key={i}>
                          <RenderPickupDate date={e.expiration} isMobile={isMobile} />
                          <td>{e?.reference?.toUpperCase()}</td>
                          <td>
                            <p className="link" onClick={() => showModalContact(e)}>
                              {e.company.name}
                            </p>
                          </td>
                          <RenderCityResult
                            origin_city={e.origin_city}
                            destination_city={e.destination_city}
                            display_city={e.origin_city}
                            isMobile={isMobile}
                            subtitle={"Origen"}
                          />
                          <RenderCityResult
                            origin_city={e.origin_city}
                            destination_city={e.destination_city}
                            display_city={e.destination_city}
                            isMobile={isMobile}
                            subtitle={"Destino"}
                          />
                          <RenderRate rate={e.total} isMobile={isMobile} currency={e.currency} />
                          <td>
                            <p> {e.distance} km </p>
                          </td>
                          <td>
                            <p> {moment(e.pickup_time).format("DD/MM/YYYY HH:MM")} </p>
                          </td>
                          <td>
                            <p> {moment(e.delivery_time).format("DD/MM/YYYY HH:MM")} </p>
                          </td>
                          <td>
                            <div className="contact-td">
                              {e?.company?.contact_information?.phone && (
                                <a
                                  href={urlWhatsQuoteMessage(e, true)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <IoLogoWhatsapp />
                                </a>
                              )}
                              {e?.company?.contact_information?.phone && (
                                <a
                                  href={`tel:${e?.company?.contact_information?.phone}`}
                                >
                                  <FaPhone />
                                </a>
                              )}
                              {e?.company?.contact_information?.mail && (
                                <a
                                  href={`mailto:${e?.company?.contact_information?.mail}`}
                                >
                                  <IoIosMail />
                                </a>
                              )}
                            </div>
                          </td>
                          <td>
                            {e.freight_id ? (
                                <RenderButtonMore
                              buttons={[
                                {
                                  label: (
                                    <>
                                      Ir al Flete <MdRocketLaunch />
                                    </>
                                  ),
                                  onClick: () => goFreight(e.freight_id),
                                },
                                {
                                  label: (
                                    <>
                                      Imprimir <FaPrint />
                                    </>
                                  ),
                                  onClick: () => window.open(`/pdf_view/quote/${e.id}`),
                                },
                                {
                                  label: (
                                    <>
                                      Eliminar <MdDelete />
                                    </>
                                  ),
                                  onClick: () => {
                                    setShowDel(true);
                                    setDataDelete(e);
                                  },
                                },
                              ]}
                            />
                                ) : (
                                <RenderButtonMore
                              buttons={[
                                {
                                  label: (
                                    <>
                                      Crear Flete <MdRocketLaunch />
                                    </>
                                  ),
                                  onClick: () => doCargaToast(e.id),
                                },
                                {
                                  label: (
                                    <>
                                      Eliminar <MdDelete />
                                    </>
                                  ),
                                  onClick: () => {
                                    setShowDel(true);
                                    setDataDelete(e);
                                  },
                                },
                              ]}
                            />
                                )}

                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {quotes && (
                <Pagination>
                  <Pagination.Prev
                    disabled={!quotes.previous}
                    onClick={() => reloadOrders(quotes.previous)}
                  />
                  <Pagination.Next
                    disabled={!quotes.next}
                    onClick={() => reloadOrders(quotes.next)}
                  />
                </Pagination>
              )}
            </>
          ) : (
            <>
              <br />
              <h3 className="text-center fyb_h2">No tienes estimaciones</h3>
            </>
          )}
        </Col>
      </Row>
      <ModalContact data={modalContact} hide={hideModalContact} />
      <ModalDelete
        show={showDel}
        setShow={setShowDel}
        dataDelete={dataDelete}
        getMore={reloadOrders}
      />
    </>
  );
};

export default QoutesTable;
