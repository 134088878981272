import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import Edit from "./views/Edit";
import Companies from "./views/Companies";

const viewList = {
  edit: "edit",
  companies: "companies",
  details: "details",
};

const AdminUsersById = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { fletesAPI } = useFetch();
  const url = window.location.pathname.split("/");
  const newURl = (uid, utype) => {
    return `/${url[1]}/${url[2]}/${uid}/${utype}`
  }

  const getUser = async () => {
    await fletesAPI
      .get(`api/users/${id}/`)
      .then((e) => {
        setUser(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, [user]);

  return (
    <>
      <Container>
        <hr />
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Editar",
                  active: type === viewList.edit,
                  onClick: () => {
                    navigate(newURl(id,viewList.edit), {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Compañias",
                  active: type === viewList.companies,
                  onClick: () => {
                    navigate(newURl(id,viewList.companies), {
                      replace: true,
                    });
                  },
                }
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && <RenderSpace><Spinner animation="border" variant="primary" /></RenderSpace>}
            {!loading && type === viewList.edit && <Edit getMore={getUser} userData={user} />}
            {!loading && type === viewList.companies && <Companies id={id} /> }
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminUsersById;
