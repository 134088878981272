import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import Map, { Marker } from "react-map-gl";
import { IoMdPin } from "react-icons/io";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";

const SelectLocation = ({
  text = "Location",
  onChange = () => null,
  defaultValue,
}) => {
  const [show, setShow] = useState(false);
  const [location, setLocation] = useState(defaultValue);

  console.log({ location });

  const hide = () => {
    setShow(false);
    setLocation(defaultValue || undefined);
  };

  const success = () => {
    onChange(location);
    toast.success("Ubicación confirmada", 400);
    setShow(false);
  };

  const showModal = () => {
    setShow(true);
  };
  
  useEffect(() => {
    if (onChange) {
      onChange(location);
    }
  }, [location]);

  return (
    <>
      <Button
        variant="primary"
        className="text-white w-100 d-flex gap-4 justify-content-center align-items-center"
        onClick={showModal}
      >
        <p className="m-0">{text} </p>{" "}
        {Boolean(location) ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
      </Button>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p> Haga clic para seleccionar la ubicación </p>
          <Map
            initialViewState={{
              latitude: Boolean(location) ? ( parseFloat(location.lat) ? parseFloat(location.lat) : 40 ) : 40,
              longitude: Boolean(location) ? ( parseFloat(location.lng) ? parseFloat(location.lng) : -100 ) : -100,
              zoom: Boolean(location) ? 8 : 3,
            }}
            mapStyle="mapbox://styles/mapbox/light-v10"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            style={{ width: "100%", height: "400px", borderRadius: "10px" }}
            onClick={(e) => {
              setLocation(e.lngLat);
            }}
          >
            {Boolean(location) && (
              <Marker
                longitude={location.lng}
                latitude={location.lat}
                anchor="bottom"
                draggable
              >
                <IoMdPin style={{ fontSize: 40, color: "var(--fyb_teal)" }} />
              </Marker>
            )}
          </Map>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={success}>
            {"Confirmar"}
          </Button>
          <Button variant="danger" onClick={hide}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SelectLocation;
