import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import useFetch from '../../../hooks/useFetch'

import { colourStyles } from "../../../utils/styles";
import { updateCompanyValidation } from "../../../utils/formsValidation";

export default function ModalEditCompany({
  show,
  setShow,
  getMore,
  dataUpdate,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(updateCompanyValidation),
  });
  const onSubmit = (data) => {
    updateCompany(data);
  };
  const { fletesAPI } = useFetch()
  const updateCompany = async (data) => {
    var url = 'api/company/'+ dataUpdate.id +'/';
    await fletesAPI
      .patch(url, data)
      .then((e) => {
        // console.log(e);
        setShow(false);
        getMore();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
      setTimeout(() => {
        if (dataUpdate) {
          setValue("name", dataUpdate.name);
          setValue("carrier", dataUpdate.carrier);
          setValue("broker", dataUpdate.broker);
          setValue("facebook_url", dataUpdate.facebook_url);
          setValue("twitter_user", dataUpdate.twitter_user);
          setValue("website_url", dataUpdate.website_url);
          setValue("ctpat_code", dataUpdate.ctpat_code);
          setValue("dot_code", dataUpdate.dot_code);
          setValue("mc_code", dataUpdate.mc_code);
          setValue("oea_code", dataUpdate.oea_code);
          setValue("rfc_code", dataUpdate.rfc_code);
          setValue("default_days_to_pay", dataUpdate.default_days_to_pay);
        }
      }, 50);
  }, [dataUpdate, show])

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Editar Compañía</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="formCompanyName">
                <Form.Label>Nombre de la Compañía</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de la Compañía"
                  {...register("name")}
                  isInvalid={errors.name}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formCarrier">
                  <Form.Check {...register("carrier")} isInvalid={errors.carrier} type="checkbox" label="Transportista" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBroker">
                  <Form.Check {...register("broker")} isInvalid={errors.broker} type="checkbox" label="Logística" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Facebook</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="pagina de facebook"
                  {...register("facebook_url")}
                  isInvalid={errors.facebook_url}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Twitter</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="usuario de twitter"
                  {...register("twitter_user")}
                  isInvalid={errors.twitter_user}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Pagina Web</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="pagina web"
                  {...register("website_url")}
                  isInvalid={errors.website_url}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>C-TPAT</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="C-TPAT Codigo"
                  {...register("ctpat_code")}
                  isInvalid={errors.ctpat_code}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>DOT Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Numero de DOT"
                  {...register("dot_code")}
                  isInvalid={errors.dot_code}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Numero de MC</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Numero de MC"
                  {...register("mc_code")}
                  isInvalid={errors.mc_code}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>Numero de OEA</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Numero de OEA"
                  {...register("oea_code")}
                  isInvalid={errors.oea_code}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="form">
                <Form.Label>RFC</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="RFC"
                  {...register("rfc_code")}
                  isInvalid={errors.rfc_code}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formCompanyWeb">
                <Form.Label>En cuantos dias pagas?</Form.Label>
                <Form.Control {...register("default_days_to_pay")} isInvalid={errors.default_days_to_pay} type="number" placeholder="Introduzca los días para pagar" />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" size="sm">
              Guardar Cambios
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancelar Cambios
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
