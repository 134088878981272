import { Image } from "react-bootstrap";
import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Section from "../components/Home/Section";
import supportImg from "../assets/images/compressed_driver.jpg";
import FybNavigationBar from "../components/FybNavigationBar";



export default function Support() {

const styles = {
  container: {
    height: "800px",
    background: "#3E85C6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputCont: {
    position: "relative",
    cursor: "pointer",
  },
  inputEye: {
    position: "absolute",
    right: "5px",
    top: "0",
    bottom: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "120px",
  },
};

  return (
    <>
      <FybNavigationBar />
      <Section
        title="Contactanos por los siguientes medios"
        subtitle="soporte@fletesybodegas.com o https://soporte.fletesybodegas.com/"
        content={
        <Image src={supportImg} style={{ height: "100%", width: "100%" }} />
        }
      />
      <Footer />
    </>
  )
}
