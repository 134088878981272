import { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import { mensajesAPI } from "../../../utils/mensajes.js";
import { fullNameCity } from "../../../utils";
import { BodegasContext } from "../../../context";

export default function ModalDelete({ show, onHide, dataDelete, update }) {
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);
  const deleteOrder = async () => {
    await fletesAPI
      .delete(`api/company/${companyId}/invoice_information/`, {
        data: { invoice_address_id: dataDelete.id },
      })
      .then(() => {
        onHide();
        const time = setTimeout(() => {
          update();
        }, 50);
        toast.success(mensajesAPI("toast_success_saving_message"));
        return () => clearTimeout(time);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Body>
          <h6>¿Estás seguro de eliminar?</h6>
          <p>
            {dataDelete && dataDelete.invoice_legal_name} - {dataDelete && dataDelete.rfc_code}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" size="sm" onClick={() => deleteOrder()}>
            Eliminar
          </Button>
          <Button variant="danger" size="sm" onClick={() => onHide()}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
