import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { MdPhone, MdEmail } from "react-icons/md"
import { IoLogoWhatsapp } from 'react-icons/io'
import moment from "moment"

import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { fullNameCity, formatNumber } from "../../../utils";

export default function ModalContact({ data, hide, carrier }) {

  const info = data.data;

  const isCarrier = carrier ? "company" : "transportation_company";

  return (
    <>
      <Modal show={data.show} onHide={hide}>
        <Modal.Header closeButton>
          <h5 className="fyb_h5 text-center">Información de Carga</h5>
        </Modal.Header>
        {info && (
          <Modal.Body>
            <Row>
              <label>Cuidad de origen</label>
              <h5>{fullNameCity(info?.origin_address?.city)}</h5>
            </Row>
            <Row>
              <label>Cuidad de destino</label>
              <h5>{fullNameCity(info?.destination_address?.city)}</h5>
            </Row>
            <Row>
              <Col>
                <label>Fecha de Carga</label>
                <h5>{moment(info?.ready_pickup_date).format("DD/MM/YYYY hh:mm") + " hrs"}</h5>
              </Col>
              <Col>
                <label>Total</label>
                <h5> {info?.total === null ? "Contacta a la compañía" : `$ ${info?.quote?.total} ${info?.quote?.currency}` }</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Numero de Tarimas</label>
                <h5>{info?.quote?.pallets} Uds</h5>
              </Col>
              <Col>
                <label>Peso</label>
                <h5> {formatNumber(info?.quote?.weight)} kg</h5>
              </Col>
            </Row>
            <Row>
                <label>Caja Tráiler</label>
                <h5>{info?.quote?.bed_type?.name}</h5>
            </Row>
            <Row>
                <label>Compañía</label>
                <h5>{info?.quote?.[isCarrier]?.name || "N/E"  }</h5>
            </Row>
            <hr />
            <Row>
              <Col>
                <label>Correo Electrónico</label>
                <h5>{info?.quote?.[isCarrier]?.contact_information?.email || "N/E"}</h5>
              </Col>
              <Col>
                <label>Telefono</label>
                <h5>{info?.quote?.[isCarrier]?.contact_information?.phone ? formatPhoneNumberIntl(info?.quote?.[isCarrier]?.contact_information?.phone) : "N/E" }</h5>
              </Col>
            </Row>
            <Row>
              <label>Nombre de contacto</label>
              <h5>{info?.quote?.[isCarrier]?.contact_information?.name ||  "N/E"}</h5>
            </Row>
            <Modal.Footer className="d-flex justify-content-between">
              {info?.quote?.[isCarrier]?.contact_information && (
                <a target="_blank" rel="noreferrer" href={`https://wa.me/${info?.quote?.[isCarrier]?.contact_information?.phone}?text=` + encodeURIComponent(`Hola me interesa tu carga con id ${info?.quote?.id} con origen ${fullNameCity(info?.origin_address?.city)}, con destino a ${fullNameCity(info?.destination_address?.city)}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`)} className="btn btn-success text-light btn-sm">
                  <IoLogoWhatsapp  /> {formatPhoneNumberIntl(info?.quote?.[isCarrier]?.contact_information?.phone)}
                </a>
              )}
              {info?.quote?.[isCarrier]?.contact_information?.email && (
                <a href={`mailto:${info?.quote?.[isCarrier]?.contact_information?.email}?body=` + encodeURIComponent(`Hola me interesa tu carga con numero de referencia ${info?.quote?.id} con origen ${fullNameCity(info?.origin_address?.city)}, con destino a ${fullNameCity(info?.destination_address?.city)}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`)} className="btn btn-primary text-light btn-sm">
                  <MdEmail /> {info?.quote?.[isCarrier]?.contact_information?.email}
                </a>
              )}
              {info?.quote?.[isCarrier]?.contact_information?.phone && (
                <a href={`tel:${info?.quote?.[isCarrier]?.contact_information?.phone}`} className="btn btn-primary text-light btn-sm">
                  <MdPhone  /> {formatPhoneNumberIntl(info?.quote?.[isCarrier]?.contact_information?.phone)}
                </a>
              )}
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
