import Step from "./Step";

const MultiStep = ({ steps = [], currentStep }) => {

  const getStepStatus = (index) => {
    if (index < currentStep) {
      return "done";
    } else if (index === currentStep) {
      return "current";
    } else {
      return "pending";
    }
  }

  return (
    <div className="multiStep">
      {steps.map((step, index) => (
        <Step 
          key={index} 
          title={step.title} 
          status={getStepStatus(index)} 
          last={index === steps.length - 1}
          onClick={step.onClick}
        />
      ))}
    </div>
  );
};

export default MultiStep;
