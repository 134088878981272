import { Form } from "react-bootstrap";

const RenderCheckbox = ({ 
  type = "checkbox",
  error,
  register,
  name,
  defaultValue = false,
  label,
  labelTitle,
 }) => {
  return (
    <Form.Group className="mb-3" controlId={name}>
      <Form.Label>{labelTitle}</Form.Label>
      <Form.Check
        {...register(name)}
        isInvalid={error}
        defaultValue={defaultValue}
        type={type}
        label={label}
      />
      <Form.Text className="text-danger position-absolute">{error}</Form.Text>
    </Form.Group>
  );
};

export default RenderCheckbox;