import { Form } from "react-bootstrap";
import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";

const RenderInputPhone = ({
  label,
  placeholder,
  defaultCountry = "MX",
  control,
  name,
  required = false,
  error,
  className = "mb-4"
}) => {
  return (
    <Form.Group controlId={name} className={className}>
      <Form.Label>{label}</Form.Label>
      <PhoneInputWithCountrySelect
        name={name}
        placeholder={placeholder}
        defaultCountry={defaultCountry}
        control={control}
        rules={{ required }}
        isInvalid={error}
      />
      <Form.Text className="text-danger position-absolute">{error}</Form.Text>
    </Form.Group>
  );
};

export default RenderInputPhone;
