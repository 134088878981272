import { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, InputGroup, FormControl } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import SelectAllCity from "../SelectAllCity";

export default function Filter({ sendRequest }) {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { control, watch } = useForm();
  const formValues = watch();
  const prevValues = useRef({});

  useEffect(() => {
    const { origin_city_id, destination_city_id, start_date, end_date, reference } = formValues;

    const hasChanged = 
      prevValues.current.origin_city_id !== origin_city_id ||
      prevValues.current.destination_city_id !== destination_city_id ||
      prevValues.current.start_date !== start_date ||
      prevValues.current.end_date !== end_date ||
      prevValues.current.reference !== reference;

    if (hasChanged) {
      sendRequest({
        origin_city_id: origin_city_id?.value ?? undefined,
        destination_city_id: destination_city_id?.value ?? undefined,
        start_date: Boolean(start_date) ? start_date : undefined,
        end_date: Boolean(end_date) ? end_date : undefined,
        reference: Boolean(reference) ? reference : undefined,
      });

      prevValues.current = formValues;
    } else if (isFirstLoad) {
      setIsFirstLoad(false);
      prevValues.current = formValues;
    }
  }, [formValues]);

  return (
    <>
      <Container>
        <Form className="w-100 d-flex flex-column gap-3">
          <Row>
            <Col>
              <Row className="mb-2">
                <SelectAllCity
                  name="origin_city_id"
                  control={control}
                  errors={"orrible"}
                  label="Ciudad de Origen"
                  placeholder="Ciudad de Origen"
                  defaultValue={null}
                />
              </Row>
              <Row>
                <SelectAllCity
                  name="destination_city_id"
                  control={control}
                  errors={null}
                  label="Ciudad de Destino"
                  placeholder="Ciudad de Destino"
                  defaultValue={null}
                />
              </Row>
            </Col>
            <Col>
              <Row className="mb-2">
                <Form.Group controlId="date">
                  <Controller
                    control={control}
                    name="start_date"
                    render={({ field }) => (
                      <DatePicker
                        className={`form-control`}
                        placeholderText="Selecciona fecha y hora de recolección"
                        minDate={new Date()}
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        isClearable
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="date">
                  <Controller
                    control={control}
                    name="end_date"
                    defaultValue={""}
                    render={({ field }) => (
                      <DatePicker
                        className={`form-control`}
                        placeholderText="Selecciona fecha y hora de entrega"
                        minDate={new Date()}
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        isClearable
                      />
                    )}
                  />
                </Form.Group>
              </Row>
            </Col>
            <Col>
              <Row className="mb-2">
                <Controller
                  control={control}
                  name="reference"
                  defaultValue={""}
                  render={({ field }) => (
                    <InputGroup>
                      <FormControl
                        placeholder="No. Referencía"
                        aria-label="Referencía"
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value}
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  )}
                />
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}