import { useState, useEffect } from "react";
import { Row, Container, Table, Spinner, Pagination, Form, Button, Col } from "react-bootstrap";
import useFetch from "../../../../hooks/useFetch";
import { MdEdit, MdPeople } from "react-icons/md";
import ModalEditCompany from "../../AdminCompanies/ModalEditCompany";
import ModalEditCompanyContactInformation from "../../AdminCompanies/ModalEditCompanyContactInformation";
import toast from "react-hot-toast";
import { VscWorkspaceTrusted, VscWorkspaceUntrusted } from "react-icons/vsc";
import RenderButtonMore from "../../../../fyb_components/results/RenderButtonMore";
import { FaPallet } from "react-icons/fa";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import { useForm } from "react-hook-form";
import RenderInputCancel from "../../../../fyb_components/results/RenderInputCancel";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { IoGlobeOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";

export default function Companies({id}) {
  const [showEditCompany, setShowEditCompany] = useState(false);
  const [showEditCompanyContactInformation, setShowEditCompanyContactInformation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const [modalUDT, setModalUDT] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    await fletesAPI
      .get("api/users/companies/")
      .then((e) => {
        setCompanies(e.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reloadCompanies = async (e) => {
    setLoading(true);
    const ur = e;
    await fletesAPINotURL
      .get(ur)
      .then((i) => {
        setCompanies(i.data);
        setLoading(false);
      })
      .catch((i) => {});
  };

  const getCompanies = async () => {
    const ur = `api/users/companies/`;
    setLoading(true);
    await fletesAPI
      .get(ur, {
        params: {
          user_id: id,
        },
      })
      .then((e) => {
        setCompanies(e.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCertification = async (data) => {
    await fletesAPI
      .post("api/company/" + data + "/validate/")
      .then((e) => {
        toast.success("Validado");
        getCompanies();
      })
      .catch((e) => {});
  };

  useEffect(() => {
    showEditCompanyContactInformation
      ? setModalUDT(true)
      : setTimeout(() => {
          setModalUDT(false);
        }, 200);
  }, [showEditCompanyContactInformation]);

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <>
      <Row className="mt-5">
        <Col className="d-flex mt-3 justify-content-between align-items-center">
          <h1 className="dashboard_main_container_h1 fyb_h1">Compañias</h1>
          <div className="d-flex">
            <Form className="d-flex" style={{ width: "300px" }} onSubmit={handleSubmit(onSubmit)}>
              <RenderInputCancel
                name={"search"}
                register={register}
                errors={errors.search && errors.search.message}
                placeholder={"Buscar"}
                type={"text"}
                onClose={() => {
                  reset();
                }}
              />
              <Button type="submit" className="text-white">
                Buscar
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      <Row>
        <Container>
          {loading ? (
            <RenderSpace>
              <Spinner animation="border" variant="primary" />
            </RenderSpace>
          ) : (
            <Table striped bordered responsive className="dashboard_main_container_table " >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Logística</th>
                  <th>Transportista</th>
                  <th>Redes</th>
                  <th>Dias a pagar</th>
                  <th>Informacion de Contacto</th>
                  <th>Verificado</th>
                  <th>Dueño</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {companies &&
                  companies.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e.company.name}</td>
                        <td>{e.company.broker ? "Si" : "No"}</td>
                        <td>{e.company.carrier ? "Si" : "No"}</td>
                        <td>
                          <div
                            className="d-flex h-100 w-100 justify-content-center align-items-center"
                            style={{ gap: 5 }}
                          >
                            {e.company.facebook_url && (
                              <a href={e.company.facebook_url} target="_blank" rel="noopener noreferrer">
                                <FaFacebookF size={20} color={"#242527"} />
                              </a>
                            )}
                            {e.company.twitter_user && (
                              <a href={e.company.twitter} target="_blank" rel="noopener noreferrer">
                                <FaTwitter size={20} color={"#1da1f2"} />
                              </a>
                            )}
                            {e.company.website_url && (
                              <a href={e.company.website} target="_blank" rel="noopener noreferrer">
                                <IoGlobeOutline size={20} color={"grey"} />
                              </a>
                            )}
                          </div>
                        </td>
                        <td>{e.company.default_days_to_pay}</td>
                        <td>
                          {e.company.contact_information.address_line1}
                          <br />
                          {e.company.contact_information.address_line2}
                          <br />
                          {e.company.contact_information.zip_code}
                          <br />
                          {e.company.contact_information.phone}
                          <br />
                          {e.company.contact_information.city && (
                            <>
                              {e.company.contact_information.city.name},{" "}
                              {e.company.contact_information.city.state.name},{" "}
                              {e.company.contact_information.city.state.country.name}
                            </>
                          )}
                        </td>
                        <td>
                          {e.company.verified ? <VscWorkspaceTrusted /> : <VscWorkspaceUntrusted />}{" "}
                          <a href="#" onClick={() => updateCertification(e.id)}>
                            Cambiar
                          </a>
                        </td>
                        <td>
                          {e.company.contact_information.email} <br />
                          {e.company.contact_information.name} <br /> 
                          {e.company.contact_information.phone}
                        </td>
                        <td>
                          <RenderButtonMore
                            buttons={[
                              {
                                label: (
                                  <>
                                    Compañia <MdEdit />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.company.id}/edit_company`;
                                },
                              },
                              {
                                label: (
                                  <>
                                    Contacto <MdEdit />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.company.id}/edit_contact`;
                                },
                              },
                              {
                                label: (
                                  <>
                                    Usuarios <MdPeople />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.company.id}/users`;
                                },
                              },
                              {
                                label: (
                                  <>
                                    Fletes <FaPallet />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.company.id}/orders`;
                                },
                              },
                              {
                                label: (
                                  <>
                                    Estimaciones <FaPallet />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.company.id}/quotes`;
                                },
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Container>
        {!loading && (
          <Container>
            <Pagination>
              <Pagination.Prev
                disabled={!companies.previous}
                onClick={() => reloadCompanies(companies.previous)}
              />
              <Pagination.Next
                disabled={!companies.next}
                onClick={() => reloadCompanies(companies.next)}
              />
            </Pagination>
          </Container>
        )}
      </Row>
      <ModalEditCompany
        show={showEditCompany}
        setShow={setShowEditCompany}
        getMore={getCompanies}
        dataUpdate={dataUpdate}
      />

      {modalUDT && (
        <ModalEditCompanyContactInformation
          show={showEditCompanyContactInformation}
          setShow={setShowEditCompanyContactInformation}
          getMore={getCompanies}
          dataUpdate={dataUpdate}
        />
      )}
    </>
  );
}
