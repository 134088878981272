export const statusOptionsAdd = [
  {value: 0, label: 'No publicado', color: '#6c757d'},
  {value: 1, label: 'Publicado', color: '#ffc107'},

]

export const statusOptions = [
  {value: 0, label: 'No publicado', color: '#6c757d'},
  {value: 1, label: 'Publicado', color: '#ffc107'},
  {value: 2, label: 'En curso', color: '#0dcaf0'},
  {value: 3, label: 'Completado', color: '#198754'},
  {value: 4, label: 'Cancelado', color: '#dc3545'}
]

export const affiliateAttachmentOptions = [
    {value: 'rfc', label: 'RFC', color: '#6c757d'},
    {value: 'dot/mc/mx', label: 'DOT/MC/MX', color: '#6c757d'},
    {value: 'caat', label: 'Parque Vehicular (CAAT)', color: '#6c757d'},
    {value: 'ctpat', label: 'CTPAT', color: '#6c757d'},
    {value: 'oea', label: 'OEA', color: '#6c757d'},
    {value: 'id', label: 'INE/Identification Oficial', color: '#6c757d'},
    {value: 'acta', label: 'Acta Constitutiva', color: '#6c757d'},
]

export const freightpostAttachmentOptions = [
    {value: 'rfc', label: 'RFC', color: '#6c757d'},
    {value: 'dot/mc/mx', label: 'DOT/MC/MX', color: '#6c757d'},
    {value: 'caat', label: 'Parque Vehicular (CAAT)', color: '#6c757d'},
    {value: 'ctpat', label: 'CTPAT', color: '#6c757d'},
    {value: 'oea', label: 'OEA', color: '#6c757d'},
    {value: 'id', label: 'INE/Identification Oficial', color: '#6c757d'},
    {value: 'recibido', label: 'Confirmacion de Entrega', color:'#6c757d'}
]

export const truckRouteDefaultValues = {
  origin_city_id: null,
  destination_city_id: null,
  base_price: '',
  base_currency: null,
  bed_type_ids: null,
}

export const addNewOrderDefaultValues = {
  status: {
    label: 'Publicado',
    value: 1,
    color: '#ffc107'
  },
  origin_city_id: null,
  destination_city_id: null,
  weight: '',
  height: '',
  length: '',
  width: '',
  point_of_contact_id: null,
  distance: '',
  bed_type_ids: null,
  rate: '',
  internal_reference_number: '',
  comments: '',
  paletas: false,
  number_of_pallets: null,
  ready_pickup_date: null,
  origin_zip_code: '',
  destination_zip_code: '',
}

export const updateOrderDefaultValues = {
  status: null,
  origin_city_id: null,
  destination_city_id: null,
  weight: '',
  height: '',
  length: '',
  width: '',
  point_of_contact_id: null,
  distance: '',
  bed_type_ids: null,
  rate: '',
  internal_reference_number: '',
  comments: '',
  paletas: false,
  number_of_pallets: null,
  ready_pickup_date: null,
  origin_zip_code: null,
  destination_zip_code: null,
}