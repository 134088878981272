import { useState, useEffect } from "react";
import { Modal, Button, Row, Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiPlus } from "react-icons/bi";
import { v4 as uuid } from "uuid";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import RenderAddFile from "../RenderAddFile";
import RenderInput from "../RenderInput";

const RenderModalCompleteTask = ({
  state,
  setState,
  dataCompany,
  getMore,
  dataTask,
}) => {
  const [arrTasks, setArrTasks] = useState([]);
  const { fletesAPI, fletesAPIFile } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onClose = () => {
    setState(false);
    reset();
    setArrTasks([]);
  };

  const registerTaskFile = async (id, data) => {
    await arrTasks.forEach((task) => {
      let formData = new FormData();
      formData.append("file_name", data[`file_name_${task.id}`]);
      formData.append("file_content", data[`file_content_${task.id}`][0]);
      formData.append("type", data[`type_${task.id}`].value);
      formData.append("task_id", id);
      fletesAPIFile
        .post(`api/company/${dataCompany.id}/task/attachment/`, formData)
        .then((res) => {
          console.log(res);
          toast.success("Archivo registrado con éxito");
        })
        .catch((err) => {
          console.log(err);
        });
    });
    createTaskByCompany(data);
  };

  const createTaskByCompany = async (data) => {
    await fletesAPI
      .patch(`api/company/${dataCompany.id}/task/`, {
        status: 2,
        comment_requestor: dataTask.comment_requestor,
        comment_submmiter: data.comment_submmiter,
        instructions: dataTask.instructions,
        title: dataTask.title,
        task_id: dataTask.id,
      })
      .then((res) => {
        reset();
        setArrTasks([]);
        setState(false);
        getMore();
        toast.success("Tarea actualizada con éxito");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (data) => {
    console.log(data);
    registerTaskFile(dataTask.id, data);
  };

  useEffect(() => {
    if (dataTask) {
      reset({
        status: {
          value: dataTask.status,
          label: dataTask.status == 0 ? "Pendiente" : "Completado",
        },
        comment_requestor: dataTask.comment_requestor,
        instructions: dataTask.instructions,
        title: dataTask.title,
      });
      setArrTasks(dataTask.attachments);
    }
  }, [dataTask]);

  console.log(dataTask);

  return (
    <>
      <Modal show={state} onHide={onClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 style={{ fontSize: "18px", textTransform: "capitalize" }}>
                {dataTask && dataTask.title}
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div>
                <p>
                  <b>instrucciones:</b>{" "}
                  <a href={dataTask && dataTask.instructions}>
                    {dataTask && dataTask.instructions}
                  </a>{" "}
                </p>
              </div>
            </Row>
            <Row>
              <p>
                <b>Comentarios de solicitante:</b>
                <br />
                {dataTask && dataTask.comment_requestor}
              </p>
            </Row>
            <Row>
              <RenderInput
                label={"comentario del remitente"}
                name={"comment_submmiter"}
                register={register}
                errors={
                  errors.comment_submitter && errors.comment_submitter.message
                }
                placeholder="Ingrese el solicitante del remitente"
                as={"textarea"}
              />
            </Row>
            <Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <Button
                  className="text-white"
                  onClick={() => {
                    setArrTasks([...arrTasks, { id: uuid() }]);
                  }}
                >
                  <BiPlus /> Agregar Archivo
                </Button>
              </div>
            </Row>
            <Row>
              {arrTasks.map((task) => (
                <RenderAddFile
                  key={task.id}
                  id={task.id}
                  onDelete={() => {
                    setArrTasks(arrTasks.filter((t) => t.id !== task.id));
                  }}
                  register={register}
                  control={control}
                />
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Guardar
            </Button>
            <Button variant="danger" onClick={onClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RenderModalCompleteTask;
