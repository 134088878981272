import {
  useEffect,
  // useContext
} from "react";
// import { BodegasContext } from "../../../../context";
import useFetch from "../../../../hooks/useFetch";
import { Button, Form, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import RenderSelect from "../../../../fyb_components/results/RenderSelect";
import { useForm } from "react-hook-form";
import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";
import RenderInputPhone from "../../../../fyb_components/results/RenderInputPhone";

const Edit = ({ getMore, userData }) => {
  const { fletesAPI } = useFetch();
  // const { companyId } = useContext(BodegasContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const patchUser = async (datos) => {
    const { first_name, last_name, phone, position_title } = datos;
    await fletesAPI
      .patch(`api/users/${userData.id}/profile/`, {
        first_name,
        last_name,
        phone,
        position_title,
      })
      .then((e) => {
        toast.success("Usuario actualizado con exito");
        getMore();
      })
      .catch((e) => {
        toast.error("Error al actualizar el usuario");
      });
  };

  const onSubmit = (data) => {
    patchUser(data);
  };

  useEffect(() => {
    if (userData !== null) {
      reset({
        first_name: userData.profile.first_name,
        last_name: userData.profile.last_name,
        phone: userData.profile.phone,
        position_title: userData.profile.position_title,
      });
    }
  }, [userData]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar Cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <RenderInput
            label="Nombre(s)"
            name="first_name"
            register={register}
            errors={errors.first_name && errors.first_name.message}
            placeholder="ingresa tu nombre o nombres"
          />
          <RenderInput
            label="Apellido(s)"
            name="last_name"
            register={register}
            errors={errors.last_name && errors.last_name.message}
            placeholder="ingresa tu apellido o apellidos"
          />
          <RenderInputPhone
            label="Telefono"
            name="phone"
            control={control}
            errors={errors.phone && errors.phone.message}
            placeholder="ingresa tu telefono"
          />
          <RenderInput
            label={"Puesto de Trabajo"}
            name="position_title"
            register={register}
            errors={errors.position_title && errors.position_title.message}
            placeholder="ingresa tu puesto de trabajo"
          />
        </Row>
      </Form>
    </>
  );
};

export default Edit;
