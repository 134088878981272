import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { MdPhone, MdEmail } from "react-icons/md"
import { IoLogoWhatsapp } from 'react-icons/io'
import moment from "moment"

import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { urlWhatsQuoteMessage } from "../../../utils";

export default function RenderModalContact({ data, hide }) {
  const info = data.data;
  return (
    <>
      <Modal show={data.show} onHide={hide}>
        <Modal.Header closeButton>
          <h5 className="fyb_h5 text-center">Informacion de Estimacion</h5>
        </Modal.Header>
        {info && (
          <Modal.Body>
            <Row>
              <label>Cuidad de origen</label>
              <h5>{`${info?.origin_city?.name}, ${info?.origin_city?.state?.name}, ${info?.origin_city?.state?.country?.name}`}</h5>
            </Row>
            <Row>
              <label>Cuidad de destino</label>
              <h5>{`${info?.destination_city?.name}, ${info?.destination_city?.state?.name}, ${info?.destination_city?.state?.country?.name}`}</h5>
            </Row>
            <Row>
              <Col>
                <label>Fecha de Carga</label>
                <h5>{moment(info?.ready_pickup_date).format("DD/MM/YYYY hh:mm")}</h5>
              </Col>
              <Col>
                <label>Total</label>
                <h5> {info?.total === null ? "Contacta a la compañía" : "$ " + info?.total}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Numero de Tarimas</label>
                <h5>{info?.pallets}</h5>
              </Col>
              <Col>
                <label>Peso</label>
                <h5> {info?.weight} kg</h5>
              </Col>
            </Row>
            <Row>
                <label>Caja Tráiler</label>
                <h5>{info?.bed_type?.name}</h5>
            </Row>
            <Row>
                <label>Compañía</label>
                <h5>{info?.company?.name}</h5>
            </Row>
            <hr />
            <Row>
              <Col>
                <label>Correo Electrónico</label>
                <h5>{info?.company?.contact_information?.email ? info?.company?.contact_information?.email : "N/E"}</h5>
              </Col>
              <Col>
                <label>Telefono</label>
                <h5>{info?.company?.contact_information?.phone ? formatPhoneNumberIntl(info?.company?.contact_information?.phone) : info?.company?.contact_information?.phone }</h5>
              </Col>
            </Row>
            <Row>
              <label>Nombre de contacto</label>
              <h5>{info?.company?.contact_information?.name ? info?.company?.contact_information?.name : "N/E"}</h5>
            </Row>
            <Modal.Footer className="d-flex justify-content-between">
              {info?.company && <a target="_blank" rel="noreferrer" href={urlWhatsQuoteMessage(info, true)} className="btn btn-success text-light btn-sm">
                <IoLogoWhatsapp  /> {info?.company?.contact_information?.phone}
              </a>}
              {info?.company?.contact_information?.email && (<a href={`mailto:${info?.company?.contact_information?.email}?body=` + encodeURIComponent(`Hola me interesa tu carga con numero de referencia ${info.internal_reference_number} con origen ${info.origin_city.name}, ${info.origin_city.state.name}, ${info.origin_city.state.country.name}, con destino a ${info.destination_city.name}, ${info.destination_city.state.name}, ${info.destination_city.state.country.name}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`)} className="btn btn-primary text-light btn-sm">
                <MdEmail /> {info?.company?.contact_information?.email}
              </a>)}
              {info?.company?.contact_information?.phone && <a href={`tel:${info?.company?.contact_information?.phone}`} className="btn btn-primary text-light btn-sm">
                <MdPhone  /> {info?.company?.contact_information?.phone}
              </a>}
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
