import styled  from "styled-components";

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.state ? "#00C853" : "#FF1744"};
`;

const RenderCircle = ({ state }) => {
  return <Circle state={state} />;
}

export default RenderCircle;