import { Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import styled  from "styled-components";

const Input = styled.div`
  position: relative;
  input {
    width: 100%;
  }
  svg{
    position: absolute;
    right: 6px;
    top: 12px;
    background-color: #fff;
  }
`;

const RenderInputCancel = ({
  register,
  name,
  type,
  placeholder,
  error,
  defaultValue,
  close,
  onClose
}) => {
  return (
    <Input >
      <Form.Control
        {...register(name)}
        isInvalid={error}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
      />
      {close && <MdClose onClick={onClose} />}
    </Input>
  );
};

export default RenderInputCancel;
