import {  Row, Col, Table } from "react-bootstrap";
import PaginationFyB from "./PaginationFyB";
import RenderEmailContent from "./RenderEmailContent";
import RenderInviteStatus from "./RenderInviteStatus";
import moment from "moment";

const RenderInvitations = ({
  invitations,
  isMobile,
  reloadInvitations,
}) => {

  return (
    <>
      {isMobile ? (
        <>
          {invitations && (
            <>
              {invitations.results.map((e, i) => {
                return (
                  <div className="v2_dashboard_body_box">
                    <Row>
                      <Col xl={3} lg={3} md={6} sm={6}>
                        {moment(e.created).format("DD/MM/YYYY")}
                      </Col>
                      <RenderInviteStatus status={e.used} isMobile={isMobile} />
                      <Col xl={3} lg={3} md={6} sm={6}>
                        {e.first_name} {e.last_name}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={3} lg={3} md={12} sm={12}>
                        {e.used ? (
                          <RenderEmailContent
                            mailto={e.email}
                            label={e.email}
                          />
                        ) : (
                          window.location.origin + "/register/" + e.token + "/"
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <PaginationFyB data={invitations} reloader={reloadInvitations} />
            </>
          )}
        </>
      ) : (
        <>
          <div className="dashboard_main_container_table">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                {invitations && invitations.results.length > 0 ? (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <td>Fecha de Inivitacion</td>
                          <td>Nombre</td>
                          <td>Correo Electrónico / Link de invitación</td>
                          <td>Status</td>
                        </tr>
                      </thead>
                      <tbody>
                        {invitations &&
                          invitations.results.map((e, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    {moment(e.created).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    {e.first_name} {e.last_name}
                                  </td>
                                  <td>
                                    {e.used ? (
                                      <RenderEmailContent
                                        mailto={e.email}
                                        label={e.email}
                                      />
                                    ) : (
                                      window.location.origin +
                                      "/register/" +
                                      e.token +
                                      "/"
                                    )}
                                  </td>
                                  <RenderInviteStatus
                                    isMobile={isMobile}
                                    status={e.used}
                                  />
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                    <PaginationFyB
                      data={invitations}
                      reloader={reloadInvitations}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default RenderInvitations;
