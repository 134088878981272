import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import useFetch from "../../../hooks/useFetch";
import { colourStyles, citiesStyles } from "../../../utils/styles";
import { addNewOrderValidation } from "../../../utils/formsValidation";
import {
  statusOptionsAdd,
  addNewOrderDefaultValues,
} from "../../../utils/defaultValues";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";
import RenderSelectCity from "../../../fyb_components/results/RenderSelectCity";

const extrasData = [
  { label: "Equipo de carga", value: "team" },
  { label: "Airport PU/DL", value: "airport_pu/dl" },
  { label: "Hazmat", value: "hazmat" },
  { label: "Straps", value: "straps" },
  { label: "Scale", value: "scale" },
  { label: "Liftgate", value: "liftgate" },
  { label: "Inside Del.Plts", value: "inside_del.plts" },
  { label: "Extra Plts", value: "extra_plts" },
];

export default function ModalCreateQoute({
  show,
  setShow,
  getMore,
  bedTypes = [],
  points = [],
}) {
  const [extras, setExtras] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(addNewOrderValidation),
  });
  const onSubmit = (data) => {
    setShow(false);
    toast.success(
      <>
        <Spinner animation="border" variant="primary" />
      </>
    );
    addNewOrder(data);
  };
  const { fletesAPI } = useFetch();
  const addNewOrder = async (data) => {
    await fletesAPI
      .post(`api/freightpost/`, {
        company_id: data.company_id,
        status: data.status.value,
        origin_city_id: data.origin_city_id.value,
        destination_city_id: data.destination_city_id.value,
        weight: data.weight === "" ? null : data.weight,
        height: data.height === "" ? null : data.height,
        length: data.length === "" ? null : data.length,
        width: data.width === "" ? null : data.width,
        number_of_pallets:
          data.number_of_pallets === "" ? null : data.number_of_pallets,
        point_of_contact_id: data.point_of_contact_id.value,
        bed_type_ids: data.bed_type_ids.flatMap((e) => [e.value]),
        rate: data.rate === "" ? null : data.rate,
        currency: data.currency,
        internal_reference_number: data.internal_reference_number,
        comments: data.comments,
        ready_pickup_date: moment.utc(data.ready_pickup_date).format(),
        origin_zip_code: data.origin_zip_code,
        destination_zip_code: data.destination_zip_code,
      })
      .then(() => {
        getMore("init");
        reset(addNewOrderDefaultValues);
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const bedTypeOptions =
    bedTypes === null
      ? []
      : bedTypes.map((e) => ({ value: e.id, label: e.name }));
  const pointsOptions =
    points === null
      ? []
      : points.map((e) => ({
          value: e.id,
          label: `${e.first_name} ${e.last_name}`,
        }));

  const updateExtras = (e, extra) => {
    if (e.target.checked) {
      setExtras([...extras, extra]);
    } else {
      setExtras(extras.filter((i) => i !== extra));
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset(addNewOrderDefaultValues);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Crear cotizacion</h5>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column gap-3">
            <Row>
              <RenderSelectCity
                name="origin_city_id"
                control={control}
                errors={
                  errors.origin_city_id && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("city_required")}
                    </p>
                  )
                }
                label="Ciudad de Origen"
                placeholder="Seleccione ciudad de origen"
                defaultValue={null}
              />
            </Row>
            <Row>
              <RenderSelectCity
                name="destination_city_id"
                control={control}
                errors={
                  errors.destination_city_id && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("city_required")}
                    </p>
                  )
                }
                label="Ciudad de Destino"
                placeholder="Seleccione ciudad de destino"
                defaultValue={null}
              />
            </Row>
            {/* <Row>
              <Col>
                <Form.Group controlId="formCodeZipOrigin">
                  <Form.Label>Código postal de origen</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="ingrese su código postal de origen"
                    {...register("origin_zip_code")}
                    isInvalid={errors.origin_zip_code}
                  />
                  {errors.origin_zip_code && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("zip_code_required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formCodeZipDestiny">
                  <Form.Label>Código postal de destino</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="ingrese su código postal de destino"
                    {...register("destination_zip_code")}
                    isInvalid={errors.destination_zip_code}
                  />
                  {errors.destination_zip_code && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("zip_code_required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row> */}
            {/* <Row className="dashboard_main_container_form_select">
              <Form.Group controlId="formLength">
                <Form.Label>Punto de contacto</Form.Label>
                <Controller
                  name="point_of_contact_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Seleccione su punto de contacto"
                      {...field}
                      isClearable
                      styles={citiesStyles}
                      options={pointsOptions}
                    />
                  )}
                />
                {errors.point_of_contact_id && (
                  <p className="dashboard_form_error_message">
                    {mensajesFormulario("point_of_contact_required")}
                  </p>
                )}
              </Form.Group>
            </Row> */}
            <Row className="dashboard_main_container_form_select">
              <Form.Group controlId="formBedType">
                <Form.Label>Tipo de Tráiler</Form.Label>
                <Controller
                  name="bed_type_ids"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Selecciona los tipos de tráiler"
                      isClearable
                      isMulti
                      {...field}
                      options={bedTypeOptions}
                    />
                  )}
                />
                {errors.bed_type_ids && (
                  <p className="dashboard_form_error_message">
                    {mensajesFormulario("bed_type_required")}
                  </p>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="date">
                  <Form.Label>Recoleccion</Form.Label>
                  <Controller
                    control={control}
                    name="ready_pickup_date"
                    render={({ field }) => (
                      <DatePicker
                        className={`form-control${
                          errors.ready_pickup_date ? " is-invalid" : ""
                        } `}
                        placeholderText="Selecciona fecha"
                        minDate={new Date()}
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                      />
                    )}
                  />
                  {errors.ready_pickup_date && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("ready_pickup_date_required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="date">
                  <Form.Label>Entrega</Form.Label>
                  <Controller
                    control={control}
                    name="ready_pickup_date"
                    render={({ field }) => (
                      <DatePicker
                        className={`form-control${
                          errors.ready_pickup_date ? " is-invalid" : ""
                        } `}
                        placeholderText="Selecciona fecha"
                        minDate={new Date()}
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                      />
                    )}
                  />
                  {errors.ready_pickup_date && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("ready_pickup_date_required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formLength">
                  <Form.Label>Tarimas</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Número de tarimas"
                      {...register("number_of_pallets")}
                      isInvalid={errors.pallets}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formWeight">
                  <Form.Label>Peso</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Introducir peso"
                      {...register("weight")}
                      isInvalid={errors.weight}
                    />
                    <InputGroup.Text>Kg</InputGroup.Text>
                  </InputGroup>
                  {errors.weight && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("weight_required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formLength">
                  <Form.Label>Producto</Form.Label>
                  <Form.Control
                    placeholder="Producto de carga"
                    {...register("product")}
                    isInvalid={errors.product}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
              <Col style={{ display: !watch("paletas") && "none" }}>
                <Form.Group className="mb-3" controlId="formLength">
                  <Form.Label>Indique número de tarimas</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Número de tarimas"
                      {...register("number_of_pallets")}
                      isInvalid={errors.length}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col style={{ display: watch("paletas") && "none" }}>
                <Form.Group className="mb-3" controlId="formLength">
                  <Form.Label>Longitud</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="ingresar la longitud"
                      {...register("length")}
                      isInvalid={errors.length}
                    />
                    <InputGroup.Text>m</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formWeight">
                  <Form.Label>Peso</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Introducir peso"
                      {...register("weight")}
                      isInvalid={errors.weight}
                    />
                    <InputGroup.Text>Kg</InputGroup.Text>
                  </InputGroup>
                  {errors.weight && (
                    <p className="dashboard_form_error_message">
                      {mensajesFormulario("weight_required")}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row> */}
            {/* <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formRate">
                  <Form.Label>Tarifa</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa tu tarifa"
                      {...register("rate")}
                      isInvalid={errors.rate}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <FormCurrencySelect name={"currency"} register={register} />
              </Col>
            </Row> */}
            {/* <Row>
              <Col className="dashboard_main_container_form_select">
                <Form.Group controlId="formStatusSelect">
                  <Form.Label>Estado</Form.Label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Status"
                        {...field}
                        styles={colourStyles}
                        defaultValue={statusOptionsAdd[1]}
                        options={statusOptionsAdd}
                        isClearable
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            {/* <Row>
              <Form.Group className="mb-3" controlId="formLoadDetails">
                <Form.Label>Número de referencia interna</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="número de referencia interna"
                  {...register("internal_reference_number")}
                  isInvalid={errors.internal_reference_number}
                />
                {errors.internal_reference_number && (
                  <p className="dashboard_form_error_message">
                    {mensajesFormulario("internal_reference_number_required")}
                  </p>
                )}
              </Form.Group>
            </Row> */}
            {/* <Row>
              <Form.Group className="mb-3" controlId="formComments">
                <Form.Label>
                  Descripcion de carga e Instrucciones Especiales
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...register("comments")}
                  isInvalid={errors.comments}
                />
                {errors.comments && (
                  <p className="dashboard_form_error_message">
                    {mensajesFormulario("comments_required")}
                  </p>
                )}
              </Form.Group>
            </Row> */}
            <hr />
            <div className="checkbox-grid-3">
              {extrasData.map((extra, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={extra.label}
                  name="team"
                  id={extra.value}
                  onChange={(e) => updateExtras(e, extra.value)}
                />
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" size="sm" type="submit">
              Crear Orden
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset(addNewOrderDefaultValues);
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
