import React from "react";
import { Modal, Button } from "react-bootstrap";
import useFetch from '../../../hooks/useFetch'
import toast from 'react-hot-toast';
import { mensajesAPI } from "../../../utils/mensajes.js";

export default function ModalDelete({ show, setShow, dataDelete, getMore }) {
  const { fletesAPI } = useFetch()
  const deleteOrder = async () => {
    await fletesAPI
      .delete(`api/freightpost/${dataDelete.id}/`)
      .then(() => {
        setShow(false);
        getMore("init");
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <h6>
          ¿Estás seguro de eliminar esta orden?
          </h6> 
          <p>{dataDelete && `De ${dataDelete.origin_city.name}, ${dataDelete.origin_city.state.name}, ${dataDelete.origin_city.state.country.name} a ${dataDelete.destination_city.name}, ${dataDelete.destination_city.state.name}, ${dataDelete.destination_city.state.country.name} | ${dataDelete.point_of_contact.first_name} ${dataDelete.point_of_contact.last_name}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            size="sm"
            onClick={() => deleteOrder()}
          >
            Eliminar
          </Button>
          <Button variant="danger" size="sm" onClick={() => setShow(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}