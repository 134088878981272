import { useState, useRef, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import { DataURIToBlob } from "../../../utils";
import { mensajesAPI } from "../../../utils/mensajes";
import { MdClose } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import { BodegasContext } from "../../../context";
import RenderDropPictureProfile from "../RenderDropPictureProfile";
import { profileType } from "../../../context/reducers";

const AvatarEdit = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  input {
    width: 100%;
  }
  .groupRanges {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .avatarCont {
    position: relative;
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
      fill: #fff;
      z-index: 1;
      width: 20px;
      height: 20px;
    }
  }
`;

const RenderModalUpdateProfilePicture = ({ state, setState, setProfile }) => {
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState(null);
  const [rotate, setRotate] = useState(0);
  const editor = useRef(null);
  const { fletesAPIFile } = useFetch();
  const { userId, dispProfile } = useContext(BodegasContext);

  const resetAll = () => {
    setZoom(1);
    setImage(null);
    setRotate(0);
  };

  const getImage = () => {
    const img64 = editor.current.getImageScaledToCanvas().toDataURL();
    return DataURIToBlob(img64);
  };

  const uploadProfilePicture = async () => {
    if (image) {
      let formData = new FormData();
      formData.append("file_content", getImage(), image.name);
      await fletesAPIFile
        .patch(`api/users/${userId}/profile/photo/`, formData)
        .then((e) => {
          toast.success(mensajesAPI("toast_success_saving_message"));
          setProfile(e.data);
          setState(false);
          dispProfile({ type: profileType.setProfile, profile: e.data });
          resetAll();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast.error("Debes seleccionar una imagen");
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={state}
        onHide={() => {
          setState(false);
          resetAll();
        }}
        className="modal-dialog-centered"
      >
        <Modal.Header closeButton />
        <Modal.Body className=" overflow-auto">
          <AvatarEdit>
            <h5>Actualizar imagen de perfil</h5>

            {image ? (
              <div className="avatarCont">
                <MdClose onClick={() => setImage(null)} />
                <AvatarEditor
                  image={image}
                  width={310}
                  height={310}
                  border={20}
                  color={[0, 0, 0, 0.6]}
                  scale={zoom}
                  rotate={rotate}
                  borderRadius={180}
                  style={{ borderRadius: "10px" }}
                  ref={editor}
                />{" "}
              </div>
            ) : (
              <RenderDropPictureProfile setImage={setImage} />
            )}
            <div className="groupRanges">
              <Form.Group>
                <Form.Label>Zoom:</Form.Label>
                <input
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  value={zoom}
                  onChange={(e) => setZoom(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Rotar:</Form.Label>
                <input
                  type="range"
                  min="0"
                  max="360"
                  step="1"
                  value={rotate}
                  onChange={(e) => setRotate(e.target.value)}
                />
              </Form.Group>
            </div>
          </AvatarEdit>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => uploadProfilePicture()}>
            Actualizar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setState(!state);
              resetAll();
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenderModalUpdateProfilePicture;
