import { Form as Formu, Row, Col, Button, Nav } from "react-bootstrap";
import { BodegasContext } from "../context";
import { sessionType } from "../context/reducers/index";
import axios from "axios";
import Footer from "../components/Footer";
import FybNavigationBar from "../components/FybNavigationBar";
import { Container, Card } from "react-bootstrap";
import { BsTruck } from "react-icons/bs";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import RenderInput from "../fyb_components/results/RenderInput";
import toast from "react-hot-toast";
import { erroresCodes } from "../utils";
import RenderInputPhone from "../fyb_components/results/RenderInputPhone";

export default function RegisterConfirm() {
    const { session, dispSession } = useContext(BodegasContext);

    const styles = {
  container: {
    height: "1000px",
    background: "#3E85C6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputCont: {
    position: "relative",
    cursor: "pointer",
  },
  inputEye: {
    position: "absolute",
    right: "5px",
    top: "0",
    bottom: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "120px",
  },
};

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        // Get query parameters from the URL
        const queryParams = new URLSearchParams(window.location.search);

        // Extract specific parameters
        const emailParam = queryParams.get('email') || '';
        const phoneParam = queryParams.get('phone') || '';
        setValue("email", emailParam);
        setValue("phone", phoneParam);
        setEmail(emailParam);
        setPhone(phoneParam);
      }, []);

    const registerValidation = yup.object().shape({
        code: yup.string().required(),
    });

    const [viewPass, setViewPass] = useState(true);
    const { token } = useParams();
        const {
            handleSubmit,
            register,
            formState: { errors },
            reset,
            control,
            setValue
  } = useForm({
    resolver: yupResolver(registerValidation),
  });

   const onSubmit = (data) => {
    validatePhoneNumber(data);
  };

  const resendReverify = async () => {
    var data = {}
    data['phone'] = phone
    await axios
      .post(`${process.env.REACT_APP_URL_API}api/users/phone/requestverify/`, data)
      .then((e) => {
        toast.success("Se ha re-enviado el codigo por mensaje de texto");
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error("Error en Fletes Y Bodegas");
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
    });
  };

  const validatePhoneNumber = async (data) => {
    const valLof = () => {
      if (token) {
        return { ...data, token: token };
      } else {
        return data;
      }
    };
    await axios
      .post(`${process.env.REACT_APP_URL_API}api/users/phone/verify/`, valLof())
      .then((e) => {
          dispSession({ type: sessionType.setSession, session: e.data.session });
          toast.success("Usuario ha sido verificado con exito!");
          reset();
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 800);
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error(
            "Error en Fletes & Bodegas, favor de contactar soporte@fletesybodegas.com"
          );
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };

  return (
    <>
      <FybNavigationBar />
      <div style={styles.container}>
      <Container className="d-flex justify-content-center align-items-center">
        <Card style={{width:600}}>
          <Card.Header>
            <h4 className="m-0">Verifica tu telefono</h4>
          </Card.Header>
          <Card.Body className="d-flex flex-column justify-content-center align-items-center gap-2">
            <Card.Text>
              Hola, te enviamos un mensaje de texto para verificar tu telefono, porfavor escribelo aqui.
                <Formu className="bg-light p-4 rounded" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <RenderInput
                          name={"email"}
                          label={"Correo electrónico"}
                          placeholder={"Ingresa tu correo electrónico"}
                          register={register}
                          error={errors.email}
                        />
                    </Row>
                    <Row>
                        <RenderInputPhone
                          control={control}
                          label="Telefono"
                          placeholder="Ingresa tu telefono"
                          defaultCountry="MX"
                          name="phone"
                          required={true}
                          errors={errors.phone}
                        />
                    </Row>
                    <Row>
                        <RenderInput
                          name={"code"}
                          label={"Codigo de Mensaje de Texto"}
                          placeholder={"Verifica tu numero de telefono por un mensaje de texto"}
                          register={register}
                          error={errors.email}
                        />
                    </Row>
                    <Row>
                        <Col>
                          <Button className="text-light w-100" type="submit">
                              Verificar Cuenta
                          </Button>
                        </Col>
                    </Row>
                </Formu>
                <a href="#" onClick={() => resendReverify()}>Re-enviar mensaje de texto para verificar</a>
            </Card.Text>
            <BsTruck style={{ color: "var(--fyb_darkblue)", fontSize: 80 }} />
          </Card.Body>
        </Card>
      </Container>
      </div>
      <Footer />
    </>
  );
}
