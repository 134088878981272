import { useState, useContext ,useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import RenderSelectCompany from "../../../fyb_components/results/RenderSelectCompany";
import { useForm } from "react-hook-form";

const AdminRoutes = () => {
  const [routes, setRoutes] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const { control, handleSubmit, watch } = useForm();

  const onSubmit = (data) => {
    updateUserById(data.company.value);
  };

  const getRoutes = async () => {
    await fletesAPI
      .get(`api/truckroutes/`)
      .then((e) => {
        setRoutes(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadRoutes = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setRoutes(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateUserById = async (id) => {
    await fletesAPI
      .get(`api/truckroutes/?company_id=${id}`)
      .then((e) => {
        setRoutes(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteRoutes = async (id) => {
    await fletesAPI
      .delete(`api/truckroutes/${id}/`)
      .then((e) => {
        getRoutes();
        toast.success("La ruta ha sido eliminado con exito");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!routes) {
      getRoutes();
    }
  }, [routes]);

  const focusCompany = watch("company");

  useEffect(() => {
    if (focusCompany === undefined || focusCompany === null) {
      getRoutes();
    }
  }, [focusCompany]);
  console.log(routes);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todas las Rutas</h4>
            <div className="d-flex">
              {/* <Button
                className="text-white mx-2"
                onClick={() => setModalAdd(true)}
              >
                Crear Usuario
              </Button> */}
              <Form
                className="d-flex"
                style={{ width: "400px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <RenderSelectCompany
                  name={"company"}
                  control={control}
                  defaultValue={null}
                  placeholder={"Buscar por empresa"}
                />
                <Button type="submit" className="text-white">
                  Buscar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Ciudad de origen</th>
              <th>Ciudad de destino</th>
              <th>Tipos de tráiler</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {routes &&
              routes.results.map((e, i) => (
                <tr key={i}>
                  <td>{`${e.origin_city.name}, ${e.origin_city.state.name}, ${e.origin_city.state.country.name}`}</td>
                  <td>
                    {`${e.destination_city.name}, ${e.destination_city.state.name}, ${e.destination_city.state.country.name}`}
                  </td>
                  <td>
                    {e.bed_type.map((e, i) => (
                      <span key={i}>{`${e.name}, `}</span>
                    ))}
                  </td>
                  <td>
                    <RenderButtonMore
                      buttons={[
                        {
                          label: (
                            <>
                              Editar <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/adminroutes/${e.id}/edit`;
                          },
                        },
                        {
                          label: (
                            <>
                              Eliminar <MdDelete />
                            </>
                          ),
                          onClick: () => {
                            setDataSelected(e);
                            setModalDelete(true);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {routes && (
          <Pagination>
            <Pagination.Prev
              disabled={!routes.previous}
              onClick={() => reloadRoutes(routes.previous)}
            />
            <Pagination.Next
              disabled={!routes.next}
              onClick={() => reloadRoutes(routes.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteRoutes(dataSelected.id)}
        confirmText="¿Estas seguro de eliminar esta ruta?"
      />
    </>
  );
};

export default AdminRoutes;
