import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import RenderEmailContent from "./RenderEmailContent"

const RenderEmail = ({ mailto, label }) => {

    return (
    <>
        <Col xl={4} lg={3} md={6} sm={6}>
            <p>
                <RenderEmailContent mailto={mailto} label={label} />
            </p>
        </Col>
    </>
    );
};

export default RenderEmail;