import { useState, useContext, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";
import { MdDelete, MdAddPhotoAlternate, MdEdit } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import ModalAddWarehouse from "./ModalAddWarehouse";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import RenderStatus from "../../../fyb_components/results/RenderStatus";
import RenderHours from "../../../fyb_components/results/RenderHours";
import RenderSelectCompany from "../../../fyb_components/results/RenderSelectCompany";
import { useForm } from "react-hook-form";

const AdminWarehouses = () => {
  const [warehouses, setWarehouses] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const { control, handleSubmit, watch } = useForm();

  const onSubmit = (data) => {
    updateWarehouseById(data.company.value);
  };

  const getWarehouses = async () => {
    await fletesAPI
      .get(`api/warehouses/`)
      .then((e) => {
        setWarehouses(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadWarehouses = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setWarehouses(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateWarehouseById = async (id) => {
    await fletesAPI
      .get(`api/warehouses/`, {
        params: {
          company_id: id,
        },
      })
      .then((e) => {
        setWarehouses(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteWarehouse = async (id) => {
    await fletesAPI
      .delete(`api/warehouses/${id}/`)
      .then((e) => {
        getWarehouses();
        toast.success("Bodega eliminada");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!warehouses) {
      getWarehouses();
    }
  }, [warehouses]);

  const focusCompany = watch("company");

  useEffect(() => {
    if (focusCompany === undefined || focusCompany === null) {
      getWarehouses();
    }
  }, [focusCompany]);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todas las Bodegas</h4>
            <div className="d-flex">
              <Button
                className="text-white mx-2"
                onClick={() => setModalAdd(true)}
              >
                Crear Bodega
              </Button>
              <Form
                className="d-flex"
                style={{ width: "400px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <RenderSelectCompany
                  name={"company"}
                  control={control}
                  defaultValue={null}
                  placeholder={"Buscar por empresa"}
                />
                <Button type="submit" className="text-white">
                  Buscar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Dirección</th>
              <th>Horario</th>
              <th>Estado</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {warehouses &&
              warehouses.results.map((e, i) => (
                <tr key={i}>
                  <td>{e.name}</td>
                  <td>{e.contact_information.address_line1}</td>
                  <td>
                    <RenderHours hours={e.hours} />
                  </td>
                  <RenderStatus status={e.status} />
                  <td>
                    <RenderButtonMore
                      buttons={[
                        {
                          label: (
                            <>
                              Imagenes <MdAddPhotoAlternate />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/adminwarehouses/${e.id}/images`;
                          },
                        },
                        {
                          label: (
                            <>
                              Editar <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/adminwarehouses/${e.id}/edit`;
                          },
                        },
                        {
                          label: (
                            <>
                              Eliminar <MdDelete />
                            </>
                          ),
                          onClick: () => {
                            setDataSelected(e);
                            setModalDelete(true);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {warehouses && (
          <Pagination>
            <Pagination.Prev
              disabled={!warehouses.previous}
              onClick={() => reloadWarehouses(warehouses.previous)}
            />
            <Pagination.Next
              disabled={!warehouses.next}
              onClick={() => reloadWarehouses(warehouses.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteWarehouse(dataSelected.id)}
        confirmText="¿Estas seguro de eliminar este almacen?"
      />
      <ModalAddWarehouse
        state={modalAdd}
        setState={setModalAdd}
        getMore={getWarehouses}
      />
    </>
  );
};

export default AdminWarehouses;
