import React from "react";
import { Col } from "react-bootstrap";
import RenderCityContent from "./RenderCityContent";

const RenderCityResult = ({
  origin_city,
  destination_city,
  display_city,
  subtitle,
  isMobile,
}) => {
  const generateGoogleMapsLink = () => {
    if (!origin_city || !destination_city) return "";
    
    const origin = `${origin_city.name.split(" ").join("+")},${origin_city.state.name.split(" ").join("+")},${origin_city.state.country.name.split(" ").join("+")}`;
    const destination = `${destination_city.name.split(" ").join("+")},${destination_city.state.name.split(" ").join("+")},${destination_city.state.country.name.split(" ").join("+")}`;
    
    return `https://www.google.com/maps/dir/${origin}/${destination}`;
  };

  if (!origin_city || !destination_city || !display_city) {
    return <td> Cuidad no encontrada </td>; // Si falta alguno de los datos necesarios, no renderiza nada
  }

  return (
    <>
      {isMobile ? (
        <Col xl={5} lg={5} md={12} sm={12}>
          <p>
            {subtitle}
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href={generateGoogleMapsLink()}
            >
              <RenderCityContent city={display_city} />
            </a>
          </p>
        </Col>
      ) : (
        <td>
          <a
            target="_blank"
            rel="noreferrer"
            href={generateGoogleMapsLink()}
          >
            <RenderCityContent city={display_city} />
          </a>
        </td>
      )}
    </>
  );
};

export default RenderCityResult;