import { Button } from "react-bootstrap";
import { MdDelete, MdVisibility } from "react-icons/md";
import styled from "styled-components";

const ImageViewAction = styled.div`
  position: relative;
  width: 300px;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hoverButtons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
    .buttonDelete {
      margin-right: 10px;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 1px 5px;
    }
    .buttonVisualizer {
      margin-left: 10px;
      color: white;
      font-size: 30px;
    }
    &:hover {
      opacity: 1;
    }
  }
`;

const ImageAction = ({ image, onRemove, onView }) => {
  return (
    <ImageViewAction>
      <div className="hoverButtons" onClick={onView}>
        <Button variant="danger" className="buttonDelete" onClick={onRemove}>
          <MdDelete />
        </Button>
        <MdVisibility className="buttonVisualizer" />
      </div>
      <img src={image} alt="" />
    </ImageViewAction>
  );
};

export default ImageAction;
