import styled from "styled-components";

const SwitchButtonCont = styled.div`
display: flex;
align-items: center;
width: 50px;
height: 25px;
border-radius: 25px;
background-color: ${(e) => (e.state ? "#4085c6" : "#e5e5e5")};
padding: 0;
transition: 0.2s;
cursor: pointer;
margin-right: 10px;
.circle {
  transition: 0.2s;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  margin: ${(e) => (e.state ? "0 0 0 28px" : "0 0 0 4px")};
}
`;

const RenderSwitch = ({state, setState, className, onClick}) => {
  const toggleSwitch = () => {
    setState(!state);
  };
  return (
    <SwitchButtonCont className={className} state={state} onClick={onClick ? onClick : toggleSwitch}>
      <div className="circle"></div>
    </SwitchButtonCont>
  );
};

export default RenderSwitch;
