import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ImageAction from "../ImageAction";
import ButtonAddImage from "../ButtonAddImage";
import styled from "styled-components";
import useFetch from "../../../../hooks/useFetch";
import ImageViewer from 'react-simple-image-viewer'; 
import RenderModalConfirm from "../../../../fyb_components/results/Modals/RenderModalConfirm";
import toast from "react-hot-toast";

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Images = ({ warehouseData }) => {
  const [images, setImages] = useState(null);
  const [imagesViewer, setImagesViewer] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [modalConfirm, setModalConfirm] = useState(false);
  const { fletesAPI } = useFetch();

  const imagesArray = images ? images.results.map((e) => e.url) : [];

  const getImages = () => {
    fletesAPI
      .get(`api/warehouses/${warehouseData.id}/photos/`)
      .then((e) => {
        setImages(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const deleteImage = (id) => {
    fletesAPI
      .delete(`api/warehouses/${warehouseData.id}/photos/`, {
        data: {
          photo_id: id,
        }
      })
      .then((e) => {
        getImages();
        toast.success("Imagen eliminada");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if(images === null && warehouseData){
      getImages();
    }
  }, [images, warehouseData]);
  return (
    <>
      <Container>
        <Row>
          <Col>
          <ImagesContainer>
            {images && images.results.map((e, i) => (
              <ImageAction
                key={i}
                image={e.url}
                onRemove={(u) => {
                  u.stopPropagation();
                  setImageSelected(e);
                  setModalConfirm(true);
                }}
                onView={() => {
                  setCurrentImage(i);
                  setImagesViewer(true);
                }}
              />
            ))}
            <ButtonAddImage getMore={getImages} info={warehouseData}  />
          </ImagesContainer>
          </Col>
        </Row>
      </Container>
      {imagesViewer && (
        <ImageViewer
          src={imagesArray}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => {
            setImagesViewer(false);
          }}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 10000,
          }}
          
        />
      )}
      <RenderModalConfirm
        state={modalConfirm}
        setState={setModalConfirm}
        confirmText={"¿Estas seguro de eliminar esta imagen?"}
        confirmModal={() => {
          deleteImage(imageSelected.id);
          setModalConfirm(false);
          getImages();
        }}
      />
    </>
  );
};

export default Images;
