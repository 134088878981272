import { useState, useEffect } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { erroresCodes } from "../../../utils";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import { useNavigate } from "react-router-dom";
import RenderBreadCrumbs from "./RenderBreadCrumbs";
import RenderDetails from "./views/RenderDetails";
import RenderPublic from "./views/RenderPublic";
import RenderGPS from "./views/RenderGPS";
import RenderFiles from "./views/RenderFiles";
import RenderComments from "./views/RenderComments";
import RenderExpenses from "./views/RenderExpenses";
import RenderSpace from "../../../fyb_components/results/RenderSpace";

const viewList = {
  public: "public",
  details: "details",
  gps: "gps",
  files: "files",
  comments: "comments",
  share: "share",
  expenses: "expenses"
}

export default function MyOrderDetail() {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [typeHook, setTypeHook] = useState("public");
  const { fletesAPI } = useFetch();
  const { id, type } = useParams();
  const navigate = useNavigate();

  const getOrder = async () => {
    setLoading(true);
    await fletesAPI
      .get(`api/freightpost/${id}/detail/`)
      .then((e) => {
        setOrder(e.data);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
        setLoading(false);
      });
  };

  const updateOrder = async () => {
    await fletesAPI
      .get(`api/freightpost/${id}/detail/`)
      .then((e) => {
        setOrder(e.data);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
      });
  };

  useEffect(() => {
    if (type !== undefined) {
      setTypeHook(type);
    }
  }, [type]);

  useEffect(() => {
    if (order === null) {
      getOrder();
    }
  }, [order]);
  return (
    <>
      <Container>
        <Row className="mt-5">
          {!loading && 
          <RenderBreadCrumbs
            breadcrumbs={[
              {
                label: order.public.origin_city ? `${order.public.origin_city.name}, ${order.public.origin_city.state.name}, ${order.public.origin_city.state.country.name}` : "Cuidad de origen",
              },
              {
                label: order.public.destination_city ? `${order.public.destination_city.name}, ${order.public.destination_city.state.name}, ${order.public.destination_city.state.country.name}` : "Cuidad de destino",
              },
              {
                label: `${order.public.internal_reference_number}`,
              },
            ]}
          />}
        </Row>
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Publicación",
                  active: typeHook === viewList.public,
                  onClick: () => {
                    navigate(`/dashboard/adminorders/${id}/${viewList.public}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Detalles",
                  active: typeHook === viewList.details,
                  onClick: () => {
                    navigate(`/dashboard/adminorders/${id}/${viewList.details}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Rastreo | GPS",
                  active: typeHook === viewList.gps,
                  onClick: () => {
                    navigate(`/dashboard/adminorders/${id}/${viewList.gps}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Archivos",
                  active: typeHook === viewList.files,
                  onClick: () => {
                    navigate(`/dashboard/adminorders/${id}/${viewList.files}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Comentarios",
                  active: typeHook === viewList.comments,
                  onClick: () => {
                    navigate(`/dashboard/adminorders/${id}/${viewList.comments}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Gastos",
                  active: typeHook === viewList.expenses,
                  onClick: () => {
                    navigate(`/dashboard/adminorders/${id}/${viewList.expenses}`, {
                      replace: true,
                    });
                  },
                }
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && <RenderSpace><Spinner animation="border" variant="primary" /></RenderSpace>}
            {!loading && typeHook === viewList.details && <RenderDetails data={order.public} updateData={getOrder} />}
            {!loading && typeHook === viewList.public && <RenderPublic data={order.public} updateData={getOrder}/>}
            {!loading && typeHook === viewList.files && <RenderFiles data={order.public} updateData={getOrder} />}
            {!loading && typeHook === viewList.comments && <RenderComments data={order.public}  />}
            {!loading && typeHook === viewList.expenses && <RenderExpenses data={order.public}  />}
          </Col>
        </Row>
      </Container>
    </>
  );
}
