import React from "react";
import { Col, Modal, Row, InputGroup} from "react-bootstrap";
import { MdPhone, MdEmail } from "react-icons/md"
import { IoLogoWhatsapp } from 'react-icons/io'
import moment from "moment"
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const RenderModalOrderDetails = ({show, setShow, info}) => {

    const bedTypeArray = (e) => e.flatMap((d) => d.name);

    return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <h5 className="fyb_h5 text-center">Informacion de Carga</h5>
        </Modal.Header>
        {info && (
          <Modal.Body>
            <Row>
              <label>Cuidad de origen</label>
              <h5>{`${info.origin_city.name}, ${info.origin_city.state.name}, ${info.origin_city.state.country.name}`}</h5>
            </Row>
            <Row>
              <label>Cuidad de destino</label>
              <h5>{`${info.destination_city.name}, ${info.destination_city.state.name}, ${info.destination_city.state.country.name}`}</h5>
            </Row>
            <Row>
              <Col>
                <label>Fecha de Carga</label>
                <h5>{moment(info.ready_pickup_date).format("DD/MM/YYYY hh:mm")}</h5>
              </Col>
              <Col>
                <label>Tarifa</label>
                <h5> {info.rate === null ? "Contacta a la compañía" : "$ " + info.rate}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Numero de Tarimas</label>
                <h5>{info.number_of_pallets}</h5>
              </Col>
              <Col>
                <label>Peso</label>
                <h5> {info.weight} kg</h5>
              </Col>
            </Row>
            <Row>
                <label>Caja Tráiler</label>
                <h5>{bedTypeArray(info.bed_type).join(" | ")}</h5>
            </Row>
            <Row>
              <label>Numero de Referencia</label>
              <h5>{info.internal_reference_number}</h5>
            </Row>
            <Row>
              <label>Descripcion de carga e Instrucciones Especiales</label>
              <h5>{info.comments}</h5>
            </Row>
            <Row>
                <label>Compañía</label>
                <h5>{info.company.name}</h5>
            </Row>
            <hr />
            <Row>
              <Col>
                <label>Correo Electrónico</label>
                <h5>{info.point_of_contact.email}</h5>
              </Col>
              <Col>
                <label>Telefono</label>
                <h5>{formatPhoneNumberIntl(info.point_of_contact.phone) ? formatPhoneNumberIntl(info.point_of_contact.phone) : info.point_of_contact.phone }</h5>
              </Col>
            </Row>
            <Row>
              <label>Nombre de contacto</label>
              <h5>{`${info.point_of_contact.first_name} ${info.point_of_contact.last_name}`}</h5>
            </Row>
            <Modal.Footer className="d-flex justify-content-between">
              {info.point_of_contact.preference_reach_by_whatsapp && <a target="_blank" rel="noreferrer" href={`https://wa.me/${info.point_of_contact.phone}?text=` + encodeURIComponent(`Hola me interesa tu carga con numero de referencia ${info.internal_reference_number} con origen ${info.origin_city.name}, ${info.origin_city.state.name}, ${info.origin_city.state.country.name}, con destino a ${info.destination_city.name}, ${info.destination_city.state.name}, ${info.destination_city.state.country.name}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`)} className="btn btn-success text-light btn-sm">
                <IoLogoWhatsapp  /> {info.point_of_contact.phone}
              </a>}
              {info.point_of_contact.preference_reach_by_email && <a href={`mailto:${info.point_of_contact.email}?body=` + encodeURIComponent(`Hola me interesa tu carga con numero de referencia ${info.internal_reference_number} con origen ${info.origin_city.name}, ${info.origin_city.state.name}, ${info.origin_city.state.country.name}, con destino a ${info.destination_city.name}, ${info.destination_city.state.name}, ${info.destination_city.state.country.name}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`)} className="btn btn-primary text-light btn-sm">
                <MdEmail /> {info.point_of_contact.email}
              </a>}
              {info.point_of_contact.preference_reach_by_phone && <a href={`tel:${info.point_of_contact.phone}`} className="btn btn-primary text-light btn-sm">
                <MdPhone  /> {info.point_of_contact.phone}
              </a>}
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    </>
    );
};

export default RenderModalOrderDetails;