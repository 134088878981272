export const companyIdType = {
  setCompanyId: 'SET_COMPANY_ID',
  delCompanyId: 'DEL_COMPANY_ID',
}

export const companyIdReducer = (state, action) => {
  const { type, companyId } = action;
  const { setCompanyId, delCompanyId } = companyIdType;
  switch(type){
    case setCompanyId:
      localStorage.setItem('company_id', companyId);
      return companyId;
    case delCompanyId:
      localStorage.removeItem('company_id');
      return ''
    default:
      return state
  }
}