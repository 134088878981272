import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../hooks/useFetch";
import RenderInputPhone from "../../fyb_components/results/RenderInputPhone";
import RenderInput from "../../fyb_components/results/RenderInput";
import SelectQouteCity from "../../fyb_components/results/SelectQouteCity";
import SelectCity from "../../components/Dashboard/SlectCity";
import { fullNameCity } from "../../utils";

const schemaValidation = yup.object().shape({
  invoice_legal_name: yup.string().required("Nombre legal requerido"),
  rfc_code: yup.string().required("RFC requerido"),
  address_line1: yup.string().required("Dirección requerida"),
  address_line2: yup.string().nullable(),
  zip_code: yup.string().required("Código postal requerido"),
  name: yup.string().required("Nombre de contacto requerido"),
  email: yup.string().email("Correo electrónico inválido").required("Correo electrónico requerido"),
  city_id: yup.object().required("Ciudad requerida").nullable(),
});

const InvoiceForm = ({ company_id, next }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const { fletesAPI } = useFetch();
  const [invoiceData, setInvoiceData] = useState(null);


  const getInvoiceData = async () => {
    try {
      const response = await fletesAPI.get(`api/company/${company_id}/invoice_information/`);
      setInvoiceData(response.data);
      setValue("invoice_legal_name", response.data.invoice_legal_name);
      setValue("rfc_code", response.data.rfc_code);
      setValue("address_line1", response.data.invoice_address.address_line1);
      setValue("address_line2", response.data.invoice_address.address_line2);
      setValue("zip_code", response.data.invoice_address.zip_code);
      setValue("phone", response.data.invoice_address.phone);
      setValue("name", response.data.invoice_address.name);
      setValue("email", response.data.invoice_address.email);
      setValue("city_id", { value: response.data.invoice_address.city.id, label: fullNameCity(response.data.invoice_address.city) });
    } catch (e) {
      console.log(e);
    }
  };

  const submitInvoiceData = async (data) => {
    data.city_id = data.city_id.value;
    await fletesAPI
      .patch(`api/company/${company_id}/invoice_information/`, data)
      .then((response) => {
        toast.success("Datos de facturación guardados");
        next();
      })
      .catch((error) => {
        toast.error("Error al guardar los datos de facturación");
      });
  };

  const onSubmit = (data) => {
    submitInvoiceData(data);
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  return (
    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
      <div style={{maxWidth: 600}}>
      <Form onSubmit={handleSubmit(onSubmit)} >
        <Row>
          <Col>
            <RenderInput
              name="invoice_legal_name"
              label="Nombre legal"
              register={register}
              placeholder="Ingrese el nombre legal"
              error={errors.invoice_legal_name?.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="rfc_code"
              label="RFC"
              register={register}
              placeholder="Ingrese el RFC"
              error={errors.rfc_code?.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="address_line1"
              label="Dirección 1"
              register={register}
              placeholder="Ingrese la dirección"
              error={errors.address_line1?.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="address_line2"
              label="Dirección 2 (opcional)"
              register={register}
              placeholder="Ingrese la dirección 2"
              error={errors.address_line2?.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="zip_code"
              label="Código postal"
              register={register}
              placeholder="Ingrese el código postal"
              error={errors.zip_code?.message}
            />
          </Col>
          <Col>
            <RenderInputPhone
              name="phone"
              label="Teléfono"
              control={control}
              placeholder="Ingrese el teléfono"
              error={errors.phone?.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="name"
              label="Nombre de contacto"
              register={register}
              placeholder="Ingrese el nombre de contacto"
              error={errors.name?.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="email"
              label="Correo electrónico"
              register={register}
              placeholder="Ingrese el correo electrónico"
              error={errors.email?.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectCity
              name="city_id"
              defaultValue={null}
              control={control}
              placeholder="Seleccione la ciudad"
              error={errors.city_id?.message}
              label="Ciudad"
            />
          </Col>
        </Row>
        <Row>
          <Col style={{width: "100%", display: "flex", justifyContent: "center", marginTop: 20}}>
            <Button type="submit" style={{width: 400}} className="text-white" >Guardar</Button>
          </Col>
        </Row>
      </Form>
      </div>
    </div>
  );
};

export default InvoiceForm;