import { MdDone, MdClose } from "react-icons/md";
import styled from "styled-components";

const UserVerified = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: ${e => e.verify ? '#3e85c6' : '#dc3545'};
    border-radius: 5px;
    color: white;
    border-radius: 50%;
  }
`;

const RenderVerifyEmail = ({ verify }) => {
  return (
    <td>
      <UserVerified verify={verify}>
        <div>
          {verify ? <MdDone /> : <MdClose />}
        </div>
      </UserVerified>
    </td>
  );
};

export default RenderVerifyEmail;
