import React from 'react'
import Footer from '../components/Footer'
import Form from '../components/Login/Form'
import FybNavigationBar from "../components/FybNavigationBar";


export default function Login() {
  return (
    <>
      <FybNavigationBar />
      <Form />
      <Footer />
    </>
  )
}
