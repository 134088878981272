import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";


const SelectDate = ({
  name,
  placeholder,
  label,
  control,
  disabled = false,
}) => {
  return(
    <Form.Group controlId={name}>
      {Boolean(label) && <Form.Label>{label}</Form.Label>}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DatePicker
            className={`form-control`}
            disabled={disabled}
            placeholderText={placeholder}
            minDate={new Date()}
            onChange={(e) => field.onChange(e)}
            selected={field.value}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
          />
        )}
      />
    </Form.Group>
  );
};

export default SelectDate;