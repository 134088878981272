import React from "react";
import { Modal, Button } from "react-bootstrap";
import useFetch from '../../../hooks/useFetch'
import toast from 'react-hot-toast';
import { fullNameCity } from "../../../utils";

export default function ModalDelete({ show, handleClose, dataDelete, update, id }) {
  const { fletesAPI } = useFetch()

  const deleteAppointment = async () => {    
    const toastId = toast.loading("Eliminando cita...");
    try {
      await fletesAPI.delete(`api/companytruck/${id}/appointments/`, {
        data: {
          appointment_id: dataDelete.id,
        },
      });
      handleClose();
      update();
      toast.dismiss(toastId);
      toast.success("Cita eliminada");
    } catch (error) {
      console.log(error);
      toast.dismiss(toastId);
      toast.error("Error al eliminar la cita");
    }
  }


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h6>
          ¿Estás seguro de eliminar esta cita?
          </h6> 
          <p>{Boolean(dataDelete?.data?.freight) && `De ${fullNameCity(dataDelete?.data?.freight?.quote?.origin_city)} a ${fullNameCity(dataDelete?.data?.freight?.quote?.destination_city)} | ${dataDelete?.data?.freight?.quote?.company?.name}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            size="sm"
            onClick={deleteAppointment}
          >
            Eliminar
          </Button>
          <Button variant="danger" size="sm" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}