import { useState, useEffect } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import useFetch from "../../../hooks/useFetch";
import { fullNameCity, erroresCodes } from "../../../utils";
import RenderBreadCrumbs from "./RenderBreadCrumbs";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import RenderMenu from "../../../fyb_components/results/RenderMenu";

import EditQoute from "./views/EditQoute";
import moment from "moment";
const viewList = {
  edit: "edit",
  comments: "comments",
};

export default function QuoteById() {
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState(null);
  const [viewSelected, setViewSelected] = useState("edit");
  const { fletesAPI } = useFetch();
  const { id, view } = useParams();
  const navigate = useNavigate();

  const getQuote = async () => {
    try {
      setLoading(true);
      const response = await fletesAPI.get(`api/quote/${id}`);
      setQuote(response.data);
    }
    catch (err) {
      throw err;
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (view !== undefined) {
      setViewSelected(view);
    }
  }, [view]);

  useEffect(() => {
    if (quote === null) {
      toast.promise(getQuote(), {
        loading: "Cargando cotización...",
        success: "Cotización cargada",
        error: "Error al cargar cotización",
      });
    }
  }, [quote]);

  console.log({ quote });
  return (
    <>
      <Container>
        <Row className="mt-5">
          {!loading && (
            <RenderBreadCrumbs
              breadcrumbs={[
                {
                  label: quote?.reference ? `No. ${quote.reference}` : "Referencia",
                },
                {
                  label: quote.origin_city
                    ? `${fullNameCity(quote.origin_city)} ${moment(quote?.pickup_time).format("DD/MM/YYYY HH:mm")}`
                    : "Cuidad de origen",
                },
                {
                  label: quote.destination_city
                    ? `${fullNameCity(quote.destination_city)} ${moment(quote?.delivery_time).format("DD/MM/YYYY HH:mm")}`
                    : "Cuidad de destino",
                },
              ]}
            />
          )}
        </Row>
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Editar",
                  active: viewSelected === viewList.edit,
                  onClick: () => {
                    navigate(`/dashboard/quote/${id}/${viewList.edit}`, {
                      replace: true,
                    });
                  },
                },
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && (
              <RenderSpace>
                <Spinner animation="border" variant="primary" />
              </RenderSpace>
            )}
            {!loading && viewSelected === viewList.edit && (
              <EditQoute quote={quote} getQoute={getQuote} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
