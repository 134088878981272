import { useState } from "react";
import styled from "styled-components";

import { MdAddAPhoto } from "react-icons/md";
import userPNG from "../../assets/icons/default-user-icon.png";
import RenderModalUpdateProfilePicture from "./Modals/RenderModalUpdateProfilePicture";

const ProfilePicture = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100% ;
    border: none;
    height: 40px;
    font-size: 20px;
    color: #fff;
    background: var(--bs-gray-700);
    opacity: 0.3;
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const RenderProfilePicture = ({ profile }) => {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(profile);
  return (
    <>
      <ProfilePicture>
        <img
          src={image && image.profile_url ? image.profile_url : userPNG}
          className="fyb_profile_picture"
        />
        <button onClick={() => setShowModal(true)}> <MdAddAPhoto /></button>
      </ProfilePicture>
      <RenderModalUpdateProfilePicture state={showModal} setState={setShowModal} profile={image} setProfile={setImage} />
    </>
  )};

  export default RenderProfilePicture;