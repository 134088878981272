import React from "react";
import { Container, Navbar, Nav, Button } from "react-bootstrap";

//assets
import { ReactComponent as Logo } from "../assets/icons/logo-white.svg";

const FybNavigationBar = () => {
  return (
    <Navbar className="fyb_main_top_navigation" expand="md">
      <Container fluid>
        <Navbar.Brand href="/">
          <Logo className="fyb_logo_nav" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className="fyb_main_top_navigation_toggler"/>
        <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto fs-5 px-4">
              <Nav.Link href="https://soporte.fletesybodegas.com" className="text-light">Blog</Nav.Link>
              <Nav.Link href="/support" className="text-light">Soporte</Nav.Link>
            </Nav>
            <Nav.Link href="/login" style={{marginRight: 10}}>
              <Button variant="light">Iniciar sesión</Button>
            </Nav.Link>
            <Nav.Link href="/register">
              <Button variant="danger">Crea tu cuenta</Button>
            </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default FybNavigationBar;
