import toast from "react-hot-toast";
import useFetch from "../hooks/useFetch";

const emailVerify = () => {
  // const { fletesAPI } = useFetch();
  // setInterval(() => {
  //   toast.error('confirma tu correo electronico');
  // }, 10000);
};

export default emailVerify;