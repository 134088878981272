const DoneIcon = ({className}) => (
  <svg
    className={className}
    width="37"
    height="29"
    viewBox="0 0 37 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="path-step-done"
      d="M3 17L12 25L34 3"
      stroke="currentColor"
      stroke-width="4"
      stroke-linecap="round"
    />
  </svg>
);

export default DoneIcon;
