import { Button, Container, Row, Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";


const ConfirmQuote = ({ quote, finish, goTo }) => {
  return (
    <Container className="d-flex flex-column gap-4">
      <Row  style={{ justifyContent: "center" }}>
        <Card style={{ width: "600px" }}>
          <Card.Body className="gap-2 d-flex flex-column">
            <ListGroup>
              <ListGroup.Item>
                <div className="header-card_section">
                  <p><b>Informacion</b></p> <FaRegEdit onClick={() => goTo(0)} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Camion:</b> {quote?.bed_type_id?.name || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Origen:</b> {quote?.origin_city_id?.label || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Destino:</b> {quote?.destination_city_id?.label || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Fecha y hora de recolección:</b> {moment.utc(quote?.pickup_time).format("YYYY-MM-DD HH:mm").toString() || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Fecha y hora de entrega:</b> La entrega es directa y se entrega tan rapido como el camion pueda transitar.
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Tarimas:</b> {quote?.pallets || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Peso:</b> {quote?.weight || ""}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Carga:</b> {quote?.cargo || ""}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </Row>
      <Row className="justify-content-center">
          <Button
            type="submit"
            className="fyb_large_button text-white"
            style={{ width: "400px", fontSize: "1.2rem", fontWeight: 600 }}
            onClick={finish}
          >
            Confirmar
          </Button>
      </Row>
    </Container>
  );
};

export default ConfirmQuote;
