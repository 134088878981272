import { useState } from "react";
import styled from "styled-components";
import { MdAdd } from "react-icons/md";
import ModalAddImage from "./ModalAddImage";

const ButtonAddImageStyle = styled.div`
  width: 300px;
  height: 250px;
  border-radius: 10px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #c4c4c4;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #e5e5e5; 
    color: white; 
  }
`;

const ButtonAddImage = ({getMore, info}) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <ButtonAddImageStyle onClick={() => setModal(true)}>
        <MdAdd />
      </ButtonAddImageStyle>
      <ModalAddImage
        state={modal}
        setState={setModal}
        getMore={getMore}
        info={info}
      />
    </>
  );
};

export default ButtonAddImage;
