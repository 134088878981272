import { useState } from "react";
import MultiStep from "../MultiStep";
import { Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";

import FormSelectCompany from "./FormSelectCompany";
import FormSelectInfo from "./FormSelectInfo";
import ConfirmQuote from "./ConfirmQuote";
import FinishQuote from "./FinishQuote";

const stepsData = (setCurrentStep) => [
  { title: "Compañia", step: "select_company", onClick: () => null },
  { title: "Informacion", step: "select_info", onClick: () => null },
  { title: "Confirmar", step: "confirm", onClick: () => null },
  { title: "Finalizado", step: "done", onClick: () => null },
];

const initialQuote = {
  expiration: null,
  company_id: null,
  origin_city_id: null,
  destination_city_id: null,
  total: null,
  currency: null,
  extras: null,
  pickup_time: null,
  delivery_time: null,
  pallets: null,
  weight: null,
  cargo: null,
  bed_type_id: null,
};

const prepareQuote = (quote) => ({
  company_id: quote.company_id.value,
  origin_city_id: quote.origin_city_id.value,
  destination_city_id: quote.destination_city_id.value,
  total: quote.total,
  currency: quote.currency.value,
  extras: "",
  pickup_time: moment
    .utc(quote.pickup_time)
    .format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
  delivery_time: moment
    .utc(quote.delivery_time)
    .format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
  pallets: quote.pallets,
  weight: quote.weight,
  cargo: quote.cargo,
  bed_type_id: quote.bed_type_id.id,
  expiration: moment.utc(quote.expiration).format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
});

const CreateQuote = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [quote, setQuote] = useState(initialQuote);

  console.log({ quote });
  const { fletesAPI } = useFetch();

  const nextStep = (data = {}) => {
    if (currentStep < stepsData().length - 1) {
      setCurrentStep(currentStep + 1);
      setQuote({ ...quote, ...data });
    }
  };

  const goTo = (step) => {
    setCurrentStep(step);
  };

  const restart = () => {
    setQuote(initialQuote);
    setCurrentStep(0);
  };

  const sendQuote = async (data) => {
    try {
      const response = await fletesAPI.post(`api/quote/`, data);
      setCurrentStep(4);
      return response;
    }
    catch (err) {
      throw err;
    }
  };
  
  const finish = async () => {
    const data = prepareQuote(quote);
    await toast.promise(sendQuote(data), {
      loading: "Generando cotización...",
      success: "Cotización generada con éxito",
      error: "Error al generar cotización",
    });
  };

  const steps = stepsData(setCurrentStep);

  return (
    <Container className="d-flex flex-column gap-4 py-4">
      <Row>
        <MultiStep steps={steps} currentStep={currentStep} />
      </Row>
      <Row>
        {currentStep === 0 && (
          <FormSelectCompany defaultData={quote} next={nextStep} />
        )}
        {currentStep === 1 && (
          <FormSelectInfo defaultData={quote} next={nextStep} />
        )}
        {currentStep === 2 && (
          <ConfirmQuote quote={quote} finish={finish} goTo={goTo} />
        )}
        {currentStep === 4 && <FinishQuote restart={restart} />}
      </Row>
    </Container>
  );
};

export default CreateQuote;
