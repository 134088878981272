import styled from "styled-components";

const Space = styled.div`
  display: flex;
  align-items: ${e => e.ai};
  justify-content: ${e => e.jc};
  width: 100%;
  height: 80px;
  border-radius: 5px;
  background-color: white;
  padding: 0;
`;

const RenderSpace = ({ children, jc = "center", ai = "center", className = "" }) => <Space className={className} jc={jc} ai={ai}>{children}</Space>;

export default RenderSpace;