import { useEffect, useContext } from "react"
import { companyIdType, userIdType, sessionType, profileType } from '../context/reducers'
import useFetch from './useFetch'

import { BodegasContext } from "../context"

export const useSession = ({setModalCompany, setModalSelectCompany, setCompanies}) => {
  const { session, dispSession, dispUserId, companyId, dispCompanyId, profile, dispProfile } = useContext(BodegasContext)
  const { fletesAPI } = useFetch()
  
  const getID = async () => {
    await fletesAPI
      .get(`api/users/whoami/`)
      .then((e) => {
        dispUserId({ type: userIdType.setUserId , userId: e.data.id });
        dispProfile({ type: profileType.setProfile, profile: e.data.profile });
      })
      .catch(() => {
        dispCompanyId({type: companyIdType.delCompanyId});
        dispUserId({type: userIdType.delUserId});
        dispSession({type: sessionType.delSession});
        window.location.href = "/login";
      });

    await fletesAPI
      .get(`api/users/companies/`)
      .then((e) => {
        if (e.data.length === 0) {
          setModalCompany(true);
        } else if (Boolean(companyId)) {
          registerCompany(companyId)
        } else if (e.data.length > 1) {
          setModalSelectCompany(true);
          setCompanies(e.data);
        } else {
          registerCompany(e.data[0].company.id);
          dispCompanyId({type: companyIdType.setCompanyId , companyId: e.data[0].company.id});
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const registerCompany = async (com) => {
    await fletesAPI
      .get(`api/company/${com}/switch/`)
      .then((e) => {
        dispSession({type: sessionType.setSession, session: e.data.session});
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if(Boolean(session)){
      getID()
    }
  }, [])
  return {
    isLoggedIn: Boolean(session)
  }
}