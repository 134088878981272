import React, {useEffect} from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePointOfContactValidation } from "../../../utils/formsValidation";
import useFetch from "../../../hooks/useFetch";
import toast from 'react-hot-toast';
import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";


export default function ModalFormUpdate({
  show,
  setShow,
  data,
  getMore,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm({
    resolver: yupResolver(updatePointOfContactValidation),
  });
  const onSubmit = (data) => {
    updatePointOfContact(data);
  };
  const { fletesAPI } = useFetch()
  const updatePointOfContact = async (e) => {
    await fletesAPI
      .patch(`api/pointofcontact/${data.id}/`, e)
      .then(() => {
        setShow(false);
        getMore("init");
        reset(defaultValue);
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const defaultValue = {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
  }
  useEffect(() => {
    setTimeout(() => {
      if(data){
        setValue("first_name", data.first_name);
        setValue("last_name", data.last_name);
        setValue("email", data.email);
        setValue("phone", data.phone);
        setValue("preference_reach_by_whatsapp", data.preference_reach_by_whatsapp);
        setValue("preference_reach_by_email", data.preference_reach_by_email);
        setValue("preference_reach_by_phone", data.preference_reach_by_phone);
      }
    }, 50)
  }, [data, show])
  
  return (
    <>
      <Modal show={show} onHide={() => {setShow(false);reset()}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Actualizar punto de contacto</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  {...register("first_name")}
                  isInvalid={errors.first_name}
                  type="text"
                  placeholder="Ingrese su nombre(s)"
                />
                { errors.first_name && <p className="dashboard_form_error_message">{mensajesFormulario("first_name_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Apellidos</Form.Label>
                <Form.Control
                  {...register("last_name")}
                  isInvalid={errors.last_name}
                  type="text"
                  placeholder="Ingrese sus apellidos"
                />
                { errors.last_name && <p className="dashboard_form_error_message">{mensajesFormulario("last_name_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  {...register("email")}
                  isInvalid={errors.email}
                  type="text"
                  placeholder="Introduce tu correo Email"
                />
                { errors.email && <p className="dashboard_form_error_message">{mensajesFormulario("email_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Teléfono</Form.Label>
                <PhoneInputWithCountry
                  name="phone"
                  placeholder="Ingrese su número telefónico"
                  control={control}
                />
                { errors.phone && <p className="dashboard_form_error_message">{mensajesFormulario("phone_invalid_format")}</p> }
              </Form.Group>
            </Row>
            <Row>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Check
                {...register("preference_reach_by_whatsapp")}
                type='checkbox'
                id={`preference_reach_by_whatsapp`}
                label={`Preferencia de búsqueda por WhatsApp  `}
              />
              </Form.Group>
            </Row>
            <Row>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Check
                {...register("preference_reach_by_email")}
                type='checkbox'
                id={`preference_reach_by_email`}
                label={`Preferencia de búsqueda por Email  `}
              />
              </Form.Group>
            </Row>
            <Row>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Check
                {...register("preference_reach_by_phone")}
                type='checkbox'
                id={`preference_reach_by_phone`}
                label={`Preferencia de búsqueda por Teléfono  `}
              />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" size="sm" type="submit">
              Guardar Cambios
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
