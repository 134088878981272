import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MdContentCopy } from 'react-icons/md';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

const URL = styled.div`

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 0 10px;
  .url{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    margin: 0;
  }
  .copy{
    display: flex;
    align-items: center;
    font-size: 14px;  
    font-weight: 500;
    color: #323232;
    cursor: pointer;
    margin-left: 15px ;
    &:hover{
      color: #0373db;
    }
  }
`;

const RenderURLtoCopy = ({ url }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <>
    <URL>
      <div className="url">{url}</div>
      <CopyToClipboard text={url} onCopy={() => toast.success("La URL ha sido copiada")}>
        <div className="copy">
          <MdContentCopy />
        </div>
      </CopyToClipboard>
    </URL>
    </>
  );
};

export default RenderURLtoCopy;