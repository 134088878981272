import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import Card from './Card';
import FybCard from './FybCard';

const Cards = ({ cards, title }) => {
  return (
    <div className="">
      <Container>
        <h2 className="fs-1"> {title} </h2>
        <Row className="py-5">
          {cards.map((e, i) => (
          <Col key={i} className=" justify-content-center d-flex">
            <Card
              image={e.image}
              title={e.title}
              text={e.text}
            />
          </Col>))}
        </Row>
      </Container>
    </div>
  )
}

export default Cards