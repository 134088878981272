import React from "react";

const MobileNotSupported = () => {
  return (
    <>
    <div>
        <h2>Por el momento Fletes & Bodegas no soporta version de celular, favor de acceder via computadora o tablet.</h2>
    </div>
    </>
  );
};

export default MobileNotSupported;
