import { useEffect } from "react";
import { Form, Row, Col, Button, Badge } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useFetch from "../../../../hooks/useFetch";
import SelectDriver from "../../SelectDriver";
import SelectTruck from "../../SelectTruck";
import RenderSelect from "../../../../fyb_components/results/RenderSelect";
import { statusOrderLabel, fullNameCity, statusLabel, statusColor } from "../../../../utils";



const ContactFreight = ({ order }) => {

  return (
    <>
      <h4>Informacion del Cliente</h4>
      <p>
          <b>Estado:</b> <Badge bg={statusColor(order?.status)}>{statusLabel(order?.status)}</Badge><br/ >
      </p>
      <p>
          <b>Nombre de la compania: </b> {order.quote.company.name && order.quote.company.name} <br />
          <b>Nombre del contacto: </b> {order.quote.company.contact_information && order.quote.company.contact_information.name} <br />
          <b>Direccion: </b> {order.quote.company.contact_information && order.quote.company.contact_information.address_line1} {order.quote.company.contact_information && order.quote.company.contact_information.address_line2} <br />
          <b>Ciudad: </b> {order.quote.company.contact_information.city && order.quote.company.contact_information.city.name},{order.quote.company.contact_information.city && order.quote.company.contact_information.city.state.name}, {order.quote.company.contact_information.city && order.quote.company.contact_information.city.state.country.name} <br />
          <b>Telefono:</b> {order.quote.company.contact_information && order.quote.company.contact_information.phone} <br />
          <b>Email: </b> {order.quote.company.contact_information && order.quote.company.contact_information.email} <br />
      </p>
      <h4>Informacion del Transportista</h4>
      <p>
          <b>Nombre de la compania: </b> {order.quote.transportation_company.name}<br />
          <b>Nombre del contacto: </b> {order.quote.transportation_company.contact_information.name}<br />
          <b>Direccion: </b> {order.quote.transportation_company.contact_information.address_line1} {order.quote.transportation_company.contact_information.address_line2} <br />
          <b>Ciudad: </b> {order.quote.transportation_company.contact_information.city.name},{order.quote.transportation_company.contact_information.city.state.name}, {order.quote.transportation_company.contact_information.city.state.country.name} <br />
          <b>Telefono:</b> {order.quote.transportation_company.contact_information.phone} <br />
          <b>Email: </b> {order.quote.transportation_company.contact_information.email} <br />
      </p>
    </>
  );
};

export default ContactFreight;
