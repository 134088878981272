import { Link } from "react-router-dom";
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const RenderPhoneContent = ({phone}) => {
    return (
    <>
        {phone &&
        <>
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = "tel:" + phone;
                    e.preventDefault();
                }}>
                {formatPhoneNumberIntl(phone) ? formatPhoneNumberIntl(phone) : <>{phone}</>}
             </Link>
         </>
        }
    </>
    );
}

export default RenderPhoneContent;