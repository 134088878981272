import { useState } from "react";
import { Controller } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import Select from "react-select";

import { citiesStyles } from "../../utils/styles";

const refotmatCompany = (company) => {
  return {
    value: company.id,
    label: `${company.name} - ${company.id}`,
  };
};

const RenderSelectCompany = ({ name, control, defaultValue = undefined, placeholder, error }) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const { fletesAPI } = useFetch();

  const getCopanies = async () => {
    await fletesAPI
      .get(`api/company/`)
      .then((e) => {
        setCompanies(e.data.results);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const resCompanies = companies.map((e) => refotmatCompany(e));

  const updateLoading = (e) => {
    setLoading(true);
    setTimeout(() => {
      updateCompanies(e.target.value);
    }, 400);
  };

  const updateCompanies = async (data) => {
    await fletesAPI
      .get(`api/company/`,{
        params: {
          name: data
        }
      })
      .then((e) => {
        setCompanies(e.data.results);
        setLoading(false);
      })
      .catch(() => {
        return null;
      });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue === null || defaultValue === undefined ? undefined : refotmatCompany(defaultValue)}
      render={({ field }) => (
        <Select
          isClearable
          isLoading={loading}
          styles={citiesStyles}
          placeholder={placeholder}
          {...field}
          options={resCompanies}
          className={error ? "invalidSelect w-100" : "w-100"}
          onFocus={() => {
            getCopanies();
            setLoading(true);
          }}
          onKeyDown={(e) => {
            e.target.value
              ? e.target.value.length > 2
                ? updateLoading(e)
                : setTimeout(() => {
                    getCopanies();
                  }, 50)
              : setTimeout(() => {
                  getCopanies();
                }, 50);
          }}
        />
      )}
    />
  );
};
export default RenderSelectCompany;
