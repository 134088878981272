import { useState } from "react";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import useFetch from "../../hooks/useFetch";
import RenderURLtoCopy from "../results/RenderURLtoCopy";

const SwitchButtonCont = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: ${(e) => (e.state ? "#4085c6" : "#e5e5e5")};
  padding: 0;
  transition: 0.2s;
  cursor: pointer;
  .circle {
    transition: 0.2s;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    margin: ${(e) => (e.state ? "0 0 0 28px" : "0 0 0 4px")};
  }
`;

const SwitchButton = ({ data }) => {
  const [stateButton, setStateButton] = useState(data.token_for_public_access);
  const { fletesAPI } = useFetch();
  const enablePublicAccess = async () => {
    await fletesAPI
      .post("api/freightpost/" + data.id + "/bucket/")
      .then((e) => {
        if (e.data) {
          setStateButton(e.data);
          toast.success("Se habilito el acceso publico");
        } else {
          setStateButton(null);
          toast.error("Se deshabilito el acceso publico");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Toaster />
      <SwitchButtonCont state={stateButton} onClick={enablePublicAccess}>
        <div className="circle"></div>
      </SwitchButtonCont>
      <h5 className="m-2">Compartir</h5>
      {stateButton && (
        <RenderURLtoCopy
          url={window.location.origin + "/share/order/" + stateButton}
        />
      )}
    </>
  );
};

export default SwitchButton;
