import React from "react";
import { Container, Button, Nav } from "react-bootstrap";

const Banner = ({ image, link, text }) => {
  return (
    <div
      className="p-5 text-center bg-image d-flex"
      style={{
        background: `url('${image}') no-repeat center center`,
        height: "600px",
        backgroundSize: "cover",
        backgroundPosition: "bottom"
      }}
    >
      <Container className="container-fluid">
        <div className="justify-content-start align-items-start">
          <div
            className="text-white d-flex"
          >
            <h1 className="mb-3 text-start" style={{fontSize: "40px", fontWeight: "800"}}>{text}</h1>
            <Nav.Link href={link} className="d-flex p-0">
              {/*
              <Button
                size="lg p-3 text-light"
                fontsSize="large"
                style={{ width: "200px" }}
              >
                Crea tu cuenta
              </Button>
              */}

            </Nav.Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Banner;
