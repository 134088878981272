import { useEffect, useState, useContext } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { GrCompliance } from "react-icons/gr";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import { BodegasContext } from "../../../context";
import RenderModalCompleteTask from "../../../fyb_components/results/Modals/RenderModalAddAttachments";
import RenderRowCetificated from "../../../fyb_components/results/RenderRowCetificated";

const View = () => {
  const [loading, setLoading] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [tasks, setTasks] = useState(null);
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const deleteTask = async (id) => {
    setLoading(true);
    await fletesAPI
      .delete(`api/company/${id}/task/`)
      .then(() => {
        toast.success("Tarea eliminada con éxito");
        setLoading(false);
        setShowModal(false);
        getTasks();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setShowModal(false);
      });
  };

  const getTasks = async () => {
    setLoading(true);
    await fletesAPI
      .get(`api/company/${companyId}/task/`)
      .then((res) => {
        setTasks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (tasks === null) {
      getTasks();
    }
  }, [tasks]);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todas las tareas</h4>
          </Col>
        </Row>
        <hr />
        {loading ? (
          tasks &&
          tasks.results.map((e) => (
            <RenderRowCetificated
              key={e.id}
              data={e}
              action={
                <RenderButtonMore
                  buttons={[
                    {
                      label: (
                        <>
                          Completar <GrCompliance />
                        </>
                      ),
                      onClick: () => {
                        setShowModal(true);
                        setDataSelected(e);
                      },
                    },
                  ]}
                />
              }
            />
          ))
        ) : (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        )}
      </Container>
      <RenderModalCompleteTask
        state={showModal}
        setState={setShowModal}
        dataCompany={{ id: dataSelected?.id }}
        getMore={getTasks}
        dataTask={dataSelected}
      />
    </>
  );
};

export default View;
