import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import { useForm } from "react-hook-form";
import RenderSelectCompany from "../../../fyb_components/results/RenderSelectCompany";
import moment from "moment";

const AdminPointofContacts = () => {
  const [contacts, setContacts] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const { control, handleSubmit, watch } = useForm();

  const onSubmit = (data) => {
    updateContactsByCompanyId(data.company.value);
  };

  const updateContactsByCompanyId = async (id) => {
    await fletesAPI
      .get(`api/pointofcontact/`, {
        params: {
          company_id: id,
        },
      })
      .then((e) => {
        setContacts(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const focusCompany = watch("company");

  const getContacts = async () => {
    await fletesAPI
      .get(`api/pointofcontact/`)
      .then((e) => {
        setContacts(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteContact = async (id) => {
    await fletesAPI
      .delete(`api/pointofcontact/${id}/`)
      .then((e) => {
        getContacts();
        toast.success("Punto de contacto eliminado");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadContacts = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setContacts(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (focusCompany === undefined || focusCompany === null) {
      getContacts();
    }
  }, [focusCompany]);

  useEffect(() => {
    if (!contacts) {
      getContacts();
    }
  }, [contacts]);
  return (
    <>
      <Toaster />
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todos Los Puntos De Contacto</h4>
            <div className="d-flex">
              {/* <Button
                className="text-white mx-2"
                onClick={() => setModalAdd(true)}
              >
                Crear Camion
              </Button> */}
              <Form
                className="d-flex"
                style={{ width: "400px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <RenderSelectCompany
                  name={"company"}
                  control={control}
                  defaultValue={null}
                  placeholder={"Buscar por empresa"}
                />
                <Button type="submit" className="text-white">
                  Buscar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th>Telefono</th>
              <th>Preferencia por email</th>
              <th>Preferencia por telefono</th>
              <th>Preferencia por Whatsapp</th>
              <th>Eliminado</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {contacts &&
              contacts.results.map((e, i) => (
                <tr key={i}>
                  <td>{e.first_name} {e.last_name}</td>
                  <td>{e.email}</td>
                  <td>{e.phone}</td>
                  <td>{e.preference_reach_by_email ? "Si" : "No"}</td>
                  <td>{e.preference_reach_by_phone ? "Si" : "No"}</td>
                  <td>{e.preference_reach_by_whatsapp ? "Si" : "No"}</td>
                  <td>{e.deleted_on ? moment(e.deleted_on).format("YYYY-MM-DD") : ''}</td>
                  <td>
                    <RenderButtonMore
                      buttons={[
                        {
                          label: (
                            <>
                              Editar <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/admincontacts/${e.id}/edit`;
                          },
                        },
                        {
                          label: (
                            <>
                              Eliminar <MdDelete />
                            </>
                          ),
                          onClick: () => {
                            setModalDelete(true);
                            setDataDelete(e);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {contacts && (
          <Pagination>
            <Pagination.Prev
              disabled={!contacts.previous}
              onClick={() => reloadContacts(contacts.previous)}
            />
            <Pagination.Next
              disabled={!contacts.next}
              onClick={() => reloadContacts(contacts.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteContact(dataDelete.id)}
        confirmText={`¿Estas seguro de eliminar este punto de contacto con el correo ${
          dataDelete ? dataDelete.email : ""
        }?`}
      />
    </>
  );
};

export default AdminPointofContacts;
