import { MdAccessTime } from "react-icons/md";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 1rem;
  .icon {
    color: #00bfa6;
  }
  .title {
    width: 200px;
    p {
      margin: 0;
    }
  }
  .instructions{
    width: 200px;
  }
  .comment{
    p{
      width: 300px;
      height: 25px;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const RenderRowCetificated = ({ data, action }) => {
  const { title, instructions, comment_requestor } = data;
  return (
    <Row>
      <div className="icon">
        <MdAccessTime />
      </div>
      <div className="title">
        <p>{title}</p>
      </div>
      <div className="instructions">
        <a href={instructions}>{instructions}</a>
      </div>
      <div className="comment">
        <p>{comment_requestor}</p>
      </div>
      <div className="actions">{action}</div>
    </Row>
  );
};

export default RenderRowCetificated;
