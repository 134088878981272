import { Form } from "react-bootstrap";

const RenderInput = ({
  placeholder,
  type = "text",
  error,
  register,
  name,
  defaultValue = undefined,
  label,
  as,
  style,
  disabled = false,
  className = "mb-4",
}) => {
  return (
    <Form.Group controlId={name} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        {...register(name)}
        isInvalid={error}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        as={as}
        style={style}
        disabled={disabled}
      />
      <Form.Text className="text-danger position-absolute">{error}</Form.Text>
    </Form.Group>
  );
};

export default RenderInput;
