import React from 'react'

import Footer from '../components/Footer'
import Form from '../components/ForgetPassword/Form'
import Navbar from '../components/Navbar'
import FybNavigationBar from "../components/FybNavigationBar";


export default function ForgetPassword() {
  return (
    <>
      <FybNavigationBar />
      <Form />
      <Footer />
    </>
  )
}