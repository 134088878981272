import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";

const RenderSelect = ({
  name,
  label,
  control,
  defaultValue = undefined,
  placeholder,
  error,
  options,
  isMulti = false,
  onFocus,
  outDiv = false,
  disabled = false,
  isLoading = false,
  className = "mb-4",
}) => {
  return outDiv ? (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          isClearable
          {...field}
          options={options}
          isSearchable={false}
          placeholder={placeholder}
          className={error ? "invalidSelect" : ""}
          isMulti={isMulti}
          onFocus={onFocus}
          isDisabled={disabled}
          isLoading={isLoading}
        />
      )}
    />
  ) : (
    <Form.Group controlId={name} className={className}>
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            isClearable
            {...field}
            options={options}
            isSearchable={false}
            placeholder={placeholder}
            className={error ? "invalidSelect" : ""}
            isMulti={isMulti}
            onFocus={onFocus}
            isDisabled={disabled}
            isLoading={isLoading}
          />
        )}
      />
      <Form.Text className="text-danger position-absolute">{error}</Form.Text>
    </Form.Group>
  );
};

export default RenderSelect;
