import { testJSON, days } from "../../utils";

const day = (e) => {
  const time = e.enabled ? `${e.start} - ${e.end}` : "Cerrado";
  return time;
};

const getFormattedHours = (e, data) => {
  return data[e.name] ? `${e.label}: ${day(data[e.name])}` : "no seleccionado";
};
const RenderJustHours = ({ hours }) => {
  const hoursCL = testJSON(hours) ? JSON.parse(hours) : null;
  return (
    <ul>
      {hoursCL
        ? days.map((e, i) => <li key={i}>{getFormattedHours(e, hoursCL)}</li>)
        : "Horario no seleccionado"}
    </ul>
  );
};

export default RenderJustHours;
