import { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import useFetch from "../../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import { colourStyles, citiesStyles } from "../../../utils/styles";
import { updateOrderValidation } from "../../../utils/formsValidation";
import { addNewOrderDefaultValues } from "../../../utils/defaultValues";
import { statusOptions } from "../../../utils/defaultValues";
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";
import FormCurrencySelect from "../../../fyb_components/Forms/FormCurrencySelect";


export default function ModalFormUpdate({
  show,
  setShow,
  getMore,
  bedTypes,
  points,
  dataUpdate,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(updateOrderValidation),
  });
  const onSubmit = (data) => {
    updateOrder(data);
  };
  const { fletesAPI } = useFetch();
  const updateOrder = async (data) => {
    await fletesAPI
      .patch(`api/freightpost/${dataUpdate.id}/`, {
        status: data.status.value,
        origin_city_id: data.origin_city_id.value,
        destination_city_id: data.destination_city_id.value,
        weight: data.weight === "" ? null : data.weight,
        height: data.height === "" ? null : data.height,
        length: data.length === "" ? null : data.length,
        width: data.width === "" ? null : data.width,
        number_of_pallets:
          data.number_of_pallets === "" ? null : data.number_of_pallets,
        point_of_contact_id: data.point_of_contact_id.value,
        bed_type_ids: data.bed_type_ids.flatMap((e) => [e.value]),
        rate: data.rate === "" ? null : data.rate,
        currency: data.currency,
        internal_reference_number: data.internal_reference_number,
        comments: data.comments,
        ready_pickup_date: moment.utc(data.ready_pickup_date).format(),
        origin_zip_code: data.origin_zip_code,
        destination_zip_code: data.destination_zip_code,
      })
      .then((e) => {
        reset();
        setShow(false);
        getMore("init");
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      if (dataUpdate) {
        setValue("status", {
          value: dataUpdate.status,
          label: statusOptions[dataUpdate.status].label,
          color: statusOptions[dataUpdate.status].color,
        });
        setValue("origin_city_id", {
          value: dataUpdate.origin_city.id,
          label: `${dataUpdate.origin_city.name}, ${dataUpdate.origin_city.state.name}, ${dataUpdate.origin_city.state.country.name}`,
        });
        setValue("destination_city_id", {
          value: dataUpdate.destination_city.id,
          label: `${dataUpdate.destination_city.name}, ${dataUpdate.destination_city.state.name}, ${dataUpdate.destination_city.state.country.name}`,
        });
        setValue("weight", dataUpdate.weight === null ? "" : dataUpdate.weight);
        setValue("height", dataUpdate.height === null ? "" : dataUpdate.height);
        setValue("length", dataUpdate.length === null ? "" : dataUpdate.length);
        setValue("width", dataUpdate.width === null ? "" : dataUpdate.width);
        setValue("point_of_contact_id", {
          value: dataUpdate.point_of_contact.id,
          label: `${dataUpdate.point_of_contact.first_name} ${dataUpdate.point_of_contact.last_name}`,
        });
        setValue(
          "bed_type_ids",
          dataUpdate.bed_type.flatMap((e) => [{ value: e.id, label: e.name }])
        );
        setValue("rate", dataUpdate.rate === null ? "" : dataUpdate.rate);
        setValue("currency", dataUpdate.currency === null ? "MXN" : dataUpdate.currency);
        setValue("comments", dataUpdate.comments);
        setValue(
          "internal_reference_number",
          dataUpdate.internal_reference_number
        );
        setValue("origin_zip_code", dataUpdate.origin_zip_code);
        setValue("destination_zip_code", dataUpdate.destination_zip_code);
        setValue("ready_pickup_date", new Date(dataUpdate.ready_pickup_date));
        setValue(
          "number_of_pallets",
          dataUpdate.number_of_pallets === null
            ? ""
            : dataUpdate.number_of_pallets
        );
      }
    }, 50);
  }, [dataUpdate, show]);

  const bedTypeOptions =
    bedTypes === null
      ? []
      : bedTypes.map((e) => ({ value: e.id, label: e.name }));

  const pointsOptions =
    points === null
      ? []
      : points.map((e) => ({
          value: e.id,
          label: `${e.first_name} ${e.last_name}`,
        }));
  const [loadOriginCities, setLoadOriginCities] = useState(false);
  const [originCities, setOriginCities] = useState(null);
  const [loadDestinationCities, setLoadDestinationCities] = useState(false);
  const [destinationCities, setDestinationCities] = useState(null);
  const resOriginCities = originCities === null ? [] : originCities.map((e) => ({ value: e.id, label: `${e.name}, ${e.state.name}, ${e.state.country.name}`}));
  const resDestinationCities = destinationCities === null ? [] : destinationCities.map((e) => ({ value: e.id, label: `${e.name}, ${e.state.name}, ${e.state.country.name}`, }));

  const updateOriginCities = async (data) => {
    await fletesAPI
      .get(`api/city/search/?name=${data}`)
      .then((e) => {
        setOriginCities(e.data);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getOriginCities = async () => {
    await fletesAPI
      .get(`api/city/`)
      .then((e) => {
        setOriginCities(e.data.results);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const updateDestinationCities = async (data) => {
    await fletesAPI
      .get(`api/city/search/?name=${data}`)
      .then((e) => {
        setDestinationCities(e.data);
        setLoadDestinationCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getDestinationCities = async () => {
    await fletesAPI
      .get(`api/city/`)
      .then((e) => {
        setDestinationCities(e.data.results);
        setLoadDestinationCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateLoadOrigin = (e) => {
    setLoadOriginCities(true);
    setTimeout(() => {
      updateOriginCities(e.target.value);
    }, 400);
  };

  const updateLoadDestination = (e) => {
    setLoadDestinationCities(true);
    setTimeout(() => {
      updateDestinationCities(e.target.value);
    }, 400);
  };

  return (
    <>
      <Toaster />
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset(addNewOrderDefaultValues);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Editar carga</h5>
          </Modal.Header>
          <Modal.Body>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formCityOrgin">
                <Form.Label>Ciudad de origen</Form.Label>
                <Controller
                  name="origin_city_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isLoading={loadOriginCities}
                      styles={citiesStyles}
                      placeholder="Seleccione su ciudad de origen"
                      isDisabled
                      {...field}
                      options={resOriginCities}
                      onFocus={() => {
                        getOriginCities();
                        setLoadOriginCities(true);
                      }}
                      onKeyDown={(e) => {
                        e.target.value
                          ? e.target.value.length > 2
                            ? updateLoadOrigin(e)
                            : setTimeout(() => {
                                getOriginCities();
                              }, 50)
                          : setTimeout(() => {
                              getOriginCities();
                            }, 50);
                      }}
                    />
                  )}
                />
               { errors.origin_city_id && <p className="dashboard_form_error_message">{mensajesFormulario("city_required")}</p> }
              </Form.Group>
            </Row>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formCityDestiny">
                <Form.Label>Ciudad de destino</Form.Label>
                <Controller
                  name="destination_city_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isLoading={loadDestinationCities}
                      styles={citiesStyles}
                      placeholder="Seleccione su ciudad de destino"
                      isDisabled
                      {...field}
                      options={resDestinationCities}
                      onFocus={() => {
                        getDestinationCities();
                        setLoadDestinationCities(true);
                      }}
                      onKeyDown={(e) => {
                        e.target.value
                          ? e.target.value.length > 2
                            ? updateLoadDestination(e)
                            : setTimeout(() => {
                                getDestinationCities();
                              }, 50)
                          : setTimeout(() => {
                              getDestinationCities();
                            }, 50);
                      }}
                    />
                  )}
                />
                { errors.destination_city_id && <p className="dashboard_form_error_message">{mensajesFormulario("city_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formCodeZipOrigin">
                  <Form.Label>Código postal de origen</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="ingrese su código postal de origen"
                      {...register("origin_zip_code")}
                      isInvalid={errors.origin_zip_code}
                    />
                  </InputGroup>
                  { errors.origin_zip_code && <p className="dashboard_form_error_message">{mensajesFormulario("zip_code_required")}</p> }
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formCodeZipDestiny">
                  <Form.Label>Código postal de destino</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="ingrese su código postal de destino"
                      {...register("destination_zip_code")}
                      isInvalid={errors.destination_zip_code}
                    />
                  </InputGroup>
                  { errors.destination_zip_code && <p className="dashboard_form_error_message">{mensajesFormulario("zip_code_required")}</p> }
                </Form.Group>
              </Col>
            </Row>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formLength">
                <Form.Label>Punto de contacto</Form.Label>
                <Controller
                  name="point_of_contact_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Seleccione su punto de contacto"
                      {...field}
                      options={pointsOptions}
                      styles={citiesStyles}
                    />
                  )}
                />
                { errors.point_of_contact_id && <p className="dashboard_form_error_message">{mensajesFormulario("point_of_contact_required")}</p> }
              </Form.Group>
            </Row>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formBedType">
                <Form.Label>Tipo de Caja Tráiler</Form.Label>
                <Controller
                  name="bed_type_ids"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Selecciona los tipos de caja tráiler"
                      isMulti
                      {...field}
                      options={bedTypeOptions}
                    />
                  )}
                />
                { errors.bed_type_ids && <p className="dashboard_form_error_message">{mensajesFormulario("bed_type_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Check
                    {...register("paletas")}
                    type="checkbox"
                    label={`Transportas tarimas ?`}
                    id={`paletas`}
                    defaultChecked={dataUpdate?.number_of_pallets !== null}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="date">
                  <Form.Label>Fecha y Hora de Carga</Form.Label>
                  <Controller
                    control={control}
                    name="ready_pickup_date"
                    render={({ field }) => (
                      <DatePicker
                        className={`form-control${
                          errors.ready_pickup_date ? " is-invalid" : ""
                        } `}
                        placeholderText="Selecciona fecha"
                        minDate={new Date()}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        selected={field.value}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                      />
                    )}
                  />
                { errors.ready_pickup_date && <p className="dashboard_form_error_message">{mensajesFormulario("ready_pickup_date_required")}</p> }
                </Form.Group>
              </Col>
            </Row>
            {!watch("paletas") && (
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formWidth">
                    <Form.Label>Ancho</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Introducir ancho"
                        {...register("width")}
                        isInvalid={errors.width}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formHeight">
                    <Form.Label>Altura</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Introducir altura"
                        {...register("height")}
                        isInvalid={errors.height}
                      />
                      <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col style={{ display: !watch("paletas") && "none" }}>
                <Form.Group className="mb-3" controlId="formLength">
                  <Form.Label>Indique número de tarimas</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Número de tarimas"
                      {...register("number_of_pallets")}
                      isInvalid={errors.length}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col style={{ display: watch("paletas") && "none" }}>
                <Form.Group className="mb-3" controlId="formLength">
                  <Form.Label>Longitud</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="ingresar la longitud"
                      {...register("length")}
                      isInvalid={errors.length}
                    />
                    <InputGroup.Text>m</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formWeight">
                  <Form.Label>Peso</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Introducir peso"
                      {...register("weight")}
                      isInvalid={errors.weight}
                    />
                    <InputGroup.Text>Kg</InputGroup.Text>
                  </InputGroup>
                  { errors.weight && <p className="dashboard_form_error_message">{mensajesFormulario("weight_required")}</p> }
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formRate">
                  <Form.Label>Tarifa</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa tu tarifa"
                      {...register("rate")}
                      isInvalid={errors.rate}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <FormCurrencySelect name={"currency"} register={register} />
              </Col>
            </Row>
            <Row>
              <Col className="dashboard_main_container_form_select">
                <Form.Group className="mb-3" controlId="formStatusSelect">
                  <Form.Label>Estado</Form.Label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Status"
                        {...field}
                        styles={colourStyles}
                        defaultValue={statusOptions[0]}
                        options={statusOptions}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Form.Group className="mb-3" controlId="formLoadDetails">
                <Form.Label>Número de referencia interna</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="número de referencia interna"
                  {...register("internal_reference_number")}
                  isInvalid={errors.internal_reference_number}
                />
                { errors.internal_reference_number && <p className="dashboard_form_error_message">{mensajesFormulario("internal_reference_number_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formComments">
                <Form.Label>
                  Descripcion de carga e Instrucciones Especiales
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...register("comments")}
                  isInvalid={errors.comments}
                />
                { errors.comments && <p className="dashboard_form_error_message">{mensajesFormulario("comments_required")}</p> }
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" size="sm">
              Guardar Cambios
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset(addNewOrderDefaultValues);
              }}
            >
              Cancelar Cambios
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
