import React, { useState, useContext, useEffect } from "react";
import { Row, Button, Spinner, Col, Container } from "react-bootstrap";
import { RiAddLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { BodegasContext } from "../../../context";
import ModalFormAdd from "./ModalFormAdd";
import useFetch from "../../../hooks/useFetch";
import { checkMobile } from "../../../utils";
import { mensajesAPI } from "../../../utils/mensajes.js";
import RenderInvitations from "../../../fyb_components/results/RenderInvitations";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import RenderURLtoCopy from "../../../fyb_components/results/RenderURLtoCopy";

export default function Users({ token }) {
  const [showAdd, setShowAdd] = useState(false);
  const [showGeneratedCode, setShowGeneratedCode] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [invitations, setInvitations] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const [loading, setLoading] = useState(false);
  const { userId } = useContext(BodegasContext);
  const [isMobile, setIsMobile] = useState(null);

  const reloadInvitations = async (e) => {
    var ur;
    switch (e) {
      case null:
        ur = null;
        break;
      case "init":
        ur = process.env.REACT_APP_URL_API + `api/users/invite/?user_id=${userId}`;
        break;
      default:
        ur = e;
        break;
    }
    if (ur != null) {
      setLoading(true);
      await fletesAPINotURL
        .get(ur)
        .then((i) => {
          setInvitations(i.data);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        })
        .catch((i) => {
          console.log(i);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getOneTimeToken = async () => {
    await fletesAPI
      .post(`api/users/invite_token/`)
      .then((e) => {
        setGeneratedCode(window.location.origin + "/register/" + e.data.token + "/");
        navigator.clipboard.writeText(window.location.origin + "/register/" + e.data.token + "/");
        toast.success(mensajesAPI("invitation_success_generated"));
        setShowGeneratedCode(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    checkMobile(setIsMobile);
  }, [isMobile]);

  useEffect(() => {
    if (invitations == null) {
      reloadInvitations("init");
    }
  }, [invitations]);

  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Mis invitaciones</h4>
            <div>
              <Button onClick={() => setShowAdd(true)} className="text-white mx-2">
                <RiAddLine />
                Invitar usuario por correo
              </Button>
              <Button onClick={() => getOneTimeToken()} className="text-white">
                <RiAddLine />
                Generar link de invitación
              </Button>
            </div>
          </Col>
        </Row>
        <hr />
        {showGeneratedCode ? (
          <>
            <div>
              <p className="text-primary m-0 p-0">
                Comparte este código para crear una cuenta nueva
              </p>
              <RenderURLtoCopy url={generatedCode} />
            </div>
            <hr />
          </>
        ) : (
          ""
        )}
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <RenderInvitations
            invitations={invitations}
            title={""}
            isMobile={isMobile}
            reloadInvitations={reloadInvitations}
          />
        )}
      </Container>
      <ModalFormAdd show={showAdd} setShow={setShowAdd} token={token} getMore={reloadInvitations} />
    </>
  );
}
