import { useState } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  Badge,
} from "react-bootstrap";
import RenderCityResult from "../../../fyb_components/results/RenderCityResult";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import ModalDelete from "./ModalDelete";
import { FiFilePlus } from "react-icons/fi";
import { MdEdit, MdDelete, MdModeComment, MdDetails } from "react-icons/md";
import { BiTask } from "react-icons/bi";
import moment from "moment";
import { statusColor, statusLabel } from "../../../utils";
import ModalContact from "./ModalContact";
import { MdOutlineContactMail } from "react-icons/md";
import { FaPrint } from "react-icons/fa";


const options = ({ data, onDelete, carrier }) => [
  {
    label: (<> Resumen <MdOutlineContactMail /></>),
    onClick: () => (window.location.href = `/dashboard/my_order/${data.id}/contact`),
  },
  {
    label: (<> Detalles <MdDetails /></>),
    onClick: () => (window.location.href = `/dashboard/my_order/${data.id}/details`),
    disable: !carrier,
  },
  {
    label: (<> Editar <MdEdit /></>),
    onClick: () => (window.location.href = `/dashboard/my_order/${data.id}/edit`),
  },
  {
    label: (<> Archivos <FiFilePlus /></>),
    onClick: () => (window.location.href = `/dashboard/my_order/${data.id}/files`),
  },
  {
    label: (<> Comentarios <MdModeComment /> </>),
    onClick: () => (window.location.href = `/dashboard/my_order/${data.id}/comments`),
  },
  {
    label: (<> Tareas <BiTask /> </>),
    onClick: () => (window.location.href = `/dashboard/my_order/${data.id}/tasks`),
  },
  {
    label: (<> Imprimir <FaPrint /> </>),
    onClick: () => (window.location.href = `/pdf_view/order/${data.id}`),
  },
  {
    label: (<> Eliminar <MdDelete /> </>),
    onClick: () => onDelete(data),
    disable: !carrier,
  },
];

const MyOrderTable = ({ orders, isMobile, reloadOrders, carrier }) => {
  const [showDel, setShowDel] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);

  const handleDelete = async (data) => {
    setShowDel(true);
    setDataDelete(data);
  };

  const [modalContact, setModalContact] = useState({
    show: false,
    data: null,
  });
  const showModalContact = (e) => {
    setModalContact({ show: true, data: e });
  };

  const hideModalContact = () => {
    setModalContact({ show: false, data: null });
  };

  return (
    <>
      <Row>
        <Col>
          {orders && orders.results.length > 0 ? (
            <>
              <Table
                striped
                bordered
                className="dashboard_main_container_table"
              >
                <thead>
                  <tr>
                    <th>Hora de recolección</th>
                    <th>No. Referencía</th>
                    <th>Estado</th>
                    <th>Compañia</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Tarifa</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {orders &&
                    orders.results.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {moment(e?.quote?.pickup_date).format("DD/MM/YYYY HH:mm")}
                          </td>
                          <td>
                            <p> {e?.reference?.toUpperCase()} </p>
                          </td>
                          <td>
                            <Badge bg={statusColor(e?.status)}>{statusLabel(e?.status)}</Badge>
                          </td>
                          <td>
                            <p className="link" onClick={() => showModalContact(e)}>{ carrier ? e?.quote?.company?.name : e?.quote?.transportation_company?.name }</p>
                          </td>
                          <RenderCityResult
                            origin_city={e?.origin_address?.city}
                            destination_city={e?.destination_address?.city}
                            display_city={e?.origin_address?.city}
                            isMobile={isMobile}
                            subtitle={"Origen"}
                          />
                          <RenderCityResult
                            origin_city={e?.origin_address?.city}
                            destination_city={e?.destination_address?.city}
                            display_city={e?.destination_address?.city}
                            isMobile={isMobile}
                            subtitle={"Destino"}
                          />
                          <td> $ {e.quote?.total} {e.quote?.currency} </td>
                          <td>
                            <RenderButtonMore
                              buttons={options({
                                data: e,
                                onDelete: handleDelete,
                                carrier,
                              })}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {orders && (
                <Pagination>
                  <Pagination.Prev
                    disabled={!orders.previous}
                    onClick={() => reloadOrders(orders.previous)}
                  />
                  <Pagination.Next
                    disabled={!orders.next}
                    onClick={() => reloadOrders(orders.next)}
                  />
                </Pagination>
              )}
            </>
          ) : (
            <>
              <br />
              <h3 className="text-center fyb_h2">
                No tienes Fletes, para tener fletes porfavor primero crea una estimacion y contacta al transportista para que el cree el flete.
              </h3>
            </>
          )}
        </Col>
      </Row>
      <ModalDelete
        show={showDel}
        setShow={setShowDel}
        dataDelete={dataDelete}
        getMore={reloadOrders}
      />
      <ModalContact 
        data={modalContact}
        hide={hideModalContact}
        carrier={carrier}
      />
    </>
  );
};

export default MyOrderTable;
