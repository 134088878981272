import { Modal, Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Map, { Marker } from "react-map-gl";
import almacenTruck from "../../../assets/icons/almacen.png";

const RenderModalConfirmLocation = ({ state, setState, location, setLocation }) => {
  return (
    <>
      <Toaster />
      <Modal show={state} onHide={() => {setState(false);setLocation(location)}}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p> Haga clic para seleccionar la ubicación </p><Map
              initialViewState={{
                latitude: location ? parseFloat(location.lat) : 40,
                longitude: location ? parseFloat(location.lng) : -100,
                zoom: location ? 15 : 3,
              }}
              mapStyle="mapbox://styles/mapbox/light-v10"
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              style={{ width: "100%", height: "400px", borderRadius: "10px" }}
              onClick={(e) => {setLocation(e.lngLat)}}
            >
              {location && 
              (<Marker longitude={location.lng} latitude={location.lat} anchor="bottom" draggable>
                <img src={almacenTruck} width={27} height={27} />
              </Marker>)}
            </Map>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              setLocation(location);
              toast.success("Ubicación confirmada", 400);
              setState(false);
            }}
            >
            {"Confirmar"}
            </Button> 
          <Button variant="danger" onClick={() => {setState(false);setLocation(location)}}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RenderModalConfirmLocation;
