import { Button } from "react-bootstrap";
import { MdRemoveRedEye, MdDelete } from "react-icons/md";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/es";

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    padding: 0;
    color: white;
    &:hover{
      color: white;
    }
    &:focus{
      color: white;
    }
  }
`;

const RenderExpensesTableRow = ({ data, onViewExpense, onDeleteExpense }) => {
  return (
    <>
      <tr>
        <td>{moment(data.created).format("DD MMMM  YYYY")}</td>
        <td>{data.comment}</td>
        <td>{data.type}</td>
        <td>$ {data.cost}</td>
        <td>
          <Buttons>
            <Button variant="info" onClick={onViewExpense}>
              <MdRemoveRedEye />
            </Button>
            <Button variant="danger" onClick={onDeleteExpense}>
              <MdDelete />
            </Button>
          </Buttons>
        </td>
      </tr>
    </>
  );
};

export default RenderExpensesTableRow;
