import { useEffect, useState } from "react";
import { Row, Col, Button, Table, Pagination, Spinner } from "react-bootstrap";
import useFetch from "../../../../hooks/useFetch";

import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import { MdDelete, MdEdit } from "react-icons/md";
import ModalDelete from "../ModalDelete";
import ModalAdd from "../ModalAdd";
import ModalEdit from "../ModalEdit";

const Invoices = ({ getMore, dataCompany }) => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);
  const [modalEdit, setModalEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const { fletesAPI } = useFetch();

  const getInvoices = async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/company/${dataCompany.id}/invoice_information/`);
      setInvoices(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (invoices === null) {
      getInvoices();
    }
  }, [invoices]);

  return (
    <>
      <Row>
        <Col className="w-100 d-flex justify-content-end">
          <Button className="text-white" onClick={() => setModalAdd(true)}>
            {" "}
            Agregar datos de facturación{" "}
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col style={{ minHeight: 800 }}>
          {loading ? (
            <RenderSpace jc="center">
              <Spinner animation="border" role="status" />
            </RenderSpace>
          ) : (
            <>
              {invoices && invoices.length > 0 ? (
                <Table striped responsive className="dashboard_main_container_table">
                  <thead>
                    <tr>
                      <th style={{textAlign: "left", paddingLeft: 20}}>Nombre legal</th>
                      <th>Contacto</th>
                      <th>Dirección</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices &&
                      invoices.map((e, i) => (
                        <tr key={i}>
                          <td style={{textAlign: "left", paddingLeft: 20}}>
                            <div>
                              <p className="m-0">{e.legal_name}</p>
                              <p className="m-0">{e.rfc_code}</p>
                            </div>
                          </td>
                          <td style={{textAlign: "left", paddingLeft: 20}}>
                            <div>
                              <p className="m-0">{e?.address?.name}</p>
                              <p className="m-0">{e?.address?.phone}</p>
                              <p className="m-0">{e?.address?.email}</p>
                            </div>
                          </td>
                          <td style={{textAlign: "left", paddingLeft: 20}}>
                            <div>
                              <p className="m-0">{e?.address?.address_line1}</p>
                              <p className="m-0">{e?.address?.address_line2}</p>
                              <p className="m-0">{e?.address?.zip_code}</p>
                            </div>
                          </td>
                          <td>
                            <div className="w-100 d-flex" style={{ gap: 10 }}>
                              <Button
                                className="btn-primary text-white btn-sm"
                                onClick={() => {
                                  setModalEdit(true);
                                  setDataEdit(e);
                                }}
                              >
                                <MdEdit />
                              </Button>
                              <Button
                                className="btn-danger btn-sm"
                                onClick={() => {
                                  setModalDelete(true);
                                  setDataDelete(e);
                                }}
                              >
                                <MdDelete />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <RenderSpace>
                  <p>No hay archivos</p>
                </RenderSpace>
              )}
            </>
          )}
        </Col>
      </Row>
      <ModalDelete
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        update={getInvoices}
        dataDelete={dataDelete}
      />
      <ModalAdd
        show={modalAdd}
        onHide={() => setModalAdd(false)}
        update={getInvoices}
        companyId={dataCompany?.id}
      />
      <ModalEdit
        show={modalEdit}
        onHide={() => setModalEdit(false)}
        update={getInvoices}
        companyId={dataCompany?.id}
        dataInvoice={dataEdit}
      />
    </>
  );
};

export default Invoices;
