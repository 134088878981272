import { useState } from "react";
import styled from "styled-components";
import { MdDone, MdAccessTime, MdOutlinePending } from "react-icons/md";
import moment from "moment";
import "moment/locale/es";
import RenderModalInvoice from "./Modals/RenderModalInvoice";

const Invoice = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 1rem;
  margin-bottom: 5px;
  p {
    margin: 0;
  }
  .invoice-icon {
    color: #00bfa6;
  }
  .invoice-actions {
    color: #0079bf;
  }
`;

const RenderInvoice = ({ dataInvoice }) => {
  const [stateModalInvoice, setStateModalInvoice] = useState(false);
  const { status, total, type, created, currency } = dataInvoice;
  return (
    <>
      <Invoice status={status}>
        <div className="invoice-icon">
          {status == 0 ? (
            <MdAccessTime />
          ) : status == 1 ? (
            <MdOutlinePending />
          ) : (
            <MdDone />
          )}
        </div>
        <div className="invoice-date">
          <p>{moment(created).format("LL")}</p>
        </div>
        <div className="invoice-amount">
          <p>
            $ {total} {currency}
          </p>
        </div>
        <div className="invoice-status">
          <p>{type}</p>
        </div>
        <div className="invoice-actions" onClick={() => setStateModalInvoice(true)}>
          {status == 0 ? (
            <p>Subir factura</p>
          ) : status == 1 ? (
            <p>En proceso</p>
          ) : (
            <p>Descargar</p>
          )}
        </div>
      </Invoice>
      <RenderModalInvoice invoiceData={dataInvoice} state={stateModalInvoice} setState={setStateModalInvoice} />
    </>
  );
};

export default RenderInvoice;
