import React from "react";
import styled from "styled-components";

const Cont = styled.div`
  background-color: #EBEBEB;
  border-radius: 8px;
  margin-top: 50px;
  .plansT{
    margin: 10px;
    display: flex;
  }
`;

export default function PlansCont({children}) {
  return (
    <Cont>
      <div className='plansT'>{children}</div>
    </Cont>
  );
}
