import { Button, Container, Row } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";

const FinishQuote = ({ restart }) => {
  return (
    <Container>
      <Row className="justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center gap-4">
          <h1 className="m-0">Finalizado</h1>
          <p className="m-0">Se ha creado la cotización</p>
          <MdCheckCircle fontSize={120} color="#4085c6" />
          <Button
            type="submit"
            className="fyb_large_button text-white"
            style={{ width: "400px", fontSize: "1.2rem", fontWeight: 600 }}
            onClick={restart}
          >
            Crear nueva cotización
          </Button>
          <a
            onClick={() => (window.location.href = "/dashboard/quotes")}
            className="link"
            style={{cursor: "pointer"}}
          >
            Ver estimaciones
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default FinishQuote;
