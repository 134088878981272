import { useState } from "react";
import { Button, Row, Col, Table } from "react-bootstrap";
import SwitchButton from "../../../../fyb_components/Forms/SwitchButton";
import RenderTableRow from "../../../../fyb_components/results/RenderTableRow";
import RenderModalDelAttachment from "../../../../fyb_components/freightpost_public/RenderModalDelAttachment";
import ModalAddAttachment from "../../Orders/ModalAddAttachment";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";

const RenderFiles = ({ data: dataFiles, updateData }) => {
  const [showDel, setShowDel] = useState(false);
  const [dataDel, setDataDel] = useState(null);
  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <SwitchButton data={dataFiles} />
          </div>
          <Button
            variant="primary"
            className="text-white"
            onClick={() => setShowAdd(true)}
          >
            Agregar Archivo
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {dataFiles.attachments && dataFiles.attachments.length > 0 ? (
            <Table striped bordered className="dashboard_main_container_table">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataFiles.attachments &&
                  dataFiles.attachments.map((e, i) => (
                    <RenderTableRow
                      data={e}
                      key={i}
                      onDelete={() => {
                        setShowDel(true);
                        setDataDel(e);
                      }}
                    />
                  ))}
              </tbody>
            </Table>
          ) : (
            <RenderSpace>
              <p>No hay archivos</p>
            </RenderSpace>
          )}
        </Col>
      </Row>
      <RenderModalDelAttachment
        show={showDel}
        setShow={setShowDel}
        attachment={dataDel}
        getMore={updateData}
      />
      <ModalAddAttachment
        show={showAdd}
        setShow={setShowAdd}
        getMore={updateData}
        dataFreightPost={dataFiles}
      />
    </>
  );
};

export default RenderFiles;
