
import { Row, Col, Table, Pagination } from "react-bootstrap";
import RenderPickupDate from "./RenderPickupDate";
import RenderCityResult from "./RenderCityResult";
import RenderRate from "./RenderRate";
import RenderDistance from "./RenderDistance";
import RenderPayPerDistance from "./RenderPayPerDistance";
import RenderBedTypes from "./RenderBedTypes";
import RenderWeight from "./RenderWeight";
import RenderCompany from "./RenderCompany";
import RenderOrderDetails from "./RenderOrderDetails";

const RenderOrderSearchResults = ({
  orders,
  isMobile,
  reloadOrders,
}) => {
  return (
    <>
      {isMobile ? (
        <>
          {orders && orders.results.length > 0 ? (
            <>
              {orders.results.map((e, i) => {
                return (
                  <div className="v2_dashboard_body_box">
                    <Row>
                      <RenderBedTypes
                        bed_types={e.bed_type}
                        isMobile={isMobile}
                      />
                    </Row>
                    <Row>
                      <RenderPickupDate
                        date={e.ready_pickup_date}
                        isMobile={isMobile}
                      />
                      <RenderCityResult
                        origin_city={e.origin_city}
                        destination_city={e.destination_city}
                        display_city={e.origin_city}
                        isMobile={isMobile}
                        subtitle={"Origen"}
                      />
                      <RenderCityResult
                        origin_city={e.origin_city}
                        destination_city={e.destination_city}
                        display_city={e.destination_city}
                        isMobile={isMobile}
                        subtitle={"Destino"}
                      />
                    </Row>
                    <Row>
                      <RenderRate
                        rate={e.rate}
                        currency={e.currency}
                        isMobile={isMobile}
                      />
                      <RenderDistance
                        distance={e.distance}
                        isMobile={isMobile}
                      />
                      <RenderPayPerDistance
                        rate={e.rate}
                        distance={e.distance}
                        isMobile={isMobile}
                      />
                      <RenderWeight weight={e.weight} isMobile={isMobile} />
                    </Row>
                    <Row>
                      <RenderOrderDetails info={e} isMobile={isMobile} />
                      <RenderCompany company={e.company} isMobile={isMobile} />
                    </Row>
                  </div>
                );
              })}
              <br />
              <Row>
                <Col xl={2} lg={2} md={6} sm={6}>
                  {orders && (
                    <Pagination>
                      <Pagination.Prev
                        disabled={!orders.previous}
                        onClick={() => reloadOrders(orders.previous)}
                      />
                      <Pagination.Next
                        disabled={!orders.next}
                        onClick={() => reloadOrders(orders.next)}
                      />
                    </Pagination>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <br />
              <h3 className="text-center fyb_h2">
                No se encontraron resultados
              </h3>
            </>
          )}
        </>
      ) : (
        <>
          <Row>
            <Col>
              {orders && orders.results.length > 0 ? (
                <>
                  <Table striped bordered className="dashboard_main_container_table">
                    <thead>
                      <tr>
                        <th>Fecha de carga</th>
                        <th>Ciudad de origen</th>
                        <th>Ciudad de destino</th>
                        <th>Tarifa</th>
                        <th>Distancia km</th>
                        <th>$ por km</th>
                        <th>Tipos de tráiler</th>
                        <th>Peso Kg</th>
                        <th>Contacto</th>
                        <th>Compañía</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders &&
                        orders.results.map((e, i) => {
                          return (
                            <tr key={i}>
                              <RenderPickupDate
                                date={e.ready_pickup_date}
                                isMobile={isMobile}
                              />
                              <RenderCityResult
                                origin_city={e.origin_city}
                                destination_city={e.destination_city}
                                display_city={e.origin_city}
                                isMobile={isMobile}
                                subtitle={"Origen"}
                              />
                              <RenderCityResult
                                origin_city={e.origin_city}
                                destination_city={e.destination_city}
                                display_city={e.destination_city}
                                isMobile={isMobile}
                                subtitle={"Destino"}
                              />
                              <RenderRate
                                rate={e.rate}
                                currency={e.currency}
                                isMobile={isMobile}
                              />
                              <RenderDistance
                                distance={e.distance}
                                isMobile={isMobile}
                              />
                              <RenderPayPerDistance
                                rate={e.rate}
                                distance={e.distance}
                                isMobile={isMobile}
                              />
                              <RenderBedTypes
                                bed_types={e.bed_type}
                                isMobile={isMobile}
                              />
                              <RenderWeight
                                weight={e.weight}
                                isMobile={isMobile}
                              />
                              <RenderOrderDetails
                                info={e}
                                isMobile={isMobile}
                              />
                              <RenderCompany
                                company={e.company}
                                isMobile={isMobile}
                              />
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {orders && (
                    <Pagination>
                      <Pagination.Prev
                        disabled={!orders.previous}
                        onClick={() => reloadOrders(orders.previous)}
                      />
                      <Pagination.Next
                        disabled={!orders.next}
                        onClick={() => reloadOrders(orders.next)}
                      />
                    </Pagination>
                  )}
                </>
              ) : (
                <>
                  <br />
                  <h3 className="text-center fyb_h2">
                    No se encontraron resultados
                  </h3>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default RenderOrderSearchResults;
