import { useEffect } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import useFetch from "../../../../hooks/useFetch";

const Edit = ({ getMore, trucksData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { fletesAPI } = useFetch();

  const patchTruck = async (datos) => {
    await fletesAPI
      .patch(`api/companytruck/${trucksData.id}/`, datos)
      .then((e) => {
        toast.success("Camion actualizado con exito");
        getMore();
      })
      .catch((e) => {
        toast.error("Error al actualizar camion");
      });
  };

  const onSubmit = (data) => {
    patchTruck(data);
  };
  useEffect(() => {
    if (trucksData !== null) {
      reset({
        number: trucksData.number,
        license_plate: trucksData.license_plate,
        model: trucksData.model,
        brand: trucksData.brand,
        year: trucksData.year,
        vin: trucksData.vin,
      });
    }
  }, [trucksData]);
  
  return (
    <>
      <Toaster />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <RenderInput
              name={"number"}
              label="Numero del camion"
              placeholder="ingrese el numero del camion"
              register={register}
              error={errors.number && errors.number.message}
            />
            <RenderInput
              name={"license_plate"}
              label="Placa del camion"
              placeholder="ingrese la placa del camion"
              register={register}
              error={errors.license_plate && errors.license_plate.message}
            />
            <RenderInput
              name={"vin"}
              label="VIN"
              placeholder="ingrese el VIN"
              register={register}
              error={errors.vin && errors.vin.message}
            />
          </Col>
          <Col>
            <RenderInput
              name={"brand"}
              label="Marca"
              placeholder="ingrese la marca"
              register={register}
              error={errors.brand && errors.brand.message}
            />
            <RenderInput
              name={"model"}
              label="Modelo"
              placeholder="ingrese el modelo"
              register={register}
              error={errors.model && errors.model.message}
            />
            <RenderInput
              name={"year"}
              label="Año"
              placeholder="ingrese el año"
              register={register}
              error={errors.year && errors.year.message}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Edit;
