import React, { useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import RenderSelect from "../../../fyb_components/results/RenderSelect";

const addNewTask = yup.object().shape({
  title: yup.string().required("El nombre de la tarea es requerido"),
  instructions: yup
    .string()
    .required("La descripción de la tarea es requerida"),
  comment_requestor: yup
    .string()
    .required("El comentario del solicitante es requerido"),
  request_for_attachment: yup.boolean().required("El campo es requerido"),
  request_to_contact_transportation: yup
    .boolean()
    .required("El campo es requerido"),
});

const ModalAddTask = ({ open, handleClose, id , update }) => {
  const { fletesAPI } = useFetch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(addNewTask),
    defaultValues: {
      title: "",
      instructions: "",
      comment_requestor: "",
      request_for_attachment: false,
      request_to_contact_transportation: false,
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    data.type = data.type.value;
    const response = fletesAPI.post(`api/freight/${id}/task/`, data);

    toast.promise(response, {
      loading: "Guardando tarea...",
      success: "Tarea guardada correctamente",
      error: "Error al guardar la tarea",
    });

    response
      .then(() => {
        handleClose();
        reset();
        update();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Tarea</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nombre de la tarea</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de la tarea"
              {...register("title")}
            />
            <Form.Text className="text-danger">
              {errors.title?.message}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Instrucciones de la tarea</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Instrucciones de la tarea"
              {...register("instructions")}
            />
            <Form.Text className="text-danger">
              {errors.instructions?.message}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Comentario del solicitante</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Comentario del solicitante"
              {...register("comment_requestor")}
            />
            <Form.Text className="text-danger">
              {errors.comment_requestor?.message}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Solicitar archivo"
              id="request_for_attachment"
              {...register("request_for_attachment")}
            />
            <Form.Text className="text-danger">
              {errors.request_for_attachment?.message}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <RenderSelect 
              name="type"
              label="Tipo"
              control={control}
              error={errors.assigned_to?.message}
              placeholder="Seleccione el tipo"
              options={[
                {
                  value: "boarding_request",
                  label: "Carta de instrucciones"
                }
              ]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Solicitar contacto con transporte"
              id="request_to_contact_transportation"
              {...register("request_to_contact_transportation")}
            />
            <Form.Text className="text-danger">
              {errors.request_to_instructions?.message}
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={loading}
          >
            {loading ? "Cargando..." : "Guardar"}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddTask;