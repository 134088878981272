import styled, { keyframes } from "styled-components";
import { BsExclamation, BsCheck, BsX } from "react-icons/bs";

const GPSContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  `;

  const ModalSlide = styled.div`
  /* position: absolute; */
  top: 0;
  right: 0;
  width: 310px;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  transform: ${(e) => (e.state ? "translate(0, 0)" : "translate(310px, 0)")};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 10px;
`;

const Header = styled.div`
  border-bottom: 1px solid #e5e5e5;
`;

const Title = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Boddy = styled.div`
  /* padding: 10px 0; */
`;

const AnimacionStatus = keyframes`
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  55% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimacionStatusBar = keyframes`
  0% {
    height: 0%;
  }
  75% {
    height: 50%;
  }
  100% {
    height: 100%;
  }
`;

const AnimacionStatusBarLastItem = keyframes`
  0% {
    height: 0%;
  }
  75% {
    height: 50%;
  }
  100% {
    height: 50%;
  }
`;

const StatusCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 70px;
  padding: 0 10px;
  &:hover {
    background-color: #f5f5f5;
  }
  p {
    margin: 0;
  }
  .contStatus {
    display: flex;
    align-items: center;
    .bar {
      width: 5px;
      height: ${(e) => e.lastItem ? '50%' :  '100%'};
      background-color: ${(e) => (e.state ? "#00bfa5" : "grey")};
      position: absolute;
      left: 22.5px;
      top: 0;
      animation-name: ${(e) => e.lastItem ? AnimacionStatusBarLastItem : AnimacionStatusBar};
      animation-duration: ${e => e.duration}s;
      animation-iteration-count: 1;
      animation-delay: ${(e) => (e.delay + 1) * e.duration }s;
      animation-fill-mode: backwards;
    }
    .icon {
      font-size: 25px;
      color: ${(e) => (e.state ? "#00bfa5" : "grey")};
      position: relative;
      background: white;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 4px solid ${(e) => (e.state ? "#00bfa5" : "grey")};
      animation-name: ${AnimacionStatus};
      animation-duration: ${e => e.duration}s;
      animation-iteration-count: 1;
      animation-delay: ${(e) => (e.delay + 1) * e.duration }s;
      animation-fill-mode: backwards;
    }
  }
`;

const Status = ({ state, text, number, lastItem = false, animationDuration = 0.5  }) => {
  return (
    <StatusCont state={state} delay={number} lastItem={lastItem} duration={animationDuration}>
      <div className="contStatus">
        <div className="bar" />
        <div className="icon">{state ? <BsCheck /> : <BsExclamation />}</div>
      </div>
      <p>{text}</p>
    </StatusCont>
  );
};

export { ModalSlide, Header, Title, Boddy, Status, GPSContainer };
