import * as yup from "yup";
import { mensajesFormulario } from "./mensajes.js";

export const phone_regular_expression =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const addNewOrderValidation = yup.object().shape({
  company_id: yup.string().default(() => localStorage.getItem("company_id")),
  status: yup.object().shape({
    label: yup.string().default("status"),
    value: yup.string().default("1"),
  }),
  origin_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  destination_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  weight: yup.number().typeError().positive().required(),
  height: yup.string(),
  length: yup.string(),
  width: yup.string(),
  number_of_pallets: yup.string(),
  point_of_contact_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  distance: yup.string(),
  bed_type_ids: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  rate: yup.string(),
  internal_reference_number: yup.string().required(),
  comments: yup.string().required(),
  ready_pickup_date: yup.date().required(),
  origin_zip_code: yup.number().typeError().positive().min(4).required(),
  destination_zip_code: yup.number().typeError().positive().min(4).required(),
});

export const addNewTruckRouteValidation = yup.object().shape({
  company_id: yup.string().default(() => localStorage.getItem("company_id")),
  origin_city_id: yup.object().required("La ciudad de origen es obligatoria").nullable(),
  destination_city_id: yup.object().required("La ciudad de destino es obligatoria").nullable(),
  base_price: yup.string().required("El precio base es obligatorio"),
  base_currency: yup.object().required("La moneda base es obligatoria").nullable(),
  bed_type_ids: yup.array().required("El tipo de cama es obligatorio").nullable(),
});

export const updateOrderValidation = yup.object().shape({
  status: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  origin_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  destination_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  weight: yup.number().typeError().positive().required(),
  height: yup.string(),
  length: yup.string(),
  width: yup.string(),
  number_of_pallets: yup.string(),
  point_of_contact_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  distance: yup.string(),
  bed_type_ids: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  rate: yup.string(),
  internal_reference_number: yup.string().required(),
  comments: yup.string().required(),
  ready_pickup_date: yup.date().required(),
  origin_zip_code: yup.number().typeError().positive().min(4).required(),
  destination_zip_code: yup.number().typeError().positive().min(4).required(),
});

export const addNewTruckValidation = yup.object().shape({
  company_id: yup.string().default(() => localStorage.getItem("company_id")),
  status: yup.object().shape({
    label: yup.string().default("status"),
    value: yup.string().default("1"),
  }),
  origin_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  destination_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  weight: yup.number().typeError().positive().required(),
  point_of_contact_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  bed_type_ids: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
  ),
  rate: yup.string(),
  internal_reference_number: yup.string().required(),
  comments: yup.string().required(),
  ready_pickup_date: yup.date().required(),
});

export const updateTruckValidation = yup.object().shape({
  status: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  origin_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  destination_city_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  weight: yup.number().typeError().positive().required(),
  point_of_contact_id: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  bed_type_ids: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  rate: yup.string(),
  internal_reference_number: yup.string().required(),
  comments: yup.string().required(),
  ready_pickup_date: yup.date().required(),
});

export const profileValidation = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  position_title: yup.string(),
});

export const addNewAttachments = yup.object().shape({
  status: yup.object().shape({
    label: yup.string().default("status"),
    value: yup.string().default("1"),
  }),
});

export const companyValidation = yup.object().shape({
  address_line1: yup.string().required(),
  address_line2: yup.string(),
  zip_code: yup.string().required(),
  phone: yup.string().matches(phone_regular_expression),
  email: yup.string().email().required(),
  name: yup.string().required(),
  city: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
});

export const addNewAffiliate = yup.object().shape({
  email: yup.string().email().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().matches(phone_regular_expression),
});

export const addNewPointOfContactValidation = yup.object().shape({
  company_id: yup.string().default(() => localStorage.getItem("company_id")),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().matches(phone_regular_expression),
  email: yup.string().email().required(),
  preference_reach_by_whatsapp: yup.boolean(),
  preference_reach_by_email: yup.boolean(),
  preference_reach_by_phone: yup.boolean(),
});

export const updatePointOfContactValidation = yup.object().shape({
  company_id: yup.string().default(() => localStorage.getItem("company_id")),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().matches(phone_regular_expression),
  email: yup.string().email().required(),
  preference_reach_by_whatsapp: yup.boolean(),
  preference_reach_by_email: yup.boolean(),
  preference_reach_by_phone: yup.boolean(),
});

export const registerCompanyValidation = yup.object().shape({
  company: yup.object().required().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
});
export const registerCompanyValidationV2 = yup.object().shape({
  name: yup.string().required(),
  broker: yup.boolean().default(true),
  facebook_url: yup.string(),
  website_url: yup.string(),
});

export const updateCompanyValidation = yup.object().shape({
  name: yup.string().required(),
  carrier: yup.boolean(),
  broker: yup.boolean(),
  facebook_url: yup.string(),
  twitter_user: yup.string(),
  website_url: yup.string(),
  ctpat_code: yup.string(),
  default_days_to_pay: yup.string().required(),
});

export const updateCompanyContactInformationValidation = yup.object().shape({
  address_line1: yup.string(),
  address_line2: yup.string(),
  zip_code: yup.string(),
  phone: yup.string(),
});

export const updateWarehouseContactInformationValidation = yup.object().shape({
  address_line1: yup.string(),
  address_line2: yup.string(),
  zip_code: yup.string(),
  phone: yup.string(),
});

export const updateWarehouseValidation = yup.object().shape({
  name: yup.string().required(),
  hours: yup.string(),
  latitude: yup.string(),
  longitude: yup.string(),
  service_ids: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
  ),
});

export const addWarehouseValidation = yup.object().shape({
  name: yup.string().required(),
  hours: yup.string(),
  latitude: yup.string(),
  longitude: yup.string(),
  service_ids: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
  ),
});

export const addNewComment = yup.object().shape({
  comment: yup.string().required("el comentario es requerido"),
});

export const addNewFile = yup.object().shape({
  type: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("el tipo de archivo es requerido"),
    }),
  file_name: yup.string().required("el nombre del archivo es requerido"),
  file_content: yup
    .mixed()
    .test("file", "el archivo es requerido", (value) => value.length > 0),
});

export const addNewExpenses = yup.object().shape({
  comment: yup.string().required("el comentario es requerido"),
  type: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("el tipo de archivo es requerido"),
    }),
  cost: yup.string().required("el costo es requerido"),
  file_name: yup.string().required("el nombre del archivo es requerido"),
  file_content: yup
    .mixed()
    .test("file", "el archivo es requerido", (value) => value.length > 0),
});

export const addNewExpenseAttachment = yup.object().shape({
  type: yup.string().required("el tipo es requerido"),
  file_name: yup.string().required("el nombre del archivo es requerido"),
  file_content: yup
    .mixed()
    .test("file", "el archivo es requerido", (value) => value.length > 0),
});

export const updateDetailSchema = yup.object().shape({
  customer_rate: yup.string().required("el costo es requerido"),
  currency: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("la moneda es requerida"),
    }),
  driver_status: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("el estatus es requerido"),
    }),
});

export const validationDetailSchema = yup.object().shape({
  origin_city_id: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("la ciudad de origen es requerida"),
    }),
  origin_address_line1: yup
    .string()
    .required("la dirección de origen es requerida"),
  origin_address_line2: yup
    .string()
    .required("la dirección de origen es requerida"),
  origin_zip_code: yup.string().required("código postal es requerido"),
  origin_phone: yup.string().required("teléfono origen es requerido"),
  //=============== Separador =================//
  destination_city_id: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("la ciudad de destino es requerida"),
    }),
  destination_address_line1: yup
    .string()
    .required("la dirección de destino es requerida"),
  destination_address_line2: yup
    .string()
    .required("la dirección de destino es requerida"),
  destination_zip_code: yup.string().required("código postal es requerido"),
  destination_phone: yup.string().required("teléfono destino es requerido"),
  //=============== Separador =================//
  customer_rate: yup.string().required("el costo es requerido"),
  currency: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required("la moneda es requerida"),
  }),
  driver_status: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("el estatus es requerido"),
    }),
  //=============== Separador =================//
  driver_id: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("el conductor es requerido"),
    }),
});

export const updateDetailAsignDriverSchema = yup.object().shape({
  driver_id: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("el conductor es requerido"),
    }),
});

export const updateDetailOriginSchema = yup.object().shape({
  city_id: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("la ciudad es requerida"),
    }),
  address_line1: yup.string().required("la calle 1 es requerida"),
  address_line2: yup.string().required("la calle 2 es requerida"),
  zip_code: yup.string().required("el codigo postal es requerido"),
  phone: yup.string().required("el telefono es requerido"),
});

export const validateServiceSchema = yup.object().shape({
  service: yup
    .object()
    .required()
    .shape({
      label: yup.string().required(),
      value: yup.string().required("el servicio es requerido"),
    }),
});
