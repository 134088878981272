import { Modal, Row, Col, Table, Button } from "react-bootstrap";
import { MdDownload } from "react-icons/md";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/es";
import RenderSpace from "../RenderSpace";

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    padding: 0;
    color: white;
    &:hover{
      color: white;
    }
    &:focus{
      color: white;
    }
  }
`;

const RenderModalViewAttachments = ({ state, setState, data, setStateAddAttachment }) => {
  const donwloadFile = (url) => {
    window.open(url, "_blank");
  };

  return (
  <Modal show={state} onHide={() => setState(false)}>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
      <h4>Archivos adjuntos</h4>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col>
          <Button className="text-white" onClick={() => {
            setState(false);
            setStateAddAttachment(true);
          }}>
            Agregar archivo
          </Button> 
          <hr />
        {data && data.attachments.length > 0 ? <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Nombre del archivo</th>
              <th>tipo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data && data.attachments.map((e, i) => (
              <tr key={i}>
                <td>{moment(e.created).format("DD MMMM  YYYY")}</td>
                <td>{e.name}</td>
                <td>{e.type}</td>
                <td>
                  <Buttons>
                    <Button
                      onClick={() => donwloadFile(e.url)}
                      variant="success"
                    >
                      <MdDownload color={"white"}  />
                    </Button>
                  </Buttons>
                </td>
              </tr>
            ))}
          </tbody>
        </Table> : <RenderSpace js="cener" > <p>No hay archivos</p></RenderSpace>}
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => setState(false)}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default RenderModalViewAttachments;

