import chroma from 'chroma-js';


  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  export const citiesStyles = {
    input: (styles) => ({...styles, minHeight: 0, placeItems: 'center', display: 'flex', fontSize: "1rem"}),
    control: (styles) => ({...styles, minHeight: 0, fontSize: "1rem"}),
    singleValue: (styles) => ({...styles, minHeight: 0, placeItems: 'center', display: 'flex' , fontSize: "1rem"}),
    placeholder: (styles) => ({...styles, minHeight: 0, placeItems: 'center', display: 'flex', padding: 0, fontSize: "1rem"}),
    option: (styles) => ({...styles, minHeight: 0, fontSize: "1rem"}),
    indicatorsContainer: (styles) => ({...styles, minHeight: 0}),
    container: (styles) => ({...styles, minHeight: 0, fontSize: "1rem"}),
    valueContainer: (styles) => ({...styles, minHeight: 0, padding: ' 0 0 0 10px', fontSize: "1rem"}),
    multiValueLabel: (styles) => ({...styles, minHeight: 0}),
    multiValue: (styles) => ({...styles, minHeight: 0}),
    value: (styles) => ({...styles, minHeight: 0, placeItems: 'center', display: 'flex', fontSize: "1rem"}),
    dropDownIndicator: (styles) => ({...styles, minHeight: 0}),
    placeholder: (styles) => ({...styles, fontSize: "1rem"}),
  }
  export const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', minHeight: 0, fontSize: "1rem" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
        minHeight: 0,
        fontSize: "1rem",
      };
    },
    input: (styles) => ({ ...styles, ...dot(), minHeight: 0, placeItems: 'center', display: 'flex' }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc'), minHeight: 0, placeItems: 'center', display: 'flex' }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color), minHeight: 0, placeItems: 'center', display: 'flex' }),
    indicatorsContainer: (styles) => ({...styles, minHeight: 0}),
    container: (styles) => ({...styles, minHeight: 0, marginTop: '10px'}),
    valueContainer: (styles) => ({...styles, minHeight: 0, padding: ' 0 0 0 10px'}),
    multiValueLabel: (styles) => ({...styles, minHeight: 0}),
    multiValue: (styles) => ({...styles, minHeight: 0}),
    value: (styles) => ({...styles, minHeight: 0, placeItems: 'center', display: 'flex'}),
    dropDownIndicator: (styles) => ({...styles, minHeight: 0}),
  };