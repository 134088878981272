import { useState, useEffect } from "react";
import { Table, Row, Col, Spinner, Button, pagination, Pagination } from "react-bootstrap";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import toast, { Toaster } from "react-hot-toast";
import useFetch from "../../../../hooks/useFetch";
import RenderExpensesTableRow from "../../../../fyb_components/results/RenderExpensesTableRow";
import { erroresCodes } from "../../../../utils";
import RenderModalAddExpenses from "../../../../fyb_components/results/Modals/RenderModalAddExpenses";
import RenderModalViewAttachments from "../../../../fyb_components/results/Modals/RenderModalViewAttachments";
import RenderModalAddExpensesAttachments from "../../../../fyb_components/results/Modals/RenderModalAddExpensesAttachments";
import RenderModalConfirm from "../../../../fyb_components/results/Modals/RenderModalConfirm";
import { getExpenses, deleteExpense } from "../../../../services";

const RenderExpenses = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [expenses, setExpenses] = useState(null);
  const [modalAddExpenses, setModalAddExpenses] = useState(false);
  const [modalViewExpenses, setModalViewExpenses] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [modalAddAttachment, setModalAddAttachment] = useState(false);
  const [expenseSelected, setExpenseSelected] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const getExpenses = async () => {
    setLoading(true);
    await fletesAPI
      .get(`api/freightpost/${data.id}/expense/`)
      .then((e) => {
        setExpenses(e.data);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
        setLoading(false);
      });
  };

  const deleteExpense = async () => {
    setLoading(true);
    await fletesAPI
      .delete(`api/freightpost/${data.id}/expense/`, {
        data: { expense_id: expenseSelected.id },
      })
      .then((e) => {
        toast.success("Gasto eliminado con exito", 800);
        getExpenses();
        setModalConfirmDelete(false);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
        setModalConfirmDelete(false);
      });
  };

  const nextGetExpenses = async (url) => {
    setLoading(true);
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setExpenses(e.data);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!expenses) {
      getExpenses();
    }
  }, [expenses]);
  return (
    <>
      <Toaster />
      <Row>
        <Col>
          {loading ? (
            <RenderSpace jc="center">
              <Spinner animation="border" variant="primary" />
            </RenderSpace>
          ) : !expenses ? (
            <p>No hay informacion</p>
          ) : (
            <>
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button
                    className="text-white"
                    onClick={() => setModalAddExpenses(true)}
                  >
                    Agregar Gasto
                  </Button>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  {expenses.results && expenses.results.length > 0 ? (
                    <>
                      <Table
                        striped
                        bordered
                        className="dashboard_main_container_table"
                      >
                        <thead>
                          <tr>
                            <th>Fecha</th>
                            <th>Comentario</th>
                            <th>Tipo</th>
                            <th>Costo</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {expenses.results.map((e, i) => (
                            <RenderExpensesTableRow
                              key={i}
                              data={e}
                              onViewExpense={() => {
                                setExpenseSelected(e);
                                setModalViewExpenses(true);
                              }}
                              onDeleteExpense={() => {
                                setExpenseSelected(e);
                                setModalConfirmDelete(true);
                              }}
                            />
                          ))}
                        </tbody>
                      </Table>
                      <Pagination>
                        <Pagination.Prev
                          disabled={expenses.previous === null}
                          onClick={() => nextGetExpenses(expenses.previous)}
                        />
                        <Pagination.Next
                          disabled={expenses.next === null}
                          onClick={() => nextGetExpenses(expenses.next)}
                        />
                      </Pagination>
                    </>
                  ) : (
                    <RenderSpace>
                      <p>No hay gastos</p>
                    </RenderSpace>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <RenderModalAddExpenses
        state={modalAddExpenses}
        setState={setModalAddExpenses}
        id={data.id}
        getMore={getExpenses}
      />
      <RenderModalViewAttachments
        state={modalViewExpenses}
        setState={setModalViewExpenses}
        data={expenseSelected}
        setStateAddAttachment={setModalAddAttachment}
      />
      <RenderModalAddExpensesAttachments
        state={modalAddAttachment}
        setState={setModalAddAttachment}
        id={data.id}
        getMore={getExpenses}
        expense={expenseSelected}
      />
      <RenderModalConfirm
        state={modalConfirmDelete}
        setState={setModalConfirmDelete}
        confirmText={
          expenseSelected &&
          `¿Estas seguro de eliminar este gasto:  ${expenseSelected.comment} de costo: ${expenseSelected.cost}?`
        }
        confirmModal={deleteExpense}
      />
    </>
  );
};

export default RenderExpenses;
