import { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import Select from "react-select";

import { citiesStyles } from "../../utils/styles";
import { reformatCity } from "../../utils";

const RenderSelectCity = ({
  name,
  control,
  defaultValue = undefined,
  placeholder,
  label,
  error,
  urlAPI,
  className,
}) => {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const { fletesAPI } = useFetch();

  const getCities = async () => {
    if (urlAPI) {
      await fletesAPI
        .get(urlAPI)
        .then((e) => {
          setCities(e.data.results);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      await fletesAPI
        .get(`api/city/`)
        .then((e) => {
          setCities(e.data.results);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const resCities = cities.map((e) => reformatCity(e));

  const updateLoading = (e) => {
    setLoading(true);
    setTimeout(() => {
      updateCities(e.target.value);
    }, 400);
  };

  const updateCities = async (data) => {
    if (urlAPI) {
      await fletesAPI
        .get(`${urlAPI}search/?name=${data}`)
        .then((e) => {
          setCities(e.data.results);
          setLoading(false);
        })
        .catch(() => {
          return null;
        });
    } else {
    await fletesAPI
      .get(`api/city/search/?name=${data}`)
      .then((e) => {
        setCities(e.data);
        setLoading(false);
      })
      .catch(() => {
        return null;
      });
    }
  };

  return (
    <Form.Group controlId="formCityOrgin" className={className}>
    {Boolean(label) && <Form.Label>{label}</Form.Label>}
    <Controller
      name={name}
      control={control}
      defaultValue={
        defaultValue === null ? undefined : reformatCity(defaultValue)
      }
      render={({ field }) => (
        <Select
          isClearable
          isLoading={loading}
          styles={citiesStyles}
          placeholder={placeholder}
          {...field}
          options={resCities}
          className={error ? "invalidSelect" : ""}
          onFocus={() => {
            getCities();
            setLoading(true);
          }}
          onKeyDown={(e) => {
            e.target.value
              ? e.target.value.length > 2
                ? updateLoading(e)
                : setTimeout(() => {
                    getCities();
                  }, 50)
              : setTimeout(() => {
                  getCities();
                }, 50);
          }}
        />
      )}
    />
    </Form.Group>
  );
};
export default RenderSelectCity;
