import { useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import toast from "react-hot-toast";
import RenderSelectCity from "../../../../fyb_components/results/RenderSelectCity";
import { reformatCity } from "../../../../utils";

import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

const EditContact = ({ getMore, dataCompany }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const { fletesAPI } = useFetch();

  const updateCompanyContactInformation = async (data) => {
    await fletesAPI
      .patch(`api/company/${dataCompany.id}/contact_information/`, {
        name: data.name,
        email: data.email,
        phone: data.phone,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        zip_code: data.zip_code,
        phone: data.phone,
        city_id: data.city.value,
      })
      .then((e) => {
        getMore();
        toast.success("Se ha actualizado la información de contacto");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = (data) => {
    updateCompanyContactInformation(data);
  };
  useEffect(() => {
    if (dataCompany !== null) {
      reset({
        name: dataCompany.contact_information.name,
        email: dataCompany.contact_information.email,
        phone: dataCompany.contact_information.phone,
        address_line1: dataCompany.contact_information.address_line1,
        address_line2: dataCompany.contact_information.address_line2,
        zip_code: dataCompany.contact_information.zip_code,
        phone: dataCompany.contact_information.phone,
        city: dataCompany.contact_information.city
          ? reformatCity(dataCompany.contact_information.city)
          : undefined,
      });
    }
  }, [dataCompany]);

  console.log(dataCompany);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar Cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <RenderInput
              name={"name"}
              label="Nombre completo"
              placeholder="Ingresa tu nombre completo"
              register={register}
              error={errors.name && errors.name.message}
            />
            <RenderInput
              name={"email"}
              label="Correo Electrónico"
              placeholder="Ingresa tu correo electrónico"
              register={register}
              error={errors.email && errors.email.message}
            />
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Telefono</Form.Label>
              <PhoneInputWithCountry
                name="phone"
                placeholder="Ingrese su número telefónico"
                defaultCountry="MX"
                control={control}
                rules={{ required: true }}
              />
              {errors.phone && (
                <p className="dashboard_form_error_message">
                  {errors.phone.message}
                </p>
              )}
            </Form.Group>
            <RenderInput
              name={"address_line1"}
              label="Dirección"
              placeholder="Ingresa tu dirección y numero"
              register={register}
              error={errors.address_line1 && errors.address_line1.message}
            />
            <RenderInput
              name={"address_line2"}
              label="Colonia"
              placeholder="Ingresa tu Colonia"
              register={register}
              error={errors.address_line2 && errors.address_line2.message}
            />
            <RenderSelectCity
              name={"city"}
              label="Ciudad"
              placeholder="Ingresa tu Ciudad"
              defaultValue={null}
              control={control}
              error={errors.city && "Ciudad es requerido"}
              className="mb-3"
            />
            <RenderInput
              name={"zip_code"}
              label="Código Postal"
              placeholder="Ingresa tu Código Postal"
              register={register}
              error={errors.zip_code && errors.zip_code.message}
            />
            <RenderInput
              name={"phone"}
              label="Teléfono"
              placeholder="Ingresa tu Teléfono"
              register={register}
              error={errors.phone && errors.phone.message}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditContact;
