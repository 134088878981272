import { useState, useEffect, useContext } from "react";

import { Pagination, Row, Col, Table, Button } from "react-bootstrap";
import { RiAddLine } from "react-icons/ri";
import { MdEdit, MdDelete } from "react-icons/md";
import PaginationFyB from "../../fyb_components/results/PaginationFyB";
import RenderEmailContent from "../../fyb_components/results/RenderEmailContent";
import RenderPhoneContent from "../../fyb_components/results/RenderPhoneContent";
import ModalDelete from "../../components/Dashboard/Points/ModalDelete";
import ModalFormUpdate from "../../components/Dashboard/Points/ModalFormUpdate";
import RenderButtonMore from "./RenderButtonMore";

const RenderMyPoints = ({ points, title, isMobile, reloadPoints }) => {
  const [showDel, setShowDel] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [pointUpdate, setPointUpdate] = useState(null);
  const [pointDelete, setPointDelete] = useState(null);
  const [modalUDT, setModalUDT] = useState(false);

  useEffect(() => {
    showUpdate
      ? setModalUDT(true)
      : setTimeout(() => {
          setModalUDT(false);
        }, 200);
  }, [showUpdate]);

  return (
    <>
      {isMobile ? (
        <>
          {points &&
            points.results.map((e, i) => {
              return (
                <div className="v2_dashboard_body_box">
                  <Row>
                    <Col md={6} sm={6}>
                      <p>
                        {e.first_name} {e.last_name}
                      </p>
                    </Col>
                    <Col md={6} sm={6}>
                      <p>{e.email}</p>
                    </Col>
                    <Col md={6} sm={6}>
                      <p>
                        <RenderPhoneContent phone={e.phone} />
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={3} lg={3} md={6} sm={6}>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="v2_dashboard_body_buttons"
                        onClick={() => {
                          setShowUpdate(true);
                          setPointUpdate(e);
                        }}
                      >
                        <MdEdit />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="v2_dashboard_body_buttons"
                        onClick={() => {
                          setShowDel(true);
                          setPointDelete(e);
                        }}
                      >
                        <MdDelete />
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </>
      ) : (
        <>
          <div className="dashboard_main_container_table">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                {points && points.results.length > 0 ? (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <td>Nombre</td>
                          <td>Apellido</td>
                          <td>Email</td>
                          <td>Telefono</td>
                          <td title="Preferencia de contacto por Email">Email</td>
                          <td title="Preferencia de contacto por Telefono">Telefono</td>
                          <td title="Preferencia de contacto por Whatsapp">Whatsapp</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {points &&
                          points.results.map((e, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>{e.first_name}</td>
                                  <td>{e.last_name}</td>
                                  <td>
                                    <RenderEmailContent
                                      mailto={e.email}
                                      label={e.email}
                                    />
                                  </td>
                                  <td>
                                    <RenderPhoneContent phone={e.phone} />
                                  </td>
                                  <td>
                                    {e.preference_reach_by_email ? "Si" : "No"}
                                  </td>
                                  <td>
                                    {e.preference_reach_by_phone ? "Si" : "No"}
                                  </td>
                                  <td>
                                    {e.preference_reach_by_whatsapp
                                      ? "Si"
                                      : "No"}
                                  </td>
                                  <td>
                                    <RenderButtonMore
                                      buttons={[
                                        {
                                          label: (
                                            <>
                                              <MdEdit /> Editar
                                            </>
                                          ),
                                          onClick: () => {
                                            setShowUpdate(true);
                                            setPointUpdate(e);
                                          },
                                        },
                                        {
                                          label: (
                                            <>
                                              <MdDelete /> Eliminar
                                            </>
                                          ),
                                          onClick: () => {
                                            setShowDel(true);
                                            setPointDelete(e);
                                          },
                                        },
                                      ]}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                    <PaginationFyB
                      previous={points.previous}
                      next={points.next}
                      reloader={reloadPoints}
                    />
                  </>
                ) : (
                  <>
                    <br />
                    <h3 className="text-center fyb_h2">
                      No se encontraron puntos de contacto
                    </h3>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
      <ModalDelete
        show={showDel}
        setShow={setShowDel}
        data={pointDelete}
        getMore={reloadPoints}
      />
      {modalUDT && (
        <ModalFormUpdate
          show={showUpdate}
          setShow={setShowUpdate}
          data={pointUpdate}
          getMore={reloadPoints}
        />
      )}
    </>
  );
};

export default RenderMyPoints;
