import { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useFetch from "../../../hooks/useFetch";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import RenderJustHours from "../../../fyb_components/results/RenderJustHours";
import imageDummy from "../../../assets/images/compressed_warehouse_trucks.jpg";

//styles

const ImageSwiper = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 5px;
`;

const ImageThumb = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
`;

const Description = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 0 10px;
  color: var(--fyb_darkblue);
  img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  h4{
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
    color: var(--fyb_darkblue);
  }
  p{
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0;
  }
  li{
    font-size: 14px;
  }
`;


const WarehousesId = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  let { id } = useParams();
  const { fletesAPI } = useFetch();

  const getImages = (warehouseId) => {
    fletesAPI
      .get(`api/warehouses/${warehouseId}/photos/`)
      .then((e) => {
        setImages(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getWarehouseById = async () => {
    await fletesAPI
      .get(`api/warehouses/${id}/`)
      .then((e) => {
        setWarehouse(e.data);
        getImages(e.data.id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getWarehouseById();
  }, []);

  if(loading){
    return(
      <RenderSpace>
        <Spinner animation="border" variant="primary" />
      </RenderSpace>
    )
  }else {
    return (
      <Container className="mt-3">
        <Row>
          <Col>
            <h3>Bodega: {warehouse.name}</h3>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xxl="9" xl="9">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
            >
              {images.results && images.results.length > 0 ? images.results.map((e, i) => (
                <SwiperSlide key={i}>
                  <ImageSwiper src={e.url} alt="" />
                </SwiperSlide>
              )) : (
                <SwiperSlide>
                  <ImageSwiper src={imageDummy} alt="" />
                </SwiperSlide>
              )}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper mt-2"
            >
              {images.results && images.results.length > 0 &&  images.results.map((e, i) => (
                <SwiperSlide key={i}>
                  <ImageThumb src={e.url} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
          <Col xxl="3" xl="3">
            <Description>
              <img src={images.results && images.results.length > 0 ?  images.results[0].url : imageDummy} alt="" />
              <p><b>Dirección: </b>{warehouse.contact_information ? warehouse.contact_information.address_line1 : 'no asignado'}</p>
              <p><b>Cuidad: </b>{warehouse.contact_information ? warehouse.contact_information.city ? warehouse.contact_information.city.name : "cuidad no asiganda" : 'no asignado'}</p>
              <p><b>Estado: </b>{warehouse.contact_information ? warehouse.contact_information.city ?  warehouse.contact_information.city.state.name : "estado no asignado" : 'no asignado'}</p>
              <p><b>C.P: </b>{warehouse.contact_information ? warehouse.contact_information.zip_code : 'no asignado'}</p>
              <p><b>País: </b>{warehouse.contact_information ?  warehouse.contact_information.city ?  warehouse.contact_information.city.state.country.name : 'pais no asignado' : 'no asignado'}</p>
              <h4>Horario:</h4>
              <RenderJustHours hours={warehouse.hours} />
              <h4>Servicios:</h4>
              <ul>
                {warehouse.services.map((e, i) => (
                  <li key={i}><p>{e.name}</p></li>
                ))}
              </ul>
            </Description>
          </Col>
        </Row>
      </Container>
    );
  }   
};

export default WarehousesId;
