import { useState, useEffect, useContext } from "react";
import { BodegasContext } from "../../../../context";
import { Row, Spinner, Container } from "react-bootstrap";
import useFetch from "../../../../hooks/useFetch";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import { checkMobile } from "../../../../utils";
import QoutesTable from "../../Quotes/QoutesTable";

export default function Quotes({ dataCompany, getMore }) {
  const [loading, setLoading] = useState(true);
  const [quotes, setQuotes] = useState(null);
  const { fletesAPINotURL } = useFetch();
  const [isMobile, setIsMobile] = useState(null);
  const { carrier } = useContext(BodegasContext);

  const reloadQuotes = async (e) => {
    let ur;
    switch (e) {
      case null:
        ur = null;
        break;
      case "init":
        ur = process.env.REACT_APP_URL_API + `api/quote/?company_id=${dataCompany.id}`;
        break;
      default:
        ur = e;
        break;
    }
    if (ur != null) {
      setLoading(true);
      await fletesAPINotURL
        .get(ur)
        .then((i) => {
          setQuotes(i.data);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          setLoading(false);
        })
        .catch((i) => {
          console.log(i);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    checkMobile(setIsMobile);
  }, [isMobile]);

  useEffect(() => {
    if (quotes == null) {
      reloadQuotes("init");
    }
  }, [quotes]);

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Estimaciones De Mi Compañia</h1>
        </Row>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : quotes.results.length > 0 ? (
          <>
            <QoutesTable
              quotes={quotes}
              title={""}
              isMobile={isMobile}
              reloadOrders={reloadQuotes}
            />
          </>
        ) : (
          <p>No hay estimaciones</p>
        )}
      </Container>
    </>
  );
}
