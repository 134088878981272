import { useEffect } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import RenderInput from "../../../fyb_components/results/RenderInput";
import RenderSelect from "../../../fyb_components/results/RenderSelect";
import SelectDate from "../../../fyb_components/results/Selectdate";
import SelectFreight from "../SelectFreight";
import moment from "moment";
import toast from "react-hot-toast";

const addNewAppointment = yup.object().shape({
  title: yup.string().required("El título es requerido"),
  // description: yup.string().required("La descripción es requerida"),
  type: yup.object().required("El tipo es requerido").nullable(),
  // freight_id: yup.object().required("El flete es requerido").nullable(),
  start_date: yup.string().required("La fecha de inicio es requerida"),
  end_date: yup.string().required("La fecha de fin es requerida"),
});

const ModalAddAppointment = ({ show, handleClose, truckData, id, update, selected = null, defaultTitle = null }) => {
  const { fletesAPI } = useFetch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(addNewAppointment),
    defaultValues: {
      title: "",
      description: "",
      type: "",
      freight_id: "",
      start_date: "",
      end_date: "",
    },
  });
  const close = () => {
    handleClose();
    reset();
  };

  const onSubmit = async (data) => {
    console.log(data);
    const toastId = toast.loading("Agregando cita...");
    try {
      await fletesAPI.post(`api/companytruck/${id}/appointments/`, {
        title: data.title,
        description: data.description,
        type: data.type.value,
        start_date: moment(data.start_date).utc().format(),
        end_date: moment(data.end_date).utc().format(),
        freight_id: data?.freight_id?.value || null,
      });
      handleClose();
      reset();
      update();
      toast.dismiss(toastId);
      toast.success("Cita agregada", 400);
    } catch (error) {
      console.log(error);
      toast.dismiss(toastId);
      toast.error("Error al agregar la cita", 400);
    }
  };
  
  const freight = watch("freight_id");

  useEffect(() => {
    if (Boolean(truckData)) {
      setValue("start_date", new Date(truckData.start));
      setValue("end_date", new Date(truckData.end));
      setValue("freight_id", selected);
      setValue("title", defaultTitle || "");
      setValue("type", { value: 4, label: "Flete" });
    }
  }, [truckData]);

  return (
    <Modal show={show} onHide={close}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Cita</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-4">
          <Row>
            <SelectFreight
              name="freight_id"
              control={control}
              error={errors.freight_id}
              label="Flete"
              placeholder="Usa la vista del flete para asociarlo"
              disabled
            />
            {freight && <a href={`/dashboard/my_order/${freight.value}/edit`} target="_blank" rel="noreferrer">ir a flete</a>}
          </Row>
          <Row>
            <RenderInput
              className=""
              label="Título"
              name="title"
              register={register}
              error={errors?.title?.message}
              placeholder={"Ingrese el título"}
            />
          </Row>
          <Row>
            <RenderInput
              className=""
              label="Descripción"
              name="description"
              register={register}
              error={errors?.description?.message}
              as={"textarea"}
              placeholder={"Ingrese la descripción"}
            />
          </Row>
          <Row>
            <RenderSelect
              className=""
              label="Tipo"
              name="type"
              control={control}
              error={errors?.type?.message}
              options={[
                { value: 0, label: "Mantenimiento" },
                { value: 1, label: "Limpieza" },
                { value: 2, label: "Reparación" },
                { value: 3, label: "Cita" },
                { value: 4, label: "Flete" },
                { value: 5, label: "Otro"}
              ]}
            />
          </Row>
          <Row>
            <Col>
              <SelectDate
                className=""
                name="start_date"
                control={control}
                placeholder="Selecciona la fecha de carga"
                label="Fecha de carga"
              />
            </Col>
            <Col>
              <SelectDate
                className=""
                name="end_date"
                control={control}
                placeholder="Selecciona la fecha de entrega"
                label="Fecha de entrega"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="success"
            type="submit"
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddAppointment;
