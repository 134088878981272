import React from 'react';

const ForbiddenPage = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#ffffff',
      color: '#0f2840',
      flexDirection: 'column',
      fontFamily: 'Arial, sans-serif',
    },
    heading: {
      fontSize: '72px',
      marginBottom: '20px',
    },
    subheading: {
      fontSize: '24px',
      marginBottom: '40px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#ffffff',
      backgroundColor: '#0f2840',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>403</h1>
      <h2 style={styles.subheading}>Acceso Denegado</h2>
      <p>No tienes permiso para acceder a esta página.</p>
      <a href="/dashboard/" style={styles.button}>Volver al inicio</a>
    </div>
  );
};

export default ForbiddenPage;