import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Form as Formu, Row, Col, Button, Nav } from "react-bootstrap";
import { erroresCodes } from "../utils";
import FybNavigationBar from "../components/FybNavigationBar";

export default function ValidateAccount() {
  const styles = {
    container: {
      height: "800px",
      background: "#ffffff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inputCont: {
      position: "relative",
      cursor: "pointer",
    },
    inputEye: {
      position: "absolute",
      right: "5px",
      top: "0",
      bottom: 0,
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    logo: {
      width: "120px",
    },
  };

  const { userId, token } = useParams();
  let resultVerified = "Tu cuenta ha sido verificada";
  let url = process.env.REACT_APP_URL_API + "api/users/" + userId + "/verify/";
  let data = new Object();
  data["token"] = token;

  axios
    .post(url, data)
    .then((e) => {
      resultVerified = "Tu cuenta ha sido verificada";
    })
    .catch((e) => {
      if (e.request.status == 500) {
        toast.error(
          "Error en Fletes & Bodegas, favor de contactar soporte@fletesybodegas.com"
        );
      } else {
        toast.error(erroresCodes(e.response.data.error_code));
      }
    });

  return (
    <>
      <FybNavigationBar />
      <Toaster />
      <div style={styles.container}>
        <Formu>
          <Row>
            <Col>
              <h2>{resultVerified}</h2>
              <Button href="/login" variant="primary" className="text-white">
                Iniciar Sesión
              </Button>
            </Col>
          </Row>
        </Formu>
      </div>
      <Footer />
    </>
  );
}
