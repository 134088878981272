import { Modal, Row, Col, Button } from "react-bootstrap";

const RenderModalConfirm = ({ state, setState, confirmModal, confirmText = "¿Está seguro de proceder?" }) => {
  return (
    <Modal show={state} onHide={() => setState(false)}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Row>
          <Col>
            <p>{confirmText}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={confirmModal}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={() => setState(false)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RenderModalConfirm;