import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

const RenderEmailContent = ({ mailto, label }) => {

    return (
    <>
        {mailto &&
        <>
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = "mailto:" + mailto;
                    e.preventDefault();
                }}>
                {label}
            </Link>
        </>
        }
    </>
    );
};

export default RenderEmailContent;