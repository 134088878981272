import { useState, useEffect, useContext } from "react";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import { Modal, Button, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import RenderInput from "../../../fyb_components/results/RenderInput";
import RenderSelect from "../../../fyb_components/results/RenderSelect";
import { useForm } from "react-hook-form";
import RenderModalConfirmLocation from "../../../fyb_components/results/Modals/RenderModalCofirmLocation";
import RenderSelectCity from "../../../fyb_components/results/RenderSelectCity";
import SelectHours from "../MyWarehouseById/SelectHours";
import { days, daysToJsonToString } from "../../../utils";
import RenderSelectCompany from "../../../fyb_components/results/RenderSelectCompany";

const statusOption = [
  { value: "1", label: "Publicado" },
  { value: "0", label: "no publicado" },
];

const ModalAddWarehouse = ({ state, setState, getMore }) => {
  const [services, setServices] = useState(null);
  const [modalLocation, setModalLocation] = useState(false);
  const [location, setLocation] = useState(null);
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const servicesOptions = services
    ? services.map((e) => ({ value: e.id, label: e.name }))
    : [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    createWarehouse(data);
  };

  const handleClose = () => {
    setState(false);
    reset();
    setLocation(null);
  };

  const patchContact = async (ide, data_patch) => {
    await fletesAPI
      .patch(`api/warehouses/${ide}/contactinformation/`, data_patch)
      .then((e) => {
        setLocation(null);
        toast.success("Direccion actualizada con exito");
        setState(false);
        reset();
        getMore();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createWarehouse = async (datos) => {
    const {
      name,
      status,
      service_ids,
      city_id,
      address_line1,
      address_line2,
      zip_code,
      phone,
      company_id
    } = datos;
    if (location) {
      await fletesAPI
        .post("api/warehouses/", {
          name,
          hours: daysToJsonToString(datos),
          status: status.value,
          service_ids: service_ids.map((e) => e.value),
          latitude: location.lat,
          longitude: location.lng,
          company_id: company_id.value,
        })
        .then((e) => {
          toast.success("Bodega creada con exito");
          console.log(e);
          patchContact(e.data.id, {
            address_line1,
            address_line2,
            zip_code,
            phone,
            city_id: city_id.value,
            latitude: location.lat,
            longitude: location.lng,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast.error("Debe seleccionar una ubicacion");
    }
  };

  const getServices = async () => {
    await fletesAPI
      .get(`api/warehouseservices/`, {
        params: {
          companyId: companyId,
        },
      })
      .then((e) => {
        setServices(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!services) {
      getServices();
    }
  }, [services]);
  return (
    <>
      <Toaster />
      <Modal show={state} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Crea una bodega</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="company">
              <Form.Label>Compañia</Form.Label>
              <RenderSelectCompany
                name={"company_id"}
                control={control}
                defaultValue={null}
                errors={errors.company_id && "Este campo es requerido"}
                placeholder={"Selecciona una compañia"}
              />
            </Form.Group>
            <RenderInput
              name={"name"}
              label="Nombre de la bodega"
              placeholder="ingrese un nombre para su bodega"
              register={register}
              error={errors.name && errors.name.message}
            />
            <SelectHours register={register} days={days} />
            <Button
              variant="primary"
              onClick={() => setModalLocation(true)}
              className="mb-4 w-100 text-white"
            >
              Selecionar ubicacion en el mapa
            </Button>
            <RenderSelect
              name="status"
              label="Estado"
              placeholder="Selecciona un estado"
              options={statusOption}
              control={control}
              error={errors.status && "el estatus es requerido"}
            />
            <RenderSelect
              name="service_ids"
              label="Servicios"
              placeholder="Selecciona uno o mas servicios"
              options={servicesOptions}
              control={control}
              error={errors.service_ids && "el servicio es requerido"}
              isMulti
            />
            <Form.Group className="mb-4" controlId="city_id">
              <Form.Label>Cuidad</Form.Label>
              <RenderSelectCity
                name="city_id"
                label="Ciudad"
                placeholder="Selecciona una ciudad"
                defaultValue={null}
                control={control}
                error={errors.city_id && "la ciudad es requerida"}
              />
            </Form.Group>
            <RenderInput
              name={"address_line1"}
              label="Direccion"
              placeholder="Ingrese la direccion"
              register={register}
              error={errors.address_line1 && errors.address_line1.message}
            />
            <RenderInput
              name={"address_line2"}
              label="Colonia"
              placeholder="Ingrese la colonia"
              register={register}
              error={errors.address_line2 && errors.address_line2.message}
            />
            <RenderInput
              name={"zip_code"}
              label="Codigo postal"
              placeholder="Ingrese el codigo postal"
              register={register}
              error={errors.address_line1 && errors.address_line1.message}
            />
            <RenderInput
              name={"phone"}
              label="Telefono"
              placeholder="Ingrese el telefono"
              register={register}
              error={errors.address_line1 && errors.address_line1.message}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Guardar
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <RenderModalConfirmLocation
        state={modalLocation}
        setState={setModalLocation}
        location={location}
        setLocation={setLocation}
      />
    </>
  );
};

export default ModalAddWarehouse;
