import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import RenderInvoice from "../../../../fyb_components/results/RenderInvoice";
import useFetch from "../../../../hooks/useFetch";
import RenderModalAddInvoice from "../../../../fyb_components/results/Modals/RenderModalAddInvoice";

const listaDeFacturas = [
  {
    id: 1,
    fecha: "01/01/2021",
    monto: 100,
    estado: "Pendiente",
    descarga: "Descargar",
    completado: false,
  },
  {
    id: 2,
    fecha: "01/01/2021",
    monto: 100,
    estado: "Pendiente",
    descarga: "Descargar",
    completado: false,
  },
  {
    id: 3,
    fecha: "01/01/2021",
    monto: 100,
    estado: "Pendiente",
    descarga: "Descargar",
    completado: false,
  },
  {
    id: 4,
    fecha: "01/01/2021",
    monto: 100,
    estado: "Pendiente",
    descarga: "Descargar",
    completado: false,
  },
  {
    id: 5,
    fecha: "01/01/2021",
    monto: 100,
    estado: "Completado",
    descarga: "Descargar",
    completado: true,
  },
  {
    id: 6,
    fecha: "01/01/2021",
    monto: 100,
    estado: "Completado",
    descarga: "Descargar",
    completado: true,
  },
  {
    id: 7,
    fecha: "01/01/2021",
    monto: 100,
    estado: "Completado",
    descarga: "Descargar",
    completado: true,
  },
];

const Invoices = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const [addInvoice, setAddInvoice] = useState(false);
  const { fletesAPI } = useFetch();

  const getInvoices = async () => {
    setLoading(true);
    await fletesAPI
      .get("api/invoice/")
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (invoices === null) {
      getInvoices();
    }
  }, [invoices]);
  console.log(invoices);

  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0 row">
            <h1 className="fyb_h1 w-auto">Facturas</h1>
            <Button className="text-white" style={{width: "200px"}} onClick={() => setAddInvoice(true)}>Descargar</Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Lista de facturas pendientes:</h5>
            {invoices &&
              invoices.results.map((invoice) => (
                <RenderInvoice key={invoice.id} dataInvoice={invoice} />
              ))}
          </Col>
        </Row>
      </Container>
      <RenderModalAddInvoice 
        state={addInvoice}
        setState={setAddInvoice}
      />
    </>
  );
};

export default Invoices;
