import { Modal, Button, Row, Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import RenderInput from "../RenderInput";
import RenderSelect from "../RenderSelect";
import { optionsCertificatedStatus } from "../../../utils";

const RenderModalAddTask = ({ state, setState, dataCompany, getMore }) => {
  const { fletesAPI } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onClose = () => {
    setState(false);
    reset();
  };

  const createTaskByCompany = async (data) => {
    await fletesAPI
      .post(`api/company/${dataCompany.id}/task/`, {
        status: data.status.value,
        comment_requestor: data.comment_requestor,
        instructions: data.instructions,
        title: data.title,
      })
      .then(() => {
        getMore();
        onClose();
        toast.success("Tarea creada con éxito");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (data) => {
    createTaskByCompany(data);
  };

  return (
    <>
      <Modal show={state} onHide={onClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Tarea</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <RenderInput
                  label={"Titulo"}
                  name={"title"}
                  register={register}
                  errors={errors.title && errors.title.message}
                  placeholder="Ingrese el titulo"
                />
              </Col>
              <Col>
                <RenderSelect
                  label={"Estado"}
                  name={"status"}
                  control={control}
                  errors={errors.title && "Este campo es requerido"}
                  placeholder="Ingrese el estado"
                  options={optionsCertificatedStatus}
                />
              </Col>
            </Row>
            <Row>
              <RenderInput
                label={"Instrucciones"}
                name={"instructions"}
                register={register}
                placeholder="Ingrese la URL de las instrucciones"
                errors={errors.instructions && errors.instructions.message}
              />
            </Row>
            <Row>
              <RenderInput
                label={"Comentarios de solicitante"}
                name={"comment_requestor"}
                register={register}
                errors={errors.comment_requestor && errors.comment_requestor.message}
                placeholder="Ingrese el solicitante de comentarios"
                as={"textarea"}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Guardar
            </Button>
            <Button variant="danger" onClick={onClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RenderModalAddTask;
