import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: calc(100%);
  height: calc(100vh - 82px);
`;

export default function ContDashboard({ children }) {
  return (
    <Container>
      {children}
    </Container>
  );
}
