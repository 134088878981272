import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Switch from "./Switch";

import Plan from "./Plan";
import PlansCont from "./PlansCont";

export default function Plans({ title, subtitle, plans }) {
  const [stateSw, setStateSw] = useState(false);
  return (
    <div>
      <Container>
        <Row className="my-5">
          <h1 className="text-center fs-1" style={{ color: "#0F2840" }}>
            {title}
          </h1>
          <p className="text-center fs-5" style={{ color: "#0F2840" }}>
            {subtitle}
          </p>
        </Row>
        <Row className="my-5 justify-content-center">
          <Switch state={stateSw} onClick={() => setStateSw(!stateSw)} />
        </Row>
        <Row className="my-5">
          <PlansCont>
            {plans.map((e, i) => (
              <Plan
                popular={e.popular}
                key={i}
                yearlyPrice={e.yearlyPrice}
                monthlyPrice={e.monthlyPrice}
                state={stateSw}
                title={e.title}
                text={e.text}
                service={e.service}
              />
            ))}
          </PlansCont>
        </Row>
      </Container>
    </div>
  );
}
