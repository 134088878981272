export function simplifyDecimal(value) {
  const roundedValue = parseFloat(value).toFixed(1);

  const simplifiedValue = roundedValue.endsWith('.0')
    ? roundedValue.slice(0, -2)
    : roundedValue;

  return simplifiedValue;
}


export function formatNumber(value) {
  const number = parseFloat(value);

  const trimmedValue = number.toFixed(0);

  const formattedValue = parseInt(trimmedValue, 10).toLocaleString();

  return formattedValue;
}