import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";
import { MdDelete, MdEdit, MdModeComment } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import RenderSelectCompany from "../../../fyb_components/results/RenderSelectCompany";
import { useForm } from "react-hook-form";
import RenderPickupDate from "../../../fyb_components/results/RenderPickupDate";
import RenderCityResult from "../../../fyb_components/results/RenderCityResult";
import RenderRate from "../../../fyb_components/results/RenderRate";
import RenderStatus from "../../../fyb_components/results/RenderStatus";
import { IoTicketSharp } from "react-icons/io5";
import { FiFilePlus, FiShare } from "react-icons/fi";
import moment from "moment";

const AdminOrders = () => {
  const [orders, setOrders] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const { control, handleSubmit, watch } = useForm();

  const onSubmit = (data) => {
    updateOrdersByCompanyId(data.company.value);
  };

  const getOrders = async () => {
    await fletesAPI
      .get(`api/freightpost/`)
      .then((e) => {
        setOrders(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadUser = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setOrders(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateOrdersByCompanyId = async (id) => {
    await fletesAPI
      .get(`api/freightpost/?company_id=${id}`)
      .then((e) => {
        setOrders(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteOrder = async () => {
    await fletesAPI
      .delete(`api/freightpost/${dataSelected.id}/`)
      .then(() => {
        setModalDelete(false);
        getOrders()
        toast.success("Se ha eliminado la carga");
      })
      .catch((e) => {
        toast.error("Error al eliminar el registro");
      });
  };

  useEffect(() => {
    if (!orders) {
      getOrders();
    }
  }, [orders]);

  const focusCompany = watch("company");

  useEffect(() => {
    if (focusCompany === undefined || focusCompany === null) {
      getOrders();
    }
  }, [focusCompany]);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todas Las Cargas</h4>
            <div className="d-flex">
              <Form
                className="d-flex"
                style={{ width: "400px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <RenderSelectCompany
                  name={"company"}
                  control={control}
                  defaultValue={null}
                  placeholder={"Buscar por empresa"}
                />
                <Button type="submit" className="text-white">
                  Buscar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Fecha de Carga</th>
              <th>Ciudad de origen</th>
              <th>Ciudad de destino</th>
              <th>Punto de contacto</th>
              <th>Numero de referencia</th>
              <th>Tarifa</th>
              <th>Estado</th>
              <th>Eliminado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders &&
              orders.results.map((e, i) => {
                return (
                  <tr key={i}>
                    <RenderPickupDate date={e.ready_pickup_date} />
                    <RenderCityResult
                      origin_city={e.origin_city}
                      destination_city={e.destination_city}
                      display_city={e.origin_city}
                      subtitle={"Origen"}
                    />
                    <RenderCityResult
                      origin_city={e.origin_city}
                      destination_city={e.destination_city}
                      display_city={e.destination_city}
                      subtitle={"Destino"}
                    />
                    <td>
                      {e.point_of_contact.first_name}{" "}
                      {e.point_of_contact.last_name}
                    </td>
                    <td>{e.internal_reference_number}</td>
                    <RenderRate rate={e.rate} currency={e.currency} />
                    <RenderStatus status={e.status} />
                  <td>{e.deleted_on ? moment(e.deleted_on).format("YYYY-MM-DD") : ''}</td>
                    <td>
                      <RenderButtonMore
                        buttons={[
                          {
                            label: (
                              <>
                                Ver archivos <FiFilePlus />
                              </>
                            ),
                            onClick: () =>
                              (window.location.href = `/dashboard/adminorders/${e.id}/files`),
                          },
                          {
                            label: (
                              <>
                                Editar <MdEdit />
                              </>
                            ),
                            onClick: () =>
                              (window.location.href = `/dashboard/adminorders/${e.id}/public`),
                          },
                          {
                            label: (
                              <>
                                Gastos <IoTicketSharp />
                              </>
                            ),
                            onClick: () =>
                              (window.location.href = `/dashboard/adminorders/${e.id}/expenses`),
                          },
                          {
                            label: (
                              <>
                                Comentarios <MdModeComment />
                              </>
                            ),
                            onClick: () =>
                              (window.location.href = `/dashboard/adminorders/${e.id}/comments`),
                          },
                          {
                            label: (
                              <>
                                Compartir <FiShare />
                              </>
                            ),
                            onClick: () =>
                              (window.location.href = `/dashboard/adminorders/${e.id}/files`),
                          },
                          {
                            label: (
                              <>
                                Eliminar <MdDelete />
                              </>
                            ),
                            onClick: () => {
                              setModalDelete(true);
                              setDataSelected(e);
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {orders && (
          <Pagination>
            <Pagination.Prev
              disabled={!orders.previous}
              onClick={() => reloadUser(orders.previous)}
            />
            <Pagination.Next
              disabled={!orders.next}
              onClick={() => reloadUser(orders.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar orden"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteOrder(dataSelected.id)}
        confirmText="¿Estas seguro de eliminar esta orden?"
      />
    </>
  );
};

export default AdminOrders;
