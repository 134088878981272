import { useRef } from "react";
import { Button, Container, Row, Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import { fullNameCity } from "../../../utils";
import { formatPhoneNumber } from "react-phone-number-input";
import { ReactComponent as Logo } from "../../../assets/icons/logo-white.svg";

export const BoardingRequestViewPDF = ({ order}) => {
  const cardRef = useRef(null);

  const handlePrint = () => {
    const originalTitle = document.title;
    const newTitle = `Cotización - ${order?.quote?.transportation_company?.name || "Sin nombre"}`;

    document.title = newTitle;

    const printContents = cardRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    document.title = originalTitle;
    window.location.reload();
  };

  const quote = order?.quote;

  return (
    <Container>
      <Row className="justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center gap-4">
          <div className="w-100 d-flex justify-content-end">
            <Button onClick={handlePrint} className="text-white">Imprimir</Button>
          </div>
          <Card ref={cardRef} style={{ width: "8.5in", height: "11in" }}>
            <Card.Body className="gap-2 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center mb-4 p-2" style={{ borderRadius: 5}}>
                <img src={"/"} alt="logo" style={{ width: "150px", height: "auto" }} />
                <div className="text-right d-flex flex-column justify-content-end align-items-end">
                  <p className="m-0 fw-bolder">Boarding request No:</p>
                  <p className="m-0">{order.reference || "Nombre de la Compañía"}</p>
                </div>
              </div>
              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section flex justify-content-center">
                    <p><b>Transportista</b></p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item><b>Compañía:</b> {quote?.transportation_company?.name || ""}</ListGroup.Item>
                <ListGroup.Item><b>Teléfono:</b> {formatPhoneNumber(quote?.transportation_company?.contact_information?.phone) || ""}</ListGroup.Item>
                <ListGroup.Item><b>Nombre:</b> {quote?.transportation_company?.contact_information?.name || ""}</ListGroup.Item>
                <ListGroup.Item><b>Correo:</b> {quote?.transportation_company?.contact_information?.email || ""}</ListGroup.Item>
                <ListGroup.Item><b>Dirección:</b> {quote?.transportation_company?.contact_information?.address_line1 || ""}</ListGroup.Item>
                <ListGroup.Item><b>Camión:</b> {quote?.bed_type?.name || ""}</ListGroup.Item>
                <ListGroup.Item><b>Placas:</b> {quote?.truck?.license_plate || "N/A"}</ListGroup.Item>
              </ListGroup>
              <div className="d-flex ">
                <ListGroup>
                  <ListGroup.Item>
                    <div className="header-card_section flex justify-content-center">
                      <p><b>Origen</b></p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item><b>Origen:</b> {fullNameCity(quote?.origin_city) || ""}</ListGroup.Item>
                  <ListGroup.Item><b>Dirección:</b> {fullNameCity(quote?.destination_city) || ""}</ListGroup.Item>
                  <ListGroup.Item><b>Fecha:</b> {moment.utc(quote?.pickup_time).format("YYYY-MM-DD HH:mm") || ""}</ListGroup.Item>
                </ListGroup>
                <ListGroup>
                  <ListGroup.Item>
                    <div className="header-card_section flex justify-content-center">
                      <p><b>Destino</b></p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item><b>Destino:</b> {fullNameCity(quote?.destination_city) || ""}</ListGroup.Item>
                  <ListGroup.Item><b>Dirección:</b> {fullNameCity(quote?.destination_city) || ""}</ListGroup.Item>
                  <ListGroup.Item><b>Fecha:</b> {moment.utc(quote?.delivery_time).format("YYYY-MM-DD HH:mm") || ""}</ListGroup.Item>
                </ListGroup>
              </div>
              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section flex justify-content-center">
                    <p><b>Detalles del Flete</b></p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item><b>Tarimas:</b> {quote?.pallets || ""}</ListGroup.Item>
                <ListGroup.Item><b>Peso:</b> {quote?.weight || ""}</ListGroup.Item>
                <ListGroup.Item><b>Carga:</b> {quote?.cargo || ""}</ListGroup.Item>
                <ListGroup.Item><b>Precio:</b> ${quote?.total || "Error"} {quote?.currency || "Error"}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Container>
  );
};
