import { useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import RenderCheckbox from "../../../../fyb_components/results/RenderCheckbox";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import toast from "react-hot-toast";

const EditCompany = ({ getMore, dataCompany }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { fletesAPI } = useFetch();
  const updateCompany = async (data) => {
    await fletesAPI
      .patch(`api/company/${dataCompany.id}/`, data)
      .then((e) => {
        getMore();
        toast.success("Se actualizó la compañia");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onSubmit = (data) => {
    updateCompany(data);
  };
  useEffect(() => {
    if (dataCompany !== null) {
      reset({
        name: dataCompany.name,
        carrier: dataCompany.carrier,
        broker: dataCompany.broker,
        facebook_url: dataCompany.facebook_url,
        twitter_user: dataCompany.twitter_user,
        website_url: dataCompany.website_url,
        ctpat_code: dataCompany.ctpat_code,
        dot_code: dataCompany.dot_code,
        mc_code: dataCompany.mc_code,
        oea_code: dataCompany.oea_code,
        rfc_code: dataCompany.rfc_code,
        default_days_to_pay: dataCompany.default_days_to_pay,
      });
    }
  }, [dataCompany]);

  console.log(dataCompany);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar Cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Row>
              <Col>
                <RenderInput
                  name={"name"}
                  label="Nombre de la compañía"
                  placeholder="Ingresa el nombre de la compañía"
                  register={register}
                  error={errors.name && errors.name.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RenderCheckbox
                  name="carrier"
                  label="Transportista"
                  error={errors.carrier && errors.carrier.message}
                  register={register}
                />
              </Col>
              <Col>
                <RenderCheckbox
                  name="broker"
                  label="Logística"
                  error={errors.broker && errors.broker.message}
                  register={register}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RenderInput
                  name="facebook_url"
                  label="Facebook"
                  error={errors.facebook_url && errors.facebook_url.message}
                  register={register}
                  placeholder="Ingresa la url de tu página de Facebook"
                />
                <RenderInput
                  name="twitter_user"
                  label="Twitter"
                  error={errors.twitter_user && errors.twitter_user.message}
                  register={register}
                  placeholder="Ingresa el usuario de tu cuenta de Twitter"
                />
                <RenderInput
                  name="website_url"
                  label="Página Web"
                  error={errors.website_url && errors.website_url.message}
                  register={register}
                  placeholder="Ingresa la url de tu página web"
                />
                <RenderInput
                  name="ctpat_code"
                  label="C-TPAT Codigo"
                  error={errors.ctpat_code && errors.ctpat_code.message}
                  register={register}
                  placeholder="Ingresa el código de tu C-TPAT"
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <RenderInput
              name="dot_code"
              label="Numero de DOT"
              error={errors.dot_code && errors.dot_code.message}
              register={register}
              placeholder="Ingresa el número de DOT"
            />
            <RenderInput
              name="mc_code"
              label="Numero de MC"
              error={errors.mc_code && errors.mc_code.message}
              register={register}
              placeholder="Ingresa el número de MC"
            />
            <RenderInput
              name="oea_code"
              label="Numero de OEA"
              error={errors.oea_code && errors.oea_code.message}
              register={register}
              placeholder="Ingresa el número de OEA"
            />
            <RenderInput
              name="rfc_code"
              label="RFC"
              error={errors.rfc_code && errors.rfc_code.message}
              register={register}
              placeholder="Ingresa tu RFC"
            />
            <RenderInput
              name="default_days_to_pay"
              label="Días para pagar"
              error={
                errors.default_days_to_pay && errors.default_days_to_pay.message
              }
              register={register}
              placeholder="Ingresa los días para pagar"
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditCompany;
