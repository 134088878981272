import { useEffect } from "react";
import { Modal, Button, Row, Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import RenderInput from "../RenderInput";
import RenderSelect from "../RenderSelect";
import { optionsCertificatedStatus } from "../../../utils";
import RenderDonwloadFile from "../RenderDonwloadFile";

const RenderModalEditTask = ({
  state,
  setState,
  dataCompany,
  getMore,
  dataTask,
}) => {
  const { fletesAPI } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onClose = () => {
    setState(false);
    reset();
  };

  const createTaskByCompany = async (data) => {
    await fletesAPI
      .patch(`api/company/${dataCompany.id}/task/`, {
        status: data.status.value,
        comment_requestor: data.comment_requestor,
        instructions: data.instructions,
        title: data.title,
        task_id: dataTask.id,
      })
      .then(() => {
        toast.success("Tarea creada con éxito");
        getMore();
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (data) => {
    createTaskByCompany(data);
  };

  useEffect(() => {
    if (dataTask) {
      reset({
        status: optionsCertificatedStatus.find(
          (e) => e.value === dataTask.status
        ),
        comment_requestor: dataTask.comment_requestor,
        comment_submmiter: dataTask.comment_submmiter,
        instructions: dataTask.instructions,
        title: dataTask.title,
      });
    }
  }, [dataTask]);

  return (
    <>
      <Modal show={state} onHide={onClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Tarea</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <RenderInput
                  label={"Titulo"}
                  name={"title"}
                  register={register}
                  errors={errors.title && errors.title.message}
                  placeholder="Ingrese el titulo"
                />
              </Col>
              <Col>
                <RenderSelect
                  label={"Estado"}
                  name={"status"}
                  control={control}
                  errors={errors.title && "Este campo es requerido"}
                  placeholder="Ingrese el estado"
                  options={optionsCertificatedStatus}
                />
              </Col>
            </Row>
            <Row>
              <RenderInput
                label={"Instrucciones"}
                name={"instructions"}
                register={register}
                placeholder="Ingrese la URL de las instrucciones"
                errors={errors.instructions && errors.instructions.message}
              />
            </Row>
            <Row>
              <RenderInput
                label={"Comentarios de solicitante"}
                name={"comment_requestor"}
                register={register}
                errors={
                  errors.comment_requestor && errors.comment_requestor.message
                }
                placeholder="Ingrese el solicitante de comentarios"
                as={"textarea"}
              />
            </Row>
            <Row>
              {dataTask && dataTask.comment_submmiter && (
                <RenderInput
                  label={"Comentario del remitente"}
                  name={"comment_submmiter"}
                  register={register}
                  errors={
                    errors.comment_submmiter && errors.comment_submmiter.message
                  }
                  placeholder="Ingrese el comentario del remitente"
                  disabled
                  as={"textarea"}
                />
              )}
            </Row>
            {dataTask && dataTask.attachments && dataTask.attachments.map((e) => (
              <Row>
                <RenderDonwloadFile url={e.url} title={e.name} />
              </Row>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Guardar
            </Button>
            <Button variant="danger" onClick={onClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RenderModalEditTask;
