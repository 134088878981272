import { Col, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import RenderInput from "./RenderInput";
import RenderSelect from "./RenderSelect";
import { affiliateAttachmentOptions } from "../../utils/defaultValues";

const AddFile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  background-color: lightgray;
  border-radius: 5px;
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    svg {
      cursor: pointer;
      &:hover {
        color: white;
      }
    }
  }
`;

const RenderAddFile = ({ register, control, id, onDelete }) => {
  return (
    <AddFile>
      <div className="header">
        <MdClose onClick={() => onDelete()} />
      </div>
      <Row>
        <Col>
          <RenderInput
            label={"Nombre del archivo"}
            name={`file_name_${id}`}
            register={register}
            placeholder="Ingrese el nombre del archivo"
          />
        </Col>
        <Col>
          <RenderSelect 
            name={`type_${id}`}
            label="Tipo"
            control={control}
            placeholder="Ingrese el tipo"
            options={affiliateAttachmentOptions}
          />
        </Col>
      </Row>
      <Row>
        <RenderInput 
          name={`file_content_${id}`}
          label="Archivo"
          register={register}
          placeholder="Ingrese el archivo"
          type={"file"}
        />
      </Row>
    </AddFile>
  );
};

export default RenderAddFile;
