import styled from "styled-components";
import userPNG from "../../assets/icons/user.png";

const Container = styled.div`
  margin-bottom: 10px;
  img {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 5px;
    background-color: #e5e5e5;
  }
`;
const RenderGPSDriver = ({ driver_profile }) => {
  return (
    <Container>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <img
            src={
              driver_profile.profile_url ? driver_profile.profile_url : userPNG
            }
            alt=""
            className="rounded-circle"
          />
          <div className="ml-2">
            <h5 className="m-0">
              {driver_profile.first_name + " " + driver_profile.last_name}
            </h5>
            <p className="m-0">{driver_profile.email}</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RenderGPSDriver;
