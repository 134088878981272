import React from "react";
import { Card as Box } from "react-bootstrap";

const Card = ({ image, title, text }) => {
  return (
    <Box style={{ width: "320px", marginBottom: "30px"}} className='border-0'>
      <Box.Img variant="top" src={image} className='border-0' />
      <Box.Body style={{backgroundColor: '#EBEBEB'}} className='px-5'>
        <Box.Title className="fs-4"> {title} </Box.Title>
        <Box.Text className="fs-6">
          {text}
        </Box.Text>
      </Box.Body>
    </Box>
  );
}

export default Card;
