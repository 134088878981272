import { useState, useContext, useEffect } from "react";
import { Button, Col, Container, Row, Table, Pagination } from "react-bootstrap";
import { MdDelete, MdAddPhotoAlternate, MdEdit } from "react-icons/md";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import ModalAddWarehouse from "./ModalAddWarehouse";
import ModalImagesList from "./ModalImagesList";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import RenderStatus from "../../../fyb_components/results/RenderStatus";
import RenderHours from "../../../fyb_components/results/RenderHours";

const MyWarehouses = () => {
  const [warehouses, setWarehouses] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalImageList, setModalImageList] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const getWarehouses = async () => {
    await fletesAPI
      .get(`api/warehouses/`, {
        params: {
          company_id: companyId,
        },
      })
      .then((e) => {
        setWarehouses(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadWarehouses = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setWarehouses(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const deleteWarehouse = async (id) => {
    await fletesAPI
      .delete(`api/warehouses/${id}/`)
      .then((e) => {
        getWarehouses();
        toast.success("Bodega eliminada");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!warehouses) {
      getWarehouses();
    }
  }, [warehouses]);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Mis Bodegas</h4>
            <Button className="text-white" onClick={() => setModalAdd(true)}>
              Crear Bodega
            </Button>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Dirección</th>
              <th>Horario</th>
              <th>Estado</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {warehouses &&
              warehouses.results.map((e, i) => (
                <tr key={i}>
                  <td>{e.name}</td>
                  <td>{e.contact_information.address_line1}</td>
                  <td>
                    <RenderHours hours={e.hours} />
                  </td>
                  <RenderStatus status={e.status} />
                  <td>
                    <RenderButtonMore
                      buttons={[
                        {
                          label: (
                            <>
                              Imagenes <MdAddPhotoAlternate />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/my_warehouses/${e.id}/images`;
                          },
                        },
                        {
                          label: (
                            <>
                              Editar <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/my_warehouses/${e.id}/edit`;
                          },
                        },
                        {
                          label: (
                            <>
                              Eliminar <MdDelete />
                            </>
                          ),
                          onClick: () => {
                            setDataSelected(e);
                            setModalDelete(true);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {warehouses && (
          <Pagination>
            <Pagination.Prev
              disabled={!warehouses.previous}
              onClick={() => reloadWarehouses(warehouses.previous)}
            />
            <Pagination.Next
              disabled={!warehouses.next}
              onClick={() => reloadWarehouses(warehouses.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteWarehouse(dataSelected.id)}
        confirmText="¿Estas seguro de eliminar este almacen?"
      />
      <ModalAddWarehouse
        state={modalAdd}
        setState={setModalAdd}
        getMore={getWarehouses}
      />
      <ModalImagesList
        state={modalImageList}
        setState={setModalImageList}
        data={dataSelected}
      />
    </>
  );
};

export default MyWarehouses;
