import { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import { useParams, useNavigate } from "react-router-dom";
import EditCompany from "./views/Edit";
import useFetch from "../../../hooks/useFetch";
import { BodegasContext } from "../../../context";
import Details from "./views/Details";
import Invoices from "./views/Invoices";
import Info from "./views/Info";


const viewList = {
  edit: "edit",  
  details: "details",
  invoices: "invoices",
  info: "info",
};

const Company = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const { companyId } = useContext(BodegasContext);
  const { type } = useParams();
  const navigate = useNavigate();
  const { fletesAPI } = useFetch();

  const getCompany = async () => {
    setLoading(true);
    await fletesAPI
      .get(`api/company/${companyId}/`)
      .then((e) => {
        setCompany(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (company === null && companyId !== undefined) {
      getCompany();
    }
  }, [company]);

  if (loading) {
    return (
      <RenderSpace>
        <Spinner animation="border" variant="primary" />
      </RenderSpace>
    );
  }
  return(
    <>
      <Container>
        <h2 className="mt-2">Mi Compañia</h2>
        <hr />
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Resumen",
                  active: type === viewList.info || type === undefined,
                  onClick: () => {
                    navigate(`/dashboard/company/${viewList.info}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Contacto",
                  active: type === viewList.details,
                  onClick: () => {
                    navigate(`/dashboard/company/${viewList.details}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Facturación",
                  active: type === viewList.invoices,
                  onClick: () => {
                    navigate(`/dashboard/company/${viewList.invoices}`, {
                      replace: true,
                    });
                  },
                },
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && <RenderSpace><Spinner animation="border" variant="primary" /></RenderSpace>}
            {!loading && type === viewList.details && <Details getMore={getCompany} dataCompany={company} />}
            {!loading && type === viewList.invoices && <Invoices getMore={getCompany} dataCompany={company} />}
            {(!loading && type === viewList.info || type === undefined) && <Info dataCompany={company} />}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Company;