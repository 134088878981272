import { useEffect, useState } from "react";
import { Form, Button, Row, Container, Card, ListGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdVerifiedUser } from "react-icons/md";
import * as yup from "yup";
import toast from "react-hot-toast";
import SelectCompany from "../SelectCompany";
import useFetch from "../../../hooks/useFetch";

const schema = yup.object().shape({
  company_id: yup.object().required("Seleccione una compañia").nullable(),
});

const FormSelectCompany = ({ defaultData, next }) => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fletesAPI } = useFetch();

  const getCompany = async (id) => {
    setLoading(true);
    await fletesAPI
      .get(`api/company/${id}/`)
      .then((res) => {
        setCompany(res.data);
      })
      .catch(() => {
        toast.error("Error al obtener la compañia", 2000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    next(data);
  };

  useEffect(() => {
    const keys = Object.keys(errors);
    if (Boolean(keys.length !== 0)) {
      toast.error(errors[keys[0]].message, 2000);
    }
  }, [errors]);

  useEffect(() => {
    if (defaultData) {
      setValue(
        "company_id",
        defaultData?.company_id ? defaultData.company_id : undefined
      );
    }
  }, []);

  const companyValue = watch("company_id");
  console.log({ companyValue });
  useEffect(() => {
    if (Boolean(companyValue)) {
      getCompany(companyValue.value);
    } else {
      setCompany(null);
    }
  }, [companyValue]);

  return (
    <Container>
      <Form
        className="d-flex flex-column gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row className="d-flex justify-content-center">
          <SelectCompany
            name={"company_id"}
            control={control}
            placeholder="Seleccione una compañia"
            error={null}
            style={{ maxWidth: "624px" }}
          />
        </Row>
        <Row className="d-flex justify-content-center">
          {loading ? (
            <p style={{textAlign: "center"}}>Cargando...</p>
          ) : (
            company && (
              <Card style={{ width: "600px" }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 20 }}>
                    {" "}
                    {company?.name || ""}{" "}
                  </Card.Title>
                  <ListGroup>
                    <ListGroup.Item>
                      <b>Verificado:</b>{" "}
                      {company?.verified && (
                        <MdVerifiedUser className="icon-verified" />
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Correo:</b> {company?.owner?.profile?.email || ""}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Nombre:</b> {company?.owner?.first_name || ""}
                      {company?.owner?.first_name ? ", " : ""}
                      {company?.owner?.last_name || ""}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Teléfono:</b>{" "}
                      {company?.contact_information?.phone || ""}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>RFC:</b> {company?.rfc_code || ""}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Pagina web:</b>{" "}
                      <a href={company?.website_url || ""}>
                        {" "}
                        {company?.website_url}{" "}
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Dirección:</b>{" "}
                      {company?.contact_information?.address_line1 || ""}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Ciudad:</b>{" "}
                      {company?.contact_information?.city?.name || ""},{" "}
                      {company?.contact_information?.city?.state?.name || ""}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            )
          )}
        </Row>
        <Row className="justify-content-center">
          <Button
            type="submit"
            className="fyb_large_button text-white"
            style={{ width: "400px", fontSize: "1.2rem", fontWeight: 600 }}
          >
            Siguiente
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default FormSelectCompany;
