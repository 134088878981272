export const errorCodes = (e) => {
  switch (e) {
    case 101:
      return "A user with such email already exist, try recovering your password";
    case 102:
      return "Password must be at least 6 characters";
    case 103:
      return "Invalid Session, please log in again";
    case 104:
      return "Invalid email/password, not found, try again or reset your password";
    case 105:
      return "Please Authenticate, your session is not valid";
    case 106:
      return "You dont have permissions to perform this action";
    case 107:
      return "Users can only be associated with one company";
    case 108:
      return "Users can only be associated with one permission per company";
    case 109:
      return "You must provide at least 4 characters to search cities by name";
    case 110:
      return "You must provide the attribute";
    case 111:
      return "We can't verify the user at this time, verify the token is valid";
    case 112:
      return "The user has already been verified";
    case 113:
      return "Invalid token to reset password, please request a new one";
    case 114:
      return "Company does not exist";
    case 115:
      return "User does not exist or is not active";
    case 116:
      return "User does not exist in that company";
    case 117:
      return "City Id is invalid, please verify the format";
    case 118:
      return "Company Id is invalid, please verify the format";
    case 119:
      return "Country Id is invalid, please verify the format";
    case 120:
      return "Freight Bed Option Id is invalid, please verify the format";
    case 121:
      return "Freight Post Id is invalid, please verify the format";
    case 122:
      return "Point of Contact Id is invalid, please verify the format";
    case 123:
      return "State Id is invalid, please verify the format";
    case 124:
      return "Invalid invitation token";
    case 125:
      return "The Warehouse Service Id is invalid, please verify the format";
    default:
      return "error";
  }
};

export const erroresCodes = (e) => {
  switch (e) {
    case 101:
      return "Ya existe un usuario con dicho correo electrónico";
    case 102:
      return "La contraseña debe tener al menos 6 caracteres";
    case 103:
      return "Sesión no válida, vuelva a iniciar sesión";
    case 104:
      return "Correo electrónico/contraseña no válido, no encontrado, inténtelo de nuevo o restablezca su contraseña";
    case 105:
      return "Autentícate, tu sesión no es válida";
    case 106:
      return "No tienes permisos para realizar esta acción";
    case 107:
      return "Los usuarios solo pueden asociarse con una empresa";
    case 108:
      return "El usuario ya esta asociado con su empresa";
    case 109:
      return "Debe proporcionar al menos 4 caracteres para buscar ciudades por nombre";
    case 110:
      return "Debe proporcionar el atributo";
    case 111:
      return "No podemos verificar al usuario en este momento, verifique que el token sea válido";
    case 112:
      return "El usuario ya ha sido verificado";
    case 113:
      return "Token no válido para restablecer la contraseña, solicite una nueva";
    case 114:
      return "La empresa no existe";
    case 115:
      return "El usuario no existe o no está activo";
    case 116:
      return "El usuario no existe en esa empresa";
    case 117:
      return "La identificación de la ciudad no es válida, verifique el formato";
    case 118:
      return "La identificación de la empresa no es válida, verifique el formato";
    case 119:
      return "El ID del país no es válido, verifique el formato";
    case 120:
      return "El Id. de opción de cama de carga no es válido, verifique el formato";
    case 121:
      return "El ID de la publicación de carga no es válido, verifique el formato";
    case 122:
      return "El ID del punto de contacto no es válido, verifique el formato";
    case 123:
      return "La identificación del estado no es válida, verifique el formato";
    case 124:
      return "Token de invitación no válido";
    case 125:
      return "El ID del servicio de almacén no es válido, verifique el formato";
    case 126:
        return "El usuario ya se le habia invitado invitado";
    case 127:
        return "Necesitas primero verificar tu correo electrónico para continuar";
    case 128:
        return "Necesitas verificar tu empresa para continuar";
    case 129:
        return "Tu plan esta limitado, te invitamos a contactar soporte@fletesybodegas.com para continuar"
    default:
      return "error";
  }
};
