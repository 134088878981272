import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useFetch from "../../hooks/useFetch";
import { Container, Row } from "react-bootstrap";
import MultiStep from "../../components/Dashboard/MultiStep";
import InitialBoardingForm from "./InitalBoardingForm";
import InvoiceForm from "./InitialInvoiceForm";
import Confirmation from "./Confirmation";
import FinishBoardingRequest from "./FinishBoardinRequest";
import SelectInvoice from "./SelectInvoice";

const stepsData = (setCurrentStep) => [
  { title: "Informacion", step: "select_info", onClick: () => null },
  { title: "Facturación", step: "select_invos", onClick: () => null },
  { title: "Confirmar", step: "confirm", onClick: () => null },
  { title: "Finalizado", step: "done", onClick: () => null },
];

const GenerateBoardingRequest = () => {
  const { taskId, orderId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [order, setOrder] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [finishTask, setFinishTask] = useState(null);

  const { fletesAPI } = useFetch();

  const nextStep = (data = {}) => {
    if (currentStep < stepsData().length - 1) {
      setCurrentStep(currentStep + 1);
      setOrder({ ...order, ...data });
    }
  };

  const defineInvoice = (data) => {
    setInvoice(data);
  }

  const getOrder = async () => {
    try {
      const response = await fletesAPI.get(`api/freight/${orderId}/`);
      setOrder(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const finish = async (order, invoice) => {
    setFinishTask({ order, invoice });
    setCurrentStep(4);
  };

  const steps = stepsData(setCurrentStep);

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <Container className="d-flex flex-column gap-4 py-4">
      <Row>
        <h1 style={{width: '100%', textAlign: "center", fontSize: 20}}>Carta de instrucciones</h1>
      </Row>
      <Row>
        <MultiStep steps={steps} currentStep={currentStep} />
      </Row>
      <Row>{currentStep === 0 && <InitialBoardingForm order={order} next={nextStep} />}</Row>
      <Row>{currentStep === 1 && <SelectInvoice next={nextStep} companyId={order?.quote?.company?.id} defInvoice={defineInvoice} />}</Row>
      <Row>{currentStep === 2 && <Confirmation finish={finish} companyId={order?.quote?.company?.id} orderId={orderId} invoice={invoice}/>}</Row>
      <Row>{currentStep >= 3 && <FinishBoardingRequest task={finishTask} invoice={invoice} taskId={taskId} />}</Row>
    </Container>
  );
};

export default GenerateBoardingRequest;
