import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import Map, { Marker } from "react-map-gl";
import {
  CardsContainer,
  MapContainer,
  UiContainer,
  FilterContainer,
} from "../../styles";
import Card from "./Card";
import { useForm } from "react-hook-form";
import { BodegasContext } from "../../../context";

//example of a data box
import ModalWarehouses from "./ModalWarehouses";
import RenderSelect from "../../../fyb_components/results/RenderSelect";


export default function Warehouses() {
  const { fletesAPI } = useFetch();
  const [warehouses, setWarehouses] = useState(null);
  const [serviceType, setServiceType] = useState([]);
  const [loadingServiceType, setLoadingServiceType] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const { handleSubmit, control, watch } = useForm();
  const { companyId } = useContext(BodegasContext);
  const mapRef = useRef(null);

  const getSometing = (arr, data) => {
    const arr2 = arr.map((item) => {
      return item.id == data;
    });
    return arr2.includes(true);
  };
  
  const serv = watch("service");

  const getDataFilter = async (data) => {
    if (data.service) {
      await fletesAPI
      .get(`api/warehouses/`, {
        params: {
          company_id: companyId,
        },
      })
      .then((e) => {
        const services = e.data.results.filter((service) =>
          getSometing(service.services, data.service.value)
        );
        setWarehouses(services);
      })
      .catch((e) => {
        console.log(e);
      });
    }
  };

  const onSubmit = async (data) => {
    getDataFilter(data);
  };

  useEffect(() => {
    if (serv == undefined) {
      getWarehouses();
    }
  }, [serv]);

  const getServicesTypes = async () => {
    if (serviceType.length == 0) {
      setLoadingServiceType(true);
      await fletesAPI
        .get(`api/warehouseservices/`)
        .then((i) => {
          console.log(i.data);
          setServiceType(i.data.map((item) => ({label: item.name, value: item.id})));
          setLoadingServiceType(false);
        })
        .catch((i) => {
          console.log(i);
        });
    }
  };

  const initialViewState = {
    latitude: 27.628,
    longitude: -99.535,
    zoom: 5,
    bearing: 0,
    pitch: 0,
  };

  const onSelectCity = useCallback(({ longitude, latitude }) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 1000,
      zoom: 18,
    });
  }, []);

  const getWarehouses = async () => {
    await fletesAPI
      .get(`api/warehouses/`, {
        params: {
          company_id: companyId,
        },
      })
      .then((e) => {
        setWarehouses(e.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!warehouses) {
      getWarehouses();
    }
  }, [warehouses]);
  return (
    <>
      <Row className="mt-3">
        <h1 className="dashboard_main_container_h1 fyb_h1">Bodegas</h1>
      </Row>
      <hr />
      <Row>
        <MapContainer>
          <Map
            ref={mapRef}
            initialViewState={initialViewState}
            onZoomStart={() => console.log(mapRef.current?.getFilter())}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            style={{
              height: "calc(100vh - 190px)",
              width: "calc(100% - 640px)",
              borderRadius: "10px",
            }}
          >
            {warehouses &&
              warehouses.map((e, i) => (
                <Marker
                  key={i}
                  longitude={e.longitude}
                  latitude={e.latitude}
                  color="rgb(15, 40, 64)"
                />
              ))}
          </Map>
          <UiContainer>
            <FilterContainer>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xxl={9} xl={9}>
                    <RenderSelect
                      name="service"
                      control={control}
                      placeholder="Selecciona un servicio"
                      options={serviceType}
                      onFocus={() => getServicesTypes()}
                      isLoading={loadingServiceType}
                    />
                  </Col>
                  <Col xxl={3} xl={3}>
                    <Button type="submit"> Filtrar </Button>
                  </Col>
                </Row>
              </Form>
            </FilterContainer>
            <CardsContainer>
              {warehouses &&
                warehouses.map((e, i) => (
                  <Card
                    warehouse={e}
                    key={i}
                    onClick={(d) => {
                      onSelectCity(e);
                      d.focus();
                    }}
                    onClickButton={(o) => {
                      o.stopPropagation();
                      window.location.href = `/dashboard/warehouses/${e.id}`;
                    }}
                  />
                ))}
            </CardsContainer>
          </UiContainer>
        </MapContainer>
      </Row>
      <ModalWarehouses
        state={selectedWarehouse}
        setState={setSelectedWarehouse}
      />
    </>
  );
}
