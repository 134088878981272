import { Button, Table } from "react-bootstrap";


const vouchersData = [
  {
    id: 1,
    voucher: "FLE-0001",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
  {
    id: 2,
    voucher: "FLE-0002",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
  {
    id: 3,
    voucher: "FLE-0003",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
  {
    id: 4,
    voucher: "FLE-0004",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
  {
    id: 5,
    voucher: "FLE-0005",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
  {
    id: 6,
    voucher: "FLE-0006",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
  {
    id: 7,
    voucher: "FLE-0007",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
  {
    id: 8,
    voucher: "FLE-0008",
    date: "2021-01-01",
    amount: 1000,
    status: "Activo",
  },
];



const Vouchers = ({}) => {
  return (
    <>
      <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Voucher</th>
              <th>Monto</th>
              <th>Descargar</th>
            </tr>
          </thead>
          <tbody>
            {vouchersData.map((voucher) => (
              <tr key={voucher.id}>
                <td>{voucher.date}</td>
                <td>{voucher.voucher}</td>
                <td>{voucher.amount}.00</td>
                <td>
                  <Button className="text-white">
                    Descargar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
      </Table>

    </>
  );
}


export default Vouchers;