import { useState } from "react";
import styled from "styled-components";
import userPNG from "../../assets/icons/user.png";
import { MdDone, MdDelete } from "react-icons/md";
import RenderModalConfirm from "./Modals/RenderModalConfirm";
import useFetch from "../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";

const Comment = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  .commentHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #bbbbbb;
    .commetInfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      .imgCont {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #f5f5f5;
        margin-right: 10px;
        img {
          background-color: var(--bs-gray-600);
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #323232;
      }
      svg {
        margin-left: 5px;
        font-size: 14px;
        color: white;
        background-color: #7cc0ff;
        border-radius: 50%;
        padding: 2px;
      }
    }
    .commentDelete {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      &:hover {
        color: var(--bs-red);
      }
    }
  }
  .comment {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    margin: 0;
    p {
      margin: 0;
    }
  }
`;

const RenderComment = ({ data, getMore }) => {
  const [modal, setModal] = useState(false);
  const { fletesAPI } = useFetch();

  const deleteComment = async () => {
    await fletesAPI
      .delete(`api/freightpost/${data.freightpost_id}/comment/`, {
        data: {
          comment_id: data.id,
        },
      })
      .then(() => {
        toast.success("Comentario eliminado");
        getMore();
        setModal(false);
      })
      .catch(() => {
        toast.error("Error al eliminar comentario");
      });
  };
  return (
    <>
      <Comment>
        <div className="commentHeader">
          <div className="commetInfo">
            <div className="imgCont">
              <img
                src={
                  data.user_profile.profile_url
                    ? data.user_profile.profile_url
                    : userPNG
                }
                alt=""
              />
            </div>
            <span>
              {data.user_profile.first_name + " " + data.user_profile.last_name}{" "}
              {data.user_profile.verified && <MdDone />}
            </span>
          </div>
          <div className="commentDelete">
            <MdDelete onClick={() => setModal(true)} />
          </div>
        </div>
        <div className="comment">
          <p>{data.comment}</p>
        </div>
      </Comment>
      <RenderModalConfirm
        state={modal}
        setState={setModal}
        confirmText={`Are you sure you want to delete this comment? ${data.comment}`}
        confirmModal={() => deleteComment()}
      />
    </>
  );
};

export default RenderComment;
