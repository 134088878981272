import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Row, Form, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import { erroresCodes } from "../../../utils/errorCodes";
import { addNewComment } from "../../../utils/formsValidation.js";

const AddNewComment = ({ updateData, dataComent }) => {
  const { fletesAPI } = useFetch();

  const addFreightPostComment = async (data) => {
    const promise = fletesAPI.post(
      `api/freight/${dataComent.id}/comment/`,
      data
    );

    toast.promise(promise, {
      loading: "Enviando comentario...",
      success: "Comentario agregado",
      error: "Error al enviar el comentario",
    });

    promise
      .then(() => {
        updateData();
        reset();
      })
      .catch((e) => {
        if (e.request.status !== 500) {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addNewComment),
  });

  return (
    <>
      <Form onSubmit={handleSubmit(addFreightPostComment)}>
        <Row>
          <Form.Group controlId="comment">
            <Form.Label>Añadir comentario </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Comentario"
              {...register("comment")}
            />
            <Form.Text className="text-danger position-absolute">
              {errors.comment && errors.comment.message}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end my-3">
            <Button type="submit" variant="primary" className="text-white">
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddNewComment;
