import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Map, { Marker } from "react-map-gl";
import iconTruck from "../../../../assets/icons/pintruck.png";
import almacenTruck from "../../../../assets/icons/almacen.png";
import RenderGPSStatus from "../../../../fyb_components/results/RenderGPSStatus";
import RenderSwitch from "../../../../fyb_components/results/RenderSwitch";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import { GPSContainer } from "../../../../fyb_components/results/styles/ModalSlide";
import useFetch from "../../../../hooks/useFetch";

const RenderGPS = ({ data, updateData }) => {
  const [switchState, setSwitchState] = useState(Boolean(data.public.tracker));
  const [locateDriver, setLocateDriver] = useState({
    created: "",
    id: "",
    latitude: "",
    longitude: "",
    status: 0,
    tracker_id: "",
  });
  const origin = data.origin.latitude && data.origin.longitude ? { lat: data.origin.latitude, lnt: data.origin.longitude } : null;
  const destination = data.destination.latitude && data.destination.longitude ? { lat: data.destination.latitude, lnt: data.destination.longitude } : null;
  const { fletesAPI } = useFetch();

  const enlableSwitch = async () => {
    await fletesAPI.post(`api/freightpost/${data.public.id}/tracker/`)
      .then((res) => {
        console.log(res);
        setSwitchState(true);
        updateData();
      })
      .catch((err) => {
        console.log(err);
      }); 
  };

  const disableSwitch = async () => {
    await fletesAPI.delete(`api/freightpost/${data.public.id}/tracker/`)
      .then((res) => {
        console.log(res);
        setSwitchState(false);
        updateData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocateInterval = async () => {
    await fletesAPI.get(`api/tracker/recent/?guest_token=${data.public.tracker.guest_token}`)
      .then((res) => {
        console.log(res.data);
        setLocateDriver(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getLocateInterval();
    }, 20000);
    return () => switchState ? clearInterval(interval) : null;
  }, []);
  return (
    <>
      <Row>
        <Col className="d-flex align-items-center">
          <RenderSwitch state={switchState} setState={setSwitchState} onClick={() => switchState ? disableSwitch() : enlableSwitch()} />
          {switchState ? (
            <p className="m-0"> Tracking de GPS habilitado </p>
          ) : (
            <p className="m-0"> Habilita el tracking para visualizar el GPS </p>
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {switchState ? (
            <GPSContainer>
              <Map
                initialViewState={{
                  longitude: -100,
                  latitude: 40,
                  zoom: 3.5,
                }}
                mapStyle="mapbox://styles/mapbox/light-v10"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                style={{ width: "calc(100% - 330px)", height: "600px", borderRadius: "10px" }}
              >
                {origin && (
                  <Marker
                    longitude={origin.lnt}
                    latitude={origin.lat}
                    anchor="bottom"
                  >
                    <img src={almacenTruck} width={27} height={27} />
                  </Marker>
                )}
                {locateDriver && <Marker longitude={locateDriver.longitude} latitude={locateDriver.latitude} anchor="bottom">
                  <img src={iconTruck} width={27} height={38} />
                </Marker>}
                {destination && (
                  <Marker
                    longitude={destination.lnt}
                    latitude={destination.lat}
                    anchor="bottom"
                    onClick={(e) => console.log(e)}
                  >
                    <img src={almacenTruck} width={27} height={27} />
                  </Marker>
                )}
              </Map>
              <RenderGPSStatus gpsStatus={locateDriver} data={data} />
            </GPSContainer>
          ) : (
            <RenderSpace> Habilita el GPS </RenderSpace>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RenderGPS;
