import {Col} from "react-bootstrap"

const RenderPayPerDistance = ({rate, distance, isMobile}) => {
    return (
    <>
        {isMobile ?
        <>
            {rate != null && distance != null ?
            <>
                <Col xl={4} lg={4} md={6} sm={6}>
                    <p>{rate != null && distance != null ? "$ " + (rate/distance).toFixed(2) + " por km" : ""}</p>
                </Col>
            </>
            :
            ""
            }
        </>
        :
        <>
            <td>{rate != null && distance != null ? "$ " + (rate/distance).toFixed(2) + " por km" : ""}</td>
        </>
        }
    </>
    );
};

export default RenderPayPerDistance;