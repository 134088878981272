import { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationDetailSchema } from "../../utils/formsValidation";
import toast, { Toaster } from "react-hot-toast";
import useFetch from "../../hooks/useFetch";
import {
  erroresCodes,
  reformatCity,
  currencyOption,
  statusOption,
} from "../../utils";
import {
  patchDetailsDestination,
  patchDetailsOrigin,
  patchDetailsStatus,
  patchDetailsDriver,
} from "../../services";
import RenderGroupSelectCity from "../results/RenderGroupSelectCity";
import RenderModalConfirmLocation from "../results/Modals/RenderModalCofirmLocation";
import RenderInput from "../results/RenderInput";
import RenderSelect from "../results/RenderSelect";
import RenderSelectAssingDriver from "../results/RenderSelectAssingDriver";

const FormDetails = ({ data, updateData }) => {
  const [originLocation, setOriginLocation] = useState(data.origin.latitude && data.origin.longitude ? { lat: data.origin.latitude, lng: data.origin.longitude } : null);
  const [destinationLocation, setDestinationLocation] = useState(data.destination.latitude && data.destination.longitude ? { lat: data.destination.latitude, lng: data.destination.longitude } : null);
  const [modalOriginLocation, setModalOriginLocation] = useState(false);
  const [modalDestinationLocation, setModalDestinationLocation] = useState(
    false
  );
  const order_id = data.public.id;
  const { fletesAPI } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const patchDetails = async (e) => {
      await patchDetailsOrigin({
        dataFetch: {
          address_line1: e.origin_address_line1 ? e.origin_address_line1 : "",
          address_line2: e.origin_address_line2 ? e.origin_address_line2 : "",
          zip_code: e.origin_zip_code ? e.origin_zip_code : 0,
          phone: e.origin_phone ? e.origin_phone : "",
          city_id: e.origin_city_id ? e.origin_city_id.value : "",
          latitude: originLocation && originLocation.lat ? originLocation.lat : null,
          longitude: originLocation && originLocation.lng  ? originLocation.lng : null,
        },
        order_id,
        fletesAPI,
        toast,
      });
      await patchDetailsDestination({
        dataFetch: {
          address_line1: e.destination_address_line1 ? e.destination_address_line1 : "",
          address_line2: e.destination_address_line2 ? e.destination_address_line2 : "",
          zip_code: e.destination_zip_code  ? e.destination_zip_code : 0,
          phone: e.destination_phone  ? e.destination_phone : "",
          city_id: e.destination_city_id ? e.destination_city_id.value : "",
          latitude: destinationLocation && destinationLocation.lat ? destinationLocation.lat : null,
          longitude: destinationLocation && destinationLocation.lng ? destinationLocation.lng : null,
        },
        order_id,
        fletesAPI,
        toast,
      });
      await patchDetailsStatus({
        dataFetch: {
          customer_rate: e.customer_rate ? e.customer_rate : "",
          currency: e.currency ? e.currency.value : "",
          driver_status: parseInt(e.driver_status.value)
        },
        order_id,
        fletesAPI,
        toast,
      });
      await patchDetailsDriver({
        dataFetch: {
          driver_id: e.driver_id ? e.driver_id.value : "",
        },
        order_id,
        fletesAPI,
        toast,
      });
      await updateData();
  };

  const onSubmit = (e) => {
    patchDetails(e);
  };
  return (
    <>
      <Toaster />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className=" d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <RenderGroupSelectCity
              name="origin_city_id"
              control={control}
              placeholder="Seleccion una cuidad de Origen"
              defaultValue={data.origin.city}
              label="Ciudad de origen"
              error={ errors.origin_city_id && "la ciudad de origen es requerida" }
            />
            <RenderInput
              name="origin_address_line1"
              label="Dirección de origen"
              placeholder="ingrese la direacción de origen"
              defaultValue={data.origin.address_line1}
              register={register}
              error={
                errors.origin_address_line1 &&
                errors.origin_address_line1.message
              }
            />
            <RenderInput
              name="origin_address_line2"
              label="Colonia de origen"
              placeholder="ingrese la colonia de origen"
              defaultValue={data.origin.address_line2}
              register={register}
              error={
                errors.origin_address_line2 &&
                errors.origin_address_line2.message
              }
            />
            <Row>
              <Col>
                <RenderInput
                  name="origin_zip_code"
                  label="Postal de origen"
                  placeholder="ingrese el código postal de origen"
                  defaultValue={data.origin.zip_code}
                  register={register}
                  error={
                    errors.origin_zip_code && errors.origin_zip_code.message
                  }
                />
              </Col>
              <Col>
                <RenderInput
                  name="origin_phone"
                  label="Teléfono de origen"
                  placeholder="ingrese el teléfono de origen"
                  defaultValue={data.origin.phone}
                  register={register}
                  error={errors.origin_phone && errors.origin_phone.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={() => setModalOriginLocation(true)}
                  className="w-100 text-white"
                >
                  Selecionar ubicacion en el mapa
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <RenderGroupSelectCity
              name="destination_city_id"
              control={control}
              placeholder="Seleccion una cuidad de Destino"
              defaultValue={data.destination.city}
              label="Ciudad de destino"
              error={
                errors.destination_city_id &&
                "la ciudad de destino es requerida"
              }
            />
            <RenderInput
              name="destination_address_line1"
              label="Dirección de origen"
              placeholder="ingrese la direacción de destino"
              defaultValue={data.destination.address_line1}
              register={register}
              error={
                errors.destination_address_line1 &&
                errors.destination_address_line1.message
              }
            />
            <RenderInput
              name="destination_address_line2"
              label="Colonia de destino"
              placeholder="ingrese la colonia de destino"
              defaultValue={data.destination.address_line2}
              register={register}
              error={
                errors.destination_address_line2 &&
                errors.destination_address_line2.message
              }
            />
            <Row>
              <Col>
                <RenderInput
                  name="destination_zip_code"
                  label="Postal de destino"
                  placeholder="ingrese el código postal de destino"
                  defaultValue={data.destination.zip_code}
                  register={register}
                  error={
                    errors.destination_zip_code &&
                    errors.destination_zip_code.message
                  }
                />
              </Col>
              <Col>
                <RenderInput
                  name="destination_phone"
                  label="Teléfono de destino"
                  placeholder="ingrese el teléfono de destino"
                  defaultValue={data.destination.phone}
                  register={register}
                  error={
                    errors.destination_phone && errors.destination_phone.message
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={() => setModalDestinationLocation(true)}
                  className="w-100 text-white"
                >
                  Selecionar ubicacion en el mapa
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <RenderSelect
              name="driver_status"
              label="Estatus del conductor"
              placeholder="Selecciona el estatus del conductor"
              defaultValue={statusOption.find(
                (e) => e.value === data.driver_status
              )}
              options={statusOption}
              control={control}
              error={errors.driver_status && "el estatus es requerido"}
            />
            <Row>
              <Col>
                <RenderInput
                  name="customer_rate"
                  label="Tarifa del cliente"
                  placeholder="ingrese la tarifa del cliente"
                  defaultValue={data.customer_rate}
                  register={register}
                  error={errors.customer_rate && errors.customer_rate.message}
                />
              </Col>
              <Col>
                <RenderSelect
                  name="currency"
                  label="Moneda"
                  placeholder="Selecciona la moneda"
                  defaultValue={currencyOption.find(
                    (e) => e.value === data.currency
                  )}
                  options={currencyOption}
                  control={control}
                  error={errors.currency && "la moneda es requerida"}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <RenderSelectAssingDriver
              name="driver_id"
              label="Asignar conductor"
              placeholder="Selecciona un conductor"
              defaultValue={data}
              error={errors.driver_id && "el conductor es requerido"}
              control={control}
            />
          </Col>
        </Row>
      </Form>
      <RenderModalConfirmLocation
        state={modalOriginLocation}
        setState={setModalOriginLocation}
        location={originLocation}
        setLocation={setOriginLocation}
      />
      <RenderModalConfirmLocation
        state={modalDestinationLocation}
        setState={setModalDestinationLocation}
        location={destinationLocation}
        setLocation={setDestinationLocation}
      />
    </>
  );
};

export default FormDetails;
