import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import emailVerify from "./utils/emailVerify";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Pricing from "./pages/Pricing";
import Register from "./pages/Register";
import ValidateAccount from "./pages/ValidateAccount";
import Dashboard from "./pages/Dashboard";
import ForgetPassword from "./pages/ForgetPassword";
import ForgetPasswordStep2 from "./pages/ForgetPasswordStep2";
import Support from "./pages/Support";
import BodegasContextProvider from './context'
import PublicDashboard from "./pages/PublicDashboard";
import FreeDashboard from "./pages/FreeDashboard";
import EmailPreferencesWithToken from "./pages/Marketing/EmailPreferencesWithToken";
import SharedOrderWithToken from "./pages/Shared/SharedOrderWithToken";
import RegisterConfirm from "./pages/RegisterConfirm";
import { QuoteViewPDF } from "./pages/QuoteViewPDF";
import { OrderViewPDF } from "./pages/OrderVIewPDF";
import GenerateBoardingRequest from "./pages/GenerateBoardingRequest";

const Router = () => {
  useEffect(() => {
    emailVerify();
  }, []);
  return (
    <>
      <Toaster />
      <BodegasContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/:token" element={<Register />} />
            <Route path="/register-confirm" element={<RegisterConfirm />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/searchLoads" element={<Dashboard start="searchLoads"/>} />
            <Route path="/dashboard/membership" element={<Dashboard start="membership"/>} />
            <Route path="/dashboard/membership/:type" element={<Dashboard start="membership"/>} />
            <Route path="/dashboard/searchTrucks" element={<Dashboard start="searchTrucks"/>} />
            <Route path="/dashboard/warehouses" element={<Dashboard start="warehouses"/>} />
            <Route path="/dashboard/warehouses/:id" element={<Dashboard start="warehousesId"/>} />
            <Route path="/dashboard/my_warehouses" element={<Dashboard start="my_warehouses"/>} />
            <Route path="/dashboard/my_warehouses/:id" element={<Dashboard start="my_warehouses_id"/>} />
            <Route path="/dashboard/my_warehouses/:id/:type" element={<Dashboard start="my_warehouses_id"/>} />
            <Route path="/dashboard/my_trucks" element={<Dashboard start="my_trucks"/>} />
            <Route path="/dashboard/my_trucks/:id" element={<Dashboard start="my_trucks_id"/>} />
            <Route path="/dashboard/my_trucks/:id/:type" element={<Dashboard start="my_trucks_id"/>} />
            <Route path="/dashboard/certificated" element={<Dashboard start="certificated"/>} />
            <Route path="/dashboard/certificated/:type" element={<Dashboard start="certificated"/>} />
            <Route path="/dashboard/gasstations" element={<Dashboard start="gasstations"/>} />
            <Route path="/dashboard/adminwarehouses" element={<Dashboard start="adminwarehouses" />} />
            <Route path="/dashboard/adminwarehouses/:id" element={<Dashboard start="adminwarehouses_id" />} />
            <Route path="/dashboard/adminwarehouses/:id/:type" element={<Dashboard start="adminwarehouses_id" />} />
            <Route path="/dashboard/adminusers" element={<Dashboard start="adminusers" />} />
            <Route path="/dashboard/adminusers/:id" element={<Dashboard start="adminusers_id" />} />
            <Route path="/dashboard/adminusers/:id/:type" element={<Dashboard start="adminusers_id" />} />
            <Route path="/dashboard/adminorders" element={<Dashboard start="adminorders" />} />
            <Route path="/dashboard/adminorders/:id" element={<Dashboard start="adminorders_id" />} />
            <Route path="/dashboard/adminorders/:id/:type" element={<Dashboard start="adminorders_id" />} />
            <Route path="/dashboard/admintrucks" element={<Dashboard start="admintrucks" />} />
            <Route path="/dashboard/admintrucks/:id" element={<Dashboard start="admintrucks_id" />} />
            <Route path="/dashboard/admintrucks/:id/:type" element={<Dashboard start="admintrucks_id" />} />
            <Route path="/dashboard/adminroutes" element={<Dashboard start="adminroutes" />} />
            <Route path="/dashboard/adminroutes/:id" element={<Dashboard start="adminroutes_id" />} />
            <Route path="/dashboard/adminroutes/:id/:type" element={<Dashboard start="adminroutes_id" />} />
            <Route path="/dashboard/admincontacts" element={<Dashboard start="admincontacts" />} />
            <Route path="/dashboard/admincontacts/:id" element={<Dashboard start="admincontacts_id" />} />
            <Route path="/dashboard/admincontacts/:id/:type" element={<Dashboard start="admincontacts_id" />} />
            <Route path="/dashboard/admininvoices" element={<Dashboard start="admininvoices" />} />
            <Route path="/dashboard/admininvoices/:id" element={<Dashboard start="admininvoices_id" />} />
            <Route path="/dashboard/admininvoices/:id/:type" element={<Dashboard start="admininvoices_id" />} />
            <Route path="/dashboard/companies" element={<Dashboard start="companies" />} />
            <Route path="/dashboard/companies/:id" element={<Dashboard start="companies_id" />} />
            <Route path="/dashboard/companies/:id/:type" element={<Dashboard start="companies_id" />} />
            <Route path="/dashboard/companies" element={<Dashboard start="companies" />} />
            <Route path="/dashboard/quote" element={<Dashboard start="quote" />} />
            <Route path="/dashboard/quote/:id/:view" element={<Dashboard start="qoute_by_id" />} />
            <Route path="/dashboard/quotes" element={<Dashboard start="quotes" />} />
            <Route path="/dashboard/quotes/create" element={<Dashboard start="create_quote" />} />
            <Route path="/dashboard/my_quotes" element={<Dashboard start="my_quotes" />} />
            <Route path="/freeloadboard/:token" element={<FreeDashboard />} />
            <Route path="/publicloadboard/:token" element={<PublicDashboard />} />
            <Route path="/share/order/:token" element={<SharedOrderWithToken />} />
            <Route path="/dashboard/profile" element={<Dashboard start="profile"/>} />
            <Route path="/dashboard/company" element={<Dashboard start="company"/>} />
            <Route path="/dashboard/company/:type" element={<Dashboard start="company"/>} />
            <Route path="/dashboard/orders" element={<Dashboard start="orders"/>} />
            <Route path="/dashboard/my_order/:id/:view" element={<Dashboard start="my_orders_by_id"/>} />
            <Route path="/dashboard/my_orders" element={<Dashboard start="my_orders"/>} />
            <Route path="/dashboard/orders/edit/:id" element={<Dashboard start="orderEdit" />} />
            <Route path="/dashboard/order/:token" element={<Dashboard start="orderdetail" />} />
            <Route path="/dashboard/order/:token/:type" element={<Dashboard start="orderdetail" />} />
            <Route path="/dashboard/trucks" element={<Dashboard start="trucks"/>} />
            <Route path="/dashboard/contacts" element={<Dashboard start="contacts"/>} />
            <Route path="/dashboard/users" element={<Dashboard start="users"/>} />
            <Route path="/dashboard/invitations" element={<Dashboard start="invitations" />} />
            <Route path="/dashboard/truckroutes" element={<Dashboard start="truckroutes" />} />
            <Route path="/dashboard/forbidden" element={<Dashboard start="forbidden" />} />
            <Route path="/boarding_requests/:taskId/:orderId" element={<GenerateBoardingRequest />} />
            <Route path="/forget-password-step2" element={<ForgetPasswordStep2 />} />
            <Route path="/user/:userId/validate/:token" element={<ValidateAccount />} />
            <Route path="/support" element={<Support />} />
            <Route path="/marketing/emailpreferences/:token" element={<EmailPreferencesWithToken />} />
            <Route path="/pdf_view/quote/:id" element={<QuoteViewPDF />} />
            <Route path="/pdf_view/order/:id" element={<OrderViewPDF />} />
          </Routes>
        </BrowserRouter>
      </BodegasContextProvider>
    </>
  );
};

export default Router;
