import { useState} from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useContext } from "react";
import { BodegasContext } from "../../context";
import useFetch from "../../hooks/useFetch";
import { getDrivers } from "../../services";
import { reformatDriver, reformatDriverDefaultVal } from "../../utils";

const RenderSelectAssingDriver = ({ name, control, error, defaultValue = undefined, placeholder, label }) => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState(null);
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);
  const optionsDrivers = drivers === null ? [] : drivers.map(({user}) => (reformatDriver(user)));
  return (
    <Form.Group controlId={name} className="mb-4">
      <Form.Label>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue.driver_id ? reformatDriverDefaultVal(defaultValue) : undefined}
        render={({ field }) => (
          <Select
            {...field}
            options={optionsDrivers}
            isClearable
            isSearchable={false}
            placeholder={placeholder}
            isLoading={loading}
            className={error ? "invalidSelect" : ""}
            onFocus={() => {
              if (drivers === null) {
                setLoading(true);
                getDrivers({setData: setDrivers, setLoading, companyId, fletesAPI});
              }
            }}
          />
        )}
      />
      <Form.Text className="text-danger position-absolute">
        {error}
      </Form.Text>
    </Form.Group>
  );
};

export default RenderSelectAssingDriver;
