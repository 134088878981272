import moment from "moment";
import {Col} from "react-bootstrap";

const RenderPickupDate = ({date, isMobile}) => {

    return (
    <>
        {isMobile ?
        <>
            <Col xl={2} lg={2} md={6} sm={6}>
                <p className="text-center">{moment(date).format("DD/MM/YYYY")}</p>
            </Col>
        </>
        :
        <>
            <td>{moment(date).format("DD/MM/YYYY")}</td>
        </>
        }
    </>
    );
};

export default RenderPickupDate;