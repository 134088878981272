import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Row, Card, ListGroup } from "react-bootstrap";
import useFetch from "../hooks/useFetch";
import moment from "moment";
import { fullNameCity } from "../utils";
import { formatPhoneNumber } from "react-phone-number-input";
import { ReactComponent as Logo } from "../assets/icons/logo-white.svg";

export const QuoteViewPDF = () => {
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState(null);
  const { id } = useParams();
  const { fletesAPI } = useFetch();
  const cardRef = useRef(null);

  const getQuote = async () => {
    try {
      setLoading(true);
      const response = await fletesAPI.get(`api/quote/${id}/`);
      setQuote(response.data);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (quote === null) {
      getQuote();
    }
  }, [quote]);

  const handlePrint = () => {
    const originalTitle = document.title;
    const newTitle = `Cotización - ${quote?.transportation_company?.name || "Sin nombre"}`;

    document.title = newTitle;

    const printContents = cardRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    document.title = originalTitle;
    window.location.reload();
  };

  if (loading) {
    return (
      <Container>
        <Row className="justify-content-center">
          <h1>Cargando...</h1>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center gap-4">
          <Button onClick={handlePrint}>Imprimir</Button>
          <Card ref={cardRef} style={{ width: "8.5in", height: "11in" }}>
            <Card.Body className="gap-2 d-flex flex-column">
              <div className="d-flex justify-content-around align-items-center mb-4 p-2 " style={{background: "#0f2840", borderRadius: 5}}>
                <Logo
                  style={{ width: "150px", height: "auto" }}
                />
                <div className="text-right text-white">
                  <h3>Cliente</h3>
                  <p>{quote?.company?.name || "Nombre de la Compañía"}</p>
                  <p>{quote?.company?.contact_information?.address_line1 || "Dirección de la compañía"}</p>
                  <p>{quote?.company?.contact_information?.phone || "Teléfono"}</p>
                  <p>{quote?.company?.contact_information?.email || "Correo electrónico"}</p>
                </div>
              </div>
              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section">
                    <p><b>Informacion del Transportista</b></p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item><b>Compañia:</b> {quote?.transportation_company?.name || ""}</ListGroup.Item>
                <ListGroup.Item><b>Nombre:</b> {quote?.transportation_company?.contact_information?.name || ""}</ListGroup.Item>
                <ListGroup.Item><b>Telefono:</b> {formatPhoneNumber(quote?.transportation_company?.contact_information?.phone) || ""}</ListGroup.Item>
                <ListGroup.Item><b>Correo:</b> {quote?.transportation_company?.contact_information?.email || ""}</ListGroup.Item>
                <ListGroup.Item><b>Direccion:</b> {quote?.transportation_company?.contact_information?.address_line1 || ""}</ListGroup.Item>
              </ListGroup>

              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section">
                    <p><b>Informacion de estimacion</b></p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item><b>Camion:</b> {quote?.bed_type?.name || ""}</ListGroup.Item>
                <ListGroup.Item><b>Origen:</b> {fullNameCity(quote?.origin_city) || ""}</ListGroup.Item>
                <ListGroup.Item><b>Destino:</b> {fullNameCity(quote?.destination_city) || ""}</ListGroup.Item>
                <ListGroup.Item><b>Fecha y hora de recolección:</b> {moment.utc(quote?.pickup_time).format("YYYY-MM-DD HH:mm").toString() || ""}</ListGroup.Item>
                <ListGroup.Item><b>Fecha y hora de entrega:</b> {moment.utc(quote?.delivery_time).format("YYYY-MM-DD HH:mm").toString() || ""}</ListGroup.Item>
                <ListGroup.Item><b>Fecha Expiración:</b> {moment.utc(quote?.expiration).format("YYYY-MM-DD HH:mm").toString() || ""}</ListGroup.Item>
                <ListGroup.Item><b>Tarimas:</b> {quote?.pallets || ""}</ListGroup.Item>
                <ListGroup.Item><b>Peso:</b> {quote?.weight || ""}</ListGroup.Item>
                <ListGroup.Item><b>Carga:</b> {quote?.cargo || ""}</ListGroup.Item>
                <ListGroup.Item>
                  <p style={{margin: 0}}><b>Precio:</b> *${quote?.total || "Error"} {quote?.currency || "Error"}</p>
                  <p style={{fontSize: 10, margin: 0}}>La estimación no incluye I.V.A. y está sujeta a disponibilidad. </p>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
          <a onClick={() => (window.location.href = "/dashboard/my_quotes")} className="link" style={{ cursor: "pointer" }}>
            Ver Mis estimaciones
          </a>
        </div>
      </Row>
    </Container>
  );
};
