import { MdCloudUpload, MdClose } from "react-icons/md";
import styled from "styled-components";

const DropFileInput = styled.div`
  border-radius: 10px;
  width: 100%;
  border: 2px dashed var(--fyb_darkblue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  position: relative;
  &:hover {
    border: 2px dashed var(--fyb_teal);
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .drop-file__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__icon {
      font-size: 60px;
      color: var(--fyb_darkblue);
      margin-bottom: 20px;
      svg {
        width: 80px;
        height: 80px;
      }
    }
    &__text {
      font-size: 14px;
      color: var(--fyb_darkblue);
      text-align: center;
      & > p {
        margin: 0;
      }
    }
  }
`;

const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  color: var(--bs-green);
  font-size: 20px;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #151935;
    margin-right: 10px;
  }
  &:hover {
    border: 1px solid #aaa;
  }
  svg {
    cursor: pointer;
    &:hover {
      color: #dc3545 !important ;
    }
  }
`;

const DropFile = ({ name, watch, setValue }) => {
  const files = watch(name) || [];

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const updatedFiles = [...files, ...newFiles];
    setValue(name, updatedFiles);
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setValue(name, updatedFiles);
  };

  const clearFiles = () => {
    setValue(name, []);
  };

  return (
    <>
      {(Boolean(files.length) && files.length > 0) && (
        files.map((file, i) => (
          <File key={i} className="file">
            <span>{file.name}</span> 
            <MdClose color="black" onClick={() => removeFile(i)} />
          </File>
        ))
      )}
      <DropFileInput className="drop-file">
        <input type="file" onChange={handleFileChange} />
        <div className="drop-file__content">
          <div className="drop-file__content__icon">
            <MdCloudUpload />
          </div>
          <div className="drop-file__content__text">
            <p>Arrastra el archivo aquí</p>
            <p>o</p>
            <p>Selecciona el archivo</p>
          </div>
        </div>
      </DropFileInput>
    </>
  );
};

export default DropFile;
