import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import Vouchers from "./views/Vouchers";
import Using from "./views/Using";
import Payment from "./views/Payment";
import Invoices from "./views/Invoices";

const viewList = {
  details: "details",
  vouchers: "vouchers",
  using: "using",
  payment: "payment",
  invoices: "invoices",
};

const Membership = () => {
  const { type } = useParams();
  const [loading, setLoading] = useState(false);
  const [typeHook, setTypeHook] = useState(type);
  const navigate = useNavigate();
  useEffect(() => {
    if (type === undefined) {
      setTypeHook(viewList.vouchers);
    } else if(type) {
      setTypeHook(type);
    }
  }, [type]);

  return (
    <>
      <Container>
        <hr />
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Recibos",
                  active: typeHook === viewList.vouchers,
                  onClick: () => {
                    navigate(`/dashboard/membership/${viewList.vouchers}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Uso",
                  active: typeHook === viewList.using,
                  onClick: () => {
                    navigate(`/dashboard/membership/${viewList.using}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Pago",
                  active: typeHook === viewList.payment,
                  onClick: () => {
                    navigate(`/dashboard/membership/${viewList.payment}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Facturas",
                  active: typeHook === viewList.invoices,
                  onClick: () => {
                    navigate(`/dashboard/membership/${viewList.invoices}`, {
                      replace: true,
                    });
                  },
                },
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && (
              <RenderSpace>
                <Spinner animation="border" variant="primary" />
              </RenderSpace>
            )}
            {!loading && typeHook === viewList.vouchers && <Vouchers />}
            {!loading && typeHook === viewList.using && <Using />}
            {!loading && typeHook === viewList.payment && <Payment />}
            {!loading && typeHook === viewList.invoices && <Invoices />}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Membership;
