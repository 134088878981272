import { FaCheckCircle } from "react-icons/fa";
import truckSVG from "../../assets/icons/delivery-car.svg";

export const BedCard = ({
  selected = false,
  title,
  description,
  maxPallets,
  maxWeight,
  dimensions,
  onClick = () => {},
}) => {
  return (
  <div className={`card-select ${ selected ? "selected" : "" }`} onClick={onClick}>
    <div className="card-select_checkbox">
      { selected && <FaCheckCircle className="icon" /> }
    </div>
    <div className="card-select_img">
      <img src={truckSVG} alt={title} />
    </div>
    <h3 className="card-select_title"> {title} </h3>
    <p> {description} </p>
    <p className="list-text">Tarimas Max: {maxPallets}</p>
    <p className="list-text">Peso Max: {maxWeight}</p>
    <p className="list-text">Dimensiones: {dimensions}</p>
  </div>
  )
};