import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import toast from "react-hot-toast";
import useFetch from "../../../../hooks/useFetch";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import RenderButtonMore from "../../../../fyb_components/results/RenderButtonMore";
import RenderModalConfirm from "../../../../fyb_components/results/Modals/RenderModalConfirm";
import RenderModalAddTask from "../../../../fyb_components/results/Modals/RenderModalAddTask";
import RenderModalEditTask from "../../../../fyb_components/results/Modals/RenderModalEditTask";
import { optionsCertificatedStatus } from "../../../../utils";
import moment from "moment";

const Cetificated = ({ dataCompany }) => {
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalAddTask, setModalAddTask] = useState(false);
  const [modalEditTask, setModalEditTask] = useState(false);
  const { fletesAPI } = useFetch();

  const deleteTask = async (id) => {
    setLoading(true);
    await fletesAPI
      .delete(`api/company/${dataCompany.id}/task/`, {
        data: { task_id: id },
      })
      .then(() => {
        toast.success("Tarea eliminada con éxito");
        setLoading(false);
        setShowModal(false);
        getTasks();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setShowModal(false);
      });
  };

  const getTasks = async () => {
    setLoading(true);
    await fletesAPI
      .get(`api/company/${dataCompany.id}/task/`)
      .then((res) => {
        setTasks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (tasks === null && dataCompany !== null) {
      getTasks();
    }
  }, [tasks]);
  console.log(tasks);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todas las tareas</h4>
            <Button className="text-white" onClick={() => setModalAddTask(true)}>Agregar tarea</Button>
          </Col>
        </Row>
        <hr />
        {loading ? (
          <Table striped bordered className="dashboard_main_container_table">
            <thead>
              <tr>
                <th>Titulos</th>
                <th>URL</th>
                <th>Estado</th>
                <th style={{ width: "60px" }}>Eliminado</th>
                <th style={{ width: "40px" }}></th>
              </tr>
            </thead>
            <tbody>
              {tasks &&
                tasks.results.map((e) => (
                  <tr key={e.id}>
                    <td>{e.title}</td>
                    <td><a href={e.instructions}>{e.instructions}</a></td>
                    <td>
                      {optionsCertificatedStatus.find((x) => x.value === e.status).label}
                    </td>
                    <td>{e.deleted && moment(e.deleted).format('YYYY-MM-DD') }</td>
                    <td>
                      <RenderButtonMore
                        buttons={[
                          {
                            label: (
                              <>
                                Editar <MdEdit />
                              </>
                            ),
                            onClick: () => {
                              setModalEditTask(true);
                              setDataSelected(e);
                            },
                          },
                          {
                            label: (
                              <>
                                Eliminar <MdDelete />
                              </>
                            ),
                            onClick: () => {
                              setShowModal(true);
                              setDataSelected(e);
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        )}
      </Container>
      <RenderModalConfirm
        confirmModal={() => deleteTask(dataSelected.id)}
        state={showModal}
        setState={setShowModal}
        confirmText={`Estas seguro de eliminar esta tarea ${dataSelected ? dataSelected.title : ''}?`}
      />
      <RenderModalAddTask 
        state={modalAddTask}
        setState={setModalAddTask}
        dataCompany={dataCompany}
        getMore={getTasks}
      />
      <RenderModalEditTask 
        state={modalEditTask}
        setState={setModalEditTask}
        dataCompany={dataCompany}
        dataTask={dataSelected}
        getMore={getTasks}
      />
    </>
  );
};

export default Cetificated;
