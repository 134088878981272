import { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import RenderJustToDay from "../../../fyb_components/results/RenderJustToDay";
import imageDummy from "../../../assets/images/compressed_warehouse_trucks.jpg";

const CardCont = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 290px;
  max-width: 290px;
  height: 410px;
  flex-grow: 2;
  padding: 0px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  &:focus {
    border: 2px solid #007bff;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 0;
    .card-image {
      width: 100%;
      height: 200px;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 3px 3px 0 0;
      }
    }
    .card-title {
      font-size: 20px;
      font-weight: 600;
      margin: 10px 0;
      padding: 0 10px;
      color: var(--fyb_darkblue);
    }
    .card-text {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      padding: 0 10px;
    }
    p {
      margin: 0;
    }
    button {
      margin: 10px;
      color: white;
    }
  }
`;

const Card = ({ warehouse, onClick, onClickButton }) => {
  const { name, contact_information, image, hours, id } = warehouse;
  const [images, setImages] = useState(null);
  const { fletesAPI } = useFetch();

  const getImages = (warehouseId) => {
    fletesAPI
      .get(`api/warehouses/${warehouseId}/photos/`)
      .then((e) => {
        setImages(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (warehouse) {
      getImages(id);
    }
  }, [warehouse]);
  return (
    <CardCont className="card" onClick={onClick} tabIndex={id}>
      <div className="card-body">
        {
          <div className="card-image">
            <img
              src={
                images
                  ? images.results.length >= 1
                    ? images.results[0].url
                    : imageDummy
                  : imageDummy
              }
              alt="Warehouse"
            />
          </div>
        }
        <h5 className="card-title">{name}</h5>
        <p className="card-text">
          {contact_information && contact_information.address_line1}
        </p>
        <p className="card-text">
          {contact_information &&
            contact_information.city &&
            contact_information.city.name}{" "}
        </p>
        <p className="card-text">
          {contact_information &&
            contact_information.city &&
            contact_information.city.state.name}
          ,{" "}
          {contact_information &&
            contact_information.city &&
            contact_information.city.state.country.name}
        </p>
        <p className="card-text">
          {contact_information && contact_information.zip_code}
        </p>
        <p className="card-text time">
          <RenderJustToDay hours={hours} />
        </p>
        <Button variant="primary" onClick={onClickButton}>
          Ver Bodega
        </Button>
      </div>
    </CardCont>
  );
};

export default Card;
