import React from "react";
import toast from "react-hot-toast";
import { Modal, Button } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import { mensajesAPI } from "../../../utils/mensajes.js";

const RenderModalDelAttachment = ({ show, setShow, attachment, getMore }) => {
  const { fletesAPI } = useFetch();
  const deleteAttachment = async () => {
    await fletesAPI
      .delete(`api/freight/${attachment.freight_id}/attachment/`, {
        data: { attachment_id: attachment.id },
      })
      .then(() => {
        setShow(false);
        toast.success(mensajesAPI("toast_success_saving_message"));
        getMore(attachment.freight_id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <h5 className="fyb_h5 text-center">Borrar archivo</h5>
      </Modal.Header>
      {attachment && (
        <>
          <Modal.Body>
            <h6>¿Estás seguro de eliminar este archivo?</h6>
            <p>
              {attachment.type}: {attachment.name}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              size="sm"
              onClick={() => deleteAttachment()}
            >
              Eliminar
            </Button>
            <Button variant="danger" size="sm" onClick={() => setShow(false)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default RenderModalDelAttachment;
