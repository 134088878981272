import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import Edit from "./views/Edit";
import Calendar from "./views/Calendar";

const viewList = {
  edit: "edit",
  calendar: "calendar",
};

const MyWarehouseById = () => {
  const [loading, setLoading] = useState(false);
  const [trucks, setTrucks] = useState(null);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { fletesAPI } = useFetch();
  const url = window.location.pathname.split("/");
  const newURl = (uid, utype) => {
    return `/${url[1]}/${url[2]}/${uid}/${utype}`
  }

  const getTrucks = async () => {
    await fletesAPI
      .get(`api/companytruck/${id}/`)
      .then((e) => {
        setTrucks(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (trucks === null) {
      getTrucks();
    }
  }, [trucks]);

  return (
    <>
      <Container>
        <hr />
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Editar",
                  active: type === viewList.edit,
                  onClick: () => {
                    navigate(newURl(id, viewList.edit));
                  },
                },
                {
                  label: "Calendario",
                  active: type === viewList.calendar,
                  onClick: () => {
                    navigate(newURl(id, viewList.calendar));
                  },
                },
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && <RenderSpace><Spinner animation="border" variant="primary" /></RenderSpace>}
            {!loading && type === viewList.edit && <Edit trucksData={trucks} getMore={getTrucks} />}
            {!loading && type === viewList.calendar && <Calendar trucksData={trucks} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyWarehouseById;
