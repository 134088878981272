import { testJSON, days } from "../../utils";

const dayCT = (e) => {
  const time = e.enabled
    ? `abierto de ${e.start} a ${e.end}`
    : "Cerrado";
  return time;
};
const getFormattedHoursCT = (e, data) => {
  return data[e.name]
    ? `Hoy ${e.label} ${dayCT(data[e.name])}`
    : "no seleccionado";
};
const RenderHours = ({ hours }) => {
  const hoursCL = testJSON(hours) ? JSON.parse(hours) : null;
  let hoy = new Date();
  let dayHoy = hoy.getDay();
  return (
    <p>
      {hoursCL
        ? getFormattedHoursCT(days[dayHoy], hoursCL)
        : "Horario no disponible"}
    </p>
  );
};

export default RenderHours;
