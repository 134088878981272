import { Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
`;

const SelectHours = ({ register, days }) => {
  return (
    <>
      <Row>
        <Col>
          {days.map((e) => (
            <Form.Group controlId={e.name} key={e.value}>
              <Form.Label>{e.label} | hora de inicio a hora finalización</Form.Label>
              <FormGroup>
                <Form.Check type="checkbox" {...register(e.name)} />
                <Form.Control {...register(`${e.name}_start`)} type="time" />
                <Form.Control {...register(`${e.name}_end`)} type="time" />
              </FormGroup>
            </Form.Group>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default SelectHours;
