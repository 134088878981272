import React from 'react';
import Footer from '../components/Footer';
import Form from '../components/ForgetPasswordStep2/Form';
import Navbar from '../components/Navbar';
import FybNavigationBar from "../components/FybNavigationBar";


export default function ForgetPasswordStep2() {
  return (
    <>
      <FybNavigationBar />
      <Form />
      <Footer />
    </>
  )
}