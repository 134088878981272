import React, { useState, useEffect } from "react";
import {
  Row,
  Form,
  FormControl,
  Button,
  Col,
  InputGroup,
  Spinner,
  Container,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderOrderSearchResults from "../../../fyb_components/results/RenderOrderSearchResults";
import RenderSelectCity from "../../../fyb_components/results/RenderSelectCity";
import RenderSelect from "../../../fyb_components/results/RenderSelect";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import { checkMobile } from "../../../utils";

const searchValidation = yup.object().shape({
  origin_city_radious: yup.string().default("1"),
  destination_city_radious: yup.string().default("1"),
});

const Orders = () => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [bedTypes, setBedTypes] = useState([]);
  const [isMobile, setIsMobile] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const { handleSubmit, register, control } = useForm({
    resolver: yupResolver(searchValidation),
  });

  const reloadOrders = async (e) => {
    setLoading(true);
    await fletesAPINotURL
      .get(e)
      .then((i) => {
        checkMobile(setIsMobile);
        setOrders(i.data);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        setLoading(false);
      })
      .catch((i) => {
        console.log(i);
      });
  };

  const getBedTypes = async () => {
    if (bedTypes.length === 0) {
      await fletesAPI
        .get(`api/freightbedoptions/`)
        .then((e) => {
          setBedTypes(e.data.map((e) => ({ value: e.id, label: e.name })));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const validateDataSearch = (data) => {
    let params = {};
    if (data.origin_city_id) {
      params.origin_city_id = data.origin_city_id.value;
    }
    if (data.origin_city_radious) {
      params.origin_city_radious = data.origin_city_radious;
    }
    if (data.destination_city_id) {
      params.destination_city_id = data.destination_city_id.value;
    }
    if (data.destination_city_radious) {
      params.destination_city_radious = data.destination_city_radious;
    }
    if (data.bed_type && data.bed_type.length > 0) {
      params.bed_type = data.bed_type.map((e) => e.value).join(",");
    }
    return params;
  };

  const getOrders = async (data) => {
    setLoading(true);
    await fletesAPI
      .get("api/freightpost/search/", {
        params: validateDataSearch(data),
      })
      .then((e) => {
        checkMobile(setIsMobile);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        setOrders(e.data);
        if (e.data.results.length === 0) {
          if(!firstLoad){
            toast.error("No se encontraron resultados, intenta removiendo ciudades");
          }
        }
        setLoading(false);
        setFirstLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    getOrders(data);
  };

  useEffect(() => {
    checkMobile(setIsMobile);
    getOrders({});
  }, [isMobile]);

  return (
    <>
      <Container>
        <Form className="w-100" onSubmit={handleSubmit(onSubmit)}>
          <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
            <h1 className="fyb_h1 w-auto">Buscar Cargas Para Transportar</h1>
            <Button
              type="submit"
              className="fyb_large_button text-white"
              style={{ width: "200px" }}
            >
              Buscar
            </Button>
          </Row>
          <Row>
            <Col>
              <RenderSelectCity
                name="origin_city_id"
                control={control}
                errors={null}
                label="Ciudad de Origen"
                placeholder="Ciudad de Origen"
                defaultValue={null}
              />
              <InputGroup>
                <FormControl
                  type="number"
                  placeholder="Radio de la ciudad de origen (km)"
                  {...register("origin_city_radious")}
                />
                <InputGroup.Text>Km</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col>
              <RenderSelectCity
                name="destination_city_id"
                control={control}
                errors={null}
                label="Ciudad de Destino"
                placeholder="Ciudad de Destino"
                defaultValue={null}
              />
              <InputGroup>
                <FormControl
                  type="number"
                  placeholder="Radio de la ciudad de destino (km)"
                  {...register("destination_city_radious")}
                />
                <InputGroup.Text>Km</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col>
              <RenderSelect
                name="bed_type"
                outDiv
                control={control}
                errors={null}
                isMulti
                placeholder="Tipo de Carga"
                onFocus={() => {
                  getBedTypes();
                }}
                defaultValue={null}
                options={bedTypes}
              />
            </Col>
          </Row>
        </Form>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <RenderOrderSearchResults
            orders={orders}
            isMobile={isMobile}
            reloadOrders={reloadOrders}
          />
        )}
      </Container>
    </>
  );
};

export default Orders;
