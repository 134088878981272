import { Modal, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import { mensajesAPI} from "../../../utils/mensajes.js";
import { addNewFile } from "../../../utils/formsValidation.js";
import { freightpostAttachmentOptions } from "../../../utils/defaultValues";
import RenderDropFile from "../../../fyb_components/results/RenderDropFile";

export default function ModalAddAttachment({
  show,
  setShow,
  getMore,
  dataFreightPost,
}) {
  const { fletesAPIFile } = useFetch();

  const addFreightPostAttachment = async (data) => {
    let formData = new FormData();
    formData.append("file_name", data.file_name);
    formData.append("type", data.type.value);
    formData.append("file_content", data.file_content[0]);
    await fletesAPIFile
      .post("api/freightpost/" + dataFreightPost.id + "/attachments/", formData)
      .then((e) => {
        getMore("init");
        setShow(false);
        toast.success(mensajesAPI("toast_success_saving_message"));
        reset();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    reset,
    control,
    setValue
  } = useForm({
    resolver: yupResolver(addNewFile),
  });
  const onSubmit = (data) => {
    addFreightPostAttachment(data);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Agregar un archivo</h5>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-4" controlId="file_name">
              <Form.Label>Nombre del archivo</Form.Label>
              <Form.Control
                type="text"
                placeholder="ejemplo"
                {...register("file_name")}
                isInvalid={errors.file_name}
              />
              <Form.Text className="text-danger position-absolute">
                {errors.file_name && errors.file_name.message}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formTipoSelect" className="mb-5">
              <Form.Label>Tipo</Form.Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder="Selecciona un tipo de archivo"
                    {...field}
                    options={freightpostAttachmentOptions}
                    isClearable
                    className={errors.type ? "invalidSelect" : ""}
                  />
                )}
              />
              <Form.Text className="text-danger position-absolute">
                {errors.type && errors.type.value.message}
              </Form.Text>
            </Form.Group>
            <RenderDropFile
              register={register}
              name="file_content"
              watch={watch}
              setValue={setValue}
            />
            <Form.Text className="text-danger">
              {errors.file_content && errors.file_content.message}
            </Form.Text>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" size="sm" type="submit">
              Subir Archivo
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
