import {Col, Button, Row} from "react-bootstrap";
import {useState} from "react";
import RenderModalDelAttachment from "./RenderModalDelAttachment";
import { MdDelete } from "react-icons/md";

const RenderOrderAttachment = ({attachment, isMobile, getMore}) => {
    const [showDel, setShowDel] = useState(false);
    const [dataDel, setDataDel] = useState(null);

    return (
        <>
            {isMobile ?
            <>
                <Col xl={4} lg={5} md={6} sm={6}>
                    <p>
                        <Button variant="danger" size="sm" onClick={() => {setShowDel(true); setDataDel(attachment);}}>
                            <MdDelete />
                        </Button>&nbsp;&nbsp;
                        <a href={attachment.url}>{attachment.type}: {attachment.name}</a>
                        <br />
                    </p>
                </Col>
            </>
            :
            <>
                <a href={attachment.url}>{attachment.type}: {attachment.name}</a>
                <br />
                <Button variant="danger" size="sm" onClick={() => {setShowDel(true); setDataDel(attachment);}}>
                    <MdDelete />
                </Button>
                <br />
                <br />
            </>
            }
            <RenderModalDelAttachment show={showDel} setShow={setShowDel} attachment={dataDel} getMore={getMore} />
        </>
    )
}

export default RenderOrderAttachment;