import { MdDownload } from "react-icons/md";
import styled from "styled-components"

const DownloadFile = styled.a`
  display: flex;
  width: 100%;
  background-color: lightgray;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-between;
  svg{
    height: 20px;
    width: 20px;
  }
  p{
    margin: 0;
  }
`;
const RenderDonwloadFile = ({ url, title }) => {
  return (
    <div class="mb-2">
      <DownloadFile href={url} >
        <p>{title}</p> <MdDownload />
      </DownloadFile>
    </div>
  );

}

export default RenderDonwloadFile;
