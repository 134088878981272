import { useState } from "react";
import { MdCloudUpload, MdDone } from "react-icons/md";
import styled from "styled-components";

const DropFile = styled.div`
  border-radius: 10px;
  width: 350px;
  height: 350px;
  border: 2px dashed var(--fyb_darkblue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  position: relative;
  &:hover {
    border: 2px dashed var(--fyb_teal);
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .drop-file__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__icon {
      font-size: 60px;
      color: var(--fyb_darkblue);
      margin-bottom: 20px;
      svg{
        width: 80px;
        height: 80px;
      }
    }
    &__text {
      font-size: 14px;
      color: var(--fyb_darkblue);
      text-align: center;
      & > p {
        margin: 0;
      }
    }
  }
`;

const RenderDropPictureProfile = ({setImage}) => {
  return (
    <>
      <DropFile className="drop-file">
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => setImage(e.target.files[0])}
        />
        <div className="drop-file__content">
          <div className="drop-file__content__icon">
            <MdCloudUpload />
          </div>
          <div className="drop-file__content__text">
            <p>Arrastra el archivo aquí</p>
            <p>o</p>
            <p>Haz click y selecciona el archivo</p>
          </div>
        </div>
      </DropFile>
    </>
  );
};

export default RenderDropPictureProfile;
