export const profileType = {
  setProfile: "SET_PROFILE",
  delProfile: "DEL_PROFILE",
};

export const profileReducer = (state, action) => {
  const { type, profile } = action;
  const { setProfile, delProfile } = profileType;
  switch (type) {
    case setProfile:
      localStorage.setItem("profile", JSON.stringify(profile));
      return profile;
    case delProfile:
      localStorage.removeItem("profile");
      return {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        position_title: "",
        verified: false,
      };
    default:
      return state;
  }
};
