import RenderBarState from "../../../../fyb_components/results/RenderBarState";
import useFetch from "../../../../hooks/useFetch";
import { useState, useContext, useEffect } from "react";
import { BodegasContext } from "../../../../context";


const Using = () => {
  const {fletesAPI } = useFetch();
  const [loading, setLoading] = useState(false);
  const[usage, setUsage] = useState(null);
  const { companyId } = useContext(BodegasContext);

  const getUsage = async() => {
    setLoading(true);
    await fletesAPI
        .get("api/company/" + companyId + "/membership/usage")
        .then((res) => {
            setUsage(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
  }

  useEffect(() => {
    if (!usage) {
      getUsage();
    }
  }, [usage]);

  return (
    <>
    {usage &&
        <>
          <RenderBarState
            title="Uso de Ordenes"
            text=""
            porcentage={(usage.billeable_freightpost/usage.max_freightpost)*100}
            porcentageText={`${usage.billeable_freightpost} de ${usage.max_freightpost} de cargas administradas`}
          />

          <RenderBarState
            title="Uso de Camiones Publicados"
            text=""
            porcentage={(usage.billeable_trucklinepost/usage.max_trucklinepost)*100}
            porcentageText={`${usage.billeable_trucklinepost} de ${usage.max_trucklinepost} de camiones publicados`}
          />

          <RenderBarState
            title="Uso de Usuarios"
            text=""
            porcentage={(usage.billeable_users/usage.max_users)*100}
            porcentageText={`${usage.billeable_users} de ${usage.max_users} de usuarios `}
          />

          <RenderBarState
            title="Uso de Memoria"
            text=""
            porcentage={(usage.billeable_storage/usage.max_storage)*100}
            porcentageText={`${usage.billeable_storage} de ${usage.max_storage} Megabytes `}
          />

      </>
    }

    </>
  );
};

export default Using;