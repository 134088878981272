import { ModalSlide, Header, Title, Boddy, Status } from "./styles/ModalSlide";
import RenderGPSDriver from "./RenderGPSDriver";
import RenderSpace from "./RenderSpace";

const statusOption = [
  { value: 0, label: "Confirmacion pendiente" },
  { value: 1, label: "En ruta para recolección" },
  { value: 2, label: "En proceso de carga" },
  { value: 3, label: "En ruta para la entrega" },
  { value: 4, label: "En proceso de descarga" },
  { value: 5, label: "Carga entregada" },
];

const RenderGPSStatus = ({ gpsStatus, data }) => {
  const status = gpsStatus.status ? gpsStatus.status : 0;
  return (
    <>
      <ModalSlide state={true}>
        <Header>
          <Title>Estado de la carga</Title>
          {data.driver_profile ? <RenderGPSDriver 
            driver_profile={data.driver_profile}
          /> : <RenderSpace><p>Asigna un conductor</p></RenderSpace>}
        </Header>
        <Boddy>
          {true &&
            statusOption.map((e, i) => (
              <Status
                key={i}
                state={status >= e.value}
                text={e.label}
                number={i}
                lastItem={statusOption.length - 1 === i}
                animationDuration={0.5}
              />
            ))}
        </Boddy>
      </ModalSlide>
    </>
  );
};

export default RenderGPSStatus;
