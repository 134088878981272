import { useState, useEffect, useContext } from "react";
import { BodegasContext } from "../../../../context";
import useFetch from "../../../../hooks/useFetch";
import { Button, Form, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import RenderSelect from "../../../../fyb_components/results/RenderSelect";
import { useForm } from "react-hook-form";
import RenderSelectCity from "../../../../fyb_components/results/RenderSelectCity";
import RenderModalConfirmLocation from "../../../../fyb_components/results/Modals/RenderModalCofirmLocation";
import SelectHours from "../SelectHours";
import {
  days,
  daysToJsonToString,
  testJSON,
  defaultDays,
  reformatCity,
} from "../../../../utils";

const statusOption = [
  { value: "1", label: "Publicado" },
  { value: "0", label: "no publicado" },
];

const Edit = ({ getMore, warehouseData }) => {
  const [services, setServices] = useState(null);
  const [modalLocation, setModalLocation] = useState(false);
  const [location, setLocation] = useState(null);
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const servicesOptions = services
    ? services.map((e) => ({ value: e.id, label: e.name }))
    : [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const patchContact = async (datos) => {
    const { address_line1, address_line2, zip_code, phone, city_id } = datos;

    await fletesAPI
      .patch(`api/warehouses/${warehouseData.id}/contactinformation/`, {
        address_line1,
        address_line2,
        zip_code,
        phone,
        city_id: city_id.value,
        latitude: location.lat,
        longitude: location.lng,
      })
      .then((e) => {
        setLocation(null);
        toast.success("Direccion actualizada con exito");
        reset();
        getMore();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const patchWarehouse = async (datos) => {
    const { name, status, service_ids } = datos;
    if (location) {
      await fletesAPI
        .patch(`api/warehouses/${warehouseData.id}/`, {
          name,
          hours: daysToJsonToString(datos),
          status: status.value,
          service_ids: service_ids.map((e) => e.value),
          latitude: location.lat,
          longitude: location.lng,
        })
        .then((e) => {
          toast.success("Bodega actualizada con exito");
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast.error("Debe seleccionar una ubicacion");
    }
  };

  const onSubmit = (data) => {
    // console.log(data);
    patchContact(data);
    patchWarehouse(data);
  };

  const getServices = async () => {
    await fletesAPI
      .get(`api/warehouseservices/`, {
        params: {
          companyId: companyId,
        },
      })
      .then((e) => {
        setServices(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!services) {
      getServices();
    }
  }, [services]);

  useEffect(() => {
    if (warehouseData !== null) {
      const hours = testJSON(warehouseData.hours) ? JSON.parse(warehouseData.hours) : defaultDays;
      reset({
        name: warehouseData.name || "",
        status: warehouseData.status ? { value: warehouseData.status, label: warehouseData.status == 1 ? "Publicado" : "No publicado", } : undefined,
        service_ids: warehouseData.services ? warehouseData.services.map((e) => ({ value: e.id, label: e.name })) : undefined,
        city_id: warehouseData.contact_information.city ? reformatCity(warehouseData.contact_information.city) : undefined,
        address_line1: warehouseData.contact_information.address_line1 || "",
        address_line2: warehouseData.contact_information.address_line2 || "",
        zip_code: warehouseData.contact_information.zip_code || "",
        phone: warehouseData.contact_information.phone || "",
        monday: hours.monday ? hours.monday.enabled : defaultDays.monday.enabled,
        monday_start: hours.monday ? hours.monday.start : "",
        monday_end: hours.monday ? hours.monday.end : "",
        tuesday: hours.tuesday ? hours.tuesday.enabled  : defaultDays.tuesday.enabled,
        tuesday_start: hours.tuesday ? hours.tuesday.start : "",
        tuesday_end: hours.tuesday ? hours.tuesday.end : "",
        wednesday: hours.wednesday ? hours.wednesday.enabled : defaultDays.wednesday.enabled,
        wednesday_start: hours.wednesday ? hours.wednesday.start : "",
        wednesday_end: hours.wednesday ? hours.wednesday.end : "",
        thursday: hours.thursday ? hours.thursday.enabled : defaultDays.thursday.enabled,
        thursday_start: hours.thursday ? hours.thursday.start : "",
        thursday_end: hours.thursday ? hours.thursday.end : "",
        friday: hours.friday ? hours.friday.enabled : defaultDays.friday.enabled,
        friday_start: hours.friday ? hours.friday.start : "",
        friday_end: hours.friday ? hours.friday.end : "",
        saturday: hours.saturday ? hours.saturday.enabled : defaultDays.saturday.enabled,
        saturday_start: hours.saturday ? hours.saturday.start : "",
        saturday_end: hours.saturday ? hours.saturday.end : "",
        sunday: hours.sunday ? hours.sunday.enabled : defaultDays.sunday.enabled,
        sunday_start:   hours.sunday ? hours.sunday.start : "",
        sunday_end: hours.sunday ? hours.sunday.end : "",
      });
      setLocation({
        lng: warehouseData.longitude,
        lat: warehouseData.latitude,
      });
    }
  }, [warehouseData]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar Cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <RenderInput
              name={"name"}
              label="Nombre de la bodega"
              placeholder="ingrese un nombre para su bodega"
              register={register}
              error={errors.name && errors.name.message}
            />
            <SelectHours register={register} days={days} />
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Locación</Form.Label>
              <Button
                variant="primary"
                onClick={() => setModalLocation(true)}
                className="mb-4 w-100 text-white"
              >
                Selecionar ubicacion en el mapa
              </Button>
            </Form.Group>
            <RenderSelect
              name="status"
              label="Estado"
              placeholder="Selecciona un estado"
              options={statusOption}
              control={control}
              error={errors.status && "el estatus es requerido"}
            />
            <RenderSelect
              name="service_ids"
              label="Servicios"
              placeholder="Selecciona uno o mas servicios"
              options={servicesOptions}
              control={control}
              error={errors.service_ids && "el servicio es requerido"}
              isMulti
            />
            <Form.Group className="mb-4" controlId="city_id">
              <Form.Label>Cuidad</Form.Label>
              <RenderSelectCity
                name="city_id"
                label="Ciudad"
                placeholder="Selecciona una ciudad"
                defaultValue={null}
                control={control}
                error={errors.city_id && "la ciudad es requerida"}
              />
            </Form.Group>
            <RenderInput
              name={"address_line1"}
              label="Dirección"
              placeholder="Ingrese la calle 1"
              register={register}
              error={errors.address_line1 && errors.address_line1.message}
            />
            <RenderInput
              name={"address_line2"}
              label="Colonia"
              placeholder="Ingrese la calle 2"
              register={register}
              error={errors.address_line2 && errors.address_line2.message}
            />
            <Row>
              <Col>
                <RenderInput
                  name={"zip_code"}
                  label="Codigo postal"
                  placeholder="Ingrese el codigo postal"
                  register={register}
                  error={errors.address_line1 && errors.address_line1.message}
                />
              </Col>
              <Col>
                <RenderInput
                  name={"phone"}
                  label="Telefono"
                  placeholder="Ingrese el telefono"
                  register={register}
                  error={errors.address_line1 && errors.address_line1.message}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <RenderModalConfirmLocation
        state={modalLocation}
        setState={setModalLocation}
        location={location}
        setLocation={setLocation}
      />
    </>
  );
};

export default Edit;
