import React from "react";
import { Col } from "react-bootstrap";
import RenderPhoneContent from "./RenderPhoneContent";
import RenderWhatsappContent from "./RenderWhatsappContent";

const RenderPhone = ({ order }) => {

    return (
        <>
        <Col xl={2} lg={3} md={6} sm={6}>
            <p>
                <RenderPhoneContent phone={order.point_of_contact.phone} />
            </p>
        </Col>
        <Col xl={2} lg={3} md={6} sm={6}>
            <RenderWhatsappContent order={order} />
        </Col>
        </>
    );
};

export default RenderPhone;