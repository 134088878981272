import styled  from "styled-components";

const BarState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 150px;
  padding: 0 1rem;
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .bar-state__text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
  .bar-state__bar {
    width: 100%;
    height: 1rem;
    background-color: #e5e5e5;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    .bar-state__bar--porcentage {
      height: 100%;
      background-color: #00a8ff;
      border-radius: 0.5rem;
    }
  }
`;

const RenderBarState = ({ title, text, porcentage, porcentageText }) => {

  return (
    <BarState>
      <h3>{title}</h3>
      <div className="bar-state__bar">
        <div className="bar-state__bar--porcentage" style={{ width: `${porcentage}%` }}></div>
      </div>
      <div className="bar-state__text">
        <span>{porcentageText}</span>
      </div>
      <div className="bar-state__porcentage">
        <span>{text}</span>
      </div>
    </BarState>
  );
}

export default RenderBarState;