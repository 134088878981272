import {Form, InputGroup } from 'react-bootstrap';


const FormCurrencySelect = ({name, register}) => {
    return(
        <>
            <Form.Group className="mb-3" controlId={name}>
              <Form.Label>Moneda</Form.Label>
              <InputGroup>
              <Form.Select aria-label="" name={name} {...register(name)}>
                <option value="MXN">MXN</option>
                <option value="USD">USD</option>
              </Form.Select>
              </InputGroup>
            </Form.Group>
        </>
    );
}

export default FormCurrencySelect;