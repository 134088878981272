import {Col} from "react-bootstrap"

const RenderWeight = ({weight, isMobile}) => {
    return (
    <>
        {isMobile ?
        <>
            <Col xl={3} lg={3} md={6} sm={6}>
                <p>{weight} kg</p>
            </Col>
        </>
        :
        <>
            <td>{weight} kg</td>
        </>
        }
    </>
    );
};

export default RenderWeight;