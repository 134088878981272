import { useState, useEffect, useCallback } from "react";
import { Controller } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import Select from "react-select";
import debounce from "lodash/debounce";

import { citiesStyles } from "../../utils/styles";

const SelectCompany = ({
  name,
  control,
  defaultValue = undefined,
  placeholder,
  error,
  style = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const { fletesAPI } = useFetch();

  const fetchCompanies = useCallback(async (query = "") => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/company/?name=${query}`);
      setCompanies(response.data.results.map(e => ({ value: e.id, label: e.name })));
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  }, [fletesAPI]);

  const handleKeyPress = debounce((e) => {
    const text = e?.target?.value || "";
    if (text.length >= 2) {
      fetchCompanies(text);
    } else if (text.length === 0) {
      fetchCompanies(text);
    }
  }, 200);

  useEffect(() => {
    return () => {
      handleKeyPress.cancel();
    };
  }, [handleKeyPress]);

  return (
    <div style={style}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || undefined}
        render={({ field }) => (
          <Select
            isClearable
            isLoading={loading}
            styles={citiesStyles}
            placeholder={placeholder}
            onChange={field.onChange}
            value={field.value}
            options={companies}
            className={error ? "invalidSelect" : ""}
            onFocus={() => fetchCompanies()}
            onKeyDown={handleKeyPress}
          />
        )}
      />
    </div>
  );
};

export default SelectCompany;