import Footer from '../components/Footer'
import Form from '../components/Register/Form'
import FybNavigationBar from "../components/FybNavigationBar";

export default function Register() {

  return (
    <>
      <FybNavigationBar />
        <Form />
      <Footer />
    </>
  );
}
