import {Col} from "react-bootstrap";
import RenderRateContent from "./RenderRateContent";

const RenderRate = ({rate, currency, isMobile}) => {
    return (
    <>
        {isMobile ?
        <>
            <Col xl={3} lg={3} md={6} sm={6}>
                <p>
                    <RenderRateContent rate={rate} /> {currency}
                </p>
            </Col>
        </>
        :
        <>
            <td>
                <RenderRateContent rate={rate} /> {currency}
            </td>
        </>
        }

    </>
    );
};

export default RenderRate;