import { useState, useEffect } from "react";
import { Button, Col, Container, Row, Table, Pagination, Form, Badge } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import { useForm } from "react-hook-form";
import RenderInputCancel from "../../../fyb_components/results/RenderInputCancel";
import { FiCheck } from "react-icons/fi";
import moment from "moment";

const AdminUsers = () => {
  const [users, setUsers] = useState(null);
  const [closeInput, setCloseInput] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const getUsers = async () => {
    await fletesAPI
      .get(`api/users/`)
      .then((e) => {
        setUsers(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadUser = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setUsers(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateUserById = async (input) => {
    const email = input.includes("@");
    if (!email) {
      if (input.length <= 0) {
        return toast.error("Rellene el campo de busqueda");
      }
      if (input.length < 10) {
        return toast.error("El telefono debe tener al menos 10 digitos o ingresar un correo");
      }
    }
    await fletesAPI
      .get(`api/users/`, {
        params: {
          email: email ? input : undefined,
          phone: !email ? input : undefined,
        },
      })
      .then((e) => {
        console.log(e.data);
        setUsers(e.data);
        setCloseInput(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteUser = async (id) => {
    await fletesAPI
      .delete(`api/users/${id}/`)
      .then((e) => {
        getUsers();
        toast.success("Usuario eliminado con exito");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const resendReverify = async (id) => {
    await fletesAPI
      .post(`api/users/reverify/`, {
        userId: id,
      })
      .then((e) => {
        toast.success("Se ha reenviado el correo de verificación");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = (data) => {
    // console.log(data);

    updateUserById(data.search);
  };

  useEffect(() => {
    if (!users) {
      getUsers();
    }
  }, [users]);

  const focusCompany = watch("company");

  useEffect(() => {
    if (focusCompany === undefined || focusCompany === null) {
      getUsers();
    }
  }, [focusCompany]);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todos los usuarios</h4>
            <div className="d-flex">
              <Form className="d-flex" style={{ width: "300px" }} onSubmit={handleSubmit(onSubmit)}>
                <RenderInputCancel
                  name={"search"}
                  register={register}
                  errors={errors.search && errors.search.message}
                  placeholder={"Correo o telefono"}
                  type={"text"}
                  close={closeInput}
                  onClose={() => {
                    setCloseInput(false);
                    getUsers();
                    reset();
                  }}
                />
                <Button type="submit" className="text-white">
                  Buscar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Telefone</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.results.map((e, i) => (
                <tr key={i} style={Boolean(e?.deleted) ? { background: "#ea899a" } : {}}>
                  <td>
                    {e.profile.first_name} {e.profile.last_name}
                    <br />
                    {e.deleted && (
                      <Badge bg="danger" style={{ fontSize: 11 }}>
                        Eliminado {moment(e.deleted).format("DD MM YYYY")}
                      </Badge>
                    )}
                  </td>
                  <td>
                    {e.email}{" "}
                    {e.confirmed_email ? (
                      <FiCheck />
                    ) : (
                      <Badge
                        style={{ fontSize: 11, cursor: "pointer" }}
                        onClick={() => resendReverify(e.id)}
                      >
                        Re-enviar
                      </Badge>
                    )}
                  </td>
                  <td>
                    {e.profile.phone} {e.confirmed_phone && <FiCheck />}
                  </td>
                  <td>
                    <RenderButtonMore
                      buttons={[
                        {
                          label: (
                            <>
                              Editar <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/adminusers/${e.id}/edit`;
                          },
                        },
                        {
                          label: (
                            <>
                              Companies <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/adminusers/${e.id}/companies`;
                          },
                        },
                        {
                          label: (
                            <>
                              Eliminar <MdDelete />
                            </>
                          ),
                          onClick: () => {
                            setDataSelected(e);
                            setModalDelete(true);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {users && (
          <Pagination>
            <Pagination.Prev
              disabled={!users.previous}
              onClick={() => reloadUser(users.previous)}
            />
            <Pagination.Next disabled={!users.next} onClick={() => reloadUser(users.next)} />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteUser(dataSelected.id)}
        confirmText="¿Estas seguro de eliminar este usuario?"
      />
    </>
  );
};

export default AdminUsers;
