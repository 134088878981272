import React, { useState } from "react";
import { Form as Formu, Row, Col, Button, Nav } from "react-bootstrap";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import * as yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";
import { erroresCodes } from "../../utils";
import RenderInputPhone from "../../fyb_components/results/RenderInputPhone";
import RenderInput from "../../fyb_components/results/RenderInput";

//assets
// import { ReactComponent as Logo } from "../../assets/icons/logo-color.svg";

const styles = {
  container: {
    height: "1000px",
    background: "#3E85C6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputCont: {
    position: "relative",
    cursor: "pointer",
  },
  inputEye: {
    position: "absolute",
    right: "5px",
    top: "0",
    bottom: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "120px",
  },
};

const phoneNumberRegex = /^\+\d{7,}$/;

const registerValidation = yup.object().shape({
  email: yup.string().email("Porfavor escribe un correo electronico valido").required("El correo electronico es obligatorio"),
  password: yup.string().min(6).max(32).required("La contraseña es obligatoria"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null])
    .required(),
  first_name: yup.string().required("El campo es obligatorio"),
  last_name: yup.string().required("El campo es obligatorio"),
  phone: yup.string("Número de teléfono inválido solo incluir numeros").matches(phoneNumberRegex, "Número de teléfono inválido solo incluir 6-8 numeros sin lada del pais").required("El campo es obligatorio"),
  position_title: yup.string().required("El campo es obligatorio"),
});

export default function Form() {
  const [viewPass, setViewPass] = useState(true);
  const { token } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(registerValidation),
  });
  const onSubmit = (data) => {
    if (data.terms) {
      registerUser(data);
    } else {
      toast.error("Porfavor acepta los terminos y condiciones");
    }
  };
  const registerUser = async (data) => {
    const valLof = () => {
      if (token) {
        return { ...data, token: token };
      } else {
        return data;
      }
    };
    await axios
      .post(`${process.env.REACT_APP_URL_API}api/users/`, valLof())
      .then((e) => {
        toast.success("Usuario registrado ahora has Login!");
        reset();
        setTimeout(() => {
          window.location.href = "/register-confirm?email="+encodeURIComponent(data.email)+"&phone="+encodeURIComponent(data.phone);
        }, 500);
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error(
            "Error en Fletes & Bodegas, favor de contactar soporte@fletesybodegas.com"
          );
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };
  return (
    <>
      <div style={styles.container}>
        <Formu
          className="bg-light p-4 rounded"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col>
              <h2>Listo para mover mercancia?</h2>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <h2 className="text-primary">Crea una cuenta</h2>
            </Col>
          </Row>
          <Row>
            <RenderInput
              name="email"
              label="Correo electrónico*"
              placeholder="Ingresa tu correo electrónico"
              register={register}
              error={Boolean(errors.email)
                  ? errors.email?.message
                  : null}
            />
          </Row>
          <Row>
            <Formu.Group className="mb-3" controlId="formGroupPassword">
              <Formu.Label>Contraseña*</Formu.Label>
              <div style={styles.inputCont}>
                <Formu.Control
                  type={viewPass ? "password" : "text"}
                  placeholder="Ingresa tu contraseña"
                  {...register("password")}
                  isInvalid={errors.password}
                />
                <div
                  style={styles.inputEye}
                  onClick={() => setViewPass(!viewPass)}
                >
                  {viewPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                </div>
              </div>
            </Formu.Group>
          </Row>
          <Row>
              <div style={styles.inputCont}>
            <Formu.Group className="mb-3" controlId="formGroupComfirmPassword">
              <Formu.Label>Confirma tu contraseña*</Formu.Label>

                <Formu.Control
                  type={viewPass ? "password" : "text"}
                  placeholder="Confirma tu Contraseña"
                  {...register("passwordConfirm")}
                  isInvalid={errors.passwordConfirm}
                />
                <div
                  style={styles.inputEye}
                  onClick={() => setViewPass(!viewPass)}
                >
                  {viewPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                </div>

            </Formu.Group>
            </div>
            <br />
            <br />
            <br />
          </Row>
          <Row>
            <RenderInput
              className=""
              name="first_name"
              label="Nombre(s)*"
              register={register}
              error={Boolean(errors.first_name)
                  ? errors.first_name?.message
                  : null}
              placeholder="Ingresa tu nombre(s)"
            />
          </Row>
          <Row>
            <RenderInput
              name="last_name"
              label="Apellido(s)*"
              placeholder="Ingresa tu apellido(s)"
              register={register}
              error={Boolean(errors.last_name)
                  ? errors.last_name?.message
                  : null}
            />
          </Row>
          <Row>
            <RenderInput
              register={register}
              name='position_title'
              placeholder="Ingresa tu puesto de trabajo"
              error={Boolean(errors.position_title)
                  ? errors.position_title?.message
                  : null}
              label="Puesto de Trabajo*"
            />
          </Row>
          <Row>
            <RenderInputPhone
              control={control}
              label="Telefono Celular*"
              placeholder="Ingresa tu celular ex. 8677145566"
              defaultCountry="MX"
              name="phone"
              required={true}
              error={Boolean(errors.phone)
                  ? errors.phone?.message
                  : null}
            />
          </Row>
          <Row>
            <p>
              <Formu.Check
                type="checkbox"
                label="Yo acepto los"
                {...register("terms")}
              />
              <a href="/terms">
                terminos y condiciones de Fletes y Bodegas{" "}
              </a>
              <br />y{" "}
              <a href="/terms">
                nuestro aviso de privacidad.
              </a>{" "}
            </p>
          </Row>
          <Row>
            <Col>
              <Button className="text-light w-100" type="submit">
                Crear cuenta
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <p className="d-flex m-0">
                <br />
                Ya tienes una cuenta?{" "}
                <Nav.Link href="/login" className="p-0 ms-1">
                  {" "}
                  <br />
                  Iniciar sesión
                </Nav.Link>
              </p>
            </Col>
          </Row>
        </Formu>
      </div>
    </>
  );
}
