import { Button } from "react-bootstrap";
import { MdDelete, MdDownload } from "react-icons/md";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/es";

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    padding: 0;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    padding: 0;
  }
`;

const RenderTableRow = ({ data, onDelete }) => {
  return (
    <tr>
      <td>{moment(data.created).format("DD MMMM  YYYY")}</td>
      <td>{data.name}</td>
      <td>{data.type}</td>
      <td>
        <Buttons>
          <a
            className={"btn btn-success"}
            href={data.url}
            download
          >
            <MdDownload />
          </a>
          <Button onClick={onDelete} variant="danger">
            <MdDelete />
          </Button>
        </Buttons>
      </td>
    </tr>
  );
};

export default RenderTableRow;
