//create component to render breadcrumbs
import React from 'react';
import styled from 'styled-components';

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px ;
  .juntos{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .breadcrumb{
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #083D6E;
      margin: 0;
      margin-right: 10px;
    }
  }
  .back{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #323232;
    cursor: pointer;
    &:hover{
      color: #0373db;
    }
  }
`;

const RenderBreadCrumbs = ({ breadcrumbs = [] }) => {
  return (
    <Breadcrumbs>
      <div className='juntos'>
        {breadcrumbs.map(({label}, i) => (
          <div className="breadcrumb" key={i}>
            {label} {breadcrumbs.length - 1 !== i && "|"}
          </div>
        ))}
      </div>
    </Breadcrumbs>
  );
}

export default RenderBreadCrumbs;