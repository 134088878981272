import {useState} from "react";
import {Col} from "react-bootstrap";
import { VscWorkspaceTrusted, VscWorkspaceUntrusted } from "react-icons/vsc";
import RenderModalCompany from "./Modals/RenderModalCompany";

const RenderCompany = ({company, isMobile}) => {

    const [showModalCompany, setShowModalCompany] = useState(false);

    return (
    <>
        {isMobile ?
        <>
            <Col xl={0} lg={0} md={6} sm={6}>
                <p>
                    {company.verified ? <VscWorkspaceTrusted /> : <VscWorkspaceUntrusted />}
                    <a className="dashboard_main_container_result_link"
                        onClick={() => {
                            setShowModalCompany(true);
                        }}>
                        {company.name}
                    </a>
                </p>
            </Col>
        </>
        :
        <>
            <td>
                {company.verified ? <VscWorkspaceTrusted /> : <VscWorkspaceUntrusted />}
                <a className="dashboard_main_container_result_link"
                    onClick={() => {
                        setShowModalCompany(true);
                    }}>
                    {company.name}
                </a>
            </td>
        </>
        }
        <RenderModalCompany show={showModalCompany} setShow={setShowModalCompany} company={company} />
    </>
    );
};

export default RenderCompany;