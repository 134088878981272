import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import RenderModalAddInvoice from "../../../fyb_components/results/Modals/RenderModalAddInvoice";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import { MdDelete } from "react-icons/md";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import toast from "react-hot-toast";
import moment from "moment";

const AdminInvoices = () => {
  const [modalAddInvoice, setModalAddInvoice] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const { fletesAPI } = useFetch();

  const getInvoices = async () => {
    setLoading(true);
    await fletesAPI
      .get("api/invoice/")
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteInvoice = async (id) => {
    await fletesAPI
      .delete(`api/invoice/${id}/`)
      .then((res) => {
        getInvoices();
        setModalConfirm(false);
        toast.success("Factura eliminada");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (invoices === null) {
      getInvoices();
    }
  }, [invoices]);
  console.log(invoices);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todas las facturas</h4>
            <Button
              type="submit"
              className="text-white"
              onClick={() => setModalAddInvoice(true)}
            >
              Agregar
            </Button>
          </Col>
        </Row>
        <hr />
        {loading ? (
          <Table striped bordered className="dashboard_main_container_table">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Total</th>
                <th>Fecha inicial</th>
                <th>Fecha final</th>
                <th>Estado</th>
                <th>Eliminado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invoices &&
                invoices.results.map((e) => (
                  <tr key={e.id}>
                    <td>{e.type}</td>
                    <td>{e.total} {e.currency}</td>
                    <td>{e.start_period}</td>
                    <td>{e.end_period}</td>
                    <td>{e.status == 0 ? "pendiente" : e.status == 1 ? 'En proceso' : e.status === 2 && "Completado" }</td>
                    <td>{e.deleted ? moment(e.deleted).format("YYYY-MM-DD") : ''}</td>
                    <td>
                      <RenderButtonMore 
                        buttons={[
                          {
                            label: <>Eliminar <MdDelete /></>,
                            onClick: () => {
                              setModalConfirm(true);
                              setDataSelected(e);
                            }
                          }
                        ]}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        )}
      </Container>
      <RenderModalAddInvoice
        state={modalAddInvoice}
        setState={setModalAddInvoice}
        getMore={getInvoices}
      />
      <RenderModalConfirm 
        confirmModal={() => deleteInvoice(dataSelected.id)}
        state={modalConfirm}
        setState={setModalConfirm}
        confirmText="Estas seguro de eliminar esta factura?"
      />
    </>
  );
};

export default AdminInvoices;
