import { useContext } from "react";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import { Modal, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import RenderInput from "../../../fyb_components/results/RenderInput";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = Yup.object().shape({
  number: Yup.string().required("El número del camión es obligatorio"),
  license_plate: Yup.string().required("La placa es obligatoria"),
  vin: Yup.string().required("El VIN es obligatorio"),
  brand: Yup.string().required("La marca es obligatoria"),
  model: Yup.string().required("El modelo es obligatorio"),
  year: Yup.number()
    .typeError("El año debe ser un número")
    .required("El año es obligatorio"),
});

const ModalAddWarehouse = ({ state, setState, getMore }) => {
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    createTruck(data);
  };

  const handleClose = () => {
    setState(false);
    reset();
  };

  const createTruck = async (data_fetech) => {
    await fletesAPI
      .post("api/companytruck/", { ...data_fetech, company_id: companyId })
      .then((e) => {
        toast.success("Bodega creada con éxito");
        setState(false);
        reset();
        getMore();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Modal show={state} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Camión</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RenderInput
              name={"number"}
              label="Numero del camion"
              placeholder="Ingrese el número del camión"
              register={register}
              error={errors.number && errors.number.message}
            />
            <RenderInput
              name={"license_plate"}
              label="Placa del camion"
              placeholder="Ingrese la placa del camión"
              register={register}
              error={errors.license_plate && errors.license_plate.message}
            />
            <RenderInput
              name={"vin"}
              label="VIN"
              placeholder="Ingrese el VIN"
              register={register}
              error={errors.vin && errors.vin.message}
            />
            <RenderInput
              name={"brand"}
              label="Marca"
              placeholder="Ingrese la marca"
              register={register}
              error={errors.brand && errors.brand.message}
            />
            <RenderInput
              name={"model"}
              label="Modelo"
              placeholder="Ingrese el modelo"
              register={register}
              error={errors.model && errors.model.message}
            />
            <RenderInput
              name={"year"}
              label="Año"
              placeholder="Ingrese el año"
              register={register}
              error={errors.year && errors.year.message}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Guardar
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAddWarehouse;
