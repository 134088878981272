import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Button,
  Modal,
  Col
} from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import Map, { Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function GasStations() {
    const { fletesAPI } = useFetch();

    const [gasStations, setGasStations] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-99.52);
    const [lat, setLat] = useState(27.44);
    const [zoom, setZoom] = useState(9);
    const [markers, setMarkers] = useState(null);
    const [regularAvg, setRegularAvg] = useState(null);
    const [premiumAvg, setPremiumAvg] = useState(null);
    const [dieselAvg, setDieselAvg] = useState(null);
    const [gasStationsAddedIds, setGasStationsAddedIds] = useState(Array());

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v9',
            center: [lng, lat],
            zoom: zoom
        });
        const marker1 = new mapboxgl.Marker()
            .setLngLat([12.554729, 55.70651])
            .addTo(map.current);
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

  const getGasStations = async () => {
    var url = 'api/gasstation/search/?latitude=' + lat + '&longitude=' + lng + '&distance=100'
    await fletesAPI
      .get(url)
      .then((e) => {
        setGasStations(null);
        setGasStations(e.data);
        var updateGasStationsIds = Array();

        const updateMarkers = Array();
        var dieselPriceSum = 0.0;
        var dieselGasStationCount = 0;
        var regularPriceSum = 0.0;
        var regularGasStationCount = 0;
        var premiumPriceSum = 0.0;
        var premiumGasStationCount = 0;

        e.data.forEach(function(element, index) {
            element.prices.forEach(function(gas, index) {
                if(gas.product == "diesel") {
                    dieselPriceSum = dieselPriceSum + parseFloat(gas.price);
                    dieselGasStationCount = dieselGasStationCount + 1;
                }
                if(gas.product == "regular") {
                    regularPriceSum = regularPriceSum + parseFloat(gas.price);
                    regularGasStationCount = regularGasStationCount + 1;
                }
                if(gas.product == "premium") {
                    premiumPriceSum = premiumPriceSum + parseFloat(gas.price);
                    premiumGasStationCount = premiumGasStationCount + 1;
                }
            })
        })

        const avgDieselPrice = dieselPriceSum / dieselGasStationCount;
        setDieselAvg(avgDieselPrice.toFixed(2));

        const avgRegularPrice = regularPriceSum / regularGasStationCount;
        setRegularAvg(avgRegularPrice.toFixed(2));

        const avgPremiumPrice = premiumPriceSum / premiumGasStationCount;
        setPremiumAvg(avgPremiumPrice.toFixed(2));

        e.data.forEach(function(element, index) {
            var markerPopupText = element.cre_legal_name + "<br />";
            var dieselPrice = "";
            var dieselFound = false;
            var regularFound = false;
            var premiumFound = false;

            element.prices.forEach(function(gas, index) {
                markerPopupText = markerPopupText + gas.product + ": " + gas.price + " <br />"
                if(gas.product == "diesel"){
                    dieselFound = true;
                    dieselPrice = gas.price
                }
                if(gas.product == "regular"){
                    regularFound = true;
                }
                if(gas.product == "premium"){
                    premiumFound = true;
                }
            })

            markerPopupText = markerPopupText + '<a href="https://maps.google.com/?q='+element.latitude+","+element.longitude+'">Abrir Google Maps</a>'

            var markerColor;

            if(dieselFound){
                markerColor = "#000000";
                if(dieselPrice < avgDieselPrice)
                {
                    markerColor = "#FFD700";
                }
            }else{
                markerColor = "#008F39";
            }

            if(dieselFound || regularFound || premiumFound)
            {
                var foundinarray = gasStationsAddedIds.indexOf(element.id);
                if(gasStationsAddedIds.indexOf(element.id) == -1)
                {
                    const toAdd = new mapboxgl.Marker({"color": markerColor})
                        .setLngLat([element.longitude, element.latitude])
                        .addTo(map.current)
                        .setPopup(new mapboxgl.Popup().setHTML(markerPopupText));
                    updateMarkers.push(toAdd);
                    gasStationsAddedIds.push(element.id);
                }
            }
        })
        setMarkers(updateMarkers);
      })
      .catch((e) => {
      });
  };

  if (!gasStations) {
    getGasStations();
  }

  return (
    <>
      <Row className="mt-5">
        <h1 className="dashboard_main_container_h1 fyb_h1">Gasolineras</h1>
      </Row>
      <Row>
        <Col xl={3} lg={3} md={6} sm={12}>
            <Button variant="success" onClick={() => getGasStations()}>
                Recargar Gasolineras
            </Button>
        </Col>
        <Col xl={3} lg={3} md={6} sm={12}>
            <h5 className="fyb_h5">Diesel Promedio en Zona: ${dieselAvg}</h5>
        </Col>
        <Col xl={3} lg={3} md={6} sm={12}>
            <h5 className="fyb_h5">Regular Promedio en Zona: ${regularAvg}</h5>
        </Col>
        <Col xl={3} lg={3} md={6} sm={12}>
            <h5 className="fyb_h5">Premium Promedio en Zona: ${premiumAvg}</h5>
        </Col>
      </Row>

      <Row>
        <div style={{ height: "700px", width: "100%" }} ref={mapContainer}>
        </div>
        <br />
        <p>La informacion se actualiza diario de <a href="https://www.gob.mx/cre">Comision Reguladora de Energia CRE Mexico</a></p>
      </Row>
    </>
  );
}
