import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ImageAction from "./ImageAction";
import ButtonAddImage from "./ButtonAddImage";
import styled from "styled-components";
import useFetch from "../../../hooks/useFetch";
import ImageViewer from 'react-simple-image-viewer'; 
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";

// import imagenTest from "../../../assets/images/bodega1.jpg";

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const ModalImagesList = ({ state, setState, data }) => {
  const [images, setImages] = useState(null);
  const [imagesViewer, setImagesViewer] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [modalConfirm, setModalConfirm] = useState(false);
  const { fletesAPI } = useFetch();
  const handleClose = () => {
    setState(false);
    setImages(null);
  };

  const imagesArray = images ? images.results.map((e) => e.url) : [];

  const getImages = () => {
    fletesAPI
      .get(`api/warehouses/${data.id}/photos/`)
      .then((e) => {
        console.log(e.data);
        setImages(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const deleteImage = (id) => {
    fletesAPI
      .delete(`api/warehouses/${data.id}/photos/`, {
        data: {
          photo_id: id,
        }
      })
      .then((e) => {
        getImages();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Modal show={state} onShow={() => getImages()} onHide={handleClose} title="Imagenes" size="xl">
        <Modal.Header closeButton>
          <Modal.Title><h4>Gestor de imagenes</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImagesContainer>
            {images && images.results.map((e, i) => (
              <ImageAction
                key={i}
                image={e.url}
                onRemove={(u) => {
                  u.stopPropagation();
                  setImageSelected(e);
                  setModalConfirm(true);
                }}
                onView={() => {
                  setCurrentImage(i);
                  setImagesViewer(true);
                }}
              />
            ))}
            <ButtonAddImage getMore={getImages} info={data}  />
          </ImagesContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      {imagesViewer && (
        <ImageViewer
          src={imagesArray}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => {
            setImagesViewer(false);
          }}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 10000,
          }}
          
        />
      )}
      <RenderModalConfirm
        state={modalConfirm}
        setState={setModalConfirm}
        confirmText={"¿Estas seguro de eliminar esta imagen?"}
        confirmModal={() => {
          deleteImage(imageSelected.id);
          setModalConfirm(false);
          getImages();
        }}
      />
    </>
  );
};

export default ModalImagesList;
