import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Button,
  Container,
  Spinner,
  Col,
} from "react-bootstrap";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import { RiAddLine } from "react-icons/ri";
import RenderTruckRoutes from "../../../fyb_components/results/RenderTruckRoutes";
import ModalFormAdd from "./ModalFormAdd";
import RenderSpace from "../../../fyb_components/results/RenderSpace";

export default function TruckRoutes() {
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [myTrucksRoutes, setMyTruckRoutes] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const { companyId } = useContext(BodegasContext);
  const [bedTypes, setBedTypes] = useState(null);

const getRoutes = async (url) => {
  setLoading(true);
  const newUrl = url ? url : `${process.env.REACT_APP_URL_API}api/truckroutes/?company_id=${companyId}`;
  try {
    const response = await fletesAPINotURL.get(newUrl);
    setMyTruckRoutes(response.data);
  } catch (error) {
    throw error;
  } finally {
    setLoading(false);
  }
};

  useEffect(() => {
    if (myTrucksRoutes == null) {
      toast.promise(getRoutes(), {
        loading: "Cargando rutas...",
        success: "Rutas cargadas",
        error: "Error al cargar rutas",
      });
    }
  }, [myTrucksRoutes]);

  const getBedTypes = async () => {
    await fletesAPI
      .get(`api/freightbedoptions/`)
      .then((e) => {
        setBedTypes(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Mis rutas de camion</h4>
            <Button
              className="text-white"
              onClick={() => {
                setShowAdd(true);
                getBedTypes();
              }}
            >
              <RiAddLine /> Añadir Ruta de camión
            </Button>
          </Col>
        </Row>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <RenderTruckRoutes
            data={myTrucksRoutes}
            title={""}
            isMobile={false}
            reload={getRoutes}
          />
        )}
      </Container>
      <ModalFormAdd
        show={showAdd}
        setShow={setShowAdd}
        getMore={getRoutes}
        bedTypes={bedTypes}
      />
    </>
  );
}
