import styled from "styled-components";

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Option = styled.div`
  background: ${(props) => (props.state ? "#0F2840" : "transparent")};
  color: ${(props) => (props.state ? "white" : "#323232")};
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 3px;
  &:hover {
    background: #0373db;
    color: white;
  }
`;

const RenderMenu = ({ options = [] }) => {
  return (
    <Menu>
      {options.map(({ label, onClick, active, disable }, index) =>
        !disable ? (
          <Option key={index} state={active} onClick={onClick}>
            {label}
          </Option>
        ) : (
          <></>
        )
      )}
    </Menu>
  );
};

export default RenderMenu;
