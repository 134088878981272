import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import Edit from "./views/Edit";
import Images from "./views/Images";

const viewList = {
  edit: "edit",
  details: "details",
  images: "images",
};

const MyWarehouseById = () => {
  const [loading, setLoading] = useState(false);
  const [warehouse, setWarehouse] = useState(null);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { fletesAPI } = useFetch();
  const url = window.location.pathname.split("/");
  const newURl = (uid, utype) => {
    return `/${url[1]}/${url[2]}/${uid}/${utype}`
  }

  const getWarehouse = async () => {
    await fletesAPI
      .get(`api/warehouses/${id}/`)
      .then((e) => {
        setWarehouse(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!warehouse) {
      getWarehouse();
    }
  }, [warehouse]);

  console.log(url); 

  return (
    <>
      <Container>
        <hr />
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Editar",
                  active: type === viewList.edit,
                  onClick: () => {
                    navigate(newURl(id,viewList.edit), {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Imágenes",
                  active: type === viewList.images,
                  onClick: () => {
                    navigate(newURl(id,viewList.images), {
                      replace: true,
                    });
                  },
                },
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && <RenderSpace><Spinner animation="border" variant="primary" /></RenderSpace>}
            {!loading && type === viewList.edit && <Edit getMore={getWarehouse} warehouseData={warehouse} />}
            {!loading && type === viewList.images && <Images getMore={getWarehouse} warehouseData={warehouse} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyWarehouseById;
