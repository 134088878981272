import { useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useFetch from "../../../../hooks/useFetch";
import SelectTruck from "../../SelectTruck";
import RenderSelect from "../../../../fyb_components/results/RenderSelect";
import { fullNameCity, statusLabel } from "../../../../utils";
import Calendar from "../../MyTrucksById/views/Calendar";
import { Appointment } from "../Appointment";

const schemaValidation = yup.object().shape({
  // truck: yup.object().required("Campo requerido").nullable(),
  status: yup.object().required("Campo requerido").nullable(),
});

const Details = ({ order, getOrder }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const { fletesAPI } = useFetch();

  const selectedFreight = {
    value: order.id,
    label: `${fullNameCity(order?.quote?.origin_city)} | ${fullNameCity(
      order?.quote?.destination_city
    )} | ${order?.quote?.company?.name}`,
  };
  const defaultTitle = `${order?.reference} | ${order?.quote?.origin_city.name} -> ${order?.quote?.destination_city.name}`;

  const updateDetails = async (data) => {
    try {
      await fletesAPI.patch(`api/freight/${order.id}/`, data);
    } catch (error) {
      console.log(error);
      throw new Error("Error al guardar los cambios");
    }
  };

  const onSubmit = (data) => {
    let new_data = {};
    if (data.truck != null) {
      new_data.companytruck_id = data.truck.value;
    }
    if (data.status != null) {
      new_data.status = data.status.value;
    }

    toast.promise(updateDetails(new_data), {
      loading: "Guardando cambios...",
      success: "Cambios guardados",
      error: "Error al guardar los cambios",
    });
  };

  const watchTruck = watch("truck");

  useEffect(() => {
    if (order) {
      if (Boolean(order.appointment.truck.id)) {
        setValue("truck", {
          value: order.appointment.truck.id,
          label: `${order.appointment.truck.number} | ${order.appointment.truck.license_plate}`,
        });
      }
      setValue("status", {
        value: order.status,
        label: statusLabel(order.status),
      });
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="d-flex flex-column gap-4">
            {!Boolean(order?.appointment?.id) && <Row>
              <SelectTruck
                name="truck"
                control={control}
                defaultValue={order.truck}
                error={errors.truck}
                label="Camión"
                companyId={order?.quote?.transportation_company?.id}
              />
            </Row>}
            <Row>
              <RenderSelect
                name="status"
                control={control}
                options={[
                  { value: 0, label: statusLabel(0) },
                  { value: 1, label: statusLabel(1) },
                  { value: 2, label: statusLabel(2) },
                  { value: 3, label: statusLabel(3) },
                  { value: 4, label: statusLabel(4) },
                  { value: 5, label: statusLabel(5) },
                ]}
                error={errors.status}
                label="Estado"
              />
            </Row>
          </Col>
        </Row>
      </Form>
      {order?.appointment?.id && (<Appointment order={order?.appointment} update={getOrder} />)}
      <Row>
        {(Boolean(watchTruck) && !order?.appointment?.id)  && (
          <Calendar
            trucksData={{ id: watchTruck.value }}
            selected={selectedFreight}
            defaultTitle={defaultTitle}
          />
        )}
      </Row>
    </>
  );
};

export default Details;
