//create component to render breadcrumbs
import React from 'react';
import styled from 'styled-components';

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(e) => e.divider ? '10px' : '0px 10px'};
  border-bottom:${(e) => e.divider ? '1px solid lightgray' : 'none'};
  margin-bottom: ${(e) => e.divider ? '20px' : '0px'};
  .juntos{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .breadcrumb{
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #083D6E;
      margin: 0;
      margin-right: 10px;
    }
  }
  .back{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #323232;
    cursor: pointer;
    &:hover{
      color: #0373db;
    }
  }
`;

const BreadCrumbs = ({ breadcrumbs = [], divider = true }) => {
  return (
    <Breadcrumbs divider={divider}>
      <div className='juntos'>
        {breadcrumbs.map(({label}, i) => (
          <div className="breadcrumb" key={i}>
            {label} {breadcrumbs.length - 1 !== i && "|"}
          </div>
        ))}
      </div>
    </Breadcrumbs>
  );
}

export default BreadCrumbs;