import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import toast from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { mensajesFormulario } from "../../../../utils/mensajes.js";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import SelectAllCity from "../../SelectAllCity.jsx";

const Details = ({ getMore, dataCompany }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const { fletesAPI } = useFetch();
  const updateCompany = async (data) => {
    data.city_id = data.city_id.value;
    await fletesAPI
      .patch(`api/company/${dataCompany.id}/contact_information/`, data)
      .then((e) => {
        getMore();
        toast.success("Se actualizó la compañia");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onSubmit = (data) => {
    updateCompany(data);
  };
  useEffect(() => {
    if (dataCompany !== null) {
      reset({
        adress_line1: dataCompany?.contact_information?.address_line1 ?? "",
        address_line2: dataCompany?.contact_information?.address_line2 ?? "",
        zip_code: dataCompany?.contact_information?.zip_code ?? "",
        phone: dataCompany?.contact_information?.phone ?? "",
        name: dataCompany?.contact_information?.name ?? "",
        email: dataCompany?.contact_information?.email ?? "",
        city_id: dataCompany?.contact_information?.city
          ? {
              value: dataCompany?.contact_information?.city?.id,
              label: `${dataCompany.contact_information.city.name}, ${dataCompany.contact_information.city.state.name}, ${dataCompany.contact_information.city.state.country.name}`,
            }
          : undefined,
      });
    }
  }, [dataCompany]);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-end">
              <Button type="submit" className="text-light">
                Guardar Cambios
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Nombre completo</Form.Label>
              <Form.Control
                type="text"
                placeholder="ingrese su nombre completo"
                {...register("name")}
                isInvalid={errors.address_line2}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="text"
                placeholder="ingrese su correo electronico"
                {...register("email")}
                isInvalid={errors.address_line2}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Telefono</Form.Label>
              <PhoneInputWithCountry
                name="phone"
                placeholder="Ingrese su número telefónico"
                defaultCountry="MX"
                control={control}
                rules={{ required: true }}
              />
              {errors.phone && (
                <p className="dashboard_form_error_message">
                  {mensajesFormulario("phone_invalid_format")}
                </p>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="formCompanyName">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                placeholder="ingrese su dirección"
                {...register("address_line1")}
                isInvalid={errors.address_line1}
              />
              {errors.address_line1 && (
                <p className="dashboard_form_error_message">
                  {mensajesFormulario("address_line1_required")}
                </p>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Colonia</Form.Label>
              <Form.Control
                type="text"
                placeholder="ingrese su colonia"
                {...register("address_line2")}
                isInvalid={errors.address_line2}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="formCityOrgin">
              <Form.Label>Ciudad</Form.Label>
              <SelectAllCity
                name="city_id"
                control={control}
                placeholder="Seleccione su ciudad de origen"
                defaultValue={null}
                error={errors.city}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="form">
              <Form.Label>Código Postal</Form.Label>
              <Form.Control
                type="text"
                placeholder="Código Postal"
                {...register("zip_code")}
                isInvalid={errors.zip_code}
              />
              {errors.zip_code && (
                <p className="dashboard_form_error_message">
                  {mensajesFormulario("zip_code_required")}
                </p>
              )}
            </Form.Group>
          </Row>
        </Card.Body>
      </Form>
    </>
  );
};

export default Details;
