import { Modal, Button, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import RenderInput from "../RenderInput";
import useFetch from "../../../hooks/useFetch";

const RenderModalInvoice = ({ state, setState, invoiceData, getMore }) => {
  const { fletesAPIFile } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (e) => {
    addInvoice(e);
  };
  const addInvoice = async (data) => {
    let formData = new FormData();
    formData.append("receipt_file_name", data.invoice_file_name);
    formData.append("receipt_file_content", data.invoice_file_content[0]);

    await fletesAPIFile
      .patch(`api/invoice/${invoiceData.id}/receipt/`, formData)
      .then((e) => {
        getMore();
        setState(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  return (
    <>
      <Modal onHide={() => setState(false)} show={state}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Factura</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <RenderInput
                register={register}
                name="receipt_file_name"
                label="Nombre del archivo"
                placeholder="Ingresa el nombre del archivo"
                error={
                  errors.receipt_file_name &&
                  "El nombre del archivo es requerido"
                }
              />
            </Row>
            <Row>
              <RenderInput
                register={register}
                name="receipt_file_content"
                label="Archivo"
                type="file"
                error={errors.receipt_file_content && "El archivo es requerido"}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Agregar
            </Button>
            <Button variant="danger" onClick={() => setState(false)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RenderModalInvoice;

