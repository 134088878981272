import { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import RenderComment from "../../../../fyb_components/results/RenderComment";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import RenderAddNewComment from "../../../../fyb_components/results/RenderAddNewComment";

import useFetch from "../../../../hooks/useFetch";

const RenderComments = ({ data }) => {
  const [comments, setComments] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const getComments = async () => {
    await fletesAPI
      .get(`api/freightpost/${data.id}/comment/`)
      .then((e) => {
        setComments(e.data);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const nextGetComments = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setComments(e.data);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  useEffect(() => {
    if (comments === null) {
      getComments();
    }
  }, [comments]);

  return (
    <>
      <RenderAddNewComment updateData={getComments} dataComent={data} />
      {comments !== null && comments.results.length > 0 ? (
        comments.results.map((e, i) => <RenderComment key={i} data={e} getMore={getComments} />)
      ) : (
        <RenderSpace jc="center">
          <p>No hay comentarios</p>
        </RenderSpace>
      )}
      {comments && (
        <Pagination>
          <Pagination.Prev
            disabled={!comments.previous}
            onClick={() => nextGetComments(comments.previous)}
          />
          <Pagination.Next
            disabled={!comments.next}
            onClick={() => nextGetComments(comments.next)}
          />
        </Pagination>
      )}
    </>
  );
};

export default RenderComments;
