import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import useFetch from "../../hooks/useFetch";

import RenderInput from "../../fyb_components/results/RenderInput";
import RenderInputPhone from "../../fyb_components/results/RenderInputPhone";
import SelectAllCity from "../../components/Dashboard/SelectAllCity";

const schemaValidation = yup.object().shape({
  legal_name: yup.string().required("Nombre legal requerido"),
  rfc_code: yup.string().required("RFC requerido"),
  address_line1: yup.string().required("Dirección requerida"),
  address_line2: yup.string().nullable(),
  zip_code: yup.string().required("Código postal requerido"),
  name: yup.string().required("Nombre de contacto requerido"),
  email: yup.string().email("Correo electrónico inválido").required("Correo electrónico requerido"),
  city_id: yup.object().required("Ciudad requerida").nullable(),
});

const ModalAdd = ({ show, onHide, update, companyId }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const { fletesAPI } = useFetch();

  const submitInvoiceData = async (data) => {
    data.city_id = data.city_id.value;
    await fletesAPI
      .post(`api/company/${companyId}/invoice_information/`, data)
      .then((response) => {
        toast.success("Datos de facturación guardados");
        update();
      })
      .catch((error) => {
        toast.error("Error al guardar los datos de facturación");
      })
      .finally(() => {
        onHide();
        reset();
      });
  };

  const onSubmit = (data) => {
    submitInvoiceData(data);
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar datos de facturación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <RenderInput
                name="legal_name"
                label="Nombre legal"
                register={register}
                placeholder="Ingrese el nombre legal"
                error={errors.legal_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RenderInput
                name="rfc_code"
                label="RFC"
                register={register}
                placeholder="Ingrese el RFC"
                error={errors.rfc_code?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RenderInput
                name="address_line1"
                label="Dirección 1"
                register={register}
                placeholder="Ingrese la dirección"
                error={errors.address_line1?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RenderInput
                name="address_line2"
                label="Dirección 2"
                register={register}
                placeholder="Ingrese la dirección 2"
                error={errors.address_line2?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RenderInput
                name="zip_code"
                label="Código postal"
                register={register}
                placeholder="Ingrese el código postal"
                error={errors.zip_code?.message}
              />
            </Col>
            <Col>
              <RenderInputPhone
                name="phone"
                label="Teléfono"
                control={control}
                placeholder="Ingrese el teléfono"
                error={errors.phone?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RenderInput
                name="name"
                label="Nombre de contacto"
                register={register}
                placeholder="Ingrese el nombre de contacto"
                error={errors.name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RenderInput
                name="email"
                label="Correo electrónico"
                register={register}
                placeholder="Ingrese el correo electrónico"
                error={errors.email?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId={"city_id"}>
                <Form.Label>Cuidad</Form.Label>
                <SelectAllCity
                  name="city_id"
                  defaultValue={null}
                  control={control}
                  placeholder="Seleccione la ciudad"
                  error={errors.city_id?.message}
                  label="Ciudad"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Cerrar
          </Button>
          <Button variant="success" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAdd;
