import { Form } from "react-bootstrap";
import RenderSelectCity from "./RenderSelectCity";

const RenderGroupSelectCity = ({
  name,
  label,
  control,
  defaultValue = undefined,
  placeholder,
  error,
}) => {
  return (
    <Form.Group controlId={name} className="mb-4">
      <Form.Label>{label}</Form.Label>
      <RenderSelectCity
        name={name}
        control={control}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error}
      />
      <Form.Text className="text-danger position-absolute">{error}</Form.Text>
    </Form.Group>
  );
};

export default RenderGroupSelectCity;
