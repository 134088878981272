import {Col} from "react-bootstrap";

const RenderInternalReferenceNumber = ({isMobile, internalReferenceNumber}) => {
    return(
    <>
        {isMobile ?
        <>
            {internalReferenceNumber ?
                <>
                    <Col xl={2} lg={2} md={6} sm={6}>
                        <p>
                            Referencia: {internalReferenceNumber}
                        </p>
                    </Col>
                </>: ""
            }
        </>
        :
        <>
            <td>{internalReferenceNumber && internalReferenceNumber}</td>
        </>
        }
    </>
    );
}

export default RenderInternalReferenceNumber;