import { useState, useEffect } from "react";
import { Pagination, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import Comment from "../Comment";
import AddNewComment from "../AddNewComment";

import useFetch from "../../../../hooks/useFetch";

const Comments = ({ order }) => {
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const getComments = async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/freight/${order.id}/comment/`);
      setComments(response.data);
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const nextGetComments = async (url) => {
    setLoading(true);
    try {
      const response = await fletesAPINotURL.get(url);
      setComments(response.data);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (comments === null) {
      toast.promise(getComments(), {
        loading: "Cargando comentarios...",
        success: "Comentarios cargados",
        error: "Error al cargar comentarios",
      });
    }
  }, [comments]);

  return (
    <>
      <AddNewComment updateData={getComments} dataComent={order} />
      {loading ? (
        <RenderSpace jc="center">
          <Spinner animation="border" role="status" />
        </RenderSpace>
      ) : (
        <>
          {comments !== null && comments.results.length > 0 ? (
            comments.results.map((e, i) => (
              <Comment key={i} data={e} getMore={getComments} />
            ))
          ) : (
            <RenderSpace jc="center">
              <p>No hay comentarios</p>
            </RenderSpace>
          )}
          {comments && (
            <Pagination>
              <Pagination.Prev
                disabled={!comments.previous}
                onClick={() => nextGetComments(comments.previous)}
              />
              <Pagination.Next
                disabled={!comments.next}
                onClick={() => nextGetComments(comments.next)}
              />
            </Pagination>
          )}
        </>
      )}
    </>
  );
};

export default Comments;