import React from "react";
import styled from "styled-components";
import { MdDone, MdClose } from "react-icons/md";

const PlanCont = styled.div`
  width: 25%;
  background: ${(e) =>
    e.popular
      ? "linear-gradient(to bottom, rgba(15,40,64,1) 0%, rgba(8,84,155,1) 100%)"
      : "#ebebeb"};
  border-radius: 18px;
  border: 6px solid ${(e) => (e.popular ? "#083D6E" : "#ebebeb")};
  transform: ${(e) => (e.popular ? "translateY(-50px)" : "translateY(0px)")};
  padding: 26px;
  position: relative;
  .most{
    position: absolute;
    top: 20px;
    right: 20px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 30px;
    border-radius: 20px;
    p{
      font-size: 11px;
      font-weight: 500;
      margin: 0;
      color: #3E85C6;
    }
  }
  .rowPlan {
    display: flex;
    flex-direction: column;
    .itemPlan {
      display: flex;
      .iconPlan {
        background-color: ${(e) => (e.popular ? "#23517A" : "white")};
        border-radius: 50%;
        height: 22px;
        width: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        margin-right: 5px;
        svg {
          fill: ${(e) => (e.popular ? "white" : "#3E85C6")};
        }
      }
    }
    h4 {
      color: #3e85c6;
      font-weight: 800;
      font-size: 40px;
      margin: 0;
      display: flex;
      align-items: flex-end;
      margin-top: 20px;
    }
    h5 {
      color: ${(e) => (e.popular ? "white" : "#083D6E")};
      margin-top: 20px;
      font-size: 30px;
      font-weight: 600;
    }
    p {
      color: ${(e) => e.colorText};
    }
    span {
      color: ${(e) => e.colorText};
      display: flex;
      align-items: flex-end;
      transform: translateY(-5px);
    }
    a {
      background-color: ${(e) => (e.popular ? "white" : "#083D6E")};
      text-decoration: none;
      width: 100%;
      height: 45px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      margin-top: 20px;
      p {
        color: ${(e) => (e.popular ? "#083D6E" : "white")};
        margin: 0;
        font-weight: 500;
      }
    }
  }
`;

export default function Plan({ popular, yearlyPrice, monthlyPrice, state, title, text, service  }) {
  return (
    <PlanCont popular={popular} colorText={popular ? "white" : "black"}>
      <div className="rowPlan flex-row">
        <h4>${state ? yearlyPrice : monthlyPrice }</h4>
        <span> / por usuario</span>
      </div>
      <div className="rowPlan">
        <h5>{title}</h5>
      </div>
      <div className="rowPlan">
        <p>{text}</p>
      </div>
      <div className="rowPlan">
        <div className="itemPlan">
          <div className="iconPlan">
          {service.s1 ? <MdDone /> : <MdClose />}
          </div>
          <p>Publicacion ilimitadas de cargas</p>
        </div>

         <div className="itemPlan">
          <div className="iconPlan">
            {service.s2 ? <MdDone /> : <MdClose />}
          </div>
          <p>Búsquedas ilimitadas de cargas</p>
        </div>

        <div className="itemPlan">
          <div className="iconPlan">
          {service.s3 ? <MdDone /> : <MdClose />}
          </div>
          <p>Puntos de contactos ilimitados</p>
        </div>

        <div className="itemPlan">
          <div className="iconPlan">
          {service.s4 ? <MdDone /> : <MdClose />}
          </div>
          <p>Soporte tecnico por chat</p>
        </div>

        <div className="itemPlan">
          <div className="iconPlan">
          {service.s5 ? <MdDone /> : <MdClose />}
          </div>
          <p>Soporte tecnico por telefono</p>
        </div>
      </div>
      <div className="rowPlan">
        <a href="/register">
          <p>Escoger este plan</p>
        </a>
      </div>
      {popular && <div className="most">
        <p>Más popular</p>
      </div>}
    </PlanCont>
  );
}
