import { useState, useEffect, useCallback } from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import Select from "react-select";
import debounce from "lodash/debounce";
import useFetch from "../../hooks/useFetch";

import { citiesStyles } from "../../utils/styles";
import { reformatCity } from "../../utils";

const SelectQouteCity = ({
  name,
  control,
  defaultValue = undefined,
  placeholder,
  error,
  label,
  url = "destination_cities",
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const { fletesAPI } = useFetch();

  const fetchCities = useCallback(async (query = "") => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`/api/quote/${url}/?name=${query}`);
      setCities(response.data.results.map(e => reformatCity(e)));
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  }, [fletesAPI]);
  

  const handleKeyPress = debounce((e) => {
    const text = e?.target?.value || "";
    if (text.length >= 2) {
      fetchCities(text);
    } else if (text.length === 0) {
      fetchCities(text);
    }
  }, 200);

  useEffect(() => {
    return () => {
      handleKeyPress.cancel();
    };
  }, [handleKeyPress]);

  return (
    <Form.Group controlId={name}>
    {Boolean(label) && <Form.Label>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || undefined}
        render={({ field }) => (
          <Select
            isClearable
            isLoading={loading}
            styles={citiesStyles}
            placeholder={placeholder}
            onChange={field.onChange}
            value={field.value}
            options={cities}
            className={error ? "invalidSelect" : ""}
            onFocus={() => fetchCities()}
            onKeyDown={handleKeyPress}
            isDisabled={disabled}
          />
        )}
      />
      <Form.Text className="text-danger position-absolute">
        {error && error.message}
      </Form.Text>
    </Form.Group>
  );
};

export default SelectQouteCity;