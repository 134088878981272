import { useState, useContext, useEffect } from "react";
import { Button, Col, Container, Row, Table, Pagination } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import ModalAddTruck from "./ModalAddTruck";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import { FaCalendarAlt } from "react-icons/fa";

const MyTrucks = () => {
  const [trucks, setTrucks] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const getTrucks = async () => {
    try {
      const response = await fletesAPI.get(`api/companytruck/?company=${companyId}`);
      setTrucks(response.data);
    } catch (error) {
      throw error;
    }
  };

  const deleteTruck = async (id) => {
    await fletesAPI
      .delete(`api/companytruck/${id}/`)
      .then((e) => {
        getTrucks();
        toast.success("Camion eliminado");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadTrucks = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setTrucks(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  useEffect(() => {
    if (!trucks) {
      toast.promise(getTrucks(), {
        loading: "Cargando camiones...",
        success: "Camiones cargados",
        error: "Error al cargar camiones",
      });
    }
  }, [trucks]);

  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Mis Camiones</h4>
            <Button className="text-white" onClick={() => setModalAdd(true)}>Agregar Camion</Button>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Modelo</th>
              <th>Placa</th>
              <th>Marca</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {trucks &&
              trucks.results.map((e, i) => (
                <tr key={i}>
                  <td>{e.model}</td>
                  <td>{e.license_plate}</td>
                  <td>{e.brand}</td>
                  <td>
                  <RenderButtonMore
                      buttons={[
                        {
                          label: (<>Editar <MdEdit /></>),
                          onClick: () => {
                            window.location.href = `/dashboard/my_trucks/${e.id}/edit`;
                          },
                        },
                        {
                          label: (<>Calendario <FaCalendarAlt /></>),
                          onClick: () => {
                            window.location.href = `/dashboard/my_trucks/${e.id}/calendar`;
                          },
                        },
                        {
                          label: (<>Eliminar <MdDelete /></>),
                          onClick: () => {
                            setModalDelete(true); 
                            setDataDelete(e);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {trucks && (
          <Pagination>
            <Pagination.Prev
              disabled={!trucks.previous}
              onClick={() => reloadTrucks(trucks.previous)}
            />
            <Pagination.Next
              disabled={!trucks.next}
              onClick={() => reloadTrucks(trucks.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm 
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteTruck(dataDelete.id)}
        confirmText={`¿Estas seguro de eliminar este camion ${dataDelete ? dataDelete.model : ''}?`}
      />
      <ModalAddTruck 
        state={modalAdd}
        setState={setModalAdd}
        getMore={getTrucks}
      />
    </>
  );
};

export default MyTrucks;
