import { createContext, useReducer, useEffect, useState } from "react";
import { sessionReducer, userIdReducer, companyIdReducer, profileReducer } from "./reducers";

export const BodegasContext = createContext();

const isTrue = (value) => {
  return value === "True";
};


export function decodeBase64(base64String) {
  try {
    const decodedString = atob(base64String);
    return decodedString;
  } catch (e) {
    console.error('Error decoding Base64 string:', e);
    return null;
  }
}


const BodegasContextProvider = ({ children }) => {
  const [session, dispSession] = useReducer(sessionReducer, "", () => {
    const sessionLocal = localStorage.getItem("session");
    return sessionLocal ? sessionLocal : "";
  });
  const [userId, dispUserId] = useReducer(userIdReducer, "", () => {
    const userIdLocal = localStorage.getItem("user_id");
    return userIdLocal ? userIdLocal : "";
  });
  const [companyId, dispCompanyId] = useReducer(companyIdReducer, "", () => {
    const companyIdLocal = localStorage.getItem("company_id");
    return companyIdLocal ? companyIdLocal : "";
  });
  const [profile, dispProfile] = useReducer(profileReducer, '', () => {
    const profileLocal = localStorage.getItem("profile");
    return profileLocal ? JSON.parse(profileLocal) : '';
  });
  const getCarrier = () => {
    let decodedSession = decodeBase64(session);
    let tokenData = null;
    try {
      tokenData = session ? JSON.parse(decodedSession) : null;
    } catch (e) {
      console.error('Error parsing session JSON:', e);
      return false;
    }
    return tokenData ? isTrue(tokenData.carrier) : false;
  }

  const [carrier, setCarrier] = useState(getCarrier());


  useEffect(() => {
    setCarrier(getCarrier());
  }, [session]);
  useEffect(() => {
    localStorage.setItem("session", session);
  }, [session]);
  useEffect(() => {
    localStorage.setItem("user_id", userId);
  }, [userId]);
  useEffect(() => {
    localStorage.setItem("company_id", companyId);
  }, [companyId]);
  useEffect(() => {
    localStorage.setItem("profile", JSON.stringify(profile));
  }, [profile]);

  return (
    <BodegasContext.Provider
      value={{
        session,
        dispSession,
        userId,
        dispUserId,
        companyId,
        dispCompanyId,
        profile,
        dispProfile,
        carrier,
      }}
    >
      {children}
    </BodegasContext.Provider>
  );
};

export default BodegasContextProvider;
