import React from "react";
import { Form as Formu, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { erroresCodes } from "../../utils";


//assets
// import { ReactComponent as Logo } from "../../assets/icons/logo-color.svg";

const styles = {
  container: {
    height: "800px",
    background: "#3E85C6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputCont: {
    position: "relative",
    cursor: "pointer",
  },
  inputEye: {
    position: "absolute",
    right: "5px",
    top: "0",
    bottom: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "120px",
  },
};

const loginValidation = yup.object().shape({
  email: yup.string().email().required(),
});

export default function Form() {
  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    resolver: yupResolver(loginValidation),
  });
  const onSubmit = data => {
    login(data);
  }
  const login = async (data) => {
    await axios.post(`${process.env.REACT_APP_URL_API}api/users/recover/`, data)
    .then((e) => {
      toast.success('Te enviamos a tu correo electrónico un enlace para el paso 2, verifica tu carpeta de spam', {duration:4000})})
    .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error("Error en Fletes & Bodegas, favor de contactar soporte@fletesybodegas.com");
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
    })
  }
  
  return (
    <>
    <Toaster />
    <div style={styles.container}>
      <Formu className="bg-light p-4 rounded" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <h2>Olvidaste tu contraseña?</h2>
          </Col>
        </Row>
        <Row>
          <Col className="my-4">
            <h4 className="text-primary">Paso 1 de 3</h4>
            <p>Ingresa tu correo electrónico y te enviaremos un enlace</p>
          </Col>
        </Row>
        <Row>
          <Formu.Group className="mb-3" controlId="formGroupEmail">
            <Formu.Label>Correo electrónico</Formu.Label>
            <Formu.Control type="text" placeholder="Ingresa tu correo electrónico" {...register('email')} isInvalid={errors.email ? true : false}/>
          </Formu.Group>
        </Row>
        <Row>
          <Col>
            <Button className="text-light w-100" type="submit">Recuperar mi cuenta</Button>
          </Col>
        </Row>
      </Formu>
    </div>
    </>
  );
}
