import { Modal, Button, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import RenderInput from "../RenderInput";
import RenderSelect from "../RenderSelect";
import RenderSelectCompany from "../RenderSelectCompany";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";

const RenderModalAddInvoice = ({ state, setState, getMore }) => {
  const { fletesAPIFile } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const onSubmit = (e) => {
    addInvoice(e);
  };
  const addInvoice = async (data) => {
    let formData = new FormData();
    formData.append("company_id", data.company_id.value);
    formData.append("status", data.status.value);
    formData.append("type", data.type.value);
    formData.append("total", data.total);
    formData.append("currency", data.currency.value);
    formData.append("invoice_file_name", data.invoice_file_name);
    formData.append("invoice_file_content", data.invoice_file_content[0]);
    formData.append("start_period", moment(data.start_perod).format("YYYY-MM-DD"));
    formData.append("end_period", moment(data.end_perod).format("YYYY-MM-DD"));
    await fletesAPIFile
      .post(`api/invoice/`, formData)
      .then((e) => {
        getMore();
        setState(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  return (
    <>
      <Modal onHide={() => setState(false)} show={state}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Factura</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group controlId="company_id" className="mb-3">
                <Form.Label>Compañia</Form.Label>
                <RenderSelectCompany
                  control={control}
                  name="company_id"
                  placeholder="Selecciona una compañia"
                  error={errors.company_id}
                />
              </Form.Group>
            </Row>
            <Row>
              <RenderSelect
                control={control}
                name="type"
                label="Tipo"
                options={[
                  { value: "Factura", label: "Factura" },
                  { value: "Remision", label: "Remision" },
                  { value: "Nota de Credito", label: "Nota de Credito" },
                  { value: "Nota de Debito", label: "Nota de Debito" },
                  { value: "Recibo", label: "Recibo" },
                  { value: "Membrecia", label: "Membrecia" },
                  { value: "Otro", label: "Otro" },
                ]}
                placeholder="Selecciona un tipo"
                error={errors.type}
              />
            </Row>
            <Row>
              <RenderSelect
                control={control}
                name="status"
                label="Estatus"
                options={[
                  { value: 0, label: "Pendiente" },
                  { value: 1, label: "En proceso" },
                  { value: 2, label: "Completada" },
                ]}
                placeholder="Selecciona un estado"
                error={errors.status}
              />
            </Row>
            <Row>
              <RenderInput
                register={register}
                name="total"
                label="Total"
                placeholder="Ingresa el total"
                error={errors.total && errors.total.message}
              />
            </Row>
            <Row>
              <RenderSelect
                control={control}
                name="currency"
                label="Moneda"
                options={[
                  { value: "MXN", label: "MXN" },
                  { value: "USD", label: "USD" },
                  { value: "EUR", label: "EUR" },
                ]}
                placeholder="Selecciona una moneda"
                error={errors.currency}
              />
            </Row>
            <Row>
              <RenderInput
                register={register}
                name="invoice_file_name"
                label="Nombre del archivo"
                placeholder="Ingresa el nombre del archivo"
                error={
                  errors.invoice_file_name &&
                  "El nombre del archivo es requerido"
                }
              />
            </Row>
            <Row>
              <RenderInput
                register={register}
                name="invoice_file_content"
                label="Archivo"
                type="file"
                error={errors.invoice_file_content && "El archivo es requerido"}
              />
            </Row>
            <Row>
              <RenderInput 
                register={register}
                name="start_period"
                label="Periodo Inicial"
                type="date"
                error={errors.start_period && "El periodo inicial es requerido"}
              />
            </Row>
            <Row>
              <RenderInput 
                register={register}
                name="end_period"
                label="Periodo Final"
                type="date"
                error={errors.end_period && "El periodo final es requerido"}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Agregar
            </Button>
            <Button variant="danger" onClick={() => setState(false)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RenderModalAddInvoice;
