import { Form, Row, Col, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import { useEffect } from "react";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import RenderInputPhone from "../../../../fyb_components/results/RenderInputPhone";
import RenderCheckbox from "../../../../fyb_components/results/RenderCheckbox";

const Edit = ({ contactData, getMore }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const { fletesAPI } = useFetch();

  const patchContact = async (datos) => {
    const {
      first_name,
      last_name,
      phone,
      email,
      preference_reach_by_whatsapp,
      preference_reach_by_email,
      preference_reach_by_phone,
    } = datos;
    await fletesAPI
      .patch(`api/pointofcontact/${contactData.id}/`, {
        company_id: contactData.company_id,
        first_name,
        last_name,
        phone,
        email,
        preference_reach_by_whatsapp,
        preference_reach_by_email,
        preference_reach_by_phone,
      })
      .then((e) => {
        toast.success("Contacto editado con éxito");
        getMore();
      })
      .catch((e) => {
        toast.error("Error al actualizar el contacto");
      });
  };

  const onSubmit = (data) => {
    patchContact(data);
  };

  useEffect(() => {
    if (contactData !== null) {
      reset({
        first_name: contactData.first_name,
        last_name: contactData.last_name,
        phone: contactData.phone,
        email: contactData.email,
        preference_reach_by_whatsapp: contactData.preference_reach_by_whatsapp,
        preference_reach_by_email: contactData.preference_reach_by_email,
        preference_reach_by_phone: contactData.preference_reach_by_phone,
      });
    }
  }, [contactData]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar Cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <RenderInput
            label={"Nombre"}
            name={"first_name"}
            register={register}
            errors={errors.first_name && errors.first_name.message}
            placeholder="Ingrese el nombre"
          />
          <RenderInput
            label={"Apellido"}
            name={"last_name"}
            register={register}
            errors={errors.last_name && errors.last_name.message}
            placeholder="Ingrese el apellido"
          />
          <RenderInputPhone
            label="Telefono"
            name="phone"
            control={control}
            errors={errors.phone && errors.phone.message}
            placeholder="ingresa tu telefono"
          />
          <RenderInput
            label={"Email"}
            name={"email"}
            register={register}
            errors={errors.email && errors.email.message}
            placeholder="Ingrese el email"
          />
          <RenderCheckbox
            label={"Preferencia de búsqueda por WhatsApp"}
            name={"preference_reach_by_whatsapp"}
            register={register}
            errors={errors.preference_reach_by_whatsapp && errors.preference_reach_by_whatsapp.message}
          />
          <RenderCheckbox
            label={"Preferencia de búsqueda por Email"}
            name={"preference_reach_by_email"}
            register={register}
            errors={errors.preference_reach_by_email && errors.preference_reach_by_email.message}
          />
          <RenderCheckbox
            label={"Preferencia de búsqueda por Telefono"}
            name={"preference_reach_by_phone"}
            register={register}
            errors={errors.preference_reach_by_phone && errors.preference_reach_by_phone.message}
          />
        </Row>
      </Form>
    </>
  );
};

export default Edit;
