import {Col} from "react-bootstrap";
import {useState} from "react";
import RenderModalOrderDetails from "./Modals/RenderModalOrderDetails";
import RenderEmail from "./RenderEmail";
import RenderPhone from "./RenderPhone";

const RenderOrderDetails = ({info, isMobile}) => {

    const [showModalOrderDetails, setShowModalOrderDetails] = useState(false);

    return (
    <>
        {isMobile ?
        <>
            <Col xl={3} lg={3} md={6} sm={6}>
                <p>
                    <a className="dashboard_main_container_result_link"
                        onClick={() => {
                          setShowModalOrderDetails(true);
                        }}>
                    {info.point_of_contact.first_name} {info.point_of_contact.last_name}
                    </a>
                </p>
            </Col>
            <RenderEmail mailto={info.point_of_contact.email} label={info.point_of_contact.email} />
            <RenderPhone order={info} />
        </>
        :
        <>
            <td>
                <a className="dashboard_main_container_result_link"
                    onClick={() => {
                        setShowModalOrderDetails(true);
                    }}
              >{`${info.point_of_contact.first_name} ${info.point_of_contact.last_name}`}</a>
              <br />
              {info.point_of_contact.preference_reach_by_email ? <> {info.point_of_contact.email} <br /></>: ""}
              {info.point_of_contact.preference_reach_by_phone ? <><a href={`tel:${info.point_of_contact.phone}`}>{info.point_of_contact.phone}</a> <br /></>: ""}
            </td>
        </>}
        <RenderModalOrderDetails show={showModalOrderDetails} setShow={setShowModalOrderDetails} info={info} />
    </>
    );
};

export default RenderOrderDetails;