import React from "react";
import { Image } from "react-bootstrap";

import FybNavigationBar from "../components/FybNavigationBar";
import Banner from "../components/Home/Banner";
import Section from "../components/Home/Section";
import AboutSection from "../components/Home/AboutSection";
import Cards from "../components/Home/Cards";

import imageBanner from "../assets/images/compressed_banner.jpg";
import driver from "../assets/images/compressed_driver.jpg";
import warehouseLift from "../assets/images/compressed_warehouse_lift.jpg";
import warehouseTrucks from "../assets/images/compressed_warehouse_trucks.jpg";
import Company from "../components/Home/Company";
import Join from "../components/Home/Join";
import Footer from "../components/Footer";

export default function Home () {
  return (
    <>
      <header>
        <FybNavigationBar />
        <Banner
          image={imageBanner}
          text="Únete y seamos la comunidad de transporte más grande de México!"
          link="/register"
        />
      </header>
      <Section
        title="Conecta tu logística con transportistas de todo México"
        subtitle="Publica cargas que necesitan ser transportadas a la red de transportistas que más está creciendo en México."
        content={<Image src={warehouseTrucks} style={{ height: "100%", width: "100%" }} />}
      />
      <AboutSection
        title="Encuentra cargas listas para ser transportadas"
        subtitle="Busca cargas listas para ser transportadas en cuestión de segundos. Contacta la logística en segundos y cierra tratos el mismo día. Asegura tu carga de regreso."
        content={
          <Image src={warehouseLift} style={{ maxHeight:"100%"}} />
        }
      />
      <Section
        title="Somos una comunidad 100% Mexicana"
        subtitle="Fletes y Bodegas es una empresa 100% Mexicana basada en Nuevo Laredo, Tamaulipas México Capital Aduanera de Latino América"
        content={
          <Image src={driver} style={{ height: "100%", width: "100%" }} />
        }
      />

      <Cards
        title="Los problemas que estamos resolviendo"
        cards={[
          {
            image: warehouseLift,
            title: "Búsqueda de Cargas",
            text:
              "Busca cargas en todo México, filtrado por ciudad origen, destino, tipos de tráiler, ¡y más! ¡Contacta directamente a la logística y cierra un trato hoy!",
          },
          {
            image: warehouseTrucks,
            title: "Busca Bodegas",
            text:
              "Descubre bodegas en todo México para almacenaje, traslado y otros servicios en México",
          },
          {
            image: driver,
            title: "Encuentra tu Transportista",
            text:
              "Publica tus ofertas de carga para transportar y espera a ser contactado por uno de nuestra red de transportistas de todo México.",
          },
        ]}
      />

      {/*
      <Company
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"
        card={{
          image: driver,
          title: "Learn about all the features",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,",
        }}
        gallery={[{ image: cana }, { image: parking }, { image: driver },{ image: cana }, { image: parking }, { image: driver }]}
      />

      */}
      <Join
        text='Si buscas transportistas o buscas carga para tus camiones, somos tu solución.'
        link='/register'
      />
      <Footer />
    </>
  );
};
