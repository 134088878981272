import React, { useState, useContext, useEffect } from "react";
import { Row, Button, Col, Spinner, Container } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import { RiAddLine } from "react-icons/ri";
import { BodegasContext } from "../../context";
import ModalFormAdd from "./Users/ModalFormAdd";
import ModalRegisterAdd from "./Users/ModalRegisterAdd";
import toast from "react-hot-toast";
import RenderMyUsers from "../../fyb_components/results/RenderMyUsers";
import RenderSpace from "../../fyb_components/results/RenderSpace";

const Users = ({ token }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showReg, setShowReg] = useState(false);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const { fletesAPINotURL } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const getUsers = async (url) => {
    const ur = url || `${process.env.REACT_APP_URL_API}api/company/${companyId}/members/`;
    setLoading(true);
    try {
      const response = await fletesAPINotURL.get(ur);
      setUsers(response.data);
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (users == null) {
      toast.promise(getUsers(), {
        loading: "Cargando usuarios...",
        success: "Usuarios cargados",
        error: "Error al cargar usuarios",
      });
    }
  }, [users]);

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Usuarios en mi compania</h1>
          <Button
            onClick={() => setShowAdd(true)}
            variant="primary"
            className="text-white"
            style={{ width: "200px" }}
          >
            <RiAddLine />
            Agregar un usuario
          </Button>
        </Row>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <RenderMyUsers users={users} isMobile={false} reloadUsers={getUsers} />
        )}
      </Container>
      <ModalFormAdd show={showAdd} setShow={setShowAdd} getMore={getUsers} />
      <ModalRegisterAdd show={showReg} setShow={setShowReg} getMore={getUsers} />
    </>
  );
};

export default Users;
