import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { Row, Col, Table, Button } from "react-bootstrap";
import RenderCityResult from "./RenderCityResult";
import RenderBedTypes from "./RenderBedTypes";
import PaginationFyB from "./PaginationFyB";
import ModalDelete from "../../components/Dashboard/TruckRoutes/ModalDelete";
import RenderButtonMore from "./RenderButtonMore";

const RenderTruckRoutes = ({ data, title, isMobile, reload }) => {
  const [showDel, setShowDel] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);

  return (
    <>
      {isMobile ? (
        <>
          {data && data.results && data.results.length > 0 ? (
            <>
              {data.results.map((e, i) => {
                return (
                  <div className="v2_dashboard_body_box">
                    <Row>
                      <RenderCityResult
                        origin_city={e.origin_city}
                        destination_city={e.destination_city}
                        display_city={e.origin_city}
                        isMobile={isMobile}
                        subtitle={"Origen"}
                      />
                      <RenderCityResult
                        origin_city={e.origin_city}
                        destination_city={e.destination_city}
                        display_city={e.destination_city}
                        isMobile={isMobile}
                        subtitle={"Destino"}
                      />
                    </Row>
                    <Row>
                      <RenderBedTypes
                        bed_types={e.bed_type}
                        isMobile={isMobile}
                      />
                    </Row>
                    <Row>
                      <Col xl={1} lg={1} md={2} sm={2}>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setShowDel(true);
                            setDataDelete(e);
                          }}
                          className="v2_dashboard_body_buttons"
                        >
                          <MdDelete />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <br />
              <h3 className="text-center fyb_h2">
                No se encontraron resultados
              </h3>
            </>
          )}
        </>
      ) : (
        <>
          <div className="dashboard_main_container_table">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                {data && data.results.length > 0 ? (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>Ciudad de origen</th>
                          <th>Ciudad de destino</th>
                          <th>Tipos de tráiler</th>
                          <th>Precio base</th>
                          <th style={{width: '40px'}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.results.map((e, i) => {
                            return (
                              <tr key={i}>
                                <RenderCityResult
                                  origin_city={e.origin_city}
                                  destination_city={e.destination_city}
                                  display_city={e.origin_city}
                                  isMobile={isMobile}
                                  subtitle={"Origen"}
                                />
                                <RenderCityResult
                                  origin_city={e.origin_city}
                                  destination_city={e.destination_city}
                                  display_city={e.destination_city}
                                  isMobile={isMobile}
                                  subtitle={"Destino"}
                                />
                                <RenderBedTypes
                                  bed_types={e.bed_type}
                                  isMobile={isMobile}
                                />
                                <td>${e.base_price} {e.base_currency}</td>
                                <td>
                                  <RenderButtonMore 
                                    buttons={[
                                      {
                                        label: (<> Eliminar <MdDelete /></>),
                                        onClick: () => {
                                          setShowDel(true);
                                          setDataDelete(e);
                                        },
                                      }
                                    ]}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    <PaginationFyB data={data} reloader={reload} />
                  </>
                ) : (
                  <>
                    <br />
                    <h3 className="text-center fyb_h2">
                      No se encontraron resultados
                    </h3>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
      <ModalDelete
        show={showDel}
        setShow={setShowDel}
        dataDelete={dataDelete}
        getMore={reload}
      />
    </>
  );
};

export default RenderTruckRoutes;
