import {
    Pagination,
} from "react-bootstrap";

const PaginationFyB = ({data, reloader}) => {
    return(
    <>
        {data && (
            <Pagination>
              <Pagination.Prev
                disabled={!data.previous}
                onClick={() => reloader(data.previous)}
              />
              <Pagination.Next
                disabled={!data.next}
                onClick={() => reloader(data.next)}
              />
            </Pagination>
        )}
    </>
    )
}

export default PaginationFyB;