import React from 'react'
import { Container, Col, Row } from "react-bootstrap";

const Section = ({ content, title, subtitle }) => {
  return (
    <div className="bg-primary mt-5 mb-5">
      <Container >
        <Row className='pt-5 pb-5'>
          <Col xl={6} lg={6} md={12} sm={12} className="d-flex flex-column justify-content-center align-items-end">
            <div className="d-flex" style={{maxHeight: '350px'}}>
              { content }
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} className="d-flex flex-column justify-content-center" >
            <h2 className="text-light" > {title} </h2>
            <p> {subtitle} </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Section