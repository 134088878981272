import { useState, useEffect } from "react";
import { Button, Row, Col, Table, Spinner, Pagination } from "react-bootstrap";
import RenderTableRow from "../../../../fyb_components/results/RenderTableRow";
import RenderModalDelAttachment from "../ModalDelAttachment";
import ModalAddAttachment from "../ModalAddAttachment";
import useFetch from "../../../../hooks/useFetch";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";

const Files = ({ order }) => {
  const [showDel, setShowDel] = useState(false);
  const [dataDel, setDataDel] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [loading, setLoading] = useState(true);

  const { fletesAPI, fletesAPINotURL } = useFetch();

  const getAttachments = async (id) => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/freight/${id}/attachment/`);
      setAttachments(response.data);
    } catch (e) {
      console.log(e.response);
    } finally {
      setLoading(false);
    }
  };

  const nextGetAttachments  = async (url) => {
    setLoading(true);
    try {
      const response = await fletesAPINotURL.get(url);
      setAttachments(response.data);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (attachments === null) {
      getAttachments(order.id);
    }
  }, [attachments]);

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <Button
            variant="primary"
            className="text-white"
            onClick={() => setShowAdd(true)}
          >
            Agregar Archivo
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {loading ? (
            <RenderSpace jc="center">
              <Spinner animation="border" role="status"  />
            </RenderSpace>
          ) : (
            <>
              {attachments?.results && attachments.results.length > 0 ? (
                <Table striped responsive className="dashboard_main_container_table">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Nombre</th>
                      <th>Tipo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {attachments.results &&
                      attachments.results.map((e, i) => (
                        <RenderTableRow
                          data={e}
                          key={i}
                          onDelete={() => {
                            setShowDel(true);
                            setDataDel(e);
                          }}
                        />
                      ))}
                  </tbody>
                </Table>
              ) : (
                <RenderSpace>
                  <p>No hay archivos</p>
                </RenderSpace>
              )}
              {attachments && (
                <Pagination>
                  <Pagination.Prev
                    disabled={!attachments.previous}
                    onClick={() => nextGetAttachments(attachments.previous)}
                  />
                  <Pagination.Next
                    disabled={!attachments.next}
                    onClick={() => nextGetAttachments(attachments.next)}
                  />
                </Pagination>
              )}
            </>
          )}
        </Col>
      </Row>
      <RenderModalDelAttachment
        show={showDel}
        setShow={setShowDel}
        attachment={dataDel}
        getMore={getAttachments}
      />
      <ModalAddAttachment
        show={showAdd}
        setShow={setShowAdd}
        getMore={getAttachments}
        order={order}
      />
    </>
  );
};

export default Files;
