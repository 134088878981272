import { Col, Row, Container, Button, Spinner, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import RenderSpace from "../../fyb_components/results/RenderSpace";
import ModalAdd from "./ModalAdd";

const SelectInvoice = ({ companyId, defInvoice, next }) => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const { fletesAPI } = useFetch();
  const getInvoices = async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/company/${companyId}/invoice_information/`);
      setInvoices(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (invoices === null) {
      getInvoices();
    }
  }, [invoices]);
  return (
    <>
      <Container>
        <Row>
          <Col className="w-100 d-flex justify-content-end">
            <Button className="text-white" onClick={() => setModalAdd(true)}>
              {" "}
              Agregar datos de facturación{" "}
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col style={{ minHeight: 800 }}>
            {loading ? (
              <RenderSpace jc="center">
                <Spinner animation="border" role="status" />
              </RenderSpace>
            ) : (
              <>
                {invoices && invoices.length > 0 ? (
                  <Table striped responsive className="dashboard_main_container_table">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left", paddingLeft: 20 }}>Nombre legal</th>
                        <th style={{ textAlign: "left", paddingLeft: 20 }}>Contacto</th>
                        <th style={{ textAlign: "left", paddingLeft: 20 }}>Dirección</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.map((invoice, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "left", paddingLeft: 20 }}>
                            <div>
                              <p className="m-0">{invoice?.legal_name}</p>
                              <p className="m-0">{invoice?.rfc_code}</p>
                            </div>
                          </td>
                          <td style={{ textAlign: "left", paddingLeft: 20 }}>
                            <div>
                              <p className="m-0">{invoice?.address?.name}</p>
                              <p className="m-0">{invoice?.address?.phone}</p>
                              <p className="m-0">{invoice?.address?.email}</p>
                            </div>
                          </td>
                          <td style={{ textAlign: "left", paddingLeft: 20 }}>
                            <div>
                              <p className="m-0">{invoice?.address?.address_line1}</p>
                              <p className="m-0">{invoice?.address?.address_line2}</p>
                              <p className="m-0">{invoice?.address?.zip_code}</p>
                            </div>
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                defInvoice(invoice);
                                next();
                              }}
                              className="text-white"
                            >
                              Seleccionar
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <RenderSpace jc="center">
                    <p>No hay datos de facturación</p>
                  </RenderSpace>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <ModalAdd
        show={modalAdd}
        onHide={() => setModalAdd(false)}
        update={getInvoices}
        companyId={companyId}
      />
    </>
  );
};

export default SelectInvoice;
