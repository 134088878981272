import { Modal, Button, Form } from "react-bootstrap";
import RenderInput from "../../../fyb_components/results/RenderInput";
import useFetch from "../../../hooks/useFetch";
import { useForm } from "react-hook-form";
import RenderDropFile from "../../../fyb_components/results/RenderDropFile";


const ModalAddImage = ({ state, setState, getMore, info }) => {
  
  const { fletesAPIFile } = useFetch();
  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm();
  const handleClose = () => {
    setState(false);
    reset();
  };
  const onSubmit = async (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("file_name", data.file_name);
    formData.append("file_content", data.file_content[0]);
    console.log(formData);
    await fletesAPIFile
      .post(`api/warehouses/${info.id}/photos/`, formData)
      .then((e) => {
        console.log(e);
        getMore();
        handleClose();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Modal show={state} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar imagen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderInput
            name={"file_name"}
            label="Nombre de la imagen"
            register={register}
            placeholder="Ingrese el nombre de la imagen"
            errors={errors.file_name && errors.file_name.message}
          />
          <RenderDropFile
            name={"file_content"}
            register={register}
            setValue={setValue}
            watch={watch}
            />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            Agregar
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Calcelar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddImage;
