import { useState } from "react";
import { Row, Spinner, Button, Container } from "react-bootstrap";
import { RiAddLine } from "react-icons/ri";
import useFetch from "../../../hooks/useFetch";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import ModalCreateQoute from "./ModalCreateQoute";
import QoutesTable from "./QoutesTable";
import Filter from "./Filter";

const URL_API = process.env.REACT_APP_URL_API;

export default function MyQuotes() {
  const { fletesAPI } = useFetch();
  const [loading, setLoading] = useState(false);
  const [myQuotes, setMyQuotes] = useState(null);
  const [isMobile, setIsMobile] = useState(null);
  const [showMCQ, setShowMCQ] = useState(false);

  const getMyQuotes = async (url = null) => {
    const finalUrl = url || `${URL_API}api/quote/`;

    await fletesAPI
      .get(finalUrl)
      .then((e) => {
        setMyQuotes(e.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const fetchQuotesFilter = async (filter) => {
    const finalUrl = `${URL_API}api/quote/`;

    setLoading(true);
    try {
      const response = await fletesAPI.get(finalUrl, {
        params: {
          reference: filter?.reference ?? undefined,
          origin_city_id: filter?.origin_city_id ?? undefined,
          destination_city_id: filter?.destination_city_id ?? undefined,
          start_date: filter?.start_date ?? undefined,
          end_date: filter?.end_date ?? undefined,
          status: filter?.status ?? undefined,
        },
      });
      setMyQuotes(response.data);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const addQuote = () => {
      window.location.href = "/dashboard/quotes/create";
  }

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Estimaciones</h1>
          <Button
            onClick={addQuote}
            variant="primary"
            className="text-white"
            style={{ width: "220px" }}
          >
            <RiAddLine /> Agregar estimacion
          </Button>
        </Row>
        <hr />
        <Filter sendRequest={fetchQuotesFilter} />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <QoutesTable
            quotes={myQuotes}
            title={""}
            isMobile={isMobile}
            reloadOrders={getMyQuotes}
          />
        )}
        <ModalCreateQoute
          show={showMCQ}
          setShow={setShowMCQ}
          getMore={getMyQuotes}
        />
      </Container>
    </>
  );
}
