import { useContext } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerCompanyValidationV2 } from "../../utils/formsValidation";
import useFetch from "../../hooks/useFetch";
import { BodegasContext } from "../../context";
import { sessionType, companyIdType, userIdType } from '../../context/reducers'

export default function ModalRegisterCompany({ show, setShow }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(registerCompanyValidationV2),
  });
  
  const { fletesAPI } = useFetch();
  const { dispSession, dispCompanyId, dispUserId } = useContext(BodegasContext);

  const registerCompanyOnAPI = async (com) => {
    await fletesAPI
      .get(`api/company/${com}/switch/`)
      .then((e) => {
        console.log(e);
        dispSession({type: sessionType.setSession,  session: e.data.session});
      })
      .catch((e) => {
      });
  };
  const onSubmit = (data) => {
    registerCompany(data);
  };
  const registerCompany = async (data) => {
    await fletesAPI
      .post(`api/company/`, data)
      .then((e) => {
        dispCompanyId({ type: companyIdType.setCompanyId , companyId: e.data.id});
        registerCompanyOnAPI(e.data.id);
        setShow(false);
        reset();
      })
      .catch((e) => {
      });
  };
  return (
    <>
      <Modal show={show}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <h5 className="fyb_h5 text-center">Asociate o Registra una empresa</h5><br/>
          </Modal.Header>
          <Modal.Body>
              <Row>
                <p>Si tu empresa no esta registrada en Fletes Y Bodegas, continua con el formulario. Si tu empresa ya esta de alta porfavor pide al administrador que te de acceso.</p>
              </Row>
          </Modal.Body>
          <Modal.Header>
            <h5 className="fyb_h5">Registrar una nueva empresa</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="formComapanyName">
                <Form.Label>Nombre de empresa</Form.Label>
                <Form.Control {...register("name")} isInvalid={errors.name} type="text" placeholder="Introduzca el nombre de la empresa" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formCompanyWeb">
                <Form.Label>Web de la empresa</Form.Label>
                <Form.Control {...register("website_url")} isInvalid={errors.website_url} type="text" placeholder="Introduzca la web de la empresa" />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              size="sm"
              type="submit"
            >
              Registrar
            </Button>
            <Button variant="danger" size="sm" onClick={() => {
                dispSession({type: sessionType.delSession});
                dispUserId({type: userIdType.delUserId});
                window.location.href = "/login";
              }}>
              Cerrar Sesion
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
