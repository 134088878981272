import { useContext } from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";
import RenderInput from "../../../fyb_components/results/RenderInput";
import RenderSelect from "../../../fyb_components/results/RenderSelect";
import RenderInputPassword from "../../../fyb_components/results/RenderInputPassword";
import RenderInputPhone from "../../../fyb_components/results/RenderInputPhone";

const searchEmailValidation = yup.object().shape({
  email: yup.string().email().required(),
  roleOption: yup.object().required().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  password: yup.string().min(6).max(32).required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null])
    .required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().required(),
  position_title: yup.string().required(),
});

export default function ModalRegisterAdd({ show, setShow, getMore }) {
  const { companyId } = useContext(BodegasContext);
  const { fletesAPI } = useFetch();
  const registerMember = async (data) => {
    await fletesAPI
      .post(`api/company/${companyId}/members/`, {
        member_id: data.id,
        role: data.role,
      })
      .then((e) => {
        getMore("init");
        setShow(false);
        reset();
        toast.success(mensajesAPI("toast_success_user_added_to_company"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const registerEmail = async (data) => {
    await fletesAPI
      .post(`api/users/`, data)
      .then((e) => {
        registerMember({ ...e.data, role: data.roleOption.value });
        toast.success(mensajesAPI("toast_success_register_user"));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(searchEmailValidation),
  });
  const onSubmit = (data) => {
    fletesAPI
      .post(`api/users/invite_token/`)
      .then((e) => {
        registerEmail({ ...data, token: e.data.token });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Agregar un nuevo miembro</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <RenderInput
                name={"email"}
                label={"Correo electrónico"}
                placeholder={"Ingrese el correo electrónico"}
                register={register}
                error={errors.email && "El correo electrónico es requerido"}
              />
            </Row>
            <Row>
              <RenderSelect
                name={"roleOption"}
                label={"Rol"}
                placeholder={"Seleccione un rol"}
                control={control}
                options={[
                  { value: "1", label: "Administrador" },
                  { value: "2", label: "Conductor" },
                ]}
                error={errors.role && "El rol es requerido"}
              />
            </Row>
            <Row>
              <RenderInputPassword
                register={register}
                mensajesFormulario={mensajesFormulario}
                errors={errors}
                name={"password"}
                label={"Contraseña"}
                placeholder={"Ingrese la contraseña"}
              />
            </Row>
            <Row>
              <RenderInputPassword
                register={register}
                mensajesFormulario={mensajesFormulario}
                errors={errors}
                name={"passwordConfirm"}
                label={"Confirmar contraseña"}
                placeholder={"Ingrese la contraseña"}
              />
            </Row>
            <Row>
              <RenderInput
                name={"first_name"}
                label={"Nombre(s)"}
                placeholder={"Ingrese el nombre(s)"}
                register={register}
                error={errors.first_name && "el nombre es requerido"}
              />
            </Row>
            <Row>
              <RenderInput
                name={"last_name"}
                label={"Apellido(s)"}
                placeholder={"Ingrese el apellido(s)"}
                register={register}
                error={errors.last_name && "el apellido es requerido"}
              />
            </Row>
            <Row>
              <RenderInput
                name={"position_title"}
                label={"Puesto de trabajo"}
                placeholder={"Ingrese el puesto de trabajo"}
                register={register}
                error={errors.position_title && "el puesto de trabajo es requerido"}
              />
            </Row>
            <Row>
              <RenderInputPhone
                name={"phone"}
                label={"Teléfono"}
                placeholder={"Ingrese el teléfono"}
                control={control}
                error={errors.phone && "el teléfono es requerido"}
                required
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" size="sm">
              Agregar
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
