import { useState, useEffect, useCallback } from "react";
import { Controller } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import Select from "react-select";
import debounce from "lodash/debounce";

import { citiesStyles } from "../../utils/styles";
import { reformatCity } from "../../utils";

const SelectAllCity = ({
  name,
  control,
  defaultValue = undefined,
  placeholder,
  error,
  disabled = false,
  style = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const { fletesAPI } = useFetch();

  const fetchCities = useCallback(
    async (query = "") => {
      const url = query.length >= 3 ? `api/city/search/?name=${query}` : `api/city/`;
      setLoading(true);
      try {
        const response = await fletesAPI.get(url);
        const results = query.length >= 3 ? response.data : response.data.results;
        setCities(results.map((e) => reformatCity(e)));
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false);
      }
    },
    [fletesAPI]
  );

  const handleKeyPress = debounce((e) => {
    const text = e?.target?.value || "";
    if (text.length >= 3) {
      fetchCities(text);
    } else if (text.length === 0) {
      fetchCities(text);
    } else {
      fetchCities();
    }
  }, 200);

  useEffect(() => {
    return () => {
      handleKeyPress.cancel();
    };
  }, [handleKeyPress]);

  return (
    <div style={style}>
      <Controller
        name={name}
        control={control}
        defaultValue={
          defaultValue === null
            ? undefined
            : defaultValue.value
            ? defaultValue
            : reformatCity(defaultValue)
        }
        render={({ field }) => (
          <Select
            isDisabled={disabled}
            isClearable
            isLoading={loading}
            styles={citiesStyles}
            placeholder={placeholder}
            onChange={field.onChange}
            value={field.value}
            options={cities}
            className={error ? "invalidSelect" : ""}
            onFocus={() => {
              fetchCities();
            }}
            onKeyDown={handleKeyPress}
          />
        )}
      />
    </div>
  );
};

export default SelectAllCity;
