import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import RenderSelectCity from "../../../../fyb_components/results/RenderSelectCity";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import RenderSelect from "../../../../fyb_components/results/RenderSelect";
import { useEffect } from "react";
import { reformatCity } from "../../../../utils";

const Edit = ({ routeData, getMore }) => {
  const [bedTypes, setBedTypes] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { fletesAPI } = useFetch();

  const patchRoute = async (datos) => {
    const { origin_city_id, destination_city_id, bed_type_ids } = datos;
    await fletesAPI
      .patch(`api/routes/${routeData.id}/`, {
        origin_city_id: origin_city_id.value,
        destination_city_id: destination_city_id.value,
        bed_type_ids: bed_type_ids.flatMap((e) => [e.value]),
      })
      .then((e) => {
        toast.success("Ruta actualizada con exito");
        getMore();
      })
      .catch((e) => {
        toast.error("Error al actualizar la ruta");
      });
  };

  const getBedTypes = async () => {
    await fletesAPI
      .get(`api/freightbedoptions/`)
      .then((e) => {
        setBedTypes(e.data.map((e) => ({ value: e.id, label: e.name })));
      })
      .catch((e) => {
        toast.error("Error al obtener los tipos de camas");
      });
  };


  const onSubmit = (data) => {
    patchRoute(data);
  };

  useEffect(() => {
    if(routeData !== null){
      reset({
        origin_city_id: reformatCity(routeData.origin_city),
        destination_city_id: reformatCity(routeData.destination_city),
        bed_type_ids: routeData.bed_type.map((e) => ({value: e.id, label: e.name}))
      });
    }
  }, [routeData]);

  useEffect(() => {
    if(bedTypes === null){
      getBedTypes()
    }
  }, [bedTypes]);

  console.log(routeData);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar Cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Form.Group className="mb-3">
            <Form.Label>Cuidad de origien</Form.Label>
            <RenderSelectCity
              name="origin_city_id"
              Label="Cuidad de origien"
              defaultValue={null}
              control={control}
              placeholder="Seleccione una cuidad"
              error={errors.origin_city_id && "Seleccione una cuidad"}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cuidad de origien</Form.Label>
            <RenderSelectCity
              name="destination_city_id"
              Label="Cuidad de destino"
              defaultValue={null}
              control={control}
              placeholder="Seleccione una cuidad"
              error={errors.origin_city_id && "Seleccione una cuidad"}
            />
          </Form.Group>
          <RenderSelect
            name="bed_type_ids"
            label="Tipo de camas"
            defaultValue={null}
            options={bedTypes}
            control={control}
            placeholder="Seleccione un tipo de camas"
            error={errors.origin_city_id && "Seleccione un tipo de camas"}
            isMulti
          />
        </Row>
      </Form>
    </>
  );
};

export default Edit;
