import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Alert, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { MdPhone, MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import useFetch from "../../../hooks/useFetch";
import RenderSelect from "../../../fyb_components/results/RenderSelect";
import { fullNameCity } from "../../../utils";
import DropFile from "../DropFile";
import { statusTaskLabel } from "../../../utils";

const updateTask = yup.object().shape({
  comment_submitter: yup.string().required("El comentario es requerido"),
});

const ModalTask = ({ open, handleClose, task, update, carrier }) => {
  const { fletesAPI, fletesAPIFile } = useFetch();
  const [loading, setLoading] = useState(false);
  const [freight, setFreight] = useState(null);

  const isCarrier = carrier ? "company" : "transportation_company";

  const addComment = async (comment) => {
    try {
      fletesAPI.post(`api/freight/${task.freight_id}/comment/`, { comment });
    } catch (error) {
      throw Error(error);
    }
  };

  const addAttachment = async ({ data }) => {
    let formData = new FormData();
    formData.append("file_name", data.name);
    formData.append("type", data.type);
    formData.append("file_content", data);
    try {
      fletesAPIFile
        .post(`api/freight/${task.freight_id}/attachment/`, formData)
        .then((response) => {
          const newComment = `Se ha adjuntado un archivo: ${response.data.url} de la tarea: ${task.title}`;
          addComment(newComment);
        });
    } catch (error) {
      console.log(error);
      throw Error(error);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(updateTask),
    defaultValues: {
      comment_submitter: "",
      files: [],
    },
  });
  const getFreight = async () => {
    if (!task) return;
    if (Boolean(!task?.request_to_contact_transportation)) return;
    const promise = fletesAPI.get(`api/freight/${task.freight_id}/`);

    toast.promise(promise, {
      loading: "Cargando información de la carga...",
      success: "Información de la carga cargada con éxito",
      error: "Error al cargar información de la carga",
    });

    promise
      .then((response) => {
        setFreight(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onSubmit = async (info) => {
    setLoading(true);
    const response = fletesAPI.patch(
      `api/freight/${task.freight_id}/task/complete/`,
      {
        status: carrier ? info.status.value : 3,
        comment_submitter: info.comment_submitter,
        task_id: task.id,
      }
    );

    toast.promise(response, {
      loading: "Guardando tarea...",
      success: "Tarea guardada correctamente",
      error: "Error al guardar la tarea",
    });

    response
      .then(() => {
        info.files.forEach((element) => {
          addAttachment({ id: task.freight_id, data: element });
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
        reset();
        setValue("files", []);
        setFreight(null);
        update();
      });
  };

  useEffect(() => {
    getFreight();
  }, [task, open]);

  useEffect(() => {
    if (task) {
      reset({
        comment_submitter: task.comment_submitter,
        status: {
          value: task.status,
          label: statusTaskLabel(task.status),
        },
      });
    }
  }, [task]);
  return (
    <Modal
      show={open}
      onHide={() => {
        handleClose();
        setValue("files", []);
        setFreight(null);
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{task?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            <p>
              <b>Instrucciones:</b> {task?.instructions}
            </p>
            <p>
              <b>Comentario solicitante:</b> {task?.comment_requestor}
            </p>
            {Boolean(task?.request_for_attachment) && (
              <p>
                <b>Requiere adjuntar archivo(s) *</b>
              </p>
            )}
            {Boolean(task?.request_to_contact_transportation) && (
              <p>
                <b>Requiere contacto con transportista *</b>
              </p>
            )}
          </Alert>
          {carrier && (
            <RenderSelect
              control={control}
              label={"Estado"}
              name={"status"}
              options={[
                { value: 1, label: "Pendiente" },
                { value: 2, label: "Pendiente verificación" },
                { value: 3, label: "Completado" },
                { value: 4, label: "Cancelado" },
              ]}
            />
          )}
          <Form.Group>
            <Form.Label>Comentario</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("comment_submitter")}
              isInvalid={errors.comment_submitter}
            />
            <Form.Control.Feedback type="invalid">
              {errors.comment_submitter?.message}
            </Form.Control.Feedback>
          </Form.Group>
          {freight && (
            <>
              <hr />
              <Row>
                <Col>
                  <label>Correo Electrónico</label>
                  <h5>
                    {freight?.quote?.[isCarrier]?.contact_information?.email ||
                      "N/E"}
                  </h5>
                </Col>
                <Col>
                  <label>Telefono</label>
                  <h5>
                    {freight?.quote?.[isCarrier]?.contact_information?.phone
                      ? formatPhoneNumberIntl(
                          freight?.quote?.[isCarrier]?.contact_information
                            ?.phone
                        )
                      : "N/E"}
                  </h5>
                </Col>
              </Row>
              <Row>
                <label>
                  Nombre de contacto
                </label>
                <h5>
                  {freight?.quote?.[isCarrier]?.contact_information?.name ||
                    "N/E"}
                </h5>
              </Row>
              <Row>
                <Col className="d-flex flex-row gap-2 flex-wrap justify-content-between">
                  {freight?.quote?.[isCarrier]?.contact_information && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        `https://wa.me/${freight?.quote?.[isCarrier]?.contact_information?.phone}?text=` +
                        encodeURIComponent(
                          `Hola me interesa tu carga con id ${
                            freight?.quote?.id
                          } con origen ${fullNameCity(
                            freight?.origin_address?.city
                          )}, con destino a ${fullNameCity(
                            freight?.destination_address?.city
                          )}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`
                        )
                      }
                      className="btn btn-success text-light btn-sm"
                    >
                      <IoLogoWhatsapp />{" "}
                      {formatPhoneNumberIntl(
                        freight?.quote?.[isCarrier]?.contact_information?.phone
                      )}
                    </a>
                  )}
                  {freight?.quote?.[isCarrier]?.contact_information?.email && (
                    <a
                      href={
                        `mailto:${freight?.quote?.[isCarrier]?.contact_information?.email}?body=` +
                        encodeURIComponent(
                          `Hola me interesa tu carga con numero de referencia ${
                            freight?.quote?.id
                          } con origen ${fullNameCity(
                            freight?.origin_address?.city
                          )}, con destino a ${fullNameCity(
                            freight?.destination_address?.city
                          )}, de estar disponible me gustaría llega a un acuerdo. Me pongo a su disposición.`
                        )
                      }
                      className="btn btn-primary text-light btn-sm "
                    >
                      <MdEmail />{" "}
                      {freight?.quote?.[isCarrier]?.contact_information?.email}
                    </a>
                  )}
                  {freight?.quote?.[isCarrier]?.contact_information?.phone && (
                    <a
                      href={`tel:${freight?.quote?.[isCarrier]?.contact_information?.phone}`}
                      className="btn btn-primary text-light btn-sm "
                    >
                      <MdPhone />{" "}
                      {formatPhoneNumberIntl(
                        freight?.quote?.[isCarrier]?.contact_information?.phone
                      )}
                    </a>
                  )}
                </Col>
              </Row>
            </>
          )}
          {task?.request_for_attachment && (
            <>
              <hr />
              <DropFile
                name={"files"}
                register={register}
                setValue={setValue}
                watch={watch}
              />
              {task?.type === "boarding_request" && (
                <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: 20}}>
                  <Button
                    variant="info"
                    onClick={() => {
                      window.open(
                        `/boarding_requests/${task.id}/${task.freight_id}/`,
                        "_blank"
                      );
                    }}
                  >
                    Generar carta de instrucciones
                  </Button>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" disabled={loading}>
            {loading ? "Cargando..." : "Completar tarea"}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleClose();
              setValue("files", []);
              setFreight(null);
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalTask;
