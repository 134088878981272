import { useState, useContext, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import { useForm } from "react-hook-form";
import RenderSelectCompany from "../../../fyb_components/results/RenderSelectCompany";
import moment from "moment";

const AdminTrucks = () => {
  const [trucks, setTrucks] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const { companyId } = useContext(BodegasContext);

  const { control, handleSubmit, watch } = useForm();

  const onSubmit = (data) => {
    updateTruckByCompanyId(data.company.value);
  };

  const updateTruckByCompanyId = async (id) => {
    await fletesAPI
      .get(`api/companytruck/`, {
        params: {
          company_id: id,
        },
      })
      .then((e) => {
        setTrucks(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const focusCompany = watch("company");

  const getTrucks = async () => {
    await fletesAPI
      .get(`api/companytruck/`)
      .then((e) => {
        setTrucks(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteTruck = async (id) => {
    await fletesAPI
      .delete(`api/companytruck/${id}/`)
      .then((e) => {
        getTrucks();
        toast.success("Camion eliminado");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadTrucks = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setTrucks(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (focusCompany === undefined || focusCompany === null) {
      getTrucks();
    }
  }, [focusCompany]);

  useEffect(() => {
    if (!trucks) {
      getTrucks();
    }
  }, [trucks]);
  return (
    <>
      <Toaster />
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todos Los Camiones</h4>
            <div className="d-flex">
              {/* <Button
                className="text-white mx-2"
                onClick={() => setModalAdd(true)}
              >
                Crear Camion
              </Button> */}
              <Form
                className="d-flex"
                style={{ width: "400px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <RenderSelectCompany
                  name={"company"}
                  control={control}
                  defaultValue={null}
                  placeholder={"Buscar por empresa"}
                />
                <Button type="submit" className="text-white">
                  Buscar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Modelo</th>
              <th>Licencia</th>
              <th>Marca</th>
              <th>Eliminado</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {trucks &&
              trucks.results.map((e, i) => (
                <tr key={i}>
                  <td>{e.model}</td>
                  <td>{e.license_plate}</td>
                  <td>{e.brand}</td>
                  <td>{e.deleted ? moment(e.deleted).format("YYYY-MM-DD") : ''}</td>
                  <td>
                    <RenderButtonMore
                      buttons={[
                        {
                          label: (
                            <>
                              Editar <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/admintrucks/${e.id}/edit`;
                          },
                        },
                        {
                          label: (
                            <>
                              Eliminar <MdDelete />
                            </>
                          ),
                          onClick: () => {
                            setModalDelete(true);
                            setDataDelete(e);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {trucks && (
          <Pagination>
            <Pagination.Prev
              disabled={!trucks.previous}
              onClick={() => reloadTrucks(trucks.previous)}
            />
            <Pagination.Next
              disabled={!trucks.next}
              onClick={() => reloadTrucks(trucks.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteTruck(dataDelete.id)}
        confirmText={`¿Estas seguro de eliminar este camion ${
          dataDelete ? dataDelete.model : ""
        }?`}
      />
    </>
  );
};

export default AdminTrucks;
