import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast, { Toaster } from "react-hot-toast";
import { addNewExpenseAttachment } from "../../../utils/formsValidation";
import { erroresCodes } from "../../../utils";
import RenderDropFile from "../RenderDropFile";
import useFetch from "../../../hooks/useFetch";

const RenderModalAddExpensesAttachments = ({
  state,
  setState,
  expense,
  id,
  getMore
}) => {
  const { fletesAPIFile } = useFetch();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(addNewExpenseAttachment),
  });

  const onSubmit = (e) => {
    addExpensesAttachment(e);
  };

  const addExpensesAttachment = async (data) => {
    let formData = new FormData();
    formData.append("file_name", data.file_name);
    formData.append("type", data.type);
    formData.append("expense_id", expense.id);
    formData.append("file_content", data.file_content[0]);
    await fletesAPIFile
      .post(`api/freightpost/${id}/expense/attachment/`, formData)
      .then((e) => {
        toast.success("Se ha guardado el archivo correctamente");
        getMore();
        setState(false);
        reset();
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error("Ha ocurrido un error");
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };

  return (
    <>
      <Toaster />
      <Modal show={state} onHide={() => {setState(false); reset()}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Adjuntar archivos</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group controlId="file_name" className="mb-4">
                  <Form.Label> Nombre del archivo </Form.Label>
                  <Form.Control
                    {...register("file_name")}
                    isInvalid={errors.file_name}
                    type="text"
                    placeholder="Nombre del archivo"
                  />
                  <Form.Text className="text-danger position-absolute">
                    {errors.file_name && errors.file_name.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="type" className="mb-4">
                  <Form.Label>Tipo de archivo</Form.Label>
                  <Form.Control
                    {...register("type")}
                    isInvalid={errors.type}
                    type="text"
                    placeholder="Tipo de archivo"
                  />
                  <Form.Text className="text-danger position-absolute">
                    {errors.type && errors.type.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <RenderDropFile
                  register={register}
                  name="file_content"
                  watch={watch}
                  setValue={setValue}
                />
                <Form.Text className="text-danger">
                  {errors.file_content && errors.file_content.message}
                </Form.Text>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="success">
              Agregar
            </Button>
            <Button variant="danger" onClick={() => {setState(false); reset()}}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RenderModalAddExpensesAttachments;
