import { useRef, useState, useEffect } from "react";
import { Button, Container, Row, Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import { fullNameCity } from "../../utils";
import { formatPhoneNumber } from "react-phone-number-input";
import logoInit from "../../assets/icons/logo.svg";
import useFetch from "../../hooks/useFetch";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import toast from "react-hot-toast";

const FinishBoardingRequest = ({ task, taskId }) => {
  const [logo, setLogo] = useState(logoInit);
  const { order, invoice } = task;
  const { fletesAPI, fletesAPIFile } = useFetch();
  const { quote } = order;

  const getLogo = async () => {
    try {
      const response = await fletesAPI.get(
        `api/company/${order?.quote?.company?.id}/boarding/generator_top_logo/`
      );
      setLogo(response.data.url);
    } catch (e) {
      console.log(e);
    }
  };

  const cardRef = useRef(null);

  const addComment = async (comment) => {
    try {
      fletesAPI.post(`api/freight/${order.id}/comment/`, { comment });
    } catch (error) {
      throw Error(error);
    }
  };

  const completeTask = async () => {
    try {
      await fletesAPI.patch(`api/freight/${order.id}/task/complete/`, {
        status: 3,
        comment_submitter: "",
        task_id: taskId,
      });
      window.location.replace(`/dashboard/my_order/${order.id}/tasks`);
    } catch (error) {
      throw Error(error);
    }
  };

  const handleDownload = async () => {
    try {
      const element = cardRef.current;
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg");

      const pdf = new jsPDF("p", "pt", "a4");
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );

      const pdfBlob = pdf.output("blob");
      let formData = new FormData();

      formData.append("file_name", `Carta_instrucciones_${order?.reference || "Sin_nombre"}.pdf`);
      formData.append("type", "image/png");
      formData.append("file_content", pdfBlob);

      const promise = await fletesAPIFile.post(`api/freight/${order.id}/attachment/`, formData);
      console.log(promise);

      const newComment = `Se ha adjuntado una carta de instrucciones: ${promise.data.url}`;
      await addComment(newComment);
      pdf.save(`Carta de instrucciones - ${order?.reference || "Sin nombre"}.pdf`);
      completeTask();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center gap-4">
          <div className="w-100 d-flex justify-content-end" style={{ maxWidth: "8.5in" }}>
            <Button
              onClick={() => {
                toast.promise(handleDownload(), {
                  loading: "Generando PDF...",
                  success: "PDF generado con éxito",
                  error: "Error al generar PDF",
                });
              }}
              className="text-white"
            >
              Descargar PDF y Finalizar tarea
            </Button>
          </div>
          <Card ref={cardRef} style={{ width: "8.5in", height: "11in" }}>
            <Card.Body className="gap-2 d-flex flex-column">
              <div
                className="d-flex justify-content-between align-items-center mb-4 p-2"
                style={{ borderRadius: 5 }}
              >
                <img src={logo} alt="logo" style={{ width: "150px", height: "auto" }} />
                <div className="text-right d-flex flex-column justify-content-end align-items-end">
                  <p className="m-0 fw-bolder">Carta de instrucciones No:</p>
                  <p className="m-0">{order.reference || "Nombre de la Compañía"}</p>
                </div>
              </div>
              <ListGroup>
                <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                  <div className="header-card_section flex justify-content-center">
                    <p>
                      <b>Transportista</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Compañía:</b> {quote?.company?.name || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Nombre:</b> {quote?.company?.contact_information?.name || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Correo:</b> {quote?.company?.contact_information?.email || ""}
                    </p>
                    <p className="m-0">
                      <b>Teléfono:</b>{" "}
                      {formatPhoneNumber(quote?.company?.contact_information?.phone) || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Dirección:</b> {quote?.company?.contact_information?.address_line1 || ""}
                    </p>
                    <p className="m-0">
                      <b>Código postal:</b> {quote?.company?.contact_information?.zip_code || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Camión:</b> {quote?.bed_type?.name || ""}
                    </p>
                    <p className="m-0">
                      <b>Placas:</b> {quote?.truck?.license_plate || "N/A"}
                    </p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <div className="d-flex ">
                <ListGroup>
                  <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                    <div className="header-card_section flex justify-content-center">
                      <p>
                        <b>Origen</b>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Origen:</b> {fullNameCity(quote?.origin_city) || ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Dirección:</b>{" "}
                    {`${order?.origin_address?.address_line1} | ${order?.origin_address?.address_line2} | ${order?.origin_address?.zip_code}` ||
                      ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Fecha:</b> {moment.utc(quote?.pickup_time).format("YYYY-MM-DD HH:mm") || ""}
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup>
                  <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                    <div className="header-card_section flex justify-content-center">
                      <p>
                        <b>Destino</b>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Destino:</b> {fullNameCity(quote?.destination_city) || ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Dirección:</b>{" "}
                    {`${order?.destination_address?.address_line1} | ${order?.destination_address?.address_line2} | ${order?.destination_address?.zip_code}` ||
                      ""}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Fecha:</b>{" "}
                    {moment.utc(quote?.delivery_time).format("YYYY-MM-DD HH:mm") || ""}
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <ListGroup>
                <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                  <div className="header-card_section flex justify-content-center">
                    <p>
                      <b>Detalles del Flete</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between w-100">
                    <p className="m-0">
                      <b>Tarimas:</b> {quote?.pallets || ""}
                    </p>
                    <p className="m-0">
                      <b>Peso:</b> {quote?.weight || ""}
                      {" kg"}
                    </p>
                    <p className="m-0">
                      <b>Carga:</b> {quote?.cargo || ""}
                    </p>
                    <p className="m-0">
                      <b>Precio:</b> ${quote?.total || "Error"} {quote?.currency || "Error"}
                    </p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                <ListGroup.Item style={{ background: "#0f2840", color: "white" }}>
                  <div className="header-card_section flex justify-content-center">
                    <p>
                      <b>Información de Factura</b>
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Nombre legal:</b> {invoice?.legal_name || ""}
                    </p>
                    <p className="m-0">
                      <b>RFC:</b> {invoice?.rfc_code || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Correo:</b> {invoice?.address?.email || ""}
                    </p>
                    <p className="m-0">
                      <b>Teléfono:</b> {invoice?.address?.phone || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    <p className="m-0">
                      <b>Dirección:</b>{" "}
                      {invoice
                        ? `${invoice?.address?.address_line1} ${invoice?.address?.address_line2}`
                        : ""}
                    </p>
                    <p className="m-0">
                      <b>Código Postal:</b> {invoice?.address?.zip_code || ""}
                    </p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Ciudad:</b> {fullNameCity(invoice?.address?.city) || ""}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default FinishBoardingRequest;
