import {Col} from "react-bootstrap";
import RenderDistanceContent from "./RenderDistanceContent";

const RenderDistance = ({distance, isMobile}) => {
    return (
    <>
        {isMobile ?
        <>
            {distance &&
            <>
                <Col xl={2} lg={2} md={6} sm={6}>
                    <p><RenderDistanceContent distance={distance} /></p>
                </Col>
            </>
            }
        </>
        :
        <>
            <td><RenderDistanceContent distance={distance} /></td>
        </>
        }
    </>
    )

};

export default RenderDistance;