import { Col, Badge } from "react-bootstrap";
import { typeUser, textUser } from "../../utils";

const RenderUserCompanyRole = ({isMobile, role}) => {
    return (
    <>
        {isMobile ?
        <>
            <Col xl={3} lg={3} md={6} sm={6}>
                <Badge bg={typeUser(role)}>{textUser(role)}</Badge>
            </Col>
        </>
        :
        <>
        <td>
            <Badge bg={typeUser(role)}>{textUser(role)}</Badge>
        </td>
        </>
        }
    </>
    );
};

export default RenderUserCompanyRole;