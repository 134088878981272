import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Plans from "../components/Pricing/Plans";
import FybNavigationBar from "../components/FybNavigationBar";

export default function Pricing() {
  return (
    <>
      <FybNavigationBar />
      <Plans
        title="Paquetes"
        subtitle="Sin sorpresas, a mensualidades y a tu medida"
        plans={[
        {
            popular: false,
            yearlyPrice: 0,
            monthlyPrice: 0,
            title: "Para Conocer",
            text: "Ideal para conocer el producto",
            service: {
              s1: true,
              s2: false,
              s3: false,
              s4: false,
              s5: false,
            },
          },
          {
            popular: false,
            yearlyPrice: 2000,
            monthlyPrice: 200,
            title: "Para Empezar",
            text: "Ideal para pequeñas empresas (1-2 empleados)",
            service: {
              s1: true,
              s2: true,
              s3: false,
              s4: false,
              s5: false,
            },
          },
          {
            popular: false,
            yearlyPrice: 3000,
            monthlyPrice: 300,
            title: "Para Empezar",
            text: "Ideal para pequeñas empresas (1-5 empleados)",
            service: {
              s1: true,
              s2: true,
              s3: false,
              s4: false,
              s5: false,
            },
          },
          {
            popular: true,
            yearlyPrice: 5000,
            monthlyPrice: 500,
            title: "Profesional",
            text: "Ideal para medianas empresas",
            service: {
              s1: true,
              s2: true,
              s3: true,
              s4: true,
              s5: false,
            },
          },
          {
            popular: false,
            yearlyPrice: 7000,
            monthlyPrice: 700,
            title: "Empresarial",
            text: "Para empresas de logística con almenos 30 empleados",
            service: {
              s1: true,
              s2: true,
              s3: true,
              s4: true,
              s5: true,
            },
          },
        ]}
      />
      <Footer />
    </>
  );
}
